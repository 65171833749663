import apiEndpoints from 'const/apiEndpoints'
import getCorrelationId from 'helpers/getCorrelationId'
import api, { generateTransId } from 'services'
import { LookupMultiAccountResponse } from 'services/typings'

const lookupMultiAccount = async (): Promise<LookupMultiAccountResponse> => {
  try {
    const {
      data: { accounts, sessionId }
    } = await api.get(apiEndpoints.dsecurity.MULTI_ACCOUNT, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('madAccessToken')}`,
        'X-SYF-Request-TrackingId': generateTransId(),
        'X-SYF-Request-CorrelationId': getCorrelationId()
      }
    })
    sessionStorage.setItem('madSessionId', sessionId)
    return accounts
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    return error
  }
}

export default lookupMultiAccount
