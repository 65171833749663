import React from 'react'
import query from 'syf-component-library/const/mediaQueries'
import NonIdpInlineError from 'ui/atoms/InlineError/NonIdP'
import styled from 'styled-components'
import IdpInlineError from 'ui/atoms/InlineError/IdP'
import LookupFormContainerProps from './typings'

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const LookupFormContainer = styled(
  ({ subheader, error, onSubmit, children }: LookupFormContainerProps) => {
    return (
      <>
        {subheader || ''}
        {error?.key && <NonIdpInlineError centerAlign error={error} />}
        <IdpInlineError centerAlign />
        <Form onSubmit={onSubmit} noValidate>
          {children}
        </Form>
      </>
    )
  }
)`
  width: 100%;
  margin: 0 auto;
  text-align: center;
  ${p => p.error && 'padding-top: 30px;'}

  @media ${query.greaterThanSmall} {
    width: 325px;
  }
`

export default LookupFormContainer
