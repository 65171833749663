import { clientInfo } from 'configureBrand'
import {
  handleRedirectRoute,
  history,
  isFourHundredError,
  triggerAnalyticsEvent
} from 'helpers'
import routes from 'const/routes'
import { RedirectOptions } from 'helpers/typings'

const handleRedirectToErrorScreen = ({ errorCode }: RedirectOptions): void => {
  const { phoneNumbers } = clientInfo
  const { customerService } = phoneNumbers

  if (isFourHundredError(errorCode)) {
    const redirectPath = handleRedirectRoute()
    history.push({
      pathname: routes.ERROR,
      search: history.location.search,
      state: {
        returnTo: redirectPath,
        customerService,
        errorCode
      }
    })
  } else {
    triggerAnalyticsEvent({ messageKey: 'default' })
  }
}

export default handleRedirectToErrorScreen
