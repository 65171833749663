import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import { Router } from 'react-router-dom'
import React, { ReactElement, useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import {
  determineCplRoute,
  getCurrentLanguageFromStorage,
  getParameterByName,
  history,
  triggerAnalyticsEvent
} from 'helpers'
import Maintenance from 'pages/Maintenance'
import ScrollToTop from 'pages/App/ScrollToTop'
import Header from 'ui/organisms/Header'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import { clientInfo, palette } from 'configureBrand'
import routes from 'const/routes'
import { resetIdpError } from 'redux/idp/actions'
import Footer from 'ui/organisms/Footer'
import { LanguageType } from 'helpers/translate'
import { resetOtpState } from 'redux/otp/actions'
import { clearRegistrationState } from 'redux/registration/actions'
import { checkForQuickPay, resetQuickpayToken } from 'redux/quickPay/actions'
import {
  checkForAkamaiConfig,
  checkForMaintenance
} from 'redux/akamaiConfig/actions'
import { checkForJwtIntrospect, handleVistaRedirect } from 'redux/jwt/actions'
import { handleLanguageToggle } from 'pages/App/helpers'
import JwtLoader from 'ui/atoms/JwtLoader'
import config, { checkForIdpRoute } from 'const/config'
import redirectToUrl from 'syf-js-utilities/helpers/redirectToUrl'
import useIvaClient from 'hooks/useIvaClient'
import { AppContainer, Container, GlobalStyle } from './subcomponents'
import Routes from './Routes'

const App = (): ReactElement => {
  const dispatch = useDispatch()
  const {
    akamaiConfig: {
      maintenanceData: { isUnderMaintenance }
    },
    jwt: { isJwtLoading }
  } = useSelector((state: RootState) => state)
  const token = getParameterByName('token')
  const isCplRoute = determineCplRoute()
  const [currentLanguage, setCurrentLanguage] = useState<LanguageType>(
    getCurrentLanguageFromStorage()
  )
  const [pageEventFired, setPageEventFired] = useState(false)
  useIvaClient({ currentLanguage })

  const handleHistoryListen = () => {
    const {
      location: { pathname }
    } = history

    dispatch(resetIdpError())
    dispatch(resetOtpState())
    dispatch(clearRegistrationState())
    if (pathname !== routes.HOME) {
      if (pathname === routes.ACCOUNTS) {
        dispatch(handleVistaRedirect())
      } else {
        dispatch(resetQuickpayToken())
      }
      triggerAnalyticsEvent({ pathName: pathname })
    }
  }

  const initiateApi = () => {
    if (token) {
      dispatch(checkForJwtIntrospect())
    }
    dispatch(checkForAkamaiConfig())
    dispatch(checkForMaintenance())
  }

  const checkTokenToRedirect = () => {
    const { href } = window.location
    const isIdpRoute = checkForIdpRoute(href)
    if (isIdpRoute && !token) {
      redirectToUrl(
        `${config.VISTA_DOMAIN}/accounts/?client=${config.RC_CLIENT_ID}`
      )
    }
  }

  const triggerPageEvent = () => {
    const {
      location: { pathname }
    } = history

    if (pathname !== routes.HOME) {
      triggerAnalyticsEvent({
        pathName: history.location.pathname
      })
    }
  }

  useEffect(() => {
    const { utagLoaded } = window

    const firePageEvent = () => {
      if (!pageEventFired) {
        setPageEventFired(true)
        triggerPageEvent()
      }
      window.removeEventListener('utagLoaded', firePageEvent)
    }

    if (utagLoaded) {
      firePageEvent()
    } else {
      window.addEventListener('utagLoaded', firePageEvent)
    }

    checkTokenToRedirect()
    initiateApi()

    const unBindHistoryListener = history.listen(() => {
      handleHistoryListen()
    })

    return () => {
      unBindHistoryListener()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderPages = isUnderMaintenance ? <Maintenance /> : <Routes />

  return (
    <ThemeProvider theme={palette}>
      <GlobalStyle />
      <HelmetProvider>
        <Helmet htmlAttributes={{ lang: currentLanguage }} />
        <AppContainer>
          <Router history={history}>
            {isJwtLoading && token ? (
              <JwtLoader data-test="jwt-loader" />
            ) : (
              <ScrollToTop>
                <Header
                  onChangeLanguage={() => {
                    const newLanguage = handleLanguageToggle(currentLanguage)
                    setCurrentLanguage(newLanguage)
                    dispatch(checkForQuickPay())
                  }}
                  languages={clientInfo.languages}
                  currentLanguage={currentLanguage}
                  isCplRoute={isCplRoute}
                  data-reason="header"
                  data-test="header"
                />
                <Container>{renderPages}</Container>
                <Footer
                  currentLanguage={currentLanguage}
                  isCplRoute={isCplRoute}
                />
              </ScrollToTop>
            )}
          </Router>
        </AppContainer>
      </HelmetProvider>
    </ThemeProvider>
  )
}

export default App
