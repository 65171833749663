import { isAfter, isBefore } from 'date-fns'

const determineIsUnderMaintenance = (
  maintenanceStartTime: string,
  maintenanceEndTime: string
): boolean =>
  isAfter(new Date(), new Date(maintenanceStartTime)) &&
  isBefore(new Date(), new Date(maintenanceEndTime))

export default determineIsUnderMaintenance
