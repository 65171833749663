import React, { ReactElement, SyntheticEvent } from 'react'
import {
  formatQueryParams,
  getParameterByName,
  redirectToClientUrl,
  translate,
  triggerAnalyticsEvent
} from 'helpers'
import {
  BackButton,
  Container,
  ErrorBody,
  Heading,
  SubContent
} from './subcomponents'
import determinePersistResult from '../helpers/determinePersistResult'

interface Props {
  errorContext: {
    errorNum: number
    errDetail?: string
  }
}

const PersistError = ({ errorContext }: Props): ReactElement => {
  const { errorNum, errDetail } = errorContext
  const qsParams = window.location.search
  const oauthRedirectUri = decodeURIComponent(
    getParameterByName('redirect_uri', qsParams)
  )
  const {
    errMsg,
    svcNum,
    clientError,
    clientErrorDescription,
    analyticsSubFunction
  } = determinePersistResult(errorNum)

  const handleBack = (e: SyntheticEvent): void => {
    e.preventDefault()
    const errRedirectUri = formatQueryParams(
      oauthRedirectUri,
      `error=${clientError}&error_description=${clientErrorDescription}`
    )
    redirectToClientUrl(errRedirectUri)
  }

  const ErrorWithFindUserLink = () => {
    triggerAnalyticsEvent({
      messageKey:
        'Error: It appears you recently updated your User ID. Please use your new User ID.'
    })
    return (
      <SubContent>{translate({ string: 'recentlyUpdatedUserId' })}</SubContent>
    )
  }

  const ErrorWithFindMessage = () => {
    triggerAnalyticsEvent({
      messageKey: `Error: ${translate({
        string: errMsg.key,
        argument: errMsg?.argument
      })}`,
      subFunction: analyticsSubFunction,
      pageFunction: 'login_persistent'
    })

    return (
      <SubContent>
        {errorNum === 500
          ? `${translate({
              string: errMsg.key,
              argument: errMsg?.argument
            })} (${errDetail})`
          : translate({ string: errMsg.key, argument: errMsg?.argument })}
      </SubContent>
    )
  }

  return (
    <Container>
      <ErrorBody>
        <Heading weight="bold">{translate({ string: 'weAreSorry' })}</Heading>
        {errorNum === 1009 || errorNum === 1007 ? (
          <ErrorWithFindUserLink data-reason="error-with-find-user-link" />
        ) : (
          <ErrorWithFindMessage data-reason="error-message" />
        )}
        {!errMsg?.argument && svcNum && (
          <SubContent>
            Please contact Customer Service at {svcNum} for assistance
          </SubContent>
        )}
        <BackButton
          buttonType="primary"
          onClick={handleBack}
          data-reason="login-not-permitted"
          data-type="back"
          data-object="button"
          className="ensightencustomevent"
        >
          Back
        </BackButton>
      </ErrorBody>
    </Container>
  )
}

export default PersistError
