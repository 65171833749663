import routes from 'const/routes'
import { history } from 'helpers'

const isAccountLocked = (responseCode: string): boolean => {
  return (
    responseCode === '1002' ||
    responseCode === '1003' ||
    responseCode === '1004' ||
    responseCode === '5003'
  )
}

const isAlreadyRegistered = (responseCode: string): boolean =>
  responseCode === '1009'

export const handleRedirectError = (responseCode: string): void => {
  if (isAccountLocked(responseCode)) {
    history.push({ pathname: routes.LOCKED_ACCOUNT, state: { responseCode } })
  }

  if (isAlreadyRegistered(responseCode)) {
    history.push({
      pathname: routes.ALREADY_REGISTERED
    })
  }
}

export default handleRedirectError
