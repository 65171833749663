import styled from 'styled-components'
import { colors } from 'theme'
import Button from 'syf-component-library/ui/atoms/Button'

interface FlexButtonContainerProps {
  centerAlign?: boolean
  pt?: string
}

export const PrimaryButtonBase = styled(Button)`
  width: 290px;
  margin-bottom: 0px;
`

export const GhostButtonBase = styled(Button)`
  color: ${colors.blue};
  width: 290px;
  margin-top: 0px;
`

export const TextButtonBase = styled(Button)`
  color: ${p => p.theme.link};
  height: inherit;
  margin: 0px;
  padding: 0px;
  display: inline;
  font-size: inherit;
  font-family: inherit;
  font-weight: normal;
`

// Container to wrap Buttons and Links
export const FlexButtonContainer = styled.div<FlexButtonContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${p => p.pt || '40px'};
  ${p => p.centerAlign && 'margin: auto; text-align: center;'};
`
FlexButtonContainer.displayName = 'FlexButtonContainer'
