import Toast from 'syf-component-library/ui/patterns/Toast'
import styled from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'

export const Error = styled(Toast)`
  justify-content: flex-start;
  line-height: 1.5;
  padding: 16px 32px;

  & > button {
    display: none;
  }

  @media ${query.lessThanMedium} {
    margin: 0 auto 15px;
  }
`

export const ErrorWrapper = styled.div`
  max-width: 730px;
  margin: 0px auto 20px;
  align-self: center;
`
