import { RegisterAction, RegisterState } from 'redux/registration/typings'
import config from 'const/config'

export const initialState: RegisterState = {
  validationModal: '',
  error: null,
  currentStep: 'lookup',
  ebillStatus: false,
  accessToken: '',
  isEbillFailure: false,
  isInUsa: true,
  accountType: '',
  cipherAccountId: '',
  clientId: config.RC_CLIENT_ID,
  userId: '',
  transmitId: '',
  isExpiredTransmitId: false,
  searchUserResponse: null,
  result: null,
  isUniqueUserId: true,
  registerForm: {
    registrationUID: '',
    email: ''
  },
  formErrors: {
    newPassword: {
      required: 'pristine',
      length: 'pristine',
      hasNumber: 'pristine',
      hasLetter: 'pristine',
      noWhiteSpace: 'pristine',
      specialChar: 'pristine'
    },
    registrationUID: '',
    email: ''
  }
}

export default (
  state: RegisterState = initialState,
  action: RegisterAction = undefined
): RegisterState => {
  switch (action.type) {
    case 'PROFILE_CREATE_FAILURE':
    case 'SEARCH_USER_FAILURE':
      return { ...state, error: action.payload }
    case 'CLEAR_ERROR_STATE':
      return { ...state, error: null }
    case 'SEARCH_USER_SUCCESS':
    case 'PROFILE_CREATE_SUCCESS':
    case 'UPDATE_VERIFY_STATE':
    case 'UPDATE_REGISTER_FORM':
    case 'USER_ID_VALIDATION_FAILED':
      return { ...state, ...action.payload }
    case 'CLEAR_VERIFY_USER_RESPONSE':
      return {
        ...state,
        accessToken: initialState.accessToken,
        ebillStatus: initialState.ebillStatus,
        result: initialState.result,
        isUniqueUserId: initialState.isUniqueUserId,
        registerForm: initialState.registerForm,
        formErrors: initialState.formErrors
      }
    case 'UPDATE_IN_USA':
      return { ...state, isInUsa: action.payload }
    case 'UPDATE_EXPIRED_TRANSMIT_ID':
      return { ...state, isExpiredTransmitId: action.payload, transmitId: '' }
    case 'UPDATE_EXPEDITED_REGISTRATION_TRANSMIT_ID':
      return { ...state, transmitId: action.payload }
    case 'UPDATE_IS_UNIQUE_USERID':
      return { ...state, isUniqueUserId: action.payload }
    case 'UPDATE_REGISTER_FORM_ERRORS':
      return { ...state, formErrors: action.payload }
    case 'UPDATE_CURRENT_STEP':
      return { ...state, currentStep: action.payload }
    case 'CLEAR_REGISTRATION_STATE':
      return initialState
    default:
      return state
  }
}
