const errorRoutes = {
  ERROR: '/error',
  CPL_ERROR: '/cpl-error',
  JWT_ERROR: '/jwt-error',
  LOCKED_ACCOUNT: '/locked-account',
  POST_REGISTRATION_ERROR: '/post-registration-error',
  ACCOUNT_LOCKED_ERROR: '/account-locked-error',
  SOFTENER_ERROR: '/softener-error',
  SESSION_TIMEOUT_ERROR: '/session-timeout-error',
  ALREADY_REGISTERED: '/already-registered'
}

const routes = Object.freeze({
  ACCOUNTS: '/accounts/redirect',
  ALEXA: '/alexa',
  CALLBACK: '/callback',
  CPL: '/cpl',
  FIND_USER_ID: '/findUserId',
  FOUND: '/findUserId/found',
  GOOGLE: '/google',
  HOME: '/',
  IDP_HOME: '/accounts/login',
  MULTI_ACCOUNT: '/select-account',
  PERSIST: '/persist',
  REGISTER: '/register',
  RESET: '/reset',
  ...errorRoutes
})

export default routes
