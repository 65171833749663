import React, { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { translate } from 'helpers'
import { FlexButtonContainer, PrimaryButton } from 'ui/atoms/Button'
import { RootState } from 'redux/store'
import { updateIsSubmitting } from 'redux/uiUtils/actions'
import handleVistaRedirect from 'helpers/handleVistaRedirect'
import { IdpFormPostRelType } from 'const/idp'
import { getIdpComplete } from 'redux/idp/actions'
import { Base, Header, Row } from './subcomponents'

const IdpLostStolen = ({ dataType }: { dataType: string }): ReactElement => {
  const {
    idp: { currentLastFour, previousLastFour, href, rel, name },
    uiUtils: { isSubmitting }
  } = useSelector((state: RootState) => state)

  const dispatch = useDispatch()

  const renderLostStolen = !!currentLastFour && !!previousLastFour && !!href

  const handleContinueClick = (): void => {
    dispatch(updateIsSubmitting(true))
    if (name === IdpFormPostRelType.COMPLETE) {
      dispatch(getIdpComplete())
    } else {
      handleVistaRedirect({ href, rel })
    }
  }

  return renderLostStolen ? (
    <Base data-test="idp-lost-stolen">
      <Header
        title={translate({ string: 'lostStolenSubtitle' })}
        description={translate({
          string: 'lostStolenDescription',
          argument: previousLastFour
        })}
      />

      <ul>
        <Row>{translate({ string: 'paperlessStatements' })}</Row>
        <Row>{translate({ string: 'autopay' })}</Row>
        <Row>{translate({ string: 'scheduledPayments' })}</Row>
      </ul>
      <p>
        {translate({
          string: 'lostStolenSoonToReceive',
          argument: currentLastFour
        })}
      </p>
      <FlexButtonContainer style={{ justifyContent: 'center' }}>
        <PrimaryButton
          className="ensightencustomevent"
          data-reason="continue"
          buttonType="primary"
          data-test="continue"
          data-type={dataType}
          data-object="button"
          disabled={isSubmitting}
          onClick={handleContinueClick}
        >
          {translate({ string: 'continue' })}
        </PrimaryButton>
      </FlexButtonContainer>
    </Base>
  ) : null
}

export default IdpLostStolen
