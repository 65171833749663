import { clientInfo } from 'configureBrand'
import translate, { LanguageType } from 'helpers/translate'
import config from 'const/config'
import { FooterLink } from './typings'

const {
  onlinePrivacy,
  privacyPolicy,
  websiteUsage,
  footerDisplay,
  footerLink,
  isMultiAccount
} = clientInfo
const { TARGET_ORIGIN } = config

export const formatLink = (link: string): string => {
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$',
    'i'
  )
  return urlPattern.test(link) ? link : `${TARGET_ORIGIN}${link}`
}

const formatFooterLinks = (currentLanguage: LanguageType): FooterLink[] => {
  const privacyPolicyLink = privacyPolicy[currentLanguage]
  const onlinePrivacyLink = formatLink(onlinePrivacy[currentLanguage])
  const websiteUsageLink = formatLink(websiteUsage[currentLanguage])
  const links = [
    {
      text: `\u00A9 ${new Date().getFullYear()} Synchrony Bank`,
      url: 'https://www.synchrony.com/',
      id: 'synchrony-bank'
    },
    onlinePrivacyLink && {
      text: translate({ string: 'onlinePrivacyPolicy' }),
      url: onlinePrivacyLink,
      id: 'online-privacy-policy'
    },
    !isMultiAccount &&
      privacyPolicyLink && {
        text: translate({ string: 'privacyPolicy' }),
        url: `${TARGET_ORIGIN}${privacyPolicyLink}`,
        id: 'privacy-policy'
      },
    websiteUsageLink && {
      text: translate({ string: 'websiteUsageAgreement' }),
      url: websiteUsageLink,
      id: 'website-usage-agreement'
    },
    {
      text: translate({ string: 'accessibilityLinkText' }),
      url: `https://www.synchrony.com/${translate({
        string: 'accessibilityLinkPath'
      })}`,
      id: 'accessibility'
    },
    {
      text: translate({ string: 'fraudProtection' }),
      url: `https://www.synchrony.com/legal/${translate({
        string: 'fraudProtectionLinkPath'
      })}`,
      id: 'fraud-protection'
    },
    footerLink && {
      text: footerDisplay,
      url: footerLink
    }
  ]

  return links.filter(link => link)
}

export default formatFooterLinks
