import { useDispatch, useSelector } from 'react-redux'
import { updateIvaIsLive } from 'redux/iva/actions'
import { clientInfo } from 'configureBrand'
import launchPointNames from 'const/ivaLaunchPointNames'
import determineCplRoute from 'helpers/determineCplRoute'
import ivaLaunchParams, { launchParams } from 'const/ivaLaunchParams'
import { useEffect } from 'react'
import { RootState } from 'redux/store'
import { LanguageType } from 'helpers/translate'
import { initialize, launch } from 'services/ivaClient'
import { IvaLaunchOptions } from '../pages/App/typings'

interface IvaClientProps {
  currentLanguage: LanguageType
}

const useIvaClient = ({ currentLanguage }: IvaClientProps): (() => void) => {
  const {
    iva: { showChatCta, isLive, loginAttempts }
  } = useSelector((state: RootState) => state)

  const dispatch = useDispatch()
  const isCplRoute = determineCplRoute()

  const checkIvaState = (): void => {
    const ivaInitialized = initialize()
    if (ivaInitialized) {
      dispatch(updateIvaIsLive(ivaInitialized))
    }
  }

  const handleIvaLaunch = (options?: IvaLaunchOptions): void => {
    if (!isCplRoute && clientInfo.ivaKey && currentLanguage !== 'es') {
      const path = window.location.pathname
      if (options) {
        const { ivaLaunchParam, launchPointName } = options
        launch(launchPointName, ivaLaunchParam)
      } else {
        launch(
          launchPointNames.global,
          ivaLaunchParams[path as keyof typeof ivaLaunchParams]
        )
      }
    }
  }

  useEffect(() => {
    setTimeout(checkIvaState, 5000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (loginAttempts === 3 && isLive) {
      handleIvaLaunch({
        ivaLaunchParam: launchParams.pointOfNeed,
        launchPointName: launchPointNames.loginError
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginAttempts])

  useEffect(() => {
    if (showChatCta) {
      handleIvaLaunch({
        ivaLaunchParam: launchParams.pointOfNeed,
        launchPointName: launchPointNames.findUserId
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showChatCta])

  return null
}

export default useIvaClient
