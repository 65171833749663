import React, { ReactElement } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { clientInfo } from 'configureBrand'
import { colors } from 'theme'
import {
  FA_ARROW_ALT_RIGHT,
  FA_CHECK_CIRCLE,
  FA_CREDIT_CARD_BLANK,
  FA_USER_CIRCLE
} from 'const/iconProp'
import {
  ArrowWrapper,
  Article,
  ArticleWrapper,
  BannerContentWrapper,
  BannerWrapper,
  Break,
  BreakLine,
  Container,
  H2,
  IconWrapper,
  Img,
  ImgWrapper,
  TextWrapper,
  Title
} from './subcomponents'

const HeroBanner = (): ReactElement => {
  const { cplHeroBannerDisplayName } = clientInfo
  if (cplHeroBannerDisplayName) {
    return (
      <BannerWrapper data-test="cpl-banner">
        <Container>
          <Title>
            Save time and hassle, link your{' '}
            <Break>{clientInfo.vanityUrl} and Synchrony accounts</Break>
          </Title>

          <ArticleWrapper>
            <Article>
              <IconWrapper>
                <FontAwesomeIcon
                  icon={FA_CHECK_CIRCLE}
                  color={colors.black}
                  size="3x"
                />
              </IconWrapper>
              <TextWrapper>
                <H2>Link your {clientInfo.vanityUrl} and Synchrony accounts</H2>
              </TextWrapper>
            </Article>

            <Article>
              <ArrowWrapper>
                <FontAwesomeIcon
                  icon={FA_ARROW_ALT_RIGHT}
                  color={colors.black}
                  size="2x"
                />
              </ArrowWrapper>
            </Article>

            <Article>
              <IconWrapper>
                <FontAwesomeIcon
                  icon={FA_USER_CIRCLE}
                  color={colors.black}
                  size="3x"
                />
              </IconWrapper>
              <TextWrapper>
                <H2>Log into your {clientInfo.vanityUrl} account</H2>
              </TextWrapper>
            </Article>

            <Article>
              <ArrowWrapper>
                <FontAwesomeIcon
                  icon={FA_ARROW_ALT_RIGHT}
                  color={colors.black}
                  size="2x"
                />
              </ArrowWrapper>
            </Article>

            <Article>
              <IconWrapper>
                <FontAwesomeIcon
                  icon={FA_CREDIT_CARD_BLANK}
                  color={colors.black}
                  size="3x"
                />
              </IconWrapper>
              <TextWrapper>
                <H2>Click Manage at Synchrony Bank to access your account</H2>
              </TextWrapper>
            </Article>
          </ArticleWrapper>

          <BannerContentWrapper>
            <Title>Just enter your Synchrony User ID and Password</Title>
            <H2>
              This is what you use to sign into your{' '}
              {clientInfo.cplHeroBannerDisplayName} website.
              <BreakLine>
                This is NOT your {clientInfo.vanityUrl} email and password.
              </BreakLine>
            </H2>
          </BannerContentWrapper>
        </Container>
      </BannerWrapper>
    )
  }
  return (
    <ImgWrapper data-test="app-banner">
      <Img />
    </ImgWrapper>
  )
}

export default HeroBanner
