import { addBmTelemetryToHeaders, getCorrelationId, translate } from 'helpers'
import { clientInfo } from 'configureBrand'
import config from 'const/config'
import { AnyObject } from 'types/custom'
import apiEndpoints from 'const/apiEndpoints'
import { ProfileCreateOptions, ProfileRequest } from 'services/typings'
import api, { generateTransId } from 'services'

const profileCreate = async ({
  formValues,
  sessionId,
  accessToken
}: ProfileCreateOptions): Promise<AnyObject> => {
  const payload: ProfileRequest = {
    enrollment_info: {
      statement_indicator: formValues.ebillSelection,
      offers: formValues.sendEmailOffers
    },
    session_info: {
      session_id: sessionId
    },
    user_info: {
      email: formValues.email,
      'cipher.password': formValues.newPassword,
      user_id: formValues.registrationUID
    }
  }

  const reqHeaders = await addBmTelemetryToHeaders({
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'X-SYF-Request-TrackingId': generateTransId(),
      'X-SYF-Request-CorrelationId': getCorrelationId()
    },
    params: {
      client_id: config.DSEC_APIGEE_CLIENT_ID
    }
  })

  const { data } = await api.post(
    apiEndpoints.dsecurity.CREATE_PROFILE,
    payload,
    reqHeaders
  )

  switch (data.status.response_code) {
    case '6001':
      return {
        message: {
          key: 'unavailableUID',
          extension: `${translate({ string: 'code' })}: 6001`
        }
      }
    case '6002':
      return {
        message: {
          key: 'lockedStandard',
          argument:
            clientInfo.phoneNumbers[
              data.account_type as keyof typeof clientInfo.phoneNumbers
            ],
          extension: `${translate({ string: 'code' })}: 6002`
        }
      }
    case '6003':
      return {
        success: data.account_type,
        clientId: data.client_id,
        withEbillFailure: false,
        extension: `${translate({ string: 'code' })}: 6003`,
        authCode: data.authorization_code
      }
    case '6004':
      return {
        success: data.account_type,
        clientId: data.client_id,
        withEbillFailure: true,
        extension: `${translate({ string: 'code' })}: 6004`,
        authCode: data.authorization_code
      }
    default:
      return {
        message: { key: 'default' }
      }
  }
}

export default profileCreate
