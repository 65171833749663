const payAsGuest = Object.freeze({
  pageEvents: {
    NAME: 'start',
    PAGE_FUNCTION: 'login',
    PAGE_SUBFUNCTION: 'pay as guest'
  },
  clickEvents: {
    REASON: 'pay as guest',
    OBJECT: 'banner',
    TYPE: 'login'
  }
})

export default payAsGuest
