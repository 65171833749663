import { clientInfo } from 'configureBrand'
import { getCurrentLanguageFromStorage } from './translate'

interface Tag {
  title: string
  description: string
}

export interface TagInfo {
  en: Tag
  es: Tag
}
export interface MetaTags {
  login: TagInfo
  reset: TagInfo
  findUserId: TagInfo
  found: TagInfo
  register: TagInfo
}

const getMetaTags = (): MetaTags => {
  const language = getCurrentLanguageFromStorage()
  const { displayName, metaTagCardTitle, metaTagCardDescription } = clientInfo
  const title = metaTagCardTitle[language]
  const description = metaTagCardDescription[language]

  return {
    login: {
      en: {
        title: `Manage Your ${displayName} ${title} Account`,
        description
      },
      es: {
        title: `Maneje su cuenta de la ${title} ${displayName}`,
        description
      }
    },
    reset: {
      en: {
        title: `${displayName} ${title} - Password Reset`,
        description: `Log into your ${displayName} ${title.toLowerCase()} account online to pay your bills, check your FICO score, sign up for paperless billing, and manage your account preferences`
      },
      es: {
        title: `${displayName} ${title} - Reestablecer Contraseña`,
        description: `Ingrese a su cuenta de la ${title} ${displayName} en línea para pagar sus facturas, revisar su puntuación FICO, inscribirse en facturas digitales y manejar su cuenta.`
      }
    },
    findUserId: {
      en: {
        title: `${displayName} ${title} - Find Your User ID`,
        description: `Log into your ${displayName} ${title.toLowerCase()} account online to pay your bills, check your FICO score, sign up for paperless billing, and manage your account preferences`
      },
      es: {
        title: `${displayName} ${title} – Encuentre Su Identificación de Usuario`,
        description: `Ingrese a su cuenta de la ${title} ${displayName} en línea para pagar sus facturas, revisar su puntuación FICO, inscribirse en facturas digitales y manejar su cuenta.`
      }
    },
    found: {
      en: {
        title: `${displayName} ${title} - Find Your User ID`,
        description: `Log into your ${displayName} ${title.toLowerCase()} account online to pay your bills, check your FICO score, sign up for paperless billing, and manage your account preferences`
      },
      es: {
        title: `${displayName} ${title} – Encuentre Su Identificación de Usuario`,
        description: `Ingrese a su cuenta de la ${title} ${displayName} en línea para pagar sus facturas, revisar su puntuación FICO, inscribirse en facturas digitales y manejar su cuenta.`
      }
    },
    register: {
      en: {
        title: `Register Your ${displayName} ${title} Account`,
        description: `Log into your ${displayName} ${title.toLowerCase()} account online to pay your bills, check your FICO score, sign up for paperless billing, and manage your account preferences`
      },
      es: {
        title: `Registre su Cuenta de ${title} ${displayName}`,
        description: `Ingrese a su cuenta de la ${title} ${displayName} en línea para pagar sus facturas, revisar su puntuación FICO, inscribirse en facturas digitales y manejar su cuenta.`
      }
    }
  }
}

export default getMetaTags
