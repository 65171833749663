import React, { ReactElement, SyntheticEvent } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import Header from 'ui/atoms/Header'
import {
  FlexButtonContainer,
  GhostButton,
  PrimaryButton
} from 'ui/atoms/Button'
import { history, translate } from 'helpers'
import routes from 'const/routes'
import { FA_EXCLAMATION_CIRCLE, FA_LOCK } from 'const/iconProp'
import { RootState } from 'redux/store'
import InlineError from 'ui/atoms/InlineError/IdP'
import {
  handleCancel,
  handleIdpVerify,
  handleValidate,
  updateSSN
} from 'redux/ssn/actions'
import { resetIdpError } from 'redux/idp/actions'
import { Form, TextfieldWithMargin } from '../../subcomponents'

const IdpSSN = (): ReactElement => {
  const dispatch = useDispatch()
  const {
    ssn: { code, validationError },
    isSubmitting,
    isAccountLocked
  } = useSelector((state: RootState) => ({
    ssn: state.ssn,
    isSubmitting: state.uiUtils.isSubmitting,
    isAccountLocked: state.idp.isAccountLocked
  }))
  const location = useLocation()

  const handleSubmit = (event: SyntheticEvent): void => {
    event.preventDefault()
    dispatch(handleIdpVerify())
  }
  return (
    <>
      <Header
        data-test="subHeader"
        title={translate({ string: 'forYourSecurity' })}
        description={translate({ string: 'pleaseEnterTheInfo' })}
        labelledby="verification-title"
        describedby="verification-description"
      />

      <InlineError data-test="error-wrapper" />
      <Form data-reason="form" onSubmit={handleSubmit} noValidate>
        <TextfieldWithMargin
          id="ssn"
          name="ssn"
          data-reason="ssn-field"
          type="password"
          value={code}
          inputMode="numeric"
          className="masked"
          helpText={translate({ string: 'pwdHelpText' })}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            dispatch(updateSSN(event.target.value))
          }
          onFocus={() => dispatch(resetIdpError())}
          onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
            dispatch(handleValidate(event.target.value))
          }
          maxLength={4}
          placeholder={translate({ string: 'last4DigitSSN' })}
          error={validationError}
          leftIcon={<FontAwesomeIcon icon={FA_LOCK} />}
          errorIcon={<FontAwesomeIcon icon={FA_EXCLAMATION_CIRCLE} />}
          width="275px"
          data-private
          required
        />
        <FlexButtonContainer centerAlign>
          {isAccountLocked ? (
            <PrimaryButton
              title={translate({ string: 'goToLogin' })}
              buttonType="primary"
              className="ensightencustomevent"
              data-reason="goToLogin"
              data-type="ssn verify"
              data-object="button"
              onClick={() => {
                history.push({
                  pathname: routes.HOME,
                  search: location?.search
                })
              }}
            >
              {translate({ string: 'goToLogin' })}
            </PrimaryButton>
          ) : (
            <PrimaryButton
              title={translate({ string: 'submit' })}
              type="submit"
              buttonType="primary"
              disabled={isSubmitting}
              className="ensightencustomevent"
              data-reason="submit"
              data-type="ssn verify"
              data-object="button"
            >
              {isSubmitting
                ? translate({ string: 'submitting' })
                : translate({ string: 'submit' })}
            </PrimaryButton>
          )}
          <GhostButton
            title={translate({ string: 'cancelToCloseDialog' })}
            buttonType="ghost"
            data-test="cancel"
            onClick={() => dispatch(handleCancel())}
            className="ensightencustomevent"
            data-reason="cancel"
            data-type="ssn verify"
            data-object="button"
          >
            {translate({ string: 'cancel' })}
          </GhostButton>
        </FlexButtonContainer>
      </Form>
    </>
  )
}

export default IdpSSN
