import { MfaValidationType } from 'const/mfa'

export const OtpStart = Object.freeze({
  NAME: 'start',
  PAGE_SUBFUNCTION: MfaValidationType.OTP
})

export const OtpError = Object.freeze({
  analyticsOTPSuccess: 'Info:OTP Success',
  analyticsBadDevice: 'Error: Bad Device'
})

export const OtpComplete = Object.freeze({
  PAGE_SUBFUNCTION: MfaValidationType.OTP,
  PAGE_NAME: 'complete',
  MESSAGE_KEY: 'analyticsOTPSuccess'
})

export const OtpVerify = Object.freeze({
  PAGE_SUBFUNCTION: MfaValidationType.OTP,
  PAGE_NAME: 'code entry'
})
