import styled from 'styled-components'
import Textfield from 'syf-component-library/ui/atoms/Textfield'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { colors } from 'theme'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Main = styled.div`
  margin: 0;
  max-width: 100%;
  width: 100%;
  height: 100%;
`

export const Section = styled.section`
  flex: 1 0 auto;
  background-color: ${p => p.theme.formBackground};
  border-bottom: 4px solid ${p => p.theme.headerDivider};
  padding: 2em 0;

  @media (min-width: 992px) {
    padding: 1em 0;
  }

  @media (max-width: 767px) {
    padding: 1em 0;
  }
`

export const LinkWrapper = styled.p`
  user-select: none;
  text-align: center;
  font-size: 14px;
  color: ${colors.grey};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  > * {
    padding-left: 3px;
    padding-right: 3px;
  }
`

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 18px;
  min-height: 48px;
  > * {
    margin: 0;
  }
`

export const TextfieldWithMargin = styled(Textfield)`
  margin-bottom: 15px;
`

export const LockIconWithMargin = styled(FontAwesomeIcon)`
  margin-right: 8px;
`
