import React, { ReactElement } from 'react'
import translate from 'helpers/translate'
import { FlexButtonContainer, PrimaryButton } from 'ui/atoms/Button'
import Header from 'ui/atoms/Header'
import { Base, Row } from './subcomponents'

interface LostStolenProps {
  oldCard: string
  newCard: string
  dataType: string
  handleButtonClick: () => void
}

const LostStolen = ({
  dataType,
  oldCard,
  newCard,
  handleButtonClick
}: LostStolenProps): ReactElement => (
  <Base data-test="legacy-lost-stolen">
    <Header
      title={translate({ string: 'lostStolenSubtitle' })}
      description={translate({
        string: 'lostStolenDescription',
        argument: oldCard
      })}
    />

    <ul>
      <Row>{translate({ string: 'paperlessStatements' })}</Row>
      <Row>{translate({ string: 'autopay' })}</Row>
      <Row>{translate({ string: 'scheduledPayments' })}</Row>
    </ul>
    <p>{translate({ string: 'lostStolenSoonToReceive', argument: newCard })}</p>
    <FlexButtonContainer style={{ justifyContent: 'center' }}>
      <PrimaryButton
        className="ensightencustomevent"
        data-reason="continue"
        buttonType="primary"
        data-type={dataType}
        data-object="button"
        onClick={handleButtonClick}
      >
        {translate({ string: 'continue' })}
      </PrimaryButton>
    </FlexButtonContainer>
  </Base>
)

export default LostStolen
