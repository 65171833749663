import apiEndpoints from 'const/apiEndpoints'
import { configServices } from 'services'
import { PsfMessage } from './typings'

const getPsfConfig = async (): Promise<PsfMessage> => {
  const { data } = await configServices.get(apiEndpoints.config.PSF_MESSAGE)
  return data
}

export default getPsfConfig
