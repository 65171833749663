import { addBmTelemetryToHeaders } from 'helpers'
import api, { generateTransId } from 'services'
import config from 'const/config'
import { AxiosInstance } from 'axios'
import getCorrelationId from 'helpers/getCorrelationId'
import apiEndpoints from 'const/apiEndpoints'
import {
  OtpInitiateRequest,
  OtpInitiateResponseData,
  OtpOptions,
  VerificationEndpoint
} from 'services/typings'

const otpInitiate = async (
  options: OtpOptions,
  endpoint: VerificationEndpoint,
  axiosApi: AxiosInstance = api
): Promise<OtpInitiateResponseData> => {
  const { sessionId = '', phoneNumber = '', deliveryMode = '' } = options || {}

  const payload: OtpInitiateRequest = {
    otp_info: {
      delivery_mode: deliveryMode,
      phone_number: phoneNumber
    },
    session_info: {
      session_id: sessionId
    }
  }

  const reqHeaders = await addBmTelemetryToHeaders({
    headers: {
      'X-SYF-Request-TrackingId': generateTransId(),
      'X-SYF-Request-CorrelationId': getCorrelationId()
    },
    params: {
      client_id: config.DSEC_APIGEE_CLIENT_ID
    }
  })

  const response = await axiosApi.post(
    apiEndpoints.dsecurity.OTP_INITIATE(endpoint),
    payload,
    reqHeaders
  )

  return response.data
}

export default otpInitiate
