import EMPTY_STRING from 'const/emptyString'
import { ReformatDobOptions } from './typings'

const reformat = ({ date, isIdpSession }: ReformatDobOptions): string => {
  const dateFormat = isIdpSession ? '$3-$1-$2' : '$3$1$2'
  return date
    ? date
        .split('/')
        .join('')
        .replace(/^(\d{1,2})(\d{1,2})(\d{4})$/, dateFormat)
    : EMPTY_STRING
}

export default reformat
