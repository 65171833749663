import { AnyObject } from 'types/custom'
import getSleep from './getSleep'

let counter = 0

const addBmTelemetryToHeaders = async (
  reqHeaders: AnyObject
): Promise<AnyObject> => {
  const { bmak } = window || {}

  if (!bmak && counter < 5) {
    counter += 1
    await getSleep(1000)
    return addBmTelemetryToHeaders(reqHeaders)
  }

  const updatedHeaders: AnyObject = { ...reqHeaders }
  return {
    ...updatedHeaders,
    headers: {
      ...updatedHeaders.headers,
      'BM-Telemetry': bmak && bmak.get_telemetry()
    }
  }
}

export default addBmTelemetryToHeaders
