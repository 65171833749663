import apiEndpoints from 'const/apiEndpoints'
import config from 'const/config'
import { addBmTelemetryToHeaders } from 'helpers'
import getCorrelationId from 'helpers/getCorrelationId'
import service, { generateTransId } from 'services'
import { AnyObject } from 'types/custom'
import {
  FindUserWithIdentifierRequest,
  FindUserWithIdentifierResponse
} from 'services/typings'

const findUserIdWithIdentifier = async (
  identifier: string,
  api: AnyObject = service
): Promise<string> => {
  try {
    const payload: FindUserWithIdentifierRequest = {
      syf_identifier: identifier
    }

    const reqHeaders = await addBmTelemetryToHeaders({
      headers: {
        'X-SYF-Request-TrackingId': generateTransId(),
        'X-SYF-Request-CorrelationId': getCorrelationId()
      },
      params: {
        client_id: config.DSEC_APIGEE_CLIENT_ID
      }
    })

    const response = await api.post(
      apiEndpoints.dsecurity.USER_NAME,
      payload,
      reqHeaders
    )

    const responseData: FindUserWithIdentifierResponse = response.data

    if (responseData.status.response_code === '1001') {
      return responseData.user_id
    }
    localStorage.setItem(`remember-${config.RC_CLIENT_ID}`, '')
    return ''
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    localStorage.setItem(`remember-${config.RC_CLIENT_ID}`, '')
    throw new Error(error)
  }
}

export default findUserIdWithIdentifier
