interface CplSystemErrorDescriptor {
  [key: number]: { descriptor: string; code: number }
}

interface CplSystemErrorDescriptors {
  [key: string]: { [key: string]: CplSystemErrorDescriptor }
}

export enum CplErrorDescriptorKeys {
  AUTH = 'auth',
  PERSIST_AUTH = 'persistAuth',
  OTP = 'otp',
  PERSIST_OTP = 'persistOtp',
  HANDOVER = 'handover'
}

const cplSystemErrorDescriptors: CplSystemErrorDescriptors = {
  auth: {
    newErrorCode: {
      400: { descriptor: '', code: 400 },
      401: { descriptor: '', code: 402 },
      500: { descriptor: '', code: 405 },
      9001: { descriptor: '', code: 406 }
    },
    oldErrorCode: {
      400: { descriptor: '', code: 0 },
      401: { descriptor: '', code: 0 },
      500: { descriptor: 'User Cancelled', code: 406 },
      9001: { descriptor: 'User Cancelled', code: 406 }
    }
  },
  persistAuth: {
    newErrorCode: {
      400: { descriptor: '', code: 410 },
      401: { descriptor: '', code: 412 },
      404: { descriptor: '', code: 414 },
      500: { descriptor: '', code: 415 }
    },
    oldErrorCode: {
      400: { descriptor: 'Synchrony system error, try again', code: 500 },
      401: {
        descriptor: 'Synchrony system error, try again',
        code: 500
      },
      404: { descriptor: 'Synchrony system error, try again', code: 500 },
      500: { descriptor: 'Synchrony system error, try again', code: 500 }
    }
  },
  otp: {
    newErrorCode: {
      400: { descriptor: '', code: 407 },
      500: { descriptor: '', code: 407 },
      9001: { descriptor: '', code: 409 }
    },
    oldErrorCode: {
      400: { descriptor: '', code: 0 },
      500: { descriptor: 'User Cancelled', code: 406 },
      9001: { descriptor: 'User Cancelled', code: 406 }
    }
  },
  persistOtp: {
    newErrorCode: {
      400: { descriptor: '', code: 417 },
      500: { descriptor: '', code: 417 }
    },
    oldErrorCode: {
      400: { descriptor: 'Synchrony system error, try again', code: 500 },
      500: { descriptor: 'Synchrony system error, try again', code: 500 }
    }
  },
  handover: {
    newErrorCode: {
      400: { descriptor: '', code: 420 },
      401: { descriptor: '', code: 424 },
      500: { descriptor: '', code: 425 }
    },
    oldErrorCode: {
      400: { descriptor: 'Synchrony system error, try again', code: 500 },
      401: { descriptor: 'Synchrony system error, try again', code: 500 },
      500: { descriptor: 'Synchrony system error, try again', code: 500 }
    }
  }
}

export default cplSystemErrorDescriptors
