import styled from 'styled-components'
import { colors } from 'theme'

const Wrapper = styled.div`
  width: 100%;
  max-width: 485px;
  margin: 0 auto;
  padding-top: 1rem;

  @media (max-width: 450px) {
    box-sizing: border-box;
    padding: 2em 2em 0.5em 2em;
  }
`

const P = styled.p`
  font-family: Open Sans;
  text-align: left;
  font-size: 14px;
  line-height: 1.43;
  margin: 1rem 0;
  color: ${colors.darkCharcoal};
`
export { Wrapper, P }
