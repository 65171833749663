import styled from 'styled-components'
import Textfield from 'syf-component-library/ui/atoms/Textfield'
import { colors } from 'theme'

interface RulesContainerProps {
  showRulesContainer?: boolean
}

interface RuleProps {
  pristine?: boolean
  hasError?: boolean
}

const RulesContainer = styled.div<RulesContainerProps>`
  margin: 2px auto;
  margin-bottom: ${p => (p.showRulesContainer ? '10px' : '2px')};
  width: 100%;
  max-width: 290px;
  background-color: ${colors.weissThree};
  border-radius: 0.25em 0.25em 0.25em 0.25em;
  margin-top: 10px;
  overflow: hidden;
  transition: max-height 1s linear;
  max-height: ${p => (p.showRulesContainer ? '350px' : '0px')};
`

const Rule = styled.div<RuleProps>`
  display: flex;
  font-size: 16px;
  padding: 6px;
  color: ${p => {
    if (p.pristine) return colors.black
    if (p.hasError) return colors.error
    return colors.green
  }};
`

const RulesDescription = styled.span`
  color: inherit;
`

const IconWrapper = styled.span`
  position: relative;
  padding-right: 10px;
  padding-left: 10px;
`

const TextfieldWithMargin = styled(Textfield)`
  margin-bottom: 10px;
`
export {
  RulesContainer,
  Rule,
  RulesDescription,
  IconWrapper,
  TextfieldWithMargin
}
