import { addBmTelemetryToHeaders } from 'helpers'
import api, { generateTransId } from 'services'
import config from 'const/config'
import { AxiosInstance } from 'axios'
import getCorrelationId from 'helpers/getCorrelationId'
import apiEndpoints from 'const/apiEndpoints'
import {
  OtpValidateOptions,
  OtpValidateRequest,
  OtpValidateResponseData,
  VerificationEndpoint
} from 'services/typings'

const otpValidate = async (
  options: OtpValidateOptions,
  endpoint: VerificationEndpoint,
  axiosApi: AxiosInstance = api
): Promise<OtpValidateResponseData> => {
  const { sessionId = '', code = '', bindDevice = false } = options || {}

  const payload: OtpValidateRequest = {
    otp_info: {
      code,
      bind_device: bindDevice
    },
    session_info: {
      session_id: sessionId
    }
  }

  const reqHeaders = await addBmTelemetryToHeaders({
    headers: {
      'X-SYF-Request-TrackingId': generateTransId(),
      'X-SYF-Request-CorrelationId': getCorrelationId()
    },
    params: {
      client_id: config.DSEC_APIGEE_CLIENT_ID
    }
  })

  const response = await axiosApi.post(
    apiEndpoints.dsecurity.OTP_VALIDATE(endpoint),
    payload,
    reqHeaders
  )

  return response.data
}

export default otpValidate
