import { AnyObject } from 'types/custom'

const analytics: AnyObject = Object.freeze({
  defaults: {
    SITE_IDENTIFIER: 'dservice',
    PAGE_KIND: 'login-registration'
  },
  pageEvents: {
    '/': {
      PAGE_FUNCTION: 'login',
      PAGE_NAME: 'start',
      SUB_FUNCTION: '',
      MESSAGE_KEY: ''
    },
    '/reset': {
      PAGE_FUNCTION: 'reset password',
      PAGE_NAME: 'start',
      SUB_FUNCTION: '',
      MESSAGE_KEY: ''
    },
    '/register': {
      PAGE_FUNCTION: 'registration',
      PAGE_NAME: 'start',
      SUB_FUNCTION: '',
      MESSAGE_KEY: ''
    },
    '/findUserId': {
      PAGE_FUNCTION: 'find user id',
      PAGE_NAME: 'start',
      SUB_FUNCTION: '',
      MESSAGE_KEY: ''
    },
    '/findUserId/found': {
      PAGE_FUNCTION: 'find user id',
      PAGE_NAME: 'complete',
      SUB_FUNCTION: '',
      MESSAGE_KEY: ''
    },
    '/cpl': {
      PAGE_FUNCTION: 'account-linking',
      PAGE_NAME: 'start',
      SUB_FUNCTION: '',
      MESSAGE_KEY: ''
    },
    '/persist': {
      PAGE_FUNCTION: 'login_persistent',
      PAGE_NAME: 'start',
      SUB_FUNCTION: '',
      MESSAGE_KEY: ''
    },
    '/google': {
      PAGE_FUNCTION: 'acknowledge',
      PAGE_NAME: 'view',
      SUB_FUNCTION: 'main',
      SITE_IDENTIFIER: 'voice',
      PAGE_KIND: 'google',
      MESSAGE_KEY: ''
    },
    '/alexa': {
      PAGE_FUNCTION: 'acknowledge',
      PAGE_NAME: 'view',
      SUB_FUNCTION: 'main',
      SITE_IDENTIFIER: 'voice',
      PAGE_KIND: 'alexa',
      MESSAGE_KEY: ''
    },
    '/select-account': {
      PAGE_FUNCTION: 'dashboard',
      PAGE_NAME: 'view',
      SUB_FUNCTION: '',
      PAGE_KIND: 'multiaccount',
      MESSAGE_KEY: ''
    },
    '/error': {
      PAGE_FUNCTION: 'login',
      PAGE_NAME: 'error',
      SUB_FUNCTION: 'process error',
      PAGE_KIND: 'login-registration',
      MESSAGE_KEY: 'somethingWentWrongContent'
    },
    '/jwt-error': {
      PAGE_FUNCTION: 'login',
      PAGE_NAME: 'error',
      SUB_FUNCTION: 'process error',
      PAGE_KIND: 'login-registration',
      MESSAGE_KEY: 'errorOccurred'
    },
    '/cpl-error': {
      PAGE_FUNCTION: 'account-linking',
      PAGE_NAME: 'error',
      SUB_FUNCTION: '',
      PAGE_KIND: 'login-registration',
      MESSAGE_KEY: 'default'
    },
    '/account-locked-error': {
      PAGE_FUNCTION: 'registration',
      PAGE_NAME: 'error',
      SUB_FUNCTION: 'account-locked',
      PAGE_KIND: 'login-registration',
      MESSAGE_KEY: 'accountLockedTitle'
    },
    '/post-registration-error': {
      PAGE_FUNCTION: 'registration',
      PAGE_NAME: 'handover-error',
      SUB_FUNCTION: 'post-registration',
      PAGE_KIND: 'login-registration',
      MESSAGE_KEY: 'postRegistrationError'
    },
    '/session-timeout-error': {
      PAGE_FUNCTION: 'registration',
      PAGE_NAME: 'error',
      SUB_FUNCTION: 'session-timeout',
      PAGE_KIND: 'login-registration',
      MESSAGE_KEY: 'sessionTimeOutTitle'
    },
    '/softener-error': {
      PAGE_FUNCTION: 'registration',
      PAGE_NAME: 'error',
      SUB_FUNCTION: 'identity-validation',
      PAGE_KIND: 'login-registration',
      MESSAGE_KEY: 'softenerValidationFailedTitle'
    }
  },
  clickEvents: {
    TYPE: 'login'
  },
  device: {
    DESK: 'desk',
    MOBI: 'mobi',
    TAB: 'tab'
  }
})

export default analytics
