import styled from 'styled-components'
import { colors } from 'theme'
import Textfield from 'syf-component-library/ui/atoms/Textfield'
import query from 'syf-component-library/const/mediaQueries'
import Small from 'syf-component-library/ui/typography/Small'
import Body from 'syf-component-library/ui/typography/Body'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const HelpText = styled(Small)`
  margin: 0 auto;
  color: ${colors.grey};
  max-width: 500px;
  @media ${query.lessThanMedium} {
    padding: 0px 10px;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 16px;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`

export const RadioContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media ${query.lessThanMedium} {
    flex-direction: column;
    align-items: center;
  }
`

export const GroupTitle = styled.div`
  margin-bottom: 11px;
`

export const RadioWrapper = styled.div`
  flex-direction: column;
  width: 250px;
  min-width: 49%;
`

export const TextfieldWithMargin = styled(Textfield)`
  margin-top: 15px;
  margin-bottom: 15px;
  min-width: 100%;
`

export const ContactNumber = styled.a`
  color: ${colors.darkBlue};
  font-weight: bold;
  text-decoration: none;
`

export const DidNotReceiveCodeContainer = styled.span`
  font-size: 14px;
  color: ${colors.grey};
  text-align: center;
  margin: 18px auto 10px;
  display: block;
  max-width: 300px;
`

export const SaveDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  margin-top: 12px;
  margin-bottom: 20px;
  @media ${query.lessThanMedium} {
    margin: 0 auto;
    line-height: 1.5;
    padding-bottom: 10px;
  }
`

export const Label = styled(Body)`
  margin: 0;
  padding-right: 20px;
  @media ${query.lessThanMedium} {
    padding-bottom: 5px;
    padding-top: 5px;
  }
`

export const VerifyForm = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 400px;
  margin: 0 auto;
`

export const SorryDescription = styled.span`
  display: block;
  max-width: 400px;
  padding: 0 16px;
`

export const PhoneIcon = styled(FontAwesomeIcon)`
  color: ${colors.darkGreyFiftyFour};
`
