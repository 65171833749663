import Textfield from 'syf-component-library/ui/atoms/Textfield'
import styled from 'styled-components'

export const TextfieldWithMargin = styled(Textfield)`
  margin-bottom: 35px;
`

export const LastTextfield = styled(Textfield)`
  margin-bottom: 25px;
`
