import { CvvAction, CvvState } from './typings'

export const initialState: CvvState = {
  code: '',
  validationError: '',
  submissionError: false
}

const Cvv = (state: CvvState = initialState, action: CvvAction): CvvState => {
  switch (action.type) {
    case 'UPDATE_CVV':
      return { ...state, code: action.payload }
    case 'UPDATE_VALIDATION_ERROR':
      return { ...state, validationError: action.payload }
    case 'UPDATE_SUBMISSION_ERROR':
      return { ...state, submissionError: action.payload }
    case 'RESET_CVV_STATE':
      return initialState
    default:
      return state
  }
}

export default Cvv
