import { combineReducers } from 'redux'
import iva from './iva/reducer'
import idp from './idp/reducer'
import uiUtils from './uiUtils/reducer'
import login from './login/reducer'
import cpl from './cpl/reducer'
import otp from './otp/reducer'
import quickPay from './quickPay/reducer'
import akamaiConfig from './akamaiConfig/reducer'
import registration from './registration/reducer'
import ssn from './ssn/reducer'
import cvv from './cvv/reducer'
import jwt from './jwt/reducer'
import psfMessage from './psfMessage/reducer'

export default combineReducers({
  iva,
  idp,
  uiUtils,
  login,
  cpl,
  otp,
  quickPay,
  akamaiConfig,
  registration,
  ssn,
  cvv,
  jwt,
  psfMessage
})
