import React, { ReactElement, SyntheticEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import { updateFormData } from 'redux/login/actions'
import { resetQuickpayToken } from 'redux/quickPay/actions'
import handoverToPWOLI from 'services/handoverToPWOLI'
import translate from 'helpers/translate'
import PwoliLogo from 'assets/pbl-logo-white.jpg'
import config from 'const/config'
import PayWithoutLogin from 'const/analytics/payWithoutLogin'

import {
  Divider,
  HeadlineUserName,
  NotYouButton,
  PwoliButton,
  PwoliDivider,
  PwoliImage,
  PwoliTextDivider
} from '../subcomponents'

const PayWithoutLoginComponent = (): ReactElement => {
  const dispatch = useDispatch()
  const {
    login: { formData },
    quickPay: { pwoliToken }
  } = useSelector((state: RootState) => state)

  const [pwoliUserId, setPwoliUserId] = useState<string>(formData?.userId)

  const resetPWOLI = (): void => {
    dispatch(resetQuickpayToken())
    setPwoliUserId('')
    dispatch(updateFormData({ ...formData, userId: '', rememberMe: false }))
    localStorage.setItem(`remember-${config.RC_CLIENT_ID}`, '')
  }

  const handlePwoliClick = (e: SyntheticEvent) => {
    e.preventDefault()
    handoverToPWOLI(pwoliToken, 'start')
  }

  return (
    <>
      <NotYouButton
        buttonType="text"
        onClick={resetPWOLI}
        className="ensightencustomevent"
        data-reason="not you"
        data-object="link"
        data-type="login"
        alt={translate({ string: 'notYou' })}
        ariaLabel="not you"
      >
        {translate({ string: 'notYou' })}
      </NotYouButton>
      <HeadlineUserName data-test="head-line-username">
        {translate({
          string: 'inAHurry',
          argument: pwoliUserId
        })}
      </HeadlineUserName>
      <PwoliButton
        buttonType="text"
        id="pay-without-log-in"
        className="ensightencustomevent"
        data-reason={PayWithoutLogin.clickEvents.REASON}
        data-object={PayWithoutLogin.clickEvents.OBJECT}
        data-type={PayWithoutLogin.clickEvents.TYPE}
        onClick={handlePwoliClick}
        alt={translate({ string: 'payWithoutLogIn' })}
        ariaLabel="pay without login"
      >
        <PwoliImage src={PwoliLogo} />
        <span>{translate({ string: 'payWithoutLogIn' })}</span>
      </PwoliButton>
      <PwoliDivider>
        <Divider />
        <PwoliTextDivider>{translate({ string: 'or' })}</PwoliTextDivider>
        <Divider />
      </PwoliDivider>
    </>
  )
}

export default PayWithoutLoginComponent
