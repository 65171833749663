import React, { ReactElement } from 'react'
import CLFooter from 'syf-component-library/ui/patterns/Footer'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import { clientInfo } from 'configureBrand'
import formatFooterLinks from './formatFooterLinks'
import { FooterProps } from './typings'

const Footer = ({ currentLanguage, isCplRoute }: FooterProps): ReactElement => {
  const {
    akamaiConfig: { coBrowse }
  } = useSelector((state: RootState) => state, shallowEqual)

  return (
    <CLFooter
      data-test="footer"
      body={clientInfo.footerContent[currentLanguage]}
      linkList={formatFooterLinks(currentLanguage)}
      showCobrowse={!isCplRoute}
      shouldUseSurfly={coBrowse?.isSurflyScriptTagEnabled}
    />
  )
}

export default Footer
