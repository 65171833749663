import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MessageBox from 'syf-component-library/ui/patterns/MessageBox/MessageBox'
import query from 'syf-component-library/const/mediaQueries'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import { FA_EXCLAMATION_CIRCLE } from 'const/iconProp'
import { formatMaintenanceMessage, shouldShowMaintenance } from './helpers'

export const MessageWrapper = styled(MessageBox)`
  margin-bottom: 18px;
  width: 855px;
  box-sizing: border-box;
  @media ${query.lessThanMedium} {
    width: 100%;
  }
`

const MaintenanceMessageBox = (): ReactElement => {
  const {
    akamaiConfig: { maintenanceData }
  } = useSelector((reduxState: RootState) => reduxState)
  const showMaintenanceMessage = shouldShowMaintenance(maintenanceData)

  return (
    showMaintenanceMessage && (
      <MessageWrapper
        type="error"
        data-test="maintenance-page"
        icon={<FontAwesomeIcon icon={FA_EXCLAMATION_CIRCLE} />}
      >
        {formatMaintenanceMessage(maintenanceData)}
      </MessageWrapper>
    )
  )
}

export default MaintenanceMessageBox
