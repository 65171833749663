const PayWithoutLogin = Object.freeze({
  pageEvents: {
    NAME: 'start',
    PAGE_FUNCTION: 'login',
    PAGE_SUBFUNCTION: 'pay without login'
  },
  clickEvents: {
    REASON: 'pwoli',
    OBJECT: 'button',
    TYPE: 'login'
  }
})

export default PayWithoutLogin
