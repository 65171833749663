export const setCookie = (
  name: string,
  value: string | number,
  days?: number
): void => {
  let expires = ''
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = `; expires=${date.toUTCString()}`
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`
}

export const getCookie = (name: string): string | null => {
  const nameLenPlus = name.length + 1
  const cookieValue = document.cookie
    .split(';')
    .map(cookie => {
      return cookie.trim()
    })
    .filter(cookie => {
      return cookie.substring(0, nameLenPlus) === `${name}=`
    })
    .map(cookie => {
      return decodeURIComponent(cookie.substring(nameLenPlus)) || ''
    })

  return cookieValue.includes('') ? '' : cookieValue[0] || null
}
