import CryptoJs from 'crypto-js'
import config from 'const/config'
import { AnyObject } from 'types/custom'
import {
  addBmTelemetryToHeaders,
  createCodeChallenge,
  getBlackBoxValues,
  getCorrelationId
} from 'helpers'
import api, { generateTransId } from 'services'
import apiEndpoints from 'const/apiEndpoints'
import {
  AuthenticateCplOptions,
  AuthenticateCplRequestData,
  AuthenticateCplResponseData
} from 'services/typings'

declare global {
  interface Window {
    IGLOO: AnyObject
  }
}

const authenticate = async (
  options: AuthenticateCplOptions
): Promise<AuthenticateCplResponseData> => {
  const { oauthClientId, oauthAccessToken, codeVerifier } = options
  const codeChallenge = createCodeChallenge(codeVerifier)
  const blackboxValues = await getBlackBoxValues()

  const reqHeaders = await addBmTelemetryToHeaders({
    headers: {
      'X-SYF-Request-TrackingId': generateTransId(),
      'X-SYF-Request-CorrelationId': getCorrelationId()
    },
    params: {
      client_id: oauthClientId
    }
  })

  const stateRandomStr = CryptoJs.enc.Utf8.parse(Date.now().toString())
  const base64EncState = CryptoJs.enc.Base64.stringify(stateRandomStr)
  const payload: AuthenticateCplRequestData = {
    auth: {
      client_id: config.PERSIST_APIGEE_CLIENT_ID,
      redirect_uri: 'https://redirect.synchronycredit.com',
      scope: 'persistent-login',
      state: base64EncState,
      code_challenge: codeChallenge,
      code_challenge_method: 'S256',
      nonce: ''
    },
    device_info: {
      black_box: blackboxValues.blackbox
    },
    user_info: {
      'cipher.access_token': oauthAccessToken
    }
  }

  const { data } = await api.post(
    apiEndpoints.cpl.PERSISTENT_AUTHENTICATE,
    payload,
    reqHeaders
  )
  return data
}

export default authenticate
