import React, { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import styled from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import { RootState } from 'redux/store'
import routes from 'const/routes'
import { PrimaryButton } from 'ui/atoms/Button'
import ErrorPage from 'ui/atoms/ErrorPage'
import {
  formatQueryParams,
  getParameterByName,
  redirectToClientUrl,
  translate
} from 'helpers'

export const FlexButtonContainer = styled.div`
  @media ${query.greaterThanMedium} {
    display: flex;
  }
`

const CplErrorPage = (): ReactElement => {
  const history = useHistory()
  const { errorDescriptor, errorCode, isAccountLinking } = useSelector(
    (state: RootState) => state.cpl,
    shallowEqual
  )

  const systemErrorSubheader = isAccountLinking
    ? 'cplSystemErrorSubheader'
    : 'persistSystemErrorSubheader'
  const goToLinkingAgainLabel = isAccountLinking
    ? 'tryLinkingAccountAgain'
    : 'goBack'
  const goToDsecLabel = isAccountLinking
    ? 'continueWithoutLinking'
    : 'continueToLogin'

  const handleContinueWithoutLinkingClick = (): void => {
    history.push({
      pathname: routes.HOME
    })
  }

  const handleTryLinkingAccountAgainClick = (): void => {
    const oauthRedirectUri = decodeURIComponent(
      getParameterByName('redirect_uri', window.location.search)
    )

    const errorDescription = errorDescriptor
      ? `&error_description=${errorDescriptor}`
      : ''
    const redirectUri = formatQueryParams(
      oauthRedirectUri,
      `error=${errorCode}${errorDescription}`
    )

    redirectToClientUrl(redirectUri)
  }

  return (
    <ErrorPage
      title={translate({ string: 'somethingWentWrong' })}
      description={translate({
        string: systemErrorSubheader
      })}
    >
      <FlexButtonContainer>
        <PrimaryButton
          buttonType="secondary"
          data-test="try-linking-account-again"
          onClick={handleTryLinkingAccountAgainClick}
        >
          {translate({ string: goToLinkingAgainLabel })}
        </PrimaryButton>
        <PrimaryButton
          buttonType="primary"
          data-test="continue-without-linking"
          onClick={handleContinueWithoutLinkingClick}
        >
          {translate({ string: goToDsecLabel })}
        </PrimaryButton>
      </FlexButtonContainer>
    </ErrorPage>
  )
}

export default CplErrorPage
