import config from 'const/config'
import EMPTY_STRING from 'const/emptyString'
import { GetRememberDatafromCookie } from './typings'

const getRememberDatafromCookie = (): GetRememberDatafromCookie => {
  try {
    let identifier = EMPTY_STRING

    const localStorageData = localStorage.getItem(
      `remember-${config.RC_CLIENT_ID}`
    )

    const clientId = localStorageData
      ? localStorageData.split('&&')[1]
      : EMPTY_STRING

    const rememberMeCookie = localStorageData
      ? localStorageData.split('&&')[0]
      : EMPTY_STRING

    const decodeTokenUsingBuffer = (encodedString: string) => {
      try {
        const decodedToken = Buffer.from(encodedString, 'base64').toString(
          'ascii'
        )
        return JSON.parse(decodedToken).sub
      } catch (error) {
        return EMPTY_STRING
      }
    }

    const decodeToken = (encodedString: string) => {
      try {
        return JSON.parse(atob(encodedString.split('.')[1])).sub
      } catch (error) {
        return decodeTokenUsingBuffer(encodedString)
      }
    }

    const encodedToken = rememberMeCookie && rememberMeCookie.split('.')[1]

    if (encodedToken) {
      identifier = decodeToken(encodedToken)
    }

    return { clientId, identifier }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    throw error
  }
}

export default getRememberDatafromCookie
