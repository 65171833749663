import { AxiosError } from 'axios'

const getLog = {
  error: (error: AxiosError): void => {
    const errorsToSkip = [422, 403]
    if (errorsToSkip.indexOf(error?.response?.status) < 0) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }
}

export default getLog
