import React, { ReactElement, SyntheticEvent, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'syf-component-library/ui/atoms/Link'
import Small from 'syf-component-library/ui/typography/Small'
import { RootState } from 'redux/store'
import { updateIsSubmitting } from 'redux/uiUtils/actions'
import { resetIdpError } from 'redux/idp/actions'
import {
  toggleRememberMe,
  updateFormData,
  updateSubmissionErrors
} from 'redux/login/actions'
import translate from 'helpers/translate'
import validate from 'helpers/validate'
import { clientInfo } from 'configureBrand'
import routes from 'const/routes'
import { FA_EXCLAMATION_CIRCLE, FA_LOCK, FA_USER } from 'const/iconProp'
import { LocationState } from 'types/locationState'
import IdpInlineError from 'ui/atoms/InlineError/IdP'
import NonIdpInlineError from 'ui/atoms/InlineError/NonIdP'
import EMPTY_STRING from 'const/emptyString'
import PayWithoutLoginComponent from './PayWithoutLogin'
import {
  CheckboxWithRightLabel,
  Container,
  Headline,
  HeadlineWrapper,
  LastTextfield,
  LinkContainer,
  LinkWrapper,
  LockIconWithMargin,
  LoginButton,
  LoginFormWrapper,
  TextfieldWithMargin
} from './subcomponents'

export interface LoginFormProps {
  handleSubmit: () => void
}

const LoginForm = ({ handleSubmit }: LoginFormProps): ReactElement => {
  const {
    uiUtils: { isSubmitting, isModalOpen },
    login: { formData, submissionError, error },
    quickPay: { pwoliToken }
  } = useSelector((state: RootState) => state)
  const dispatch = useDispatch()
  const location = useLocation<Partial<LocationState>>()
  const [errors, setErrors] = useState<{
    userId: string
    password: string
  }>({ userId: EMPTY_STRING, password: EMPTY_STRING })
  const { userId, password, rememberMe } = formData
  const errorMessage = submissionError?.key ? submissionError : error

  const validateLoginField = (target: string, value: string) => {
    const validationError = validate(target, value)
    setErrors({ ...errors, [target]: validationError })
  }

  const validateAllFields = () => {
    const validation = { errors: {} }
    Object.keys(errors).forEach(key => {
      const { [key as keyof typeof errors]: stateValue } = formData
      const validateError = validate(key, stateValue)
      validation.errors = { ...validation.errors, [key]: validateError }
    })

    setErrors({ ...errors, ...validation.errors })

    return Object.keys(validation.errors).find(
      e => validation.errors[e as keyof typeof validation.errors]
    )
  }

  const handleFormSubmit = (e: SyntheticEvent) => {
    e.preventDefault()
    if (!validateAllFields()) {
      dispatch(updateIsSubmitting(true))
      handleSubmit()
    }
  }

  const handleOnChange = (target: string, value: string) => {
    dispatch(updateFormData({ ...formData, [target]: value }))
    setErrors({ ...errors, [target]: '' })
    dispatch(resetIdpError())
  }

  const idpError = !isModalOpen && <IdpInlineError />

  return (
    <Container>
      <HeadlineWrapper>
        <Headline as="h1">
          {translate({ string: 'accessYourAccount' })}
        </Headline>
      </HeadlineWrapper>
      {pwoliToken && userId && <PayWithoutLoginComponent />}
      <LoginFormWrapper data-test="form" onSubmit={handleFormSubmit} noValidate>
        {errorMessage ? <NonIdpInlineError error={errorMessage} /> : idpError}
        <TextfieldWithMargin
          id="userId"
          name="userId"
          type="text"
          value={userId}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleOnChange('userId', e.target.value.trim())
          }
          onFocus={() => dispatch(updateSubmissionErrors({}))}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
            validateLoginField('userId', e.target.value)
          }
          maxLength={50}
          placeholder={translate({ string: 'userId' })}
          error={errors.userId}
          helpText={translate({
            string: 'userIdHelpText',
            argument: clientInfo.vanityUrl
          })}
          leftIcon={<FontAwesomeIcon icon={FA_USER} />}
          errorIcon={<FontAwesomeIcon icon={FA_EXCLAMATION_CIRCLE} />}
          width="275px"
          data-test="login-userid"
          required
        />
        <LastTextfield
          id="password"
          name="password"
          type="password"
          value={password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleOnChange('password', e.target.value.trim())
          }
          onFocus={() => dispatch(updateSubmissionErrors({}))}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
            validateLoginField('password', e.target.value)
          }
          maxLength={20}
          placeholder={translate({ string: 'password' })}
          error={errors.password}
          inputClassName="masked"
          leftIcon={<FontAwesomeIcon icon={FA_LOCK} />}
          errorIcon={<FontAwesomeIcon icon={FA_EXCLAMATION_CIRCLE} />}
          showMaskedOption
          hideTextValue={translate({ string: 'hide' })}
          showTextValue={translate({ string: 'show' })}
          width="275px"
          data-private
          data-test="login-password"
          required
        />
        <CheckboxWithRightLabel
          isChecked={rememberMe}
          onChange={() => dispatch(toggleRememberMe(!rememberMe))}
          data-test="remember-me"
        >
          <Small isInline>{translate({ string: 'rememberUserId' })}</Small>
        </CheckboxWithRightLabel>
        <LoginButton
          buttonType="primary"
          type="submit"
          id="secure-login"
          disabled={isSubmitting}
          showLockIcon
          title={translate({ string: 'loginToYourAccount' })}
          className="ensightencustomevent"
          data-reason="secure login"
          data-type="login"
          data-object="button"
        >
          <LockIconWithMargin icon={FA_LOCK} />
          {isSubmitting
            ? translate({ string: 'loggingIn' })
            : translate({ string: 'secureLogin' })}
        </LoginButton>
      </LoginFormWrapper>
      <LinkContainer>
        <LinkWrapper>
          {translate({ string: 'iForgotMy' })}
          <Link
            to={{
              pathname: routes.FIND_USER_ID,
              state: { returnTo: routes.HOME, queryParams: location.search },
              search: location.search
            }}
            isInline
            className="ensightencustomevent"
            data-reason="find user id"
            data-type="login"
            data-object="link"
          >
            {translate({ string: 'userId' })}
          </Link>
          {translate({ string: 'or' })}
          <Link
            to={{
              pathname: routes.RESET,
              state: { returnTo: routes.HOME, queryParams: location.search },
              search: location.search
            }}
            isInline
            className="ensightencustomevent"
            data-reason="reset password"
            data-type="login"
            data-object="link"
          >
            {translate({ string: 'password' })}.
          </Link>
        </LinkWrapper>
        <LinkWrapper>
          {translate({ string: 'iWantTo' })}
          <Link
            to={{
              pathname: routes.REGISTER,
              state: { returnTo: routes.HOME, queryParams: location.search },
              search: location.search
            }}
            isInline
            className="ensightencustomevent"
            data-reason="register"
            data-type="login"
            data-object="button"
          >
            {translate({ string: 'register' })}
          </Link>
          {clientInfo.applicationUrl && (
            <>
              {translate({ string: 'or' })}
              <Link
                isInline
                href={clientInfo.applicationUrl}
                className="ensightencustomevent"
                data-reason="apply"
                data-type="login"
                data-object="button"
              >
                {translate({ string: 'apply' })}.
              </Link>
            </>
          )}
        </LinkWrapper>
      </LinkContainer>
    </Container>
  )
}

export default LoginForm
