export const colors = {
  aluminum: '#d6d6d6',
  black: '#000000',
  blue: '#017ac9',
  blueLagoon: '#075d7e',
  boxShadowGrey: 'rgba(0, 0, 0, 0.25)',
  burnishedSilver: '#c8c8c8',
  cyanBlue: '#94969a',
  darkBlue: '#070e43',
  darkCharcoal: '#333333',
  darkCyanBlue: '#34657f',
  darkGrey: '#767676',
  darkGreyFiftyFour: 'rgba(0, 0, 0, 0.54)',
  darkGreySeventyFive: 'rgba(0, 0, 0, 0.75)',
  error: '#C63527',
  fordGrey: '#979797',
  freshLilac: '#e0dbd7',
  green: '#1f8a1d',
  grey: '#757575',
  greyFifteen: 'rgba(0, 0, 0, 0.15)',
  greyNinetyOne: '#e8e8e8',
  greyTwo: 'rgba(0, 0, 0, 0.2)',
  gunpowderGrey: '#4a4a4a',
  lightGrey: '#DDDDDD',
  lightGreyTwo: '#D8D8D8',
  offWhiteOne: 'rgba(0, 0, 0, 0.1)',
  offWhiteFiftyNine: 'rgba(255, 255, 255, 0.35)',
  solidGrey: '#cccccc',
  white: '#FFFFFF',
  whiteSmoke: '#f5f5f5',
  weissThree: '#f4f4f4'
}

export const opacities = {
  greyTwentyTwo: 'rgba(0, 0, 0, 0.08)',
  greyEightyEight: 'rgba(0, 0, 0, 0.12)'
}

const theme = {
  colors,
  opacities
}

export default theme
