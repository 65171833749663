import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { FlattenSimpleInterpolation, css } from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import { colors } from 'theme'
import { Palette } from 'brands/typings'
import { StandardRadioButtonWrapperType } from './typings'

export const RadioTitle = styled.div`
  text-align: center;
  padding: 5px 0px 5px 0px;
`

export const RadioInput = styled.input`
  position: absolute;
  opacity: 0;
`

export const RadioButtonWrapper = styled.div`
  display: inline-block;
  position: relative;

  &:not(:first-child) {
    margin-left: 20px;
    @media ${query.lessThanMedium} {
      margin-left: 27px;
    }
  }
`

export const RadioLabel = styled.label`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  height: 32px;
  width: auto;
  min-width: 55px;
  padding: 5px 12px 5px 7.2px;
  box-shadow: 0 0 15px ${colors.offWhiteOne};
  font-size: 14px;
  border-radius: 999px;
  box-sizing: border-box;

  ${RadioInput}:focus + & {
    box-shadow: inset 0 0 999em ${colors.offWhiteOne};
    outline: 3px solid Highlight;
  }

  ${RadioInput}:checked + & {
    color: ${p => p.theme.contrastingText};
    background-color: ${p => p.theme.button.background};
  }

  &:hover {
    opacity: 0.8;
  }
  @media ${query.lessThanMedium} {
    white-space: normal;
    max-width: 130px;
    min-height: 38px;
    height: 100%;
  }
`
const selectedStyles = (theme: Palette): FlattenSimpleInterpolation =>
  css`
    background: ${theme.button.background};
    color: ${theme.contrastingText};
    &:hover {
      background: ${theme.button.background};
      opacity: 0.8;
    }
  `

export const StandardRadioButtonWrapper = styled.div<StandardRadioButtonWrapperType>`
  box-sizing: border-box;
  display: inline-flex;
  width: 100%;
  border-bottom: 1px solid ${colors.burnishedSilver};
  padding: 7px 7px 9px 7px;

  &:hover {
    background: ${colors.whiteSmoke};
    opacity: 0.8;
  }

  :last-child {
    border-bottom: 0px;
  }

  ${({ selected, theme }) => selected && selectedStyles(theme)}
`

export const RadioIcon = styled.div`
  position: relative;
  width: 16px;
  height: 16px;
  background: white;
  border-radius: 50%;
  border: 1px solid ${colors.darkCharcoal};
  cursor: pointer;
  margin-right: 8px;
`

export const StandardRadioInput = styled.input`
  display: none;

  &:checked + ${RadioIcon} {
    background: ${p => p.theme.button.background};
    border: 1px solid white;
    box-sizing: border-box;
    &::after {
      content: '';
      position: absolute;
      top: 3px;
      left: 3px;
      width: 8px;
      height: 8px;
      background-color: white;
      border-radius: 12px;
      display: block;
    }
  }
`

export const FontAwesomeIconStyle = styled(FontAwesomeIcon)`
  height: 24.4px;
  width: 28.6px;
`
