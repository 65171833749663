import translate from 'helpers/translate'
import validate from 'helpers/validate'
import {
  NewPasswordType,
  RegisterForm,
  RegistrationFormErrors
} from 'redux/registration/typings'

interface ValidateRegistrationFormOptions {
  registerForm: RegisterForm
  formErrors: RegistrationFormErrors
  isUniqueUserId: boolean
}

interface ValidateErrorsOptions {
  errors: RegistrationFormErrors
  isUniqueUserId: boolean
}

export const validateRegistrationForm = ({
  formErrors,
  registerForm,
  isUniqueUserId
}: ValidateRegistrationFormOptions): RegistrationFormErrors => {
  let newErrors: RegistrationFormErrors
  Object.keys(formErrors).forEach(key => {
    const value = registerForm[key as keyof RegisterForm]
    const validationError = validate(key, value)
    newErrors = { ...newErrors, [key]: validationError }
  })

  if (!isUniqueUserId) {
    newErrors.registrationUID = translate({ string: 'unavailableUID' })
  }

  return newErrors
}

export const validateErrors = ({
  errors,
  isUniqueUserId
}: ValidateErrorsOptions): string | boolean => {
  return (
    Object.keys(errors).find(
      errorKey =>
        errorKey !== 'newPassword' &&
        errors[errorKey as keyof RegistrationFormErrors]
    ) || // field errors (not including pw)
    Object.keys(errors.newPassword).find(
      newPasswordErrorKey =>
        !errors.newPassword[newPasswordErrorKey as keyof NewPasswordType]
    ) || // password errors
    !isUniqueUserId // user id not unique
  )
}
