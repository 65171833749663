import { AnyObject } from 'types/custom'

const convertPayloadToFormData = (payload: AnyObject): string =>
  Object.keys(payload).reduce((agg, key, index, src) => {
    if (!key || !payload[key]) return ''
    const ampersand = index === src.length - 1 ? '' : '&'
    return `${agg + key}=${payload[key]}${ampersand}`
  }, '')

export default convertPayloadToFormData
