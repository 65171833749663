import styled from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import ProgressStepBar from 'syf-component-library/ui/patterns/ProgressStepBar'

const Container = styled.div`
  margin: 10px 20px;
  height: 100%;
`

const Form = styled.form`
  width: 100%;
  margin: 10px 20px;
  margin: 0 auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  @media${query.greaterThanSmall} {
    width: 325px;
  }
`

const HorizontalProgressStepBar = styled(ProgressStepBar)`
  padding-top: 30px;
  padding-left: 0px;
  margin: 10px auto 25px;
`
export { Container, Form, HorizontalProgressStepBar }
