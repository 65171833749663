import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Textfield from 'syf-component-library/ui/atoms/Textfield'
import query from 'syf-component-library/const/mediaQueries'
import Checkbox from 'syf-component-library/ui/atoms/Checkbox'
import Small from 'syf-component-library/ui/typography/Small'
import { colors } from 'theme'
import RoundedRadio from 'ui/atoms/RadioButtons/RoundedRadio'
import { GhostButton } from 'ui/atoms/Button'

export const Container = styled.div`
  margin: 10px;
`

export const FormContainer = styled.form`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${query.greaterThanSmall} {
    width: 375px;
  }
`

export const StatementsContainer = styled.div`
  margin: 35px 0 9px;
  width: 100%;
  #statements {
    width: 100%;
    justify-content: center;
  }
`

export const TermsButton = styled(GhostButton)`
  margin: 5px auto 16px;
  padding: 4px;
  div {
    text-align: left;
  }
`

export const StyledOption = styled(RoundedRadio)`
  min-width: 110px;
`

export const TextfieldWithMargin = styled(Textfield)`
  margin-bottom: 10px;
`

export const LastTextfield = styled(Textfield)`
  margin-bottom: 25px;
`

export const CheckboxWithRightLabel = styled(Checkbox)`
  width: 76%;
  margin-bottom: 15px;
`

export const SubText = styled(Small)`
  height: auto;
  color: ${colors.grey};
  width: 100%;
`

export const FileAltIconWithMargin = styled(FontAwesomeIcon)`
  margin-right: 10px;
`

export const ButtonContainer = styled.div`
  padding: 80px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const EnrollmentFailure = styled.div`
  line-height: normal;
  margin-top: 30px;
  text-align: center;
`

export const EbillSuccess = styled.div`
  line-height: 2em;
  text-align: center;
`
