import React, { ReactElement, SyntheticEvent, useState } from 'react'
import {
  debounceOneMS,
  formatQueryParams,
  getParameterByName,
  handleRedirectToErrorScreen,
  redirectToClientUrl,
  translate,
  validate
} from 'helpers'
import checkVoicePin from 'services/cpl/voiceLinking/checkVoicePin'
import Body from 'syf-component-library/ui/typography/Body'
import InlineError from 'ui/atoms/InlineError/NonIdP'
import H5 from 'syf-component-library/ui/typography/H5'
import Pinfield from 'syf-component-library/ui/atoms/Pinfield'
import VoiceBanner from 'ui/atoms/VoiceBanner'
import { FA_LOCK } from 'const/iconProp'
import { MfaValidationType } from 'const/mfa'
import {
  Container,
  CreateKeyButton,
  Form,
  LockIconWithMargin
} from './subcomponents'
import { VoicePinContainerProps } from './typings'

const VoicePinContainer = ({ auth }: VoicePinContainerProps): ReactElement => {
  const queryParams = window.location.search

  const oauthState = decodeURIComponent(
    getParameterByName('state', queryParams)
  )

  const oauthRedirectUri = decodeURIComponent(
    getParameterByName('redirect_uri', queryParams)
  )

  const oauthClientId = decodeURIComponent(
    getParameterByName('client_id', queryParams)
  )

  const [pin, setPin] = useState<string>('')
  const [errors, setErrors] = useState<string>('')
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const [submissionError, setSubmissionError] = useState<
    Record<string, string>
  >({
    key: '',
    extension: ''
  })

  const onChange = (pinData: string): void => {
    setPin(pinData)
    debounceOneMS(() => setErrors(validate(MfaValidationType.OTP, pinData)))
  }

  const handleSubmit = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault()
    const validationError = validate(MfaValidationType.OTP, pin)
    if (validationError) return setErrors(validationError)
    const { data } = auth
    const { session_id: sessionId } = data || {}
    if (!oauthRedirectUri || !oauthClientId) {
      setSubmissionError({
        key: 'default',
        extension: ''
      })
    }
    try {
      setIsSubmitting(true)
      const pinData = await checkVoicePin({ sessionId, pin })
      const { response_code: responseCode } = pinData.status
      const authCode = pinData.authorization_code
      if (responseCode === '1001') {
        const redirectUri = formatQueryParams(
          oauthRedirectUri,
          `code=${authCode}&state=${oauthState}`
        )
        redirectToClientUrl(redirectUri)
      } else {
        setSubmissionError({
          key: 'default',
          extension: ''
        })
      }
    } catch (newError) {
      const { status } = newError.response || {}
      // eslint-disable-next-line no-console
      console.error(newError)
      handleRedirectToErrorScreen({
        errorCode: status
      })
      setSubmissionError({
        key: 'default',
        extension: `${translate({ string: 'code' })}: ${status}`
      })
      setIsSubmitting(false)
    }
    return undefined
  }

  return (
    <>
      <VoiceBanner />
      <Container>
        <H5>{translate({ string: 'createaVoiceKey' })}</H5>
        <Body isInline>
          {translate({ string: 'createVoiceKeyDescription' })}
        </Body>

        <Form data-test="form" onSubmit={handleSubmit} autoComplete="off">
          {(errors || submissionError) && (
            <InlineError
              error={
                submissionError.key
                  ? submissionError
                  : {
                      key: errors,
                      extension: ' '
                    }
              }
              data-test="errorWrapper"
            />
          )}
          <Pinfield
            id="pin"
            data-test="pin-field"
            numberOfFields={4}
            onChange={onChange}
            hasError={!!errors}
            data-private
          />

          <CreateKeyButton
            buttonType="primary"
            type="submit"
            title={translate({ string: 'createKey' })}
            disabled={isSubmitting}
            className="ensightencustomevent"
            data-reason="create key"
            data-test="create-key"
            data-type="key"
            data-object="button"
          >
            <LockIconWithMargin icon={FA_LOCK} />
            {isSubmitting
              ? translate({ string: 'creatingKey' })
              : translate({ string: 'createKey' })}
          </CreateKeyButton>
        </Form>
      </Container>
    </>
  )
}

export default VoicePinContainer
