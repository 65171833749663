import { LoginAction, LoginState } from './typings'

export const initialState: LoginState = {
  formData: {
    userId: '',
    password: '',
    rememberMe: false
  },
  phoneNumbers: [],
  sessionId: '',
  userId: '',
  lostStolenInfo: undefined,
  submissionError: {},
  auth: {
    responseData: {
      status: {
        response_code: '1001',
        response_desc: '',
        trans_id: ''
      },
      last_4_acct_no: '',
      prev_last_4_acct_no: '',
      user_id: '',
      client_id: '',
      account_type: '',
      authorization_code: '',
      session_id: '',
      phone_numbers: ['']
    },
    result: {
      redirect: '',
      otp: false,
      message: {
        key: '',
        extension: '',
        argument: ''
      },
      token: false,
      lostStolen: false
    },
    codeVerifier: '',
    client: ''
  },
  error: {}
}

export default function (
  state: LoginState = initialState,
  action: LoginAction = undefined
): LoginState {
  switch (action.type) {
    case 'UPDATE_FORM_DATA':
      return { ...state, formData: action.payload }
    case 'UPDATE_AUTH_DATA':
      return { ...state, auth: action.payload }
    case 'UPDATE_PHONE_NUMBERS':
      return {
        ...state,
        phoneNumbers: action.payload
      }
    case 'UPDATE_SESSION_ID':
      return { ...state, sessionId: action.payload }
    case 'UPDATE_USER_ID':
      return { ...state, userId: action.payload }
    case 'UPDATE_LOST_STOLEN_INFO':
      return { ...state, lostStolenInfo: action.payload }
    case 'UPDATE_SUBMISSION_ERRORS':
      return { ...state, submissionError: action.payload }
    case 'UPDATE_ERROR':
      return { ...state, error: action.payload }
    case 'UPDATE_REMEMBER_ME':
      return {
        ...state,
        formData: { ...state.formData, rememberMe: action.payload }
      }
    default:
      return state
  }
}
