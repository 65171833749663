import { ErrorType } from 'types/errorTypes'

export interface LostStolenTypes {
  oldCard?: string
  newCard?: string
  accessToken?: string
}

export enum ResetStep {
  LOOKUP = 'lookup',
  SELECT = 'select',
  LOST_STOLEN = 'lost-stolen',
  COMPLETE = 'complete'
}

export interface StepsData {
  label: string
  value: number
}

export interface UpdatePasswordProps {
  handleSubmit: (newPassword: string) => void
  steps: Array<StepsData>
  isSubmitting?: boolean
  submissionError: ErrorType
  returnTo: string
  onFocusTextField?: () => void
}

export interface Errors {
  required: string
  length: string
  hasNumber: string
  hasLetter: string
  noWhiteSpace: string
  specialChar: string
}

export interface FormDataType {
  userId: string
  zipCode: string
  dob: string
}

export interface LookupFormProps {
  steps: Array<StepsData>
  error?: ErrorType
  isSubmitting?: boolean
  returnTo: string
  userId: string
  zipCode: string
  dob: string
  onFocusTextField?: () => void
  onSubmit: ({ userId, zipCode, dob }: FormDataType) => void
}
