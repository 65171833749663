import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { palette } from 'configureBrand'
import config from 'const/config'
import { isNotIE } from 'helpers'

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-display: fallback;
    src: local('Open Sans Light'), local('OpenSans-Light'),
      url("${config.ASSET_ORIGIN}/assets/fonts/Light/OpenSans-Light.woff") format('woff');
  }

  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
      url("${config.ASSET_ORIGIN}/assets/fonts/Regular/OpenSans-Regular.woff") format('woff');
  }

  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-display: fallback;
    src: local('Open Sans Semibold'), local('OpenSans-Semibold'),
      url("${config.ASSET_ORIGIN}/assets/fonts/Semibold/OpenSans-Semibold.woff") format('woff');
  }

  body {
    background: ${palette.bodyBackground};
    color: ${palette.bodyText};
    margin: 0;
    padding: 0;
    font-weight: normal;
    font-style: normal;
    font-family: Open Sans;
  }

  .ReactModal__Content {
    box-sizing: border-box;
  }

  #root {
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .ReactModal__Overlay {
    display: flex;
  }
  
  input {
    line-height: normal;
  }
`

export const Container = styled(props =>
  isNotIE() ? <main {...props} /> : <div {...props} role="main" />
)`
  flex-grow: 1;
  display: flex;
`

export const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`
