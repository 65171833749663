import React, { ReactElement, SyntheticEvent, useState } from 'react'
import { formatDate, translate, validate } from 'helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LookupFormContainer from 'ui/molecules/LookupFormContainer'
import {
  FlexButtonContainer,
  GhostButton,
  PrimaryButton,
  TextButton
} from 'ui/atoms/Button'
import Header from 'ui/atoms/Header'
import { clientInfo } from 'configureBrand'
import {
  FA_CALENDAR,
  FA_EXCLAMATION_CIRCLE,
  FA_MAP_MARKER,
  FA_USER
} from 'const/iconProp'
import BillingInUsHeader from 'ui/atoms/BillingInUsHeader'
import { FormDataType, LookupFormProps } from 'pages/Reset/typings'
import useCancelRedirection from 'hooks/useCancelRedirection'
import {
  HorizontalProgressStepBar,
  LastTextfield,
  TextfieldWithMargin
} from './subcomponents'

const LookUpForm = ({
  isSubmitting,
  returnTo,
  steps,
  error,
  onFocusTextField,
  onSubmit,
  userId = '',
  zipCode = '',
  dob = ''
}: LookupFormProps): ReactElement => {
  const [formData, setFormData] = useState<FormDataType>({
    userId,
    zipCode,
    dob
  })
  const [inUsa, setInUsa] = useState<boolean>(true)
  const [errors, setErrors] = useState<FormDataType>({
    userId: '',
    zipCode: '',
    dob: ''
  })
  const handleCancelRedirection = useCancelRedirection(returnTo)

  const validateAllFields = (): string => {
    const validation = { errors: {} }
    Object.keys(errors).forEach(key => {
      const { [key as keyof typeof errors]: stateKey } = formData
      const errorData = validate(key, stateKey, {
        ...formData,
        inUsa
      })
      validation.errors = { ...validation.errors, [key]: errorData }
    })

    setErrors((prevErrors: FormDataType): FormDataType => {
      return { ...prevErrors, ...validation.errors }
    })

    return Object.keys(validation.errors).find(
      e => validation.errors[e as keyof typeof validation.errors]
    )
  }

  const handleSubmit = (e: SyntheticEvent): void => {
    e.preventDefault()
    if (!validateAllFields()) {
      onSubmit(formData)
    }
  }

  const handleOnChange = (target: string, value: string): void => {
    setFormData({ ...formData, [target]: value })
    setErrors({
      ...errors,
      [target]: ''
    })
  }

  const handleOnBlur = (target: string, value: string): void =>
    setErrors({
      ...errors,
      [target]: validate(target, value, { ...errors, inUsa })
    })

  return (
    <>
      <Header
        title={translate({ string: 'resetYourPassword' })}
        description={translate({ string: 'subHeadDescription' })}
      />

      <HorizontalProgressStepBar
        data-test="horizontalStepBar"
        steps={steps}
        width={450}
        circleRadius={4}
        activeStep={0}
      />

      <LookupFormContainer
        data-test="lookupFormContainer"
        onSubmit={handleSubmit}
        error={error}
        subheader={
          !inUsa && <BillingInUsHeader handleClick={() => setInUsa(true)} />
        }
      >
        <TextfieldWithMargin
          id="userId"
          name="userId"
          type="text"
          value={formData.userId}
          data-test="userId"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleOnChange('userId', e.target.value)
          }
          helpText={translate({
            string: 'userIdHelpText',
            argument: clientInfo.vanityUrl
          })}
          onFocus={onFocusTextField}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleOnBlur('userId', e.target.value)
          }
          maxLength={50}
          placeholder={translate({ string: 'userId' })}
          error={errors.userId}
          leftIcon={<FontAwesomeIcon icon={FA_USER} />}
          errorIcon={<FontAwesomeIcon icon={FA_EXCLAMATION_CIRCLE} />}
          width="275px"
          required
        />
        {inUsa ? (
          <LastTextfield
            id="zipCode"
            name="zipCode"
            type="text"
            data-test="zipCode"
            value={formData.zipCode}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleOnChange('zipCode', e.target.value)
            }
            onFocus={onFocusTextField}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleOnBlur('zipCode', e.target.value)
            }
            maxLength={5}
            placeholder={translate({ string: 'zipCode' })}
            error={errors.zipCode}
            leftIcon={<FontAwesomeIcon icon={FA_MAP_MARKER} />}
            errorIcon={<FontAwesomeIcon icon={FA_EXCLAMATION_CIRCLE} />}
            width="275px"
            required
          />
        ) : (
          <LastTextfield
            type="text"
            id="dob"
            name="dob"
            data-reason="dob"
            inputClassName="ensightencustomevent"
            value={formData.dob}
            error={errors.dob}
            placeholder={translate({ string: 'dateOfBirth' })}
            helpText={translate({ string: 'dobHelptext' })}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleOnChange('dob', formatDate(e.target.value))
            }
            onFocus={onFocusTextField}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleOnBlur('dob', e.target.value)
            }
            maxLength={10}
            errorIcon={<FontAwesomeIcon icon={FA_EXCLAMATION_CIRCLE} />}
            leftIcon={<FontAwesomeIcon icon={FA_CALENDAR} />}
            width="275px"
            required
          />
        )}

        {clientInfo.crossBorder && inUsa && (
          <TextButton
            data-test="cross-border-billing"
            buttonType="text"
            onClick={() => setInUsa(false)}
          >
            {translate({ string: 'billingNotUs' })}
          </TextButton>
        )}
        <FlexButtonContainer pt="10px">
          <PrimaryButton
            buttonType="primary"
            type="submit"
            className="ensightencustomevent"
            disabled={isSubmitting}
            data-reason="continue"
            data-type="reset password"
            data-test="submit button"
            data-object="button"
          >
            {isSubmitting
              ? translate({ string: 'searching' })
              : translate({ string: 'continue' })}
          </PrimaryButton>
          <GhostButton
            buttonType="ghost"
            className="ensightencustomevent"
            onClick={handleCancelRedirection}
            data-type="reset password"
            data-reason="cancel"
            data-object="button"
          >
            {translate({ string: 'cancel' })}
          </GhostButton>
        </FlexButtonContainer>
      </LookupFormContainer>
    </>
  )
}

export default LookUpForm
