import {
  addBmTelemetryToHeaders,
  createCodeChallenge,
  determineEligibilityResult,
  getBlackBoxValues,
  getCorrelationId,
  reformat
} from 'helpers'
import config from 'const/config'
import api, { generateTransId } from 'services'
import { AnyObject } from 'types/custom'
import { AxiosInstance } from 'axios'
import apiEndpoints from 'const/apiEndpoints'
import generateUUID from 'syf-js-utilities/helpers/generateUUID'
import {
  FormatUserInfo,
  FormatUserInfoReturn,
  RegisterUser,
  SearchForUserIdRequest,
  SearchResponse,
  SearchUser,
  SearchUserInfo,
  SearchUserResetPasswordRequest,
  VerificationEndpoint
} from 'services/typings'

export const codeVerifier = generateUUID()
export const codeChallenge = createCodeChallenge(codeVerifier)

declare global {
  interface Window {
    GLANCE: AnyObject
  }
}

export const formatUserInfo = ({
  registrationType,
  transmitId,
  zipCode,
  dob,
  accountNumber
}: FormatUserInfo): FormatUserInfoReturn => {
  switch (registrationType.type) {
    case 'transmitId':
      return { tid: transmitId }
    case 'zipCode':
      return { zip_code: zipCode, 'cipher.account_number': accountNumber }
    case 'dob':
      return {
        dob: reformat({ date: dob }),
        'cipher.account_number': accountNumber
      }
    default:
      return { zip_code: '', 'cipher.account_number': accountNumber }
  }
}

const getRegistrationType = (options: SearchUserInfo) => {
  let registrationType
  Object.keys(options).forEach(key => {
    if (options[key as keyof SearchUserInfo]) {
      registrationType = key
    }
  })
  return { type: registrationType }
}

const searchUser = async (
  options: SearchUserInfo,
  endpoint: VerificationEndpoint,
  axiosApi: AxiosInstance = api
): Promise<SearchUser> => {
  const { accountNumber, dob, zipCode, userId, transmitId } = options

  const blackboxValues = await getBlackBoxValues()

  const payLoadState = 'V1o3Wnk1ZEN5TkR6cWZ5NG8xWmZEanFhUm1vQW9XZG'

  const registrationType = getRegistrationType({
    transmitId,
    zipCode,
    dob
  })

  const userInfo = formatUserInfo({
    registrationType,
    accountNumber,
    dob,
    transmitId,
    zipCode
  })

  const buildResetPasswordPayload = (): SearchUserResetPasswordRequest => ({
    auth: {
      client_id: config.DSEC_APIGEE_CLIENT_ID,
      redirect_uri: 'https://redirect.synchronycredit.com',
      scope: endpoint,
      state: payLoadState,
      code_challenge: codeChallenge,
      code_challenge_method: 'S256',
      nonce: ''
    },
    device_info: {
      black_box: blackboxValues.blackbox
    },
    user_info: {
      user_id: userId,
      zip_code: zipCode || '',
      dob: dob ? reformat({ date: dob }) : undefined
    }
  })

  const buildSearchForUserIdPayload = (): SearchForUserIdRequest => ({
    device_info: {
      black_box: blackboxValues.blackbox
    },
    user_info: userInfo
  })

  const buildRegisterUserPayload = (): RegisterUser => ({
    auth: {
      client_id: config.DSEC_APIGEE_CLIENT_ID,
      redirect_uri: 'https://redirect.synchronycredit.com',
      scope: endpoint,
      state: payLoadState,
      code_challenge: codeChallenge,
      code_challenge_method: 'S256',
      nonce: ''
    },
    device_info: {
      black_box: blackboxValues.blackbox
    },
    user_info: userInfo
  })

  let payload

  switch (endpoint) {
    case 'lookup-userid':
      payload = buildSearchForUserIdPayload()
      break
    case 'reset-password':
      payload = buildResetPasswordPayload()
      break
    case 'registration':
      payload = buildRegisterUserPayload()
      break
    default:
      break
  }
  const reqHeaders = await addBmTelemetryToHeaders({
    headers: {
      'X-SYF-Request-TrackingId': generateTransId(),
      'X-SYF-Request-CorrelationId': getCorrelationId()
    },
    params: {
      client_id:
        endpoint !== 'reset-password' ? config.DSEC_APIGEE_CLIENT_ID : undefined
    }
  })
  const response = await axiosApi.post(
    apiEndpoints.dsecurity.ELIGIBILITY_CHECK(endpoint),
    payload,
    reqHeaders
  )

  const responseData: SearchResponse = response.data

  const result = determineEligibilityResult({
    data: responseData,
    endpoint
  })

  return {
    responseData,
    result
  }
}

export default searchUser
