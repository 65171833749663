import apiInstance, { generateTransId } from 'services'
import config from 'const/config'
import { addBmTelemetryToHeaders, getBlackBoxValues } from 'helpers'
import { MfaPhone } from 'redux/idp/typings'
import { AxiosResponse } from 'axios'
import apiEndpoints from 'const/apiEndpoints'
import {
  GetCompleteResponseData,
  PostAuthenticateOptions,
  PostIdpOtpInitiateOptions,
  PostIdpOtpSoftenerOptions,
  PostIdpProfileOptions,
  PostOtpValidateOptions,
  PostSoftenerResponseData,
  PostVerifyUserId,
  RequestHeaderProps
} from 'services/typings'

const getReqHeaders = async ({
  idpSessionId,
  isGlobalAuth
}: RequestHeaderProps) => {
  return addBmTelemetryToHeaders({
    headers: {
      'X-SYF-Client-Id': isGlobalAuth
        ? config.AUTH_CLIENT_ID
        : config.DSEC_APIGEE_CLIENT_ID,
      'X-SYF-Auth-Session-Id': idpSessionId,
      'X-SYF-Request-CorrelationId': idpSessionId,
      'X-SYF-Request-TrackingId': generateTransId()
    }
  })
}

const api = {
  postAuthenticate: async ({
    flowId,
    options,
    idpSessionId
  }: PostAuthenticateOptions): Promise<AxiosResponse<number>> => {
    const blackboxValues = await getBlackBoxValues()
    const reqHeaders = await getReqHeaders({ idpSessionId })
    const payload = {
      flowId,
      deviceInfo: {
        blackBox: blackboxValues.blackbox
      },
      userInfo: options
    }
    return apiInstance.post(
      apiEndpoints.idp.IDP_AUTHENTICATE,
      payload,
      reqHeaders
    )
  },

  getMfaOptions: async (
    idpSessionId: string
  ): Promise<AxiosResponse<MfaPhone[]>> => {
    const reqHeaders = await getReqHeaders({ idpSessionId })
    return apiInstance.get(apiEndpoints.idp.MFA_METHODS, reqHeaders)
  },

  postOtpInitiate: async ({
    phoneNumber,
    deliveryMethod,
    idpSessionId
  }: PostIdpOtpInitiateOptions): Promise<AxiosResponse<number>> => {
    const reqHeaders = await getReqHeaders({ idpSessionId })
    const payload = {
      deliveryMethod,
      phoneNumber
    }
    return apiInstance.post(apiEndpoints.idp.IDP_OTP, payload, reqHeaders)
  },

  postOtpValidate: async ({
    code,
    deviceRegister,
    idpSessionId
  }: PostOtpValidateOptions): Promise<
    AxiosResponse<{
      code: string
      deviceRegister: boolean
    }>
  > => {
    const reqHeaders = await getReqHeaders({ idpSessionId })
    const payload = {
      code,
      deviceRegister
    }
    return apiInstance.post(apiEndpoints.idp.IDP_OTP_FLOW, payload, reqHeaders)
  },

  getComplete: async (
    idpSessionId: string
  ): Promise<AxiosResponse<GetCompleteResponseData>> => {
    const reqHeaders = await getReqHeaders({ idpSessionId })
    return apiInstance.get(apiEndpoints.idp.IDENTITY_COMPLETE, reqHeaders)
  },

  postOtpSoftener: async ({
    code,
    idpSessionId
  }: PostIdpOtpSoftenerOptions): Promise<
    AxiosResponse<PostSoftenerResponseData>
  > => {
    const reqHeaders = await getReqHeaders({ idpSessionId })
    const payload = {
      code
    }
    return apiInstance.post(
      apiEndpoints.idp.IDP_OTP_SOFTENER,
      payload,
      reqHeaders
    )
  },

  postProfileCreate: async ({
    idpSessionId,
    ebillStatus,
    eConsentStatus,
    registrationUID,
    newPassword,
    email,
    accessToken
  }: PostIdpProfileOptions): Promise<AxiosResponse> => {
    const reqHeaders = await getReqHeaders({ idpSessionId, isGlobalAuth: true })
    reqHeaders.headers.Authorization = `Bearer ${accessToken}`

    const payload = {
      enrollment: {
        ebill: ebillStatus,
        eConsent: eConsentStatus
      },
      userInfo: {
        userId: registrationUID,
        password: newPassword,
        email
      }
    }

    return apiInstance.post(
      apiEndpoints.idp.IDP_PROFILE_CREATE,
      payload,
      reqHeaders
    )
  },
  checkUserId: async ({
    registrationUID,
    idpSessionId,
    accessToken
  }: PostVerifyUserId): Promise<AxiosResponse> => {
    const reqHeaders = await getReqHeaders({ idpSessionId })
    reqHeaders.headers['X-SYF-UserId'] = registrationUID
    reqHeaders.headers.Authorization = `Bearer ${accessToken}`
    return apiInstance.get(apiEndpoints.idp.USER_ID_EXISTS, reqHeaders)
  }
}

export default api
