import { UiAction } from './typings'

export const updateIsModalOpen = (status: boolean): UiAction => {
  return { type: 'UPDATE_IS_MODAL_OPEN', payload: status }
}

export const updateIsSubmitting = (submitState: boolean): UiAction => {
  return { type: 'UPDATE_IS_SUBMITTING', payload: submitState }
}

export const updateIsLoading = (loadingStatus: boolean): UiAction => {
  return { type: 'UPDATE_IS_LOADING', payload: loadingStatus }
}
