import { clientInfo } from 'configureBrand'
import translate from 'helpers/translate'
import { AuthResult, VerifyCodes } from './typings'

const determineVerifyResult = (
  code: VerifyCodes,
  endpoint: string,
  accountType: string
): AuthResult => {
  if (endpoint === 'reset-password') {
    switch (code) {
      case '5001':
        return { lostStolen: true }
      case '5002':
        return {}
      case '5003':
        return {
          message: {
            key: 'tryAgainUpToSix',
            extension: `${translate({ string: 'code' })}: 5003`
          }
        }
      case '5004':
        return {
          message: {
            key: 'lockedStandard',
            argument:
              clientInfo.phoneNumbers[
                accountType as keyof typeof clientInfo.phoneNumbers
              ],
            extension: `${translate({ string: 'code' })}: 5004`,
            locked: true
          }
        }
      case '5005':
        return {
          message: {
            key: 'lockedProblem',
            argument:
              clientInfo.phoneNumbers[
                accountType as keyof typeof clientInfo.phoneNumbers
              ],
            extension: `${translate({ string: 'code' })}: 5005`,
            locked: true
          }
        }
      case '5006':
        return {
          message: {
            key: 'oneAttemptRemainingShort',
            extension: `${translate({ string: 'code' })}: 5006`
          }
        }
      default:
        return { message: { key: 'default' } }
    }
  } else if (endpoint === 'lookup-userid') {
    switch (code) {
      case '5001':
        return {}
      case '5002':
        return {
          message: {
            key: 'lockedStandard',
            argument:
              clientInfo.phoneNumbers[
                accountType as keyof typeof clientInfo.phoneNumbers
              ],
            extension: `${translate({ string: 'code' })}: 5002`,
            locked: true
          }
        }
      case '5003':
        return {
          message: {
            key: 'tryAgainUpToSix',
            extension: `${translate({ string: 'code' })}: 5003`
          }
        }
      case '5004':
        return {
          message: {
            key: 'oneAttemptRemainingShort',
            extension: `${translate({ string: 'code' })}: 5004`
          }
        }
      default:
        return { message: { key: 'default' } }
    }
  } else {
    switch (code) {
      case '5001':
        return {}
      case '5002':
        return {
          message: {
            key: 'tryAgainUpToSix',
            extension: `${translate({ string: 'code' })}: 5002`
          }
        }
      case '5003':
        return {
          message: {
            key: 'lockedStandard',
            argument:
              clientInfo.phoneNumbers[
                accountType as keyof typeof clientInfo.phoneNumbers
              ],
            extension: `${translate({ string: 'code' })}: 5003`,
            locked: true
          }
        }
      case '5004':
        return {
          message: {
            key: 'oneAttemptRemainingShort',
            extension: `${translate({ string: 'code' })}: 5004`
          }
        }
      default:
        return { message: { key: 'default' } }
    }
  }
}

export default determineVerifyResult
