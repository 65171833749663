import translate from 'helpers/translate'
import { clientInfo } from 'configureBrand'
import { EligibilityResult, EligibilityResultArgs } from 'helpers/typings'
import { MfaValidationType } from 'const/mfa'

const determineEligibilityResult = ({
  data,
  endpoint
}: EligibilityResultArgs): EligibilityResult => {
  const {
    status: { response_code: code },
    account_type: accountType,
    phone_numbers: phoneNumbers
  } = data
  switch (code) {
    case '1001':
      return {
        message: {
          key: 'tryAgainUpToSix',
          extension: `${translate({ string: 'code' })}: 1001`
        }
      }
    case '1002':
      return {
        message: {
          key: 'lockedStandard',
          argument:
            clientInfo.phoneNumbers[
              accountType as keyof typeof clientInfo.phoneNumbers
            ],
          extension: `${translate({ string: 'code' })}: 1002`
        }
      }
    case '1003':
      return {
        message: {
          key: 'lockedProblem',
          argument:
            clientInfo.phoneNumbers[
              accountType as keyof typeof clientInfo.phoneNumbers
            ],
          extension: `${translate({ string: 'code' })}: 1003`
        }
      }
    case '1004':
      return {
        message: {
          key: 'lockedProblem',
          argument: clientInfo.phoneNumbers.fraud,
          extension: `${translate({ string: 'code' })}: 1004`
        }
      }
    case '1005':
      return {
        validation: MfaValidationType.SSN
      }
    case '1006':
      return {
        validation: MfaValidationType.CVV
      }
    case '1007':
      if (phoneNumbers?.length) {
        return { validation: MfaValidationType.OTP }
      }
      return {
        message: {
          key: 'lockedProblem',
          argument: clientInfo.phoneNumbers.fraud,
          extension: `${translate({ string: 'code' })}: 1007`
        }
      }

    case '1008':
      return {}
    case '1009':
      return {
        message: {
          extension: `${translate({ string: 'code' })}: 1009`,
          key:
            (endpoint === 'registration' && 'accountAlreadyRegistered') ||
            (endpoint === 'reset-password' && 'recentlyUpdatedUserId') ||
            (endpoint === 'lookup-userid' && 'findUserIdUnregisteredCard') ||
            ''
        }
      }

    case '1010':
      return {
        message: {
          key: 'oneAttemptRemainingShort',
          extension: `${translate({ string: 'code' })}: 1010`
        }
      }

    case '1011':
      return {
        message: {
          key: 'gapBrandAccountIssue',
          argument: clientInfo.phoneNumbers.customerService,
          extension: `${translate({ string: 'code' })}: 1011`
        }
      }

    default:
      return { message: { key: 'default' } }
  }
}

export default determineEligibilityResult
