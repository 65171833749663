import React, { ReactElement, ReactNode } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { colors } from 'theme'
import Inset from 'syf-component-library/ui/spacing/Inset'
import Stack from 'syf-component-library/ui/spacing/Stack'
import H4 from 'syf-component-library/ui/typography/H4'
import H5 from 'syf-component-library/ui/typography/H5'
import { FA_CLOUD_RAIN } from 'const/iconProp'
import Template from 'ui/molecules/Template'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

interface ErrorPageProps {
  title: string
  description: string
  errorIcon?: IconDefinition
  children?: ReactNode
}

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 18px;
  text-align: center;
`

const SubHeading = styled(H5)`
  color: ${colors.darkGrey};
  max-width: 700px;
`

const ErrorIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.button.primary.hover};
`

const ErrorPage = ({
  title,
  description,
  errorIcon,
  children
}: ErrorPageProps): ReactElement => (
  <Template>
    <ErrorWrapper>
      <ErrorIcon icon={errorIcon || FA_CLOUD_RAIN} size="5x" />
      <Inset top="large" bottom="large">
        <H4 as="h1">{title}</H4>
        <Stack size="large" />
        <SubHeading as="h2">{description}</SubHeading>
      </Inset>
      {children}
    </ErrorWrapper>
  </Template>
)

export default ErrorPage
