import { RootState } from 'redux/store'
import { ThunkDispatch } from 'redux-thunk'
import {
  CurrentStep,
  ProfileCreatePayload,
  ProfileCreateSuccess,
  RegisterAction,
  RegistrationFormErrors,
  SearchUserSuccessPayload,
  UpdateRegisterFormPayload,
  UpdateRegisterFormState,
  UserIdValidationFailedState,
  VerificationPayload
} from 'redux/registration/typings'
import { Dispatch } from 'redux'
import {
  handleRedirectToErrorScreen,
  history,
  reformat,
  translate,
  triggerAnalyticsEvent
} from 'helpers'
import { ErrorType } from 'types/errorTypes'
import profileCreate from 'services/nonIdP/profileCreate'
import generateToken from 'services/nonIdP/generateToken'
import searchUser, { codeVerifier } from 'services/nonIdP/searchUser'
import {
  CheckUserIdOptions,
  SearchUserInfo,
  VerifyOptions
} from 'services/typings'
import { handleRedirectError } from 'redux/registration/helpers'
import routes from 'const/routes'
import {
  updateIsLoading,
  updateIsModalOpen,
  updateIsSubmitting
} from 'redux/uiUtils/actions'
import { UiAction } from 'redux/uiUtils/typings'
import ErrorCode from 'const/errorCode'
import ExpeditedRegistrationSSN from 'const/analytics/ssn'
import verify from 'services/nonIdP/verify'
import checkUserId from 'services/nonIdP/checkUserId'
import Registration from 'const/analytics/registration'
import { FlowId } from 'const/idp'
import { postIdpAuthenticate } from 'redux/idp/actions'
import { getPsfMessage } from 'redux/psfMessage/actions'
import handleMfaMethodAnalyticsEvent from 'helpers/handleMfaMethodAnalyticsEvent'

export type RegisterThunkDispatch = ThunkDispatch<
  RootState,
  undefined,
  RegisterAction | UiAction
>

export const clearRegistrationState = (): RegisterAction => {
  return { type: 'CLEAR_REGISTRATION_STATE' }
}

export const resetRegistrationState =
  () =>
  (dispatch: RegisterThunkDispatch): void => {
    dispatch(updateIsModalOpen(false))
    dispatch(clearRegistrationState())
  }

export const clearVerifyUserResponse = (): RegisterAction => {
  return { type: 'CLEAR_VERIFY_USER_RESPONSE' }
}

export const updateExpeditedRegistrationTransmitId = (
  tid: string
): RegisterAction => {
  return {
    type: 'UPDATE_EXPEDITED_REGISTRATION_TRANSMIT_ID',
    payload: tid || ''
  }
}

const handleProfileCreateSuccess = (
  profileCreateSuccessPayload: ProfileCreateSuccess
): RegisterAction => {
  return {
    type: 'PROFILE_CREATE_SUCCESS',
    payload: profileCreateSuccessPayload
  }
}

export const updateIsInUsa = (isInUsa: boolean): RegisterAction => {
  return {
    type: 'UPDATE_IN_USA',
    payload: isInUsa
  }
}

export const handleVerified = (
  verifyUserPayload: VerificationPayload
): RegisterAction => {
  triggerAnalyticsEvent({
    pageName: Registration.registration
  })

  return {
    type: 'UPDATE_VERIFY_STATE',
    payload: verifyUserPayload
  }
}

export const handleSearchUserSuccess = (
  searchUserPayload: SearchUserSuccessPayload
): RegisterAction => {
  return {
    type: 'SEARCH_USER_SUCCESS',
    payload: searchUserPayload
  }
}

export const clearErrorState = (): RegisterAction => {
  return {
    type: 'CLEAR_ERROR_STATE'
  }
}

export const updateError = (
  payload: ErrorType,
  isSearchUser?: boolean
): RegisterAction => {
  return {
    type: isSearchUser ? 'SEARCH_USER_FAILURE' : 'PROFILE_CREATE_FAILURE',
    payload
  }
}

export const updateIsExpiredTransmitId = (status: boolean): RegisterAction => {
  return {
    type: 'UPDATE_EXPIRED_TRANSMIT_ID',
    payload: status
  }
}

const handleExpiredToken = () => (dispatch: Dispatch) => {
  history.push({
    pathname: routes.REGISTER
  })
  dispatch(updateIsExpiredTransmitId(true))
}

export const handleProfileCreate =
  ({
    ebillSelection,
    registrationUID,
    newPassword,
    email,
    sendEmailOffers,
    sessionId,
    accessToken
  }: ProfileCreatePayload) =>
  async (dispatch: RegisterThunkDispatch): Promise<void> => {
    dispatch(updateIsLoading(true))
    dispatch(updateIsSubmitting(true))
    try {
      const result = await profileCreate({
        formValues: {
          ebillSelection,
          registrationUID,
          newPassword,
          email,
          sendEmailOffers
        },
        sessionId,
        accessToken
      })

      if (result.success) {
        const { success: accountType, withEbillFailure, clientId } = result

        const profileData: ProfileCreateSuccess = {
          accountType,
          isEbillFailure: withEbillFailure,
          clientId,
          accessToken,
          currentStep: 'complete',
          userId: registrationUID
        }
        triggerAnalyticsEvent({
          pageName: Registration[profileData.currentStep]
        })
        dispatch(handleProfileCreateSuccess(profileData))
        dispatch(updateIsLoading(false))
        dispatch(updateIsSubmitting(false))
      } else if (result?.message) {
        dispatch(updateIsLoading(false))
        dispatch(updateIsSubmitting(false))
        triggerAnalyticsEvent({ messageKey: result.message?.key })
        dispatch(updateError(result.message))
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      dispatch(updateIsLoading(false))
      dispatch(updateIsSubmitting(false))
      const { status } = error.response || {}
      dispatch(
        updateError({
          key: 'default',
          extension: `${translate({ string: 'code' })}: ${status}`
        })
      )
      handleRedirectToErrorScreen({
        errorCode: status
      })
    }
  }

export const checkRegistrationEligibility =
  (options: SearchUserInfo) =>
  async (dispatch: RegisterThunkDispatch): Promise<void> => {
    dispatch(updateIsSubmitting(true))
    dispatch(clearErrorState())
    const { transmitId } = options
    if (transmitId) {
      triggerAnalyticsEvent({
        subFunction: ExpeditedRegistrationSSN.PAGE_SUBFUNCTION,
        pageName: ExpeditedRegistrationSSN.NAME
      })
    }
    try {
      const searchUserResponse = await searchUser(options, 'registration')
      const {
        responseData: {
          status: { response_code: responseCode }
        },
        result
      } = searchUserResponse
      handleRedirectError(responseCode)
      dispatch(updateIsLoading(false))
      dispatch(updateIsSubmitting(false))
      const isRegistrationStep = !result?.message && !result?.validation
      if (isRegistrationStep) {
        triggerAnalyticsEvent({
          pageName: Registration.registration
        })
      }
      handleMfaMethodAnalyticsEvent(result?.validation)
      dispatch(updateIsModalOpen(!!result?.validation))
      dispatch(
        handleSearchUserSuccess({
          searchUserResponse,
          validationModal: result?.validation,
          currentStep: isRegistrationStep ? 'registration' : 'lookup',
          error: result?.message
        })
      )
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      dispatch(updateIsLoading(false))
      dispatch(updateIsSubmitting(false))
      const { status } = error?.response || {}
      if (transmitId && status === ErrorCode.FORBIDDEN_ERROR) {
        dispatch(handleExpiredToken())
      } else {
        dispatch(
          updateError(
            {
              key: 'default',
              extension: `${translate({ string: 'code' })}: ${status}`
            },
            true
          )
        )
        handleRedirectToErrorScreen({
          errorCode: status
        })
      }
    }
  }

export const verifyUser =
  (options: VerifyOptions) =>
  async (dispatch: RegisterThunkDispatch): Promise<void> => {
    try {
      const endpoint = 'registration'
      const { responseData, result } = await verify(options, endpoint)
      const {
        authorization_code: authCode,
        status: { response_code: responseCode },
        ebill_status: ebillStatus
      } = responseData || {}
      const cipherAccountId = responseData?.['cipher.accountId']

      handleRedirectError(responseCode)

      let accessToken = ''
      if (authCode && codeVerifier) {
        accessToken = await generateToken({ authCode, codeVerifier })
      }

      if (accessToken && !ebillStatus && cipherAccountId) {
        await dispatch(
          getPsfMessage({
            accessToken,
            cipherAccountId
          })
        )
      }

      const verifyUserState: VerificationPayload = {
        accessToken,
        ebillStatus,
        result,
        currentStep: result?.message ? 'lookup' : 'registration',
        cipherAccountId
      }
      dispatch(handleVerified(verifyUserState))
      dispatch(updateIsSubmitting(false))
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      dispatch(updateIsSubmitting(false))
      const { status } = error.response || {}
      dispatch(
        updateError({
          key: 'default',
          extension: `${translate({ string: 'code' })}: ${status}`
        })
      )
    }
  }

export const updateRegisterForm = (
  formPayload: UpdateRegisterFormState
): RegisterAction => {
  return {
    type: 'UPDATE_REGISTER_FORM',
    payload: formPayload
  }
}

export const updateFormErrors = (
  formErrorsPayload: RegistrationFormErrors
): RegisterAction => {
  return {
    type: 'UPDATE_REGISTER_FORM_ERRORS',
    payload: formErrorsPayload
  }
}

export const updateIsUniqueUserId = (
  isUniqueUserId: boolean
): RegisterAction => {
  return {
    type: 'UPDATE_IS_UNIQUE_USERID',
    payload: isUniqueUserId
  }
}

export const handleFormUpdate =
  (payload: UpdateRegisterFormPayload) =>
  (dispatch: RegisterThunkDispatch, getState: () => RootState): void => {
    const {
      registration: { registerForm, formErrors }
    } = getState()
    const { target, value } = payload
    const formData = { ...registerForm, [target]: value }
    let errors: RegistrationFormErrors

    if (target === 'newPassword') {
      errors = {
        ...formErrors,
        newPassword: {
          ...formErrors.newPassword,
          required: 'pristine',
          length: 'pristine',
          hasNumber: 'pristine',
          hasLetter: 'pristine',
          noWhiteSpace: 'pristine',
          specialChar: 'pristine'
        }
      }
    } else {
      errors = { ...formErrors, [target]: '' }
    }

    dispatch(updateRegisterForm({ registerForm: formData, formErrors: errors }))
  }

export const handleUserIdValidationFailed = (
  payload: UserIdValidationFailedState
): RegisterAction => {
  return {
    type: 'USER_ID_VALIDATION_FAILED',
    payload
  }
}

export const validateUserId =
  (options: CheckUserIdOptions) =>
  async (
    dispatch: RegisterThunkDispatch,
    getState: () => RootState
  ): Promise<void> => {
    try {
      const validationError = await checkUserId(options)

      if (validationError) {
        const {
          registration: { formErrors }
        } = getState()

        dispatch(
          handleUserIdValidationFailed({
            formErrors: {
              ...formErrors,
              registrationUID: translate({ string: 'unavailableUID' })
            },
            isUniqueUserId: false
          })
        )
      } else {
        dispatch(updateIsUniqueUserId(true))
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
      dispatch(updateIsUniqueUserId(true))
    }
  }

export const postIdpRegistration =
  (options: SearchUserInfo) =>
  async (dispatch: RegisterThunkDispatch): Promise<void> => {
    const { accountNumber, zipCode, dob } = options
    dispatch(updateIsSubmitting(true))

    if (dob) {
      const dateOfBirth = reformat({ date: dob })
      dispatch(
        postIdpAuthenticate({ accountNumber, dateOfBirth }, FlowId.REGISTRATION)
      )
    } else {
      dispatch(
        postIdpAuthenticate({ accountNumber, zipCode }, FlowId.REGISTRATION)
      )
    }
  }

export const updateCurrentStep = (step: CurrentStep): RegisterAction => {
  return {
    type: 'UPDATE_CURRENT_STEP',
    payload: step
  }
}
