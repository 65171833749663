import styled from 'styled-components'

export const SpinnerWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto 30px;
`

export const LoadingWrapper = styled.div`
  margin: 50px 0 80px 0;
`
