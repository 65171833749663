export const formatCreditCard = (value: string): string => {
  const v = value.replace(/\s+/g, '').replace(/[^0-9*]/gi, '')
  const match = v.length > 4 ? v : ''
  const parts = []
  if (!match) return value
  for (let i = 0, len = match.length; i < len; i += 4) {
    parts.push(match.substring(i, i + 4))
  }
  return parts.length ? parts.join(' ') : value
}

export const maskCreditCard = (value: string): string =>
  formatCreditCard(value.replace(/ /g, '').replace(/.(?=.{4,}$)/g, '*'))

export const formatPhoneNumber = (value: string): string => {
  const v = value
    .replace(/ /g, '')
    .replace(/\s+/g, '')
    .replace(/[^0-9*]/gi, '')
  return v.replace(
    /^([0-9]{3})-?([0-9]{3})-?([0-9]{4})(ext|x)?([0-9]+)?$/i,
    '$1-$2-$3'
  )
}
export const checkValue = (str: string, max: number): string => {
  let parsedString = str
  if (parsedString.charAt(0) !== '0' || str === '00') {
    let num = parseInt(parsedString, 10)
    if (Number.isNaN(num) || num <= 0 || num > max) num = 1
    parsedString =
      num > parseInt(max.toString().charAt(0), 10) &&
      num.toString().length === 1
        ? `0${num}`
        : num.toString()
  }
  return parsedString
}

export const formatDate = (value: string): string => {
  let formatDateValue = value
  if (/\D\/$/.test(formatDateValue))
    formatDateValue = formatDateValue.substr(0, value.length - 3)
  const values = formatDateValue.split('/').map(v => v.replace(/\D/g, ''))
  if (values[0]) values[0] = checkValue(values[0], 12)
  if (values[1]) values[1] = checkValue(values[1], 31)
  const output = values.map((v, i) => (v.length === 2 && i < 2 ? `${v}/` : v))
  return output.join('').substr(0, 10)
}

export const formatAccountLengthsIntoString = (
  acceptedLengthsArray: number[],
  conjunction: string
): string => {
  if (acceptedLengthsArray.length === 1) return `${acceptedLengthsArray[0]}`
  if (acceptedLengthsArray.length === 2)
    return `${acceptedLengthsArray[0]} ${conjunction} ${acceptedLengthsArray[1]}`
  if (acceptedLengthsArray.length >= 3)
    return `${acceptedLengthsArray.slice(0, -1)}`
      .replace(/,/g, ', ')
      .concat(
        ` ${conjunction} ${
          acceptedLengthsArray[acceptedLengthsArray.length - 1]
        }`
      )
  return ''
}
