import styled from 'styled-components'
import { PrimaryButton } from 'ui/atoms/Button'
import Body from 'syf-component-library/ui/typography/Body'
import H2 from 'syf-component-library/ui/typography/H2'

export const ErrorBody = styled.section`
  margin: 0 auto;
  text-align: center;
  background-color: ${p => p.theme.formBackground};
  border-bottom: 4px solid ${p => p.theme.headerDivider};
  padding: 3em 0;
  @media (min-width: 992px) {
    padding: 3em 0;
  }

  @media (max-width: 767px) {
    padding: 2em 1.5em;
  }
`

export const Container = styled.div`
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
`

export const BackButton = styled(PrimaryButton)`
  display: inline-flex;
`

export const Heading = styled(H2)`
  margin: 0.67em 0;
`

export const SubContent = styled(Body)`
  margin: 10px 0px;
`
