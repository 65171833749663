import React, { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { postIdpRegistration } from 'redux/registration/actions'
import { resetIdpError } from 'redux/idp/actions'
import { SearchUserInfo } from 'services/typings'
import AccountLookup from 'ui/organisms/Registration/AccountLookup'

const RegistrationAccountLookup = (): ReactElement => {
  const dispatch = useDispatch()

  const handleSearchResult = (options: SearchUserInfo) => {
    dispatch(postIdpRegistration(options))
  }

  const handleTextfieldFocus = () => {
    dispatch(resetIdpError())
  }

  return (
    <AccountLookup
      handleSearchResult={handleSearchResult}
      handleTextfieldFocus={handleTextfieldFocus}
    />
  )
}

export default RegistrationAccountLookup
