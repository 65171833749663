import React, { ReactElement } from 'react'
import ErrorPage from 'ui/atoms/ErrorPage'
import GoToSignInButton from 'ui/atoms/GoToSignInButton'
import translate from 'helpers/translate'

const PostRegistrationError = (): ReactElement => (
  <ErrorPage
    title={translate({ string: 'letsTryThatAgain' })}
    description={translate({ string: 'postRegistrationError' })}
  >
    <GoToSignInButton />
  </ErrorPage>
)

export default PostRegistrationError
