import React, { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import { updateIsModalOpen } from 'redux/uiUtils/actions'
import { postAuthenticate, updateLostStolenInfo } from 'redux/login/actions'
import { clearRegistrationState } from 'redux/registration/actions'
import { generateTransId } from 'services'
import {
  determineWhereToHandover,
  translate,
  triggerAnalyticsEvent
} from 'helpers'
import { clientInfo } from 'configureBrand'
import VerificationEndpoint from 'const/verificationEndPoint'
import { MfaValidationType } from 'const/mfa'
import ToastError from 'ui/atoms/ToastError'
import Template from 'ui/molecules/Template'
import SmartBanner from 'ui/molecules/SmartBanner'
import MaintenanceMessageBox from 'ui/organisms/MaintenanceMessageBox'
import NeedsVerificationModal from 'ui/organisms/NeedsVerificationModal/NonIdP'
import LostStolen from 'ui/organisms/LostStolen'
import BannerContainer from 'pages/Login/BannerContainer'
import LoginContainer from 'pages/Login/LoginContainer'
import { LoginInner } from 'pages/Login/subcomponents'

const Login = (): ReactElement => {
  const {
    uiUtils: { isModalOpen },
    login: { phoneNumbers, sessionId, userId, lostStolenInfo, formData },
    akamaiConfig: { hasJwtToken },
    registration: { searchUserResponse }
  } = useSelector((reduxState: RootState) => reduxState)
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    newCard: lostStolenNewCard,
    oldCard: lostStolenOldCard,
    accountType: lostStolenAccountType = '',
    clientId: lostStolenClientId = ''
  } = lostStolenInfo || {}
  const searchUserResult = searchUserResponse?.result
  const errorMessageData = searchUserResult?.message
  const { key, extension } = errorMessageData || {}
  const errorMessage = translate({
    string: key
  })
  const nonIdpErrorMessage = errorMessage && `${errorMessage} ${extension}`

  const handleCancelOtp = (): void => {
    dispatch(updateIsModalOpen(false))
    triggerAnalyticsEvent({ subFunction: '' })
  }

  const handleSubmit = () => {
    dispatch(clearRegistrationState())
    const transId = generateTransId()
    dispatch(
      postAuthenticate({
        ...formData,
        remember: formData?.rememberMe,
        transId
      })
    )
  }

  return (
    <Template
      renderBackground={!lostStolenInfo}
      innerCss={LoginInner}
      shouldLoadAllScripts
    >
      {(clientInfo.iOSAppId || clientInfo.androidApp.id) && (
        <SmartBanner data-test="smart-banner" />
      )}
      <MaintenanceMessageBox />
      {lostStolenInfo ? (
        <LostStolen
          dataType="login"
          oldCard={lostStolenOldCard}
          newCard={lostStolenNewCard}
          handleButtonClick={() =>
            determineWhereToHandover({
              accountType: lostStolenAccountType,
              clientId: lostStolenClientId,
              history,
              hasJwtToken
            })
          }
        />
      ) : (
        <>
          <BannerContainer />
          {nonIdpErrorMessage && <ToastError message={nonIdpErrorMessage} />}
          <LoginContainer
            data-test="login-container"
            handleSubmit={handleSubmit}
          />
        </>
      )}
      <NeedsVerificationModal
        endpoint={VerificationEndpoint.LOGIN}
        verificationType={MfaValidationType.OTP}
        showModal={isModalOpen}
        containerId="idpOtpModal"
        handleCancel={() => handleCancelOtp()}
        authData={{
          phoneNumbers,
          sessionId,
          userId
        }}
        handleError={verificationError => {
          if (verificationError.type === 'lost-stolen') {
            dispatch(updateIsModalOpen(false))
            dispatch(
              updateLostStolenInfo({
                oldCard: verificationError.oldCard,
                newCard: verificationError.newCard,
                accessToken: verificationError.accessToken,
                accountType: verificationError.accountType
              })
            )
          } else if (verificationError.type === 'customer-service') {
            dispatch(updateIsModalOpen(false))
          }
        }}
        data-test="needs-verification-modal"
      />
    </Template>
  )
}

export default Login
