import React, { ReactElement } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { translate } from 'helpers'
import { FlexButtonContainer, PrimaryButton } from 'ui/atoms/Button'
import Header from 'ui/atoms/Header'
import { RootState } from 'redux/store'
import { ContactNumber, Container, SorryDescription } from './subcomponents'
import { SorryProps } from './typings'

const formatPhoneNumber = (phoneNumber: string): string =>
  phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')

const Sorry = ({ contactNumber, handleCancel }: SorryProps): ReactElement => {
  const { isMaximumAttemptReached } = useSelector(
    (state: RootState) => state.otp,
    shallowEqual
  )

  return (
    <Container>
      <Header
        title={
          isMaximumAttemptReached
            ? translate({ string: 'identityConfiramation' })
            : translate({ string: 'weApologize' })
        }
        description={
          <SorryDescription>
            {isMaximumAttemptReached
              ? translate({ string: 'manageAccount' })
              : translate({ string: 'sorryDescription' })}{' '}
            <ContactNumber href={`tel:+${contactNumber}`}>
              {formatPhoneNumber(contactNumber)}.
            </ContactNumber>
          </SorryDescription>
        }
        labelledby="verification-title"
        describedby="verification-description"
      />

      <FlexButtonContainer centerAlign pt="20px">
        <PrimaryButton buttonType="primary" onClick={handleCancel}>
          {translate({ string: 'close' })}
        </PrimaryButton>
      </FlexButtonContainer>
    </Container>
  )
}

export default Sorry
