import React, { ReactElement } from 'react'
import {
  FlexButtonContainer,
  GhostButtonBase,
  PrimaryButtonBase,
  TextButtonBase
} from './subcomponents'
import { ButtonProps } from './typings'

export const PrimaryButton = ({
  children,
  disabled,
  ...rest
}: ButtonProps): ReactElement => {
  return (
    <PrimaryButtonBase buttonType="primary" disabled={disabled} {...rest}>
      {children}
    </PrimaryButtonBase>
  )
}

export const GhostButton = ({
  children,
  ...rest
}: ButtonProps): ReactElement => {
  return (
    <GhostButtonBase buttonType="ghost" {...rest}>
      {children}
    </GhostButtonBase>
  )
}

export const TextButton = ({
  children,
  ...rest
}: ButtonProps): ReactElement => {
  return (
    <TextButtonBase buttonType="text" {...rest}>
      {children}
    </TextButtonBase>
  )
}

export { FlexButtonContainer }
