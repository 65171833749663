import React, { ReactElement, useCallback } from 'react'
import translate from 'helpers/translate'
import { FA_EXCLAMATION_CIRCLE } from 'const/iconProp'
import {
  ErrorMessage,
  ErrorMessageWrapper,
  FontAwesomeIconStyle
} from './subcomponents'
import { InlineErrorProps } from './typings'

const InlineError = (props: InlineErrorProps): ReactElement => {
  const { style, error } = props
  const { key = '', argument = '', extension = '' } = error || {}
  const errorRef = useCallback(node => node?.focus(), [])

  return (
    !!key && (
      <ErrorMessageWrapper
        style={style}
        ref={errorRef}
        aria-live="assertive"
        data-test="error-wrapper"
        tabIndex={-1}
        {...props}
      >
        <FontAwesomeIconStyle
          icon={FA_EXCLAMATION_CIRCLE}
          title={translate({ string: 'requirementNotMet' })}
        />
        <ErrorMessage data-test="error-message">
          {translate({
            string: key,
            argument
          })}
          {extension && ` ${extension}`}
        </ErrorMessage>
      </ErrorMessageWrapper>
    )
  )
}

export default InlineError
