import api, { generateTransId } from 'services'
import config from 'const/config'
import { AxiosInstance } from 'axios'
import {
  addBmTelemetryToHeaders,
  determineVerifyResult,
  getCorrelationId
} from 'helpers'
import apiEndpoints from 'const/apiEndpoints'
import {
  VerificationEndpoint,
  Verify,
  VerifyOptions,
  VerifyResponseData
} from 'services/typings'

const verify = async (
  options: VerifyOptions,
  endpoint: VerificationEndpoint,
  axiosAPI: AxiosInstance = api
): Promise<Verify> => {
  const { sessionId, validationCode } = options
  const payload = {
    session_info: {
      session_id: sessionId
    },
    'cipher.validation_code': validationCode
  }

  const reqHeaders = await addBmTelemetryToHeaders({
    headers: {
      'X-SYF-Request-TrackingId': generateTransId(),
      'X-SYF-Request-CorrelationId': getCorrelationId()
    },
    params: {
      client_id: config.DSEC_APIGEE_CLIENT_ID
    }
  })

  const response = await axiosAPI.post(
    apiEndpoints.dsecurity.VERIFY(endpoint),
    payload,
    reqHeaders
  )

  const responseData: VerifyResponseData = response.data

  const result = determineVerifyResult(
    responseData.status.response_code,
    endpoint,
    responseData.account_type
  )

  return { responseData, result }
}

export default verify
