import { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import windowScrollTop from 'syf-js-utilities/helpers/windowScrollTop'
import { ScrollToTopProps } from './typings'

class ScrollToTop extends Component<ScrollToTopProps & RouteComponentProps> {
  componentDidUpdate(prevProps: ScrollToTopProps) {
    const { location } = this.props
    if (location.pathname !== prevProps.location.pathname) {
      windowScrollTop()
    }
  }

  render() {
    const { children } = this.props
    return children
  }
}

export default withRouter(ScrollToTop)
