import { RootState } from 'redux/store'
import { ThunkDispatch } from 'redux-thunk'
import loginApi from 'services/nonIdP/login'
import { Authenticate, VerificationEndpoint } from 'services/typings'
import { ErrorType } from 'types/errorTypes'
import {
  determineWhereToHandover,
  handleRedirectToErrorScreen,
  history,
  translate,
  triggerAnalyticsEvent
} from 'helpers'
import { OtpStart } from 'const/analytics/otp'
import config from 'const/config'
import generateToken from 'services/nonIdP/generateToken'
import {
  FormData,
  LoginAction,
  LostStolenInfo,
  PostAuthenticateOptions
} from './typings'
import { updateIsModalOpen, updateIsSubmitting } from '../uiUtils/actions'
import { UiAction } from '../uiUtils/typings'
import { incrementLoginAttemptsCount } from '../iva/actions'
import { IvaAction } from '../iva/typings'

export type LoginThunkDispatch = ThunkDispatch<
  RootState,
  undefined,
  LoginAction | UiAction | IvaAction
>

// -- Login index actions are here

export const updateAuthData = (value: Authenticate): LoginAction => {
  return { type: 'UPDATE_AUTH_DATA', payload: value }
}

export const updatePhoneNumbers = (
  phoneNumbers: Array<string>
): LoginAction => {
  return { type: 'UPDATE_PHONE_NUMBERS', payload: phoneNumbers }
}

export const updateSessionId = (sessionId: string): LoginAction => {
  return { type: 'UPDATE_SESSION_ID', payload: sessionId }
}

export const updateUserId = (userId: string): LoginAction => {
  return { type: 'UPDATE_USER_ID', payload: userId }
}

export const updateLostStolenInfo = (info: LostStolenInfo): LoginAction => {
  return { type: 'UPDATE_LOST_STOLEN_INFO', payload: info }
}

export const updateError = (error: ErrorType): LoginAction => {
  return { type: 'UPDATE_ERROR', payload: error }
}

// --Login Form container actions are here

export const updateSubmissionErrors = (errors: ErrorType): LoginAction => {
  return { type: 'UPDATE_SUBMISSION_ERRORS', payload: errors }
}

export const updateFormData = (formData: FormData): LoginAction => {
  return { type: 'UPDATE_FORM_DATA', payload: formData }
}

export const handleAuth =
  ({ responseData, result, codeVerifier }: Authenticate) =>
  async (
    dispatch: LoginThunkDispatch,
    getState: () => RootState
  ): Promise<void> => {
    const {
      akamaiConfig: { hasJwtToken }
    } = getState()

    const {
      account_type: accountType = '',
      authorization_code: authCode = '',
      phone_numbers: authPhoneNumbers = [],
      session_id: authSessionId,
      last_4_acct_no: newCard = '',
      prev_last_4_acct_no: oldCard = '',
      user_id: authUserId = '',
      client_id: clientId = config.RC_CLIENT_ID
    } = responseData

    let accessToken
    if (authCode) {
      accessToken = await generateToken({
        authCode,
        codeVerifier,
        clientId
      })
    }

    const shouldHandover = result.token && accessToken && accountType
    if (shouldHandover) {
      determineWhereToHandover({
        accountType,
        clientId,
        history,
        hasJwtToken
      })
    }

    if (result.otp) {
      dispatch(updateFormData({ userId: '', password: '' }))
      dispatch(updatePhoneNumbers(authPhoneNumbers))
      dispatch(updateSessionId(authSessionId))
      dispatch(updateUserId(authUserId))
      dispatch(updateIsModalOpen(true))
      triggerAnalyticsEvent({
        subFunction: OtpStart.PAGE_SUBFUNCTION,
        pageName: OtpStart.NAME
      })
    }

    if (result.redirect) {
      dispatch(updateFormData({ userId: '', password: '' }))
      dispatch(updateIsSubmitting(false))
      history.push({
        pathname: result.redirect,
        state: { error: result.message }
      })
    }

    if (result.lostStolen) {
      if (!newCard) {
        dispatch(updateError({ key: 'missingOld' }))
      } else {
        triggerAnalyticsEvent({
          pageName: 'lost-stolen'
        })
        dispatch(
          updateLostStolenInfo({
            oldCard,
            newCard,
            accessToken,
            accountType,
            clientId
          })
        )
      }
    }
  }

export const postAuthenticate =
  (
    { userId, password, remember, transId }: PostAuthenticateOptions,
    endpoint: VerificationEndpoint = 'login'
  ) =>
  async (dispatch: LoginThunkDispatch): Promise<void | UiAction> => {
    try {
      const auth = await loginApi.postAuthenticate(
        { userId, password, remember, transId },
        endpoint
      )
      dispatch(updateAuthData(auth))
      const isError =
        auth.result && auth.result.message && !auth.result.redirect
      if (isError) {
        dispatch(updateIsSubmitting(false))
        dispatch(incrementLoginAttemptsCount())
        dispatch(updateFormData({ userId: '', password: '' }))
        dispatch(
          updateSubmissionErrors({
            ...(auth.result?.message || '')
          })
        )
        return triggerAnalyticsEvent({ messageKey: auth.result.message.key })
      }
      dispatch(updateSubmissionErrors({}))
      return dispatch(handleAuth(auth))
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
      const { status } = err.response || {}
      handleRedirectToErrorScreen({
        errorCode: status
      })
      dispatch(
        updateSubmissionErrors({
          key: 'default',
          extension: `${translate({ string: 'code' })}: ${status}`
        })
      )
      return dispatch(updateIsSubmitting(false))
    }
  }

export const toggleRememberMe = (payload: boolean): LoginAction => {
  return { type: 'UPDATE_REMEMBER_ME', payload }
}
