import query from 'syf-component-library/const/mediaQueries'
import Button from 'syf-component-library/ui/atoms/Button'
import Checkbox from 'syf-component-library/ui/atoms/Checkbox'
import Textfield from 'syf-component-library/ui/atoms/Textfield'
import Body from 'syf-component-library/ui/typography/Body'
import H5 from 'syf-component-library/ui/typography/H5'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { colors } from 'theme'
import { PrimaryButton } from 'ui/atoms/Button'

export const HeadlineWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  @media ${query.lessThanMedium} {
    width: 275px;
  }
`

export const Headline = styled(H5)`
  color: ${p => p.theme.bodyText};
`

export const HeadlineUserName = styled(Body)`
  color: ${p => p.theme.bodyText};
  padding-bottom: 11px;
  padding-left: 0px;
  text-align: left;
  width: 100%;
  white-space: pre-wrap;
  word-break: break-word;
  @media ${query.lessThanMedium} {
    width: 290px;
  }
`

export const PwoliButton = styled(Button)`
  border-radius: 4px;
  border: solid 2px ${colors.blue};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 290px;
  height: 48px;
  cursor: pointer;
  background: white;
  color: ${colors.blue};
`

export const NotYouButton = styled(Button)`
  margin-left: auto;
  color: ${p => p.theme.link};
`

export const Divider = styled.div`
  width: 104px;
  height: 2px;
  border: solid 1px ${colors.lightGrey};
`

export const PwoliDivider = styled.div`
  margin-top: 25px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
`

export const PwoliTextDivider = styled(Body)`
  width: 17px;
  color: ${colors.darkCharcoal};
  margin-left: 19px;
  margin-right: 19px;
  margin-top: -12px;
`

export const PwoliImage = styled.img`
  width: 27px;
  height: 26.9px;
  background-color: ${colors.blue};
  margin-right: 5px;
  margin-bottom: 0px;
`

export const Container = styled.div`
  box-sizing: border-box;
  background-color: ${colors.white};
  padding: 18px;
  width: 100%;
  box-shadow: 0 0 4px 0 ${colors.boxShadowGrey};
  border: solid 1px ${colors.aluminum};
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${query.lessThanMedium} {
    padding: 0;
    border-color: transparent;
    border: none;
    box-shadow: none;
  }
`

export const LoginFormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const LoginButton = styled(PrimaryButton)`
  width: 100%;
  margin-bottom: 15px;
`

export const LinkWrapper = styled(Body)`
  user-select: none;
  text-align: center;
  color: ${colors.grey};
  letter-spacing: normal;
  > * {
    padding-left: 3px;
    padding-right: 3px;
  }
`

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 48px;
  > * {
    margin: 0;
  }
`

export const TextfieldWithMargin = styled(Textfield)`
  margin-bottom: 15px;
`

export const LastTextfield = styled(Textfield)`
  margin-bottom: 15px;
`

export const CheckboxWithRightLabel = styled(Checkbox)`
  margin-right: auto;
  margin-top: 15px;
  margin-bottom: 15px;
`

export const LockIconWithMargin = styled(FontAwesomeIcon)`
  margin-right: 8px;
`
