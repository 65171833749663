import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import { brandImages } from 'configureBrand'
import { colors } from 'theme'
import config from 'const/config'

export const BannerWrapper = styled.section`
  width: 100%;
  background-color: ${colors.white};
  padding: 1rem;
  box-sizing: border-box;
`

export const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
`

export const Title = styled.h1`
  font-family: Open Sans;
  word-wrap: break-word;
  font-size: 24px;
  font-weight: bold;
  color: ${colors.black};
  line-height: normal;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 18px;
  }
`

export const ArticleWrapper = styled.div`
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: space-between;

  @media (max-width: 767px) {
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
`

export const Article = styled.article`
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  @media (max-width: 767px) {
    &:first-child {
      margin-top: 2em;
    }
    &:last-child {
      margin-bottom: 2em;
    }
    padding: 0.5rem 0;
  }
`

export const IconWrapper = styled.div`
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  height: 70px;
  @media (max-width: 767px) {
    height: 36px;
  }
`

export const ArrowWrapper = styled.div`
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  margin-bottom: 10px;
  height: 70px;
  @media (max-width: 767px) {
    display: none;
  }
`

export const TextWrapper = styled.div`
  width: 210px;
`

export const BreakLine = styled.span`
  display: block;
  @media (max-width: 767px) {
    display: inline;
  }
`

export const H2 = styled.h2`
  font-size: 18px;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  color: ${colors.black};
`

export const BannerContentWrapper = styled.div``

export const Break = styled.span`
  display: block;
`

export const Img = styled.img.attrs({
  src:
    config.ASSET_ORIGIN +
    (isMobile ? brandImages.heroBgMobile : brandImages.heroBgDesktop),
  alt: 'hero banner'
})`
  display: block;
  width: 100%;
  height: auto;
  margin: 0 auto 0 auto; /* Centred */
  @media (max-width: 767px) {
    display: flex;
    display: -ms-flexbox;
    display: -webkit-flex;
    justify-content: center;
    margin-left: 0px;
  }
`

export const ImgWrapper = styled.div`
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
`
