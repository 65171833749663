import { DIGITS_ONLY, FIELD_REQUIRED, MIN_FOUR_DIGITS } from 'const/idpErrors'
import translate from 'helpers/translate'
import EMPTY_STRING from 'const/emptyString'

const testEmailFormat = (value: string) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  )
const isNotDigits = (value: string) => !/^\d+$/.test(value)
const emptyIsNumber = (value: string) => !/^[\s\d]*$/.test(value)
const checkMinLength = (value: string, desiredLength: number) =>
  value.length < desiredLength
const checkLength = (value: string, desiredLength: number) =>
  value.length !== desiredLength
const checkPhoneLength = (value: string, desiredLength: number) =>
  value.length !== 0 && value.length !== desiredLength
const checkAccountLength = (value: string, acceptedLengths: Array<number>) =>
  !acceptedLengths.includes(value.length)

const validateCVVOrSSN = (value: string, desiredLength: number) => {
  if (checkMinLength(value, 1)) {
    return translate({ string: 'required' })
  }
  if (isNotDigits(value)) {
    return translate({ string: 'digitsOnly' })
  }
  if (checkLength(value, desiredLength)) {
    if (desiredLength === 4) return translate({ string: 'minFourDigits' })
    if (desiredLength === 3) return translate({ string: 'cvvMinLength' })
  }
  return ''
}

const validationRules = {
  userId: (value: string) => {
    if (checkMinLength(value, 1)) {
      return translate({ string: 'required' })
    }
    if (checkMinLength(value, 6)) {
      return translate({ string: 'minSixChar' })
    }
    if (!/^[a-zA-Z0-9.\-_@]{6,50}$/.test(value)) {
      return translate({ string: 'invalidUID' })
    }
    return ''
  },

  registrationUID: (value: string) => {
    const invalidUID =
      !/^([\w-.]+@([\w-]+\.)+[a-zA-z]{2,4}?$|(?=.*[a-zA-Z])(?=.*[\w])[a-zA-Z0-9]{6,50}$)/.test(
        value
      )

    if (checkMinLength(value, 1)) {
      return translate({ string: 'required' })
    }
    if (checkMinLength(value, 6)) {
      return translate({ string: 'minSixChar' })
    }
    if (invalidUID) {
      return translate({ string: 'invalidUID' })
    }

    return ''
  },
  password: (value: string) =>
    value.length < 1 ? translate({ string: 'required' }) : '',
  newPassword: (value: string) => {
    return {
      required: !!value,
      length: value?.length >= 7 && value?.length < 21,
      hasNumber: /[0-9]/.test(value),
      hasLetter: /[a-zA-Z]/.test(value),
      noWhiteSpace: /^\S+$/.test(value),
      specialChar: /^[\w.!#$+-.:;=?@^_`|~,*]*$/.test(value)
    }
  },
  email: (value: string) => {
    if (checkMinLength(value, 1)) {
      return translate({ string: 'required' })
    }
    return !testEmailFormat(value) ? translate({ string: 'invalidEmail' }) : ''
  },
  ssn: (value: string) => {
    return validateCVVOrSSN(value, 4)
  },
  cvv: (value: string) => {
    return validateCVVOrSSN(value, 3)
  },
  otp: (value: string) => {
    if (checkMinLength(value, 1)) {
      return FIELD_REQUIRED
    }
    if (isNotDigits(value)) {
      return DIGITS_ONLY
    }
    if (checkLength(value, 4)) {
      return MIN_FOUR_DIGITS
    }
    return EMPTY_STRING
  },

  creditCard: (value: string, state: { acceptedLengths: Array<number> }) => {
    if (checkMinLength(value, 1)) {
      return translate({ string: 'required' })
    }

    if (isNotDigits(value)) {
      return translate({ string: 'digitsOnly' })
    }

    if (checkAccountLength(value, state.acceptedLengths)) {
      return translate({
        string: 'notAnAcceptedLength',
        argument: state.acceptedLengths
      })
    }

    return ''
  },
  phoneNumber: (value: string) => {
    if (emptyIsNumber(value)) {
      return translate({ string: 'digitsOnly' })
    }

    if (checkPhoneLength(value, 10)) {
      return translate({
        string: 'phoneNumberAcceptedLength'
      })
    }
    return ''
  },
  dob: (value: string, state: { inUsa: boolean }) => {
    if (state.inUsa) return ''
    if (checkMinLength(value, 1)) {
      return translate({ string: 'required' })
    }
    if (
      !/^(((0)[0-9])|((1)[0-2]))(\/)([0-2][0-9]|(3)[0-1])(\/)\d{4}$/.test(value)
    ) {
      return translate({ string: 'invalidDob' })
    }
    return ''
  },
  zipCode: (value: string, state: { inUsa: boolean }) => {
    if (!state.inUsa) return ''
    if (checkMinLength(value, 1)) {
      return translate({ string: 'required' })
    }
    if (isNotDigits(value)) {
      return translate({ string: 'digitsOnly' })
    }
    if (checkLength(value, 5)) {
      return translate({ string: 'minFiveDigits' })
    }
    return ''
  }
}

// eslint-disable-next-line
const validate = (target: string, value: string, state?: any): any =>
  validationRules[target as keyof typeof validationRules](value, state)

export default validate
