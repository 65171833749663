import formPost from 'syf-js-utilities/helpers/formPost'
import getQueryParamFromString from 'syf-js-utilities/formats/getQueryParamFromString'
import config from 'const/config'
import apiEndpoints from 'const/apiEndpoints'
import { FlowId } from 'const/idp'
import routes from 'const/routes'
import { history } from './triggerAnalyticsEvent'
import { getCurrentLanguageFromStorage } from './translate'
import handleRememberMe from './handleRememberMe'
import { IdpFormPostOptions } from './typings'

const idpFormPost = ({
  href,
  rememberMe,
  flowId = FlowId.LOGIN
}: IdpFormPostOptions): void => {
  try {
    if (href) {
      const formData = {
        id_token_hint: getQueryParamFromString(href, 'id_token_hint'),
        lang: getCurrentLanguageFromStorage()
      }

      if (flowId === FlowId.LOGIN) {
        handleRememberMe(formData.id_token_hint, rememberMe)
      }

      formPost(`${config.AUTH_BASE_URL}${apiEndpoints.idp.AUTHORIZE}`, formData)
    } else {
      throw new Error('Error: No href provided.')
    }
  } catch (error) {
    const errorPath =
      flowId === FlowId.LOGIN ? routes.ERROR : routes.POST_REGISTRATION_ERROR
    // eslint-disable-next-line no-console
    console.error(error)
    history.push({ pathname: errorPath })
  }
}

export default idpFormPost
