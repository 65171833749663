import SSN from 'const/analytics/ssn'
import { OtpStart } from 'const/analytics/otp'
import CVV from 'const/analytics/cvv'
import triggerAnalyticsEvent from 'helpers/triggerAnalyticsEvent'
import { MfaValidationType } from 'const/mfa'

export type ValidationType =
  | MfaValidationType.CVV
  | MfaValidationType.SSN
  | MfaValidationType.OTP
  | ''

const handleMfaMethodAnalyticsEvent = (
  validationtype: ValidationType
): void => {
  switch (validationtype) {
    case MfaValidationType.OTP:
      return triggerAnalyticsEvent({
        subFunction: OtpStart.PAGE_SUBFUNCTION,
        pageName: OtpStart.NAME
      })
    case MfaValidationType.SSN:
      return triggerAnalyticsEvent({
        subFunction: SSN.PAGE_SUBFUNCTION,
        pageName: SSN.NAME
      })
    default:
      return triggerAnalyticsEvent({
        subFunction: CVV.PAGE_SUBFUNCTION,
        pageName: CVV.NAME
      })
  }
}

export default handleMfaMethodAnalyticsEvent
