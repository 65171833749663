import getSleep from 'helpers/getSleep'
import { BlackBoxValues } from 'helpers/typings'

let counter = 0

const getBlackBoxValues = async (): Promise<BlackBoxValues> => {
  const blackboxValues =
    window.IGLOO && window.IGLOO.getBlackbox ? window.IGLOO.getBlackbox() : {}
  if (!blackboxValues.finished && counter < 10) {
    counter += 1
    await getSleep(500)
    return getBlackBoxValues()
  }
  return blackboxValues
}

export default getBlackBoxValues
