import {
  determineIsUnderMaintenance,
  getParameterByName,
  history,
  log
} from 'helpers'
import { ThunkDispatch } from 'redux-thunk'
import { RootState } from 'redux/store'
import configApi from 'services/akamaiConfig'
import {
  AkamaiAction,
  AkamaiConfigState,
  DownTimeState
} from 'redux/akamaiConfig/typings'
import config from 'const/config'
import {
  updateExpeditedRegistrationTransmitId,
  updateIsExpiredTransmitId
} from 'redux/registration/actions'
import routes from 'const/routes'
import { updateIsIdpFlow } from 'redux/idp/actions'
import { IdpAction } from 'redux/idp/typings'
import checkForDowntime from 'services/checkForDowntime'
import { RegisterAction } from 'redux/registration/typings'

type AkamaiConfigDispatch = ThunkDispatch<
  RootState,
  undefined,
  IdpAction | AkamaiAction | RegisterAction
>

export const updateShowIdpBanner = (showIdpBanner: boolean): AkamaiAction => {
  return {
    type: 'UPDATE_SHOW_IDP_BANNER',
    payload: showIdpBanner
  }
}

export const updateAkamaiConfig = (
  payload: AkamaiConfigState
): AkamaiAction => {
  return { type: 'UPDATE_AKAMAI_CONFIG', payload }
}

export const updateDowntimeConfig = (payload: DownTimeState): AkamaiAction => {
  return { type: 'UPDATE_AKAMAI_DOWNTIME_CONFIG', payload }
}

const updateSurflyFlag = (payload: boolean): AkamaiAction => {
  return { type: 'UPDATE_SURFLY_FLAG', payload }
}

export const checkForAkamaiConfig =
  () =>
  async (
    dispatch: AkamaiConfigDispatch,
    getState: () => RootState
  ): Promise<void> => {
    const {
      idp: { isIdpFlow }
    } = getState()
    const token = getParameterByName('token')
    const tid = getParameterByName('tid')
    const shouldUpdateIdpFlow = !isIdpFlow && token

    try {
      const {
        config: {
          idpBanner = false,
          disableExpReg = [],
          hasJwtToken = false,
          defaultIdpFlow = [],
          defaultAccountManagerOn = [],
          isIdpRegistrationFlow = !!token,
          isSurflyScriptTagEnabled = true
        }
      } = await configApi.getAkamaiConfig()

      if (shouldUpdateIdpFlow) {
        dispatch(
          updateIsIdpFlow(
            !idpBanner ||
              defaultIdpFlow.includes(config.RC_CLIENT_ID) ||
              defaultAccountManagerOn.includes(config.RC_CLIENT_ID)
          )
        )
      }

      dispatch(
        updateAkamaiConfig({
          showIdpBanner: !!(
            token &&
            idpBanner &&
            !defaultIdpFlow.includes(config.RC_CLIENT_ID)
          ),
          disableExpReg,
          hasJwtToken,
          isIdpRegistrationFlow: isIdpRegistrationFlow && !!token,
          coBrowse: {
            isSurflyScriptTagEnabled
          }
        })
      )

      if (disableExpReg?.indexOf(config.RC_CLIENT_ID) >= 0) {
        if (tid) {
          history.replace(routes.REGISTER)
          dispatch(updateIsExpiredTransmitId(true))
        }
      } else {
        dispatch(updateExpeditedRegistrationTransmitId(tid))
      }
    } catch (error) {
      dispatch(updateExpeditedRegistrationTransmitId(tid))
      dispatch(updateIsIdpFlow(!!token))
      dispatch(updateSurflyFlag(true))
      log.error(error)
    }
  }

export const checkForMaintenance =
  () =>
  async (dispatch: AkamaiConfigDispatch): Promise<void> => {
    try {
      const {
        downTime: {
          startDateTime = '',
          stopDateTime = '',
          downTimeMessage = ''
        },
        underMaintenance: isUnderGlobalMaintenance = false,
        isDowntimePay = false
      } = await checkForDowntime()

      const maintenanceData = {
        maintenanceStartTime: startDateTime,
        maintenanceEndTime: stopDateTime,
        isUnderMaintenance:
          isUnderGlobalMaintenance ||
          determineIsUnderMaintenance(startDateTime, stopDateTime),
        downTimeMessage,
        isDowntimePay
      }

      dispatch(updateDowntimeConfig(maintenanceData))
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }
