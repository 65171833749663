import React, { ReactElement } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FA_CHECK, FA_MINUS, FA_TIMES } from 'const/iconProp'
import { translate } from 'helpers'

const determineValidationIcon = (rule: string | boolean): ReactElement => {
  if (rule && rule !== 'pristine') {
    return (
      <FontAwesomeIcon
        title={translate({ string: 'requirementMet' })}
        icon={FA_CHECK}
      />
    )
  }
  if (rule !== 'pristine') {
    return (
      <FontAwesomeIcon
        title={translate({ string: 'requirementNotMet' })}
        icon={FA_TIMES}
      />
    )
  }
  return <FontAwesomeIcon icon={FA_MINUS} />
}

export default determineValidationIcon
