import React, { ReactElement, SyntheticEvent } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { formatPhoneNumber, translate, validate } from 'helpers'
import Header from 'ui/atoms/Header'
import InlineError from 'ui/atoms/InlineError/NonIdP'
import ToastError from 'ui/atoms/ToastError'
import {
  FlexButtonContainer,
  GhostButton,
  PrimaryButton
} from 'ui/atoms/Button'
import StandardRadio from 'ui/atoms/RadioButtons/StandardRadio'
import RadioGroup from 'ui/molecules/RadioGroup'
import { clientInfo } from 'configureBrand'
import { FA_EXCLAMATION_CIRCLE, FA_PHONE_ALT } from 'const/iconProp'
import { CommunicationPreference } from 'ui/organisms/NeedsVerificationModal/NonIdP/OTP/typings'
import {
  Container,
  Form,
  GroupTitle,
  HelpText,
  PhoneIcon,
  RadioContainer,
  RadioWrapper,
  TextfieldWithMargin
} from './subcomponents'
import { ConfirmProps, ConfirmState } from './typings'

export default class Confirm extends React.Component<
  ConfirmProps,
  ConfirmState
> {
  constructor(props: ConfirmProps) {
    super(props)
    this.state = {
      deliveryMethodError: false,
      deliveryAddressError: false,
      phoneNumber: '',
      selectedPhoneNumber: props.phoneNumbers?.[0],
      selectedCommunication: 'TEXT',
      phoneNumberError: ''
    }
  }

  componentDidUpdate = (prevProps: ConfirmProps): void => {
    const { statusCode } = this.props
    if (prevProps.statusCode !== statusCode && statusCode === '2005') {
      this.updateCommunication()
    }
  }

  updateCommunication = (): void =>
    this.setState({ selectedCommunication: 'CALL' })

  handleSubmit = (e: SyntheticEvent): void => {
    const { toggleIsSending, handleValidate } = this.props
    e.preventDefault()
    const { selectedCommunication, selectedPhoneNumber, phoneNumberError } =
      this.state
    const isValidFields =
      selectedCommunication && selectedPhoneNumber && !phoneNumberError
    if (isValidFields) {
      toggleIsSending()
      handleValidate({
        selectedCommunication,
        selectedPhoneNumber
      })
    } else {
      this.setState({
        deliveryMethodError: !selectedCommunication,
        deliveryAddressError: !selectedPhoneNumber
      })
    }
  }

  handleOnChange = (target: string, value: string): void => {
    this.setState(prevState => ({
      ...prevState,
      [target]: value,
      selectedPhoneNumber: value,
      phoneNumberError: ''
    }))
  }

  handleOnBlur = (): void => {
    const { phoneNumber, selectedCommunication, selectedPhoneNumber } =
      this.state
    const phoneNumberError = validate('phoneNumber', phoneNumber)
    this.setState({ phoneNumberError })
    if (!phoneNumberError && selectedPhoneNumber && selectedCommunication) {
      this.setState({ deliveryAddressError: false })
    }
  }

  setDataAttribute = (): string => {
    const { phoneNumbers } = this.props
    const { phoneNumber } = this.state
    const isUserEnteredPhoneNumber = phoneNumbers?.some((number: string) => {
      return number === phoneNumber
    })
    return isUserEnteredPhoneNumber ? 'entered-phone-number' : 'select'
  }

  render(): ReactElement {
    const {
      phoneNumbers = [],
      isSending,
      error,
      handleCancel,
      statusCode,
      handleStatusCode
    } = this.props

    const {
      selectedCommunication,
      selectedPhoneNumber,
      deliveryAddressError,
      deliveryMethodError,
      phoneNumber,
      phoneNumberError
    } = this.state

    const OTPMethods = [
      {
        label: translate({ string: 'sms' }),
        value: 'TEXT'
      },
      {
        label: translate({ string: 'call' }),
        value: 'CALL'
      }
    ]

    const isToastError = statusCode === '2005' || statusCode === '2003'
    const toastErrorMessage =
      statusCode === '2005'
        ? translate({
            string: 'landlineNumbeErrorMessage'
          })
        : translate({
            string: 'unableToVerify',
            argument: clientInfo.phoneNumbers.fraud
          })

    return (
      <Container>
        <Header
          title={translate({ string: 'confirmYourIdentity' })}
          description={translate({ string: 'sendYouOneTimeCode' })}
          data-test="subHeader"
          labelledby="verification-title"
          describedby="verification-description"
        />
        {isToastError && (
          <ToastError type="error" message={toastErrorMessage} />
        )}
        {(error || deliveryAddressError || deliveryMethodError) && (
          <InlineError
            centerAlign
            error={
              error || {
                key: 'selectAllRequired',
                extension: ' '
              }
            }
            data-test="errorWrapper"
          />
        )}

        <Form data-test="form" onSubmit={this.handleSubmit} noValidate>
          <RadioContainer>
            <RadioWrapper>
              <GroupTitle>
                {translate({ string: 'selectPhoneNumber' })}
              </GroupTitle>
              <RadioGroup
                data-test="sendCodeTo"
                data-reason={this.setDataAttribute()}
                hasError={!!deliveryAddressError}
                aria-label={translate({ string: 'selectPhoneNumber' })}
                aria-required
                selectedValue={selectedPhoneNumber}
                toggleRadioValue={(value: string) => {
                  this.setState({
                    selectedPhoneNumber: value,
                    deliveryAddressError: false,
                    phoneNumber: ''
                  })
                  handleStatusCode()
                  this.setState({ phoneNumberError: '' })
                }}
                secondary
              >
                {phoneNumbers.map(number => (
                  <StandardRadio
                    key={number}
                    name={translate({ string: 'selectPhoneNumber' })}
                    label={translate({
                      string: 'phoneNumberEnding',
                      argument: number.slice(6, 10)
                    })}
                    value={number}
                  />
                ))}
              </RadioGroup>
              <TextfieldWithMargin
                id="phoneNumber"
                name="phoneNumber"
                type="text"
                data-reason="enter"
                value={
                  phoneNumber ? formatPhoneNumber(phoneNumber) : phoneNumber
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  this.handleOnChange(
                    'phoneNumber',
                    e.target.value.replace(/-/g, '')
                  )
                }
                onFocus={() => handleStatusCode()}
                maxLength={10}
                placeholder={translate({ string: 'enterPhoneNumber' })}
                onBlur={() => phoneNumber && this.handleOnBlur()}
                error={phoneNumberError}
                leftIcon={<PhoneIcon icon={FA_PHONE_ALT} />}
                errorIcon={<FontAwesomeIcon icon={FA_EXCLAMATION_CIRCLE} />}
                width="250px"
                data-private
              />
            </RadioWrapper>

            <RadioWrapper>
              <GroupTitle>{translate({ string: 'sendCodeAs' })}</GroupTitle>
              <RadioGroup
                data-test="sendCodeAs"
                hasError={!!deliveryMethodError}
                aria-label={translate({ string: 'sendCodeAs' })}
                aria-required
                selectedValue={selectedCommunication}
                toggleRadioValue={(value: CommunicationPreference) => {
                  this.setState({
                    selectedCommunication: value,
                    deliveryMethodError: false
                  })
                  handleStatusCode()
                }}
                secondary
              >
                {OTPMethods.map(name => (
                  <StandardRadio
                    label={name.label}
                    value={name.value}
                    name={translate({ string: 'sendCodeAs' })}
                    key={name.value}
                  />
                ))}
              </RadioGroup>
            </RadioWrapper>
          </RadioContainer>

          <FlexButtonContainer centerAlign pt="20px">
            <PrimaryButton
              type="submit"
              buttonType="primary"
              title={translate({ string: 'submit' })}
              disabled={isSending}
              className="ensightencustomevent"
              data-reason="continue"
              data-type="otp"
              data-object="button"
            >
              {isSending
                ? translate({ string: 'sending' })
                : translate({ string: 'continue' })}
            </PrimaryButton>
            <GhostButton
              buttonType="ghost"
              title={translate({ string: 'cancelToCloseDialog' })}
              onClick={handleCancel}
              className="ensightencustomevent"
              data-reason="cancel"
              data-type="otp"
              data-object="button"
            >
              {translate({ string: 'cancel' })}
            </GhostButton>
          </FlexButtonContainer>
        </Form>
        <HelpText data-test="helpText">
          {translate({ string: 'whenYouChooseContinue' })}
        </HelpText>
      </Container>
    )
  }
}
