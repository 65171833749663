import styled from 'styled-components'
import { PrimaryButton } from 'ui/atoms/Button'
import query from 'syf-component-library/const/mediaQueries'
import Textfield from 'syf-component-library/ui/atoms/Textfield'
import H5 from 'syf-component-library/ui/typography/H5'
import { colors } from 'theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Container = styled.div`
  width: 290px;
  display: flex;
  flex-direction: column;
  align-self: center;
  padding-top: 22px;
  margin: 0 auto;
  @media ${query.greaterThanMedium} {
    width: 100%;
    align-items: center;
  }
`

export const CreateKeyButton = styled(PrimaryButton)`
  margin-top: 30px;
`

export const Main = styled.div`
  margin: 0;
  max-width: 100%;
  width: 100%;
  height: 100%;
`

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${colors.white};
  border-bottom: 4px solid ${p => p.theme.headerDivider};
  padding: 0;
  @media (min-width: 992px) {
    padding: 1em 0;
  }
  @media (max-width: 767px) {
    padding: 1em 0;
  }
`

export const LinkWrapper = styled.p`
  user-select: none;
  text-align: center;
  font-size: 14px;
  color: ${colors.grey};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  > * {
    padding-left: 3px;
    padding-right: 3px;
  }
`

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 18px;
  min-height: 48px;
  > * {
    margin: 0;
  }
`

export const TextfieldWithMargin = styled(Textfield)`
  margin-bottom: 15px;
`

export const TitleWrapper = styled.div`
  width: 100%;
  @media ${query.lessThanMedium} {
    width: 280px;
  }
`

export const Title = styled(H5)`
  text-align: center;
  @media ${query.greaterThanMedium} {
    width: 100%;
    align-items: center;
    padding-top: 20px;
  }
`

export const LockIconWithMargin = styled(FontAwesomeIcon)`
  margin-right: 8px;
`
