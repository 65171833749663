/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios'
import config from 'const/config'

declare module 'axios' {
  export interface AxiosRequestConfig {
    status?: number
    baseURL?: string
  }
}

export default axios.create({
  baseURL: config.API_HOST || 'https://MissingUrl.com'
})

export const hostNameServices = axios.create({
  baseURL: config.TARGET_ORIGIN || `https://MissingAPI.com`
})

export const configServices = axios.create({
  baseURL: config.ASSET_ORIGIN || 'https://MissingAPI.com'
})

export const generateTransId = (a?: number): string => {
  return a // if the placeholder was passed, return
    ? // a random number from 0 to 15
      /* eslint-disable no-bitwise */
      (
        a ^ // unless b is 8, eslint-disable-line no-bitwise
        ((Math.random() * // in which case
          16) >> // a random number from
          (a / 4))
      ) // 8 to 11
        .toString(16) // in hexadecimal
    : // or otherwise a concatenated string:
      (
        ([1e7] as any) + // 10000000 +
        -1e3 + // -1000 +
        -4e3 + // -4000 +
        -8e3 + // -80000000 +
        -1e11
      ) // -100000000000,
        .replace(
          // replacing
          /[018]/g, // zeroes, ones, and eights with
          generateTransId // random hex digits
        ) /* eslint-enable no-bitwise */
}
