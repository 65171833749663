import React, { ReactElement, SyntheticEvent, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { translate, validate } from 'helpers'
import {
  RegisterThunkDispatch,
  checkRegistrationEligibility,
  clearRegistrationState,
  clearVerifyUserResponse,
  verifyUser
} from 'redux/registration/actions'
import { RootState } from 'redux/store'
import { updateIsLoading, updateIsSubmitting } from 'redux/uiUtils/actions'
import { FA_EXCLAMATION_CIRCLE, FA_LOCK } from 'const/iconProp'
import Textfield from 'syf-component-library/ui/atoms/Textfield'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  FlexButtonContainer,
  GhostButton,
  PrimaryButton
} from 'ui/atoms/Button'
import { useHistory } from 'react-router-dom'
import routes from 'const/routes'
import { MfaValidationType } from 'const/mfa'
import { ErrorToast, Form, Header, LoadingSpinner } from './subcomponents'

const ExpeditedRegistrationSSN = (): ReactElement => {
  const {
    uiUtils: { isSubmitting, isLoading },
    registration: { transmitId, searchUserResponse }
  } = useSelector((state: RootState) => state, shallowEqual)
  const history = useHistory()
  const dispatch = useDispatch()
  const submitButtonLabel = isSubmitting ? 'submitting' : 'submit'
  const { session_id: sessionId } = searchUserResponse?.responseData || {}
  const [ssn, setSsn] = useState<string>('')
  const [formError, setFormError] = useState<string>('')

  const handleSubmit = (e: SyntheticEvent): RegisterThunkDispatch | void => {
    e.preventDefault()
    dispatch(updateIsSubmitting(true))
    const error = validate(MfaValidationType.SSN, ssn)
    if (error) {
      dispatch(updateIsSubmitting(false))
      return setFormError(error)
    }
    return dispatch(verifyUser({ sessionId, validationCode: ssn }))
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFormError('')
    setSsn(e.target.value)
  }

  const handleOnBlur = (ssnValue: string): void =>
    setFormError(validate(MfaValidationType.SSN, ssnValue))

  useEffect(() => {
    dispatch(updateIsLoading(true))
    dispatch(checkRegistrationEligibility({ transmitId }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <LoadingSpinner />
      <Header />
      <ErrorToast />
      {!isLoading ? (
        <Form
          data-test="form"
          data-reason="form"
          onSubmit={handleSubmit}
          noValidate
        >
          <Textfield
            id="ssn"
            name="ssn"
            data-reason="ssn-field"
            type="password"
            inputMode="numeric"
            value={ssn}
            className="masked"
            data-test="ssn-field"
            helpText={translate({ string: 'pwdHelpText' })}
            onChange={handleOnChange}
            onFocus={() => dispatch(clearVerifyUserResponse())}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleOnBlur(e.target.value)
            }
            maxLength={4}
            placeholder={translate({ string: 'last4DigitSSN' })}
            error={formError}
            leftIcon={<FontAwesomeIcon icon={FA_LOCK} />}
            errorIcon={<FontAwesomeIcon icon={FA_EXCLAMATION_CIRCLE} />}
            width="275px"
            data-private
            required
          />
          <FlexButtonContainer centerAlign>
            <PrimaryButton
              buttonType="primary"
              title={translate({ string: 'submit' })}
              type="submit"
              disabled={isSubmitting}
              className="ensightencustomevent"
              data-reason="submit"
              data-test="submit"
              data-type="ssn verify"
              data-object="button"
            >
              {translate({ string: submitButtonLabel })}
            </PrimaryButton>

            <GhostButton
              buttonType="ghost"
              title={translate({ string: 'cancelToCloseDialog' })}
              onClick={() => {
                dispatch(clearRegistrationState())
                history.push({
                  pathname: routes.REGISTER
                })
              }}
              className="ensightencustomevent"
              data-reason="cancel"
              data-type="ssn verify"
              data-object="button"
            >
              {translate({ string: 'cancel' })}
            </GhostButton>
          </FlexButtonContainer>
        </Form>
      ) : null}
    </>
  )
}

export default ExpeditedRegistrationSSN
