import config from 'const/config'
import {
  addBmTelemetryToHeaders,
  determineAuthCplResult,
  getCorrelationId,
  getParameterByName
} from 'helpers'
import { AnyObject } from 'types/custom'
import apiEndpoints from 'const/apiEndpoints'
import { AuthenticateCplLoginResponse, Request } from 'services/typings'
import api, { generateTransId } from 'services'

const authenticate = async (
  options: Request
): Promise<AuthenticateCplLoginResponse> => {
  const queryParams = window.location.search
  const { userId, password } = options
  const { blackbox }: AnyObject =
    window.IGLOO && window.IGLOO.getBlackbox ? window.IGLOO.getBlackbox() : {}
  const client = config.RC_CLIENT_ID
  const oauthRedirectUri = getParameterByName('redirect_uri', queryParams)
  const oauthState = getParameterByName('state', queryParams)
  const oauthData = getParameterByName('data', queryParams)
  const clientId = getParameterByName('client_id', queryParams)
  const oauthNonce = getParameterByName('nonce', queryParams)

  const payload = {
    auth: {
      client_id: clientId,
      redirect_uri: oauthRedirectUri,
      scope: 'account-linking',
      state: oauthState,
      nonce: oauthNonce === null ? '' : oauthNonce
    },
    device_info: {
      black_box: blackbox
    },
    user_info: { user_id: userId, 'cipher.password': password }
  }
  const reqHeaders = await addBmTelemetryToHeaders({
    headers: {
      'X-SYF-Request-TrackingId': generateTransId(),
      'X-SYF-Request-CorrelationId': getCorrelationId()
    },
    params: {
      data: oauthData
    }
  })

  const { data } = await api.post(
    apiEndpoints.dsecurity.AUTHENTICATE('login'),
    payload,
    reqHeaders
  )

  const result = determineAuthCplResult(data)

  return {
    data,
    result,
    client
  }
}

export default authenticate
