import styled from 'styled-components'
import Textfield from 'syf-component-library/ui/atoms/Textfield'
import SimpleModalPortal from 'syf-component-library/ui/patterns/SimpleModalPortal'

export const Form = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 325px;
  margin: 0 auto;
`

export const TextfieldWithMargin = styled(Textfield)`
  margin-bottom: 5px;
`

export const ModalContainer = styled(SimpleModalPortal)`
  max-width: 875px;
  z-index: 10;
`
