import styled from 'styled-components'
import { colors } from 'theme'
import H4 from 'syf-component-library/ui/typography/H4'
import H5 from 'syf-component-library/ui/typography/H5'

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 28px;
  text-align: center;
`

const HeaderTitle = styled(H4)`
  margin-bottom: 6px;
`

const SubHeading = styled(H5)`
  color: ${colors.darkGrey};
`
export { HeaderWrapper, HeaderTitle, SubHeading }
