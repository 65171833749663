import styled from 'styled-components'
import Textfield from 'syf-component-library/ui/atoms/Textfield'

const Form = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const TextfieldWithMargin = styled(Textfield)`
  margin-bottom: 5px;
`
export { Form, TextfieldWithMargin }
