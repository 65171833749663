import React, { ReactElement } from 'react'
import { useLocation } from 'react-router-dom'
import { FA_EXCLAMATION_TRIANGLE } from 'const/iconProp'
import { clientInfo } from 'configureBrand'
import translate from 'helpers/translate'
import ErrorPage from 'ui/atoms/ErrorPage'
import { ErrorLocationStateProps } from 'types/locationState'

const LockedAccountError = (): ReactElement => {
  const location = useLocation<ErrorLocationStateProps>()
  const { fraud, customerService } = clientInfo.phoneNumbers
  const phoneNumber =
    location.state?.responseCode === '1004' ? fraud : customerService

  return (
    <ErrorPage
      title={translate({ string: 'weApologize' })}
      description={translate({
        string: 'accountLockedError',
        argument: phoneNumber
      })}
      errorIcon={FA_EXCLAMATION_TRIANGLE}
    />
  )
}

export default LockedAccountError
