import React, { ReactElement, SyntheticEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Textfield from 'syf-component-library/ui/atoms/Textfield'
import { Container } from 'pages/UserIDFound/subcomponents'
import translate from 'helpers/translate'
import getIdpErrorMessage from 'helpers/generateIdpErrorMessage'
import triggerAnalyticsEvent from 'helpers/triggerAnalyticsEvent'
import isToastError from 'helpers/isToastError'
import { FA_EXCLAMATION_CIRCLE } from 'const/iconProp'
import { RootState } from 'redux/store'
import {
  FlexButtonContainer,
  GhostButton,
  PrimaryButton
} from 'ui/atoms/Button'
import RoundedRadio from 'ui/atoms/RadioButtons/RoundedRadio'
import RadioGroup from 'ui/molecules/RadioGroup'
import Header from 'ui/atoms/Header'
import { updateIsModalOpen, updateIsSubmitting } from 'redux/uiUtils/actions'
import {
  clearIdpState,
  handleOtpValidate,
  resetIdpError
} from 'redux/idp/actions'
import { updateFormData } from 'redux/login/actions'
import ToastError from 'ui/atoms/ToastError'
import {
  GetNewCodeWrapper,
  Label,
  RememberDeviceHelpText,
  SaveDetailsContainer,
  VerifyForm,
  radioStyles
} from './subcomponents'

const Verify = (): ReactElement => {
  const [bindDevice, setBindDevice] = useState<boolean>(false)
  const [verificationCode, setVerificationCode] = useState<string>('')
  const dispatch = useDispatch()
  const { isSubmitting, lastFourOfSelectedPhoneNumber, idpErrorCode, flowId } =
    useSelector((state: RootState) => {
      return {
        isSubmitting: state.uiUtils.isSubmitting,
        lastFourOfSelectedPhoneNumber: state.idp.lastFourOfSelectedPhoneNumber,
        idpErrorCode: state.idp.idpErrorCode,
        flowId: state.idp.flowId
      }
    })

  const idpErrorMessage = idpErrorCode
    ? getIdpErrorMessage({ idpErrorCode, flowId })
    : ''
  const isToastMessage = isToastError(idpErrorCode)

  const handleCancel = () => {
    dispatch(updateIsModalOpen(false))
    dispatch(updateIsSubmitting(false))
    dispatch(clearIdpState())
    triggerAnalyticsEvent({ subFunction: '' })
    dispatch(updateFormData({ userId: '', password: '' }))
  }

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault()
    dispatch(
      handleOtpValidate({
        code: verificationCode,
        deviceRegister: bindDevice
      })
    )
  }

  const handleOnChange = (input: string) => {
    dispatch(resetIdpError())
    setVerificationCode(input)
  }

  return (
    <>
      <Container>
        <Header
          data-test="subHeader"
          title={translate({ string: 'codeEnter' })}
          description={translate({
            string: 'yourCodeHasBeenSent',
            argument: lastFourOfSelectedPhoneNumber
          })}
          labelledby="verification-title"
          describedby="verification-description"
        />
        {idpErrorMessage && isToastMessage && (
          <ToastError
            type="error"
            data-test="idp-toast-error"
            message={idpErrorMessage}
          />
        )}
        <VerifyForm data-test="form" onSubmit={handleSubmit}>
          <Textfield
            id="otp"
            data-test="otp-field"
            onChange={(e: SyntheticEvent) =>
              handleOnChange((e.target as HTMLInputElement).value)
            }
            value={verificationCode}
            errorIcon={
              <FontAwesomeIcon
                icon={FA_EXCLAMATION_CIRCLE}
                title={translate({ string: 'requirementNotMet' })}
              />
            }
            error={!isToastMessage && idpErrorMessage}
            type="text"
            placeholder={translate({ string: 'otpField' })}
            maxLength={4}
            matchPattern={/^([0-9]*){3,5}$/}
            data-private
          />
          <GetNewCodeWrapper />
          <SaveDetailsContainer>
            <Label>{translate({ string: 'saveComputer' })}</Label>
            <RadioGroup
              aria-label={translate({ string: 'saveComputer' })}
              selectedValue={bindDevice}
              toggleRadioValue={setBindDevice}
            >
              <RoundedRadio
                value
                key={translate({ string: 'yes' })}
                name={translate({ string: 'saveComputer' })}
                label={translate({ string: 'yes' })}
                data-reason="save computer:yes"
                data-type="otp"
                data-object="checkbox"
                style={radioStyles}
              />
              <RoundedRadio
                value={false}
                key={translate({ string: 'no' })}
                name={translate({ string: 'saveComputer' })}
                label={translate({ string: 'no' })}
                data-reason="save computer:no"
                data-type="otp"
                data-object="checkbox"
                style={radioStyles}
              />
            </RadioGroup>
          </SaveDetailsContainer>

          <RememberDeviceHelpText>
            {translate({ string: 'rememberDevice' })}
          </RememberDeviceHelpText>

          <FlexButtonContainer centerAlign pt="20px">
            <PrimaryButton
              title={translate({ string: 'verifyTheCode' })}
              type="submit"
              buttonType="primary"
              disabled={isSubmitting}
              className="ensightencustomevent"
              data-reason="verify code"
              data-type="otp"
              data-object="button"
            >
              {isSubmitting
                ? translate({ string: 'submitting' })
                : translate({ string: 'submit' })}
            </PrimaryButton>

            <GhostButton
              title={translate({ string: 'closeThePopup' })}
              onClick={handleCancel}
              buttonType="ghost"
              className="ensightencustomevent"
              data-reason="cancel"
              data-type="otp"
              data-object="button"
              data-test="verify-cancel-button"
            >
              {translate({ string: 'cancel' })}
            </GhostButton>
          </FlexButtonContainer>
        </VerifyForm>
      </Container>
    </>
  )
}
export default Verify
