import cplSystemErrorDescriptors, {
  CplErrorDescriptorKeys
} from 'const/cplSystemErrorDescriptors'
import { Dispatch } from 'redux'
import routes from 'const/routes'
import authenticateCpl from 'services/cpl/persistLogin/authenticate'
import { ThunkDispatch } from 'redux-thunk'
import { RootState } from 'redux/store'
import {
  CplAction,
  CplFormdata,
  CplHandoverProps,
  ErrorProps
} from 'redux/cpl/typings'
import { updateIsModalOpen, updateIsSubmitting } from 'redux/uiUtils/actions'
import { UiAction } from 'redux/uiUtils/typings'
import generateToken from 'services/cpl/persistLogin/generateToken'
import generateUUID from 'syf-js-utilities/helpers/generateUUID'
import { AuthenticateCplResponseData } from 'services/typings'
import { OtpStart } from 'const/analytics/otp'
import authenticate from 'services/cpl/accountLinking/authenticate'
import {
  determineCplErrorCode,
  determineCplErrorType,
  formatQueryParams,
  getParameterByName,
  history,
  isCplRedirectError,
  redirectToClientUrl,
  triggerAnalyticsEvent
} from 'helpers'
import { ErrorType } from 'types/errorTypes'

type CplThunkDispatch = ThunkDispatch<
  RootState,
  undefined,
  CplAction | UiAction
>

export const updateCplSystemError = (
  code: number,
  descriptor: string
): CplAction => {
  return {
    type: 'UPDATE_CPL_SYSTEM_ERROR',
    payload: {
      code,
      descriptor,
      isAccountLinking: history.location.pathname === routes.CPL
    }
  }
}

export const handleCplErrorResponseRedirect =
  (status: number, endpoint: CplErrorDescriptorKeys) =>
  (dispatch: Dispatch<CplAction>): void => {
    const cplErrorType = determineCplErrorType()
    if (isCplRedirectError(status, endpoint)) {
      const cplErrorDescriptor =
        cplSystemErrorDescriptors[endpoint][cplErrorType]
      const { descriptor, code } = cplErrorDescriptor[status]
      dispatch(updateCplSystemError(code, descriptor))
      history.push({
        pathname: routes.CPL_ERROR,
        search: window.location.search
      })
    }
  }

export const updateCplAuthResponseData = (
  cplAuthResponse: AuthenticateCplResponseData
): CplAction => {
  return { type: 'UPDATE_CPL_AUTHENTICATE_RESPONSE', payload: cplAuthResponse }
}

export const updateIsPersistError = (isPersistError: boolean): CplAction => ({
  type: 'UPDATE_IS_PERSIST_ERROR',
  payload: isPersistError
})

const handleError =
  ({ errorCode, errorDetail, errorDescriptorKey }: ErrorProps) =>
  (dispatch: CplThunkDispatch): void => {
    dispatch(updateIsModalOpen(false))

    if (isCplRedirectError(errorCode, errorDescriptorKey)) {
      dispatch(handleCplErrorResponseRedirect(errorCode, errorDescriptorKey))
    } else {
      dispatch(updateCplSystemError(errorCode, errorDetail || ''))
      if (errorDescriptorKey === CplErrorDescriptorKeys.PERSIST_AUTH) {
        dispatch(updateIsPersistError(true))
      }
    }
  }

export const resetSubmissionError = (): CplAction => {
  return {
    type: 'RESET_SUBMISSION_ERROR'
  }
}

export const updateSubmissionError = (error: ErrorType): CplAction => {
  return {
    type: 'UPDATE_SUBMISSION_ERROR',
    payload: error
  }
}

export const postAccountLinkingAuthentication =
  (formData: CplFormdata) =>
  async (dispatch: CplThunkDispatch): Promise<void> => {
    try {
      const queryParams = window.location.search
      const oauthRedirectUri = decodeURIComponent(
        getParameterByName('redirect_uri', queryParams)
      )
      const oauthClientId = decodeURIComponent(
        getParameterByName('client_id', queryParams)
      )
      const oauthState = decodeURIComponent(
        getParameterByName('state', queryParams)
      )

      if (oauthRedirectUri && oauthClientId) {
        dispatch(updateIsSubmitting(true))
        const response = await authenticate(formData)
        const { data, result } = response || {}
        const { response_code: responseCode } = data?.status || {}
        const { message, otp: isMfaRequired } = result || {}

        if (responseCode === '1005' || responseCode === '1007') {
          const { authorization_code: authCode } = data || {}
          const redirectUri = formatQueryParams(
            oauthRedirectUri,
            `code=${authCode}&state=${oauthState}`
          )
          triggerAnalyticsEvent({ pageName: 'complete' })
          redirectToClientUrl(redirectUri)
        } else if (message) {
          dispatch(updateIsSubmitting(false))
          dispatch(updateSubmissionError(message))
          triggerAnalyticsEvent({ messageKey: message.key })
        } else if (isMfaRequired) {
          dispatch(updateCplAuthResponseData(data))
          triggerAnalyticsEvent({
            subFunction: OtpStart.PAGE_SUBFUNCTION,
            pageName: OtpStart.NAME
          })
          dispatch(updateIsModalOpen(true))
        }
      } else {
        dispatch(updateIsSubmitting(false))
        dispatch(updateSubmissionError({ key: 'default' }))
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      const { data } = error?.response || {}
      const errorCode = determineCplErrorCode(error, 'account-linking')

      dispatch(
        handleError({
          errorCode,
          errorDetail: data?.message,
          errorDescriptorKey: CplErrorDescriptorKeys.AUTH
        })
      )
    }
  }

export const handlePersistHandover =
  ({
    authCode,
    codeVerifier,
    accountType,
    oauthRedirectUri
  }: CplHandoverProps) =>
  async (dispatch: CplThunkDispatch): Promise<void> => {
    await generateToken({
      authCode,
      codeVerifier,
      accountType,
      clientRedirectUri: oauthRedirectUri,
      errorDescriptorKey: CplErrorDescriptorKeys.HANDOVER,
      handleError: ({ errorCode, errorDetail, errorDescriptorKey }) => {
        dispatch(
          handleError({
            errorCode,
            errorDetail,
            errorDescriptorKey
          })
        )
      }
    })
  }

export const postPersistentAuthentication =
  () =>
  async (dispatch: CplThunkDispatch): Promise<void> => {
    const oauthParams = window?.location?.search
    const oauthRedirectUri = decodeURIComponent(
      getParameterByName('redirect_uri', oauthParams)
    )
    try {
      dispatch(updateIsSubmitting(true))
      const oauthAccessToken = decodeURIComponent(
        getParameterByName('access_token', oauthParams)
      )
      const oauthNonce = decodeURIComponent(
        getParameterByName('nonce', oauthParams)
      )
      const oauthClientId = decodeURIComponent(
        getParameterByName('client_id', oauthParams)
      )
      const codeVerifier = generateUUID()

      const response = await authenticateCpl({
        oauthClientId,
        oauthAccessToken,
        oauthNonce,
        codeVerifier
      })
      const {
        status: { response_code: responseCode = '' },
        authorization_code: authCode = '',
        account_type: accountType = ''
      } = response || {}

      switch (responseCode) {
        case '1008': {
          dispatch(updateCplAuthResponseData({ ...response, codeVerifier }))
          triggerAnalyticsEvent({
            subFunction: OtpStart.PAGE_SUBFUNCTION,
            pageName: OtpStart.NAME
          })
          dispatch(updateIsModalOpen(true))
          break
        }
        case '1005':
        case '1009': {
          dispatch(
            handlePersistHandover({
              authCode,
              accountType,
              codeVerifier,
              oauthRedirectUri
            })
          )
          break
        }
        default: {
          dispatch(
            handleError({
              errorCode: parseInt(responseCode, 10),
              errorDetail: '',
              errorDescriptorKey: CplErrorDescriptorKeys.PERSIST_AUTH
            })
          )
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      const { status, data } = error?.response || {}

      dispatch(
        handleError({
          errorCode: status,
          errorDetail: data?.message,
          errorDescriptorKey: CplErrorDescriptorKeys.PERSIST_AUTH
        })
      )
    }
  }
