import config from 'const/config'
import triggerAnalyticsEvent, { history } from 'helpers/triggerAnalyticsEvent'
import { LanguageType } from 'helpers/translate'
import routes from 'const/routes'
import { clientInfo } from 'configureBrand'
import { PathNames } from './typings'

export const handleLanguageToggle = (
  currentLanguage: LanguageType
): LanguageType => {
  const newLanguage = currentLanguage === 'en' ? 'es' : 'en'
  const { _SFDDL: sfddl } = window
  const {
    pageInfo: { PageSubFunction: subFunction }
  } = sfddl
  localStorage.setItem(`language-${config.RC_CLIENT_ID}`, newLanguage)
  triggerAnalyticsEvent({ subFunction })
  return newLanguage
}

export const getVoiceRouteTermsLink = (): string => {
  let voiceRouteTermsLink = ''

  if (
    history.location.pathname === routes.GOOGLE ||
    history.location.pathname === routes.ALEXA
  ) {
    const path = history.location.pathname.substring(1)
    const loginType = clientInfo.voice[
      path as keyof typeof clientInfo.voice
    ] as PathNames

    voiceRouteTermsLink = loginType.termsAndConditionsLink
  }
  return voiceRouteTermsLink
}
