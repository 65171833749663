import WebAuthnSupportMessage from 'const/webAuthnSupportMessage'

const determineWebAuthnSupport = async (): Promise<WebAuthnSupportMessage> => {
  const { PublicKeyCredential } = window
  if (PublicKeyCredential) {
    const hasAuthenticatorSupport =
      await PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable()
    return hasAuthenticatorSupport
      ? WebAuthnSupportMessage.FULL_SUPPORT
      : WebAuthnSupportMessage.PARTIAL_SUPPORT
  }
  return WebAuthnSupportMessage.NO_SUPPORT
}

export default determineWebAuthnSupport
