import formPost from 'syf-js-utilities/helpers/formPost'
import {
  getCorrelationId,
  getCurrentLanguageFromStorage,
  getParameterByName
} from 'helpers'
import { isMobile } from 'react-device-detect'
import { clientInfo } from 'configureBrand'
import config from 'const/config'
import apiEndpoints from 'const/apiEndpoints'
import { HandoverToServicingRequest, PostFormData } from 'services/typings'
import api, { generateTransId } from 'services'

const handoverToServicing = async ({
  accountType,
  clientId,
  syfIdentifier = '',
  hasJwtToken
}: HandoverToServicingRequest): Promise<void> => {
  const deepLinkQueryParam = getParameterByName('link')
  const handOverQueryParam = deepLinkQueryParam
    ? `?link=${deepLinkQueryParam}`
    : ''
  try {
    const language = getCurrentLanguageFromStorage()
    const headerConfig = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('madAccessToken')}`,
        'X-SYF-Request-TrackingId': generateTransId(),
        'X-SYF-Request-CorrelationId': getCorrelationId()
      }
    }
    const apiEndpoint = hasJwtToken
      ? `${apiEndpoints.dsecurity.HANDOVER}?token=jwt`
      : apiEndpoints.dsecurity.HANDOVER
    const handOver = hasJwtToken ? 'handOverJwt' : 'handOver'

    const { data } = await api.get(apiEndpoint, headerConfig)

    const redirectExtension =
      clientInfo.hasMss && isMobile && language === 'en'
        ? `/mService/AccountSummary/${handOver}.action${handOverQueryParam}`
        : `/eService/AccountSummary/${handOver}.action${handOverQueryParam}`
    const redirectURL = config.TARGET_ORIGIN + redirectExtension
    const formData: PostFormData = {
      accountType,
      data: data.service_token,
      clientId: clientId || config.RC_CLIENT_ID,
      transferLoginAccessed: 'false',
      langId: language,
      startTime: '',
      syfIdentifier,
      URL: redirectExtension,
      tokenId: getCorrelationId()
    }
    if (deepLinkQueryParam) formData.link = deepLinkQueryParam
    sessionStorage.setItem('madAccessToken', '')
    sessionStorage.setItem('madSessionId', '')
    sessionStorage.setItem('madApiBaseUrl', '')
    return formPost(redirectURL, formData)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    throw error
  }
}

export default handoverToServicing
