import React, { ReactElement } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import styled from 'styled-components'
import HeaderBase from 'ui/atoms/Header'
import Spinner from 'syf-component-library/ui/atoms/Loader/Spinner'
import ToastError from 'ui/atoms/ToastError'
import translate from 'helpers/translate'

export const Form = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
`

export const SpinnerWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto 30px;
`

export const LoadingSpinner = (): ReactElement => {
  const { isLoading } = useSelector(
    (state: RootState) => state.uiUtils,
    shallowEqual
  )
  return isLoading ? (
    <SpinnerWrapper>
      <Spinner data-test="spinner" size="3x" />
    </SpinnerWrapper>
  ) : null
}

export const Header = (): ReactElement => {
  const { isLoading } = useSelector(
    (state: RootState) => state.uiUtils,
    shallowEqual
  )
  const titleLabel = isLoading ? 'findingYourAccount' : 'continueRegistration'
  const descriptionLabel = isLoading
    ? 'securelyLocatingYourAccount'
    : 'littleMoreInformation'

  return (
    <HeaderBase
      data-test="header"
      title={translate({ string: titleLabel })}
      description={translate({ string: descriptionLabel })}
    />
  )
}

export const ErrorToast = (): ReactElement => {
  const { result, error } = useSelector(
    (state: RootState) => state.registration,
    shallowEqual
  )
  const errorMessageData = result?.message || error
  const { key, argument, extension } = errorMessageData || {}
  const errorMesage = translate({
    string: key,
    argument: argument || ''
  })
  const errorCode = `${extension}`
  const message = `${errorMesage} ${errorCode}`
  return errorMesage ? (
    <ToastError type="error" data-test="toast-error" message={message} />
  ) : null
}

export const LoadingWrapper = styled.div`
  margin: 50px 0 80px 0;
`
