import React, { ReactElement, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import {
  handleProfileCreate,
  updateFormErrors,
  validateUserId,
  verifyUser
} from 'redux/registration/actions'
import { getCurrentLanguageFromStorage, validate } from 'helpers'
import { TermsOfService } from 'const/idp'
import InlineError from 'ui/atoms/InlineError/NonIdP'
import Form from 'ui/organisms/Registration/Form'
import { useLocation } from 'react-router-dom'
import { LocationState } from 'types/locationState'

interface RegistrationFormProps {
  returnTo?: string
}

const RegistrationForm = ({
  returnTo
}: RegistrationFormProps): ReactElement => {
  const { registration } = useSelector(
    (state: RootState) => ({
      registration: state.registration
    }),
    shallowEqual
  )
  const {
    accessToken,
    ebillStatus,
    registerForm,
    formErrors,
    searchUserResponse,
    error
  } = registration || {}
  const [ebillSelection, setEbillSelection] = useState<string>(
    ebillStatus ? '0' : '1'
  )
  const { newPassword, registrationUID, email } = registerForm || {}
  const authData = searchUserResponse?.responseData
  const { session_id: sessionId } = authData || {}
  const dispatch = useDispatch()
  const { state } = useLocation<LocationState>()
  const { queryParams = '' } = state || {}
  const language = getCurrentLanguageFromStorage()

  const handleSubmit = (sendEmailOffers: boolean): void => {
    dispatch(
      handleProfileCreate({
        accessToken,
        sessionId,
        registrationUID,
        newPassword,
        email,
        ebillSelection,
        sendEmailOffers
      })
    )
  }

  const handleOnBlur = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target
    if (name === 'registrationUID' && registrationUID) {
      dispatch(
        validateUserId({
          accessToken,
          registrationUID
        })
      )
    }

    dispatch(updateFormErrors({ ...formErrors, [name]: validate(name, value) }))
  }

  useEffect(() => {
    if (!accessToken) {
      dispatch(verifyUser({ sessionId }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const errorWrapper = error && <InlineError error={error} centerAlign />

  return (
    <Form
      returnTo={`${returnTo}${queryParams}`}
      eConsentLink={TermsOfService[language]}
      ebillStatus={ebillStatus}
      ebillSelection={ebillSelection}
      handleEbillSelection={setEbillSelection}
      handleOnBlur={handleOnBlur}
      handleOnSubmit={handleSubmit}
      errorWrapper={errorWrapper}
      hasEmailOffers
    />
  )
}

export default RegistrationForm
