import React, { ReactElement, useEffect } from 'react'
import windowScrollTop from 'syf-js-utilities/helpers/windowScrollTop'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import IdpRegistration from './IdP'
import NonIdpRegistration from './NonIdP'

const Registration = (): ReactElement => {
  const {
    idp: { isIdpFlow },
    akamaiConfig: { isIdpRegistrationFlow },
    registration: { currentStep }
  } = useSelector((reduxState: RootState) => reduxState)

  useEffect(() => {
    windowScrollTop()
  }, [currentStep])

  return isIdpFlow && isIdpRegistrationFlow ? (
    <IdpRegistration data-test="idp-registration" />
  ) : (
    <NonIdpRegistration data-test="non-idp-registration" />
  )
}

export default Registration
