import React, { ReactElement, useState } from 'react'
import translate from 'helpers/translate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  FA_CHECK,
  FA_EXCLAMATION_CIRCLE,
  FA_INFO_CIRCLE,
  FA_LOCK
} from 'const/iconProp'
import determineValidationIcon from './determineValidationIcon'
import {
  IconWrapper,
  Rule,
  RulesContainer,
  RulesDescription,
  TextfieldWithMargin
} from './subcomponents'
import { TextfieldWithValidationRulesProps } from './typings'

const TextfieldWithValidationRules = ({
  value,
  onChange,
  onBlur,
  validationErrors,
  updatePassword,
  onFocus,
  name,
  ...props
}: TextfieldWithValidationRulesProps): ReactElement => {
  const [showRulesContainer, setShowRulesContainer] = useState(false)

  const checkPasswordRule = (rule: string): string => {
    const ruleDescription = {
      length: translate({ string: 'pwdLength' }),
      noWhiteSpace: translate({ string: 'noWhiteSpace' }),
      hasNumber: translate({ string: 'hasNumber' }),
      hasLetter: translate({ string: 'hasLetter' }),
      specialChar: translate({ string: 'pwdSpecialChar' }),
      lastFour: translate({ string: 'lastFour' })
    }
    return ruleDescription[rule as keyof typeof ruleDescription]
  }

  const errorKey = Object.keys(validationErrors).find(
    rule => !validationErrors[rule]
  )

  const pristine = Object.keys(validationErrors).find(
    rule => validationErrors[rule] === 'pristine'
  )

  return (
    <>
      <TextfieldWithMargin
        id="password"
        name={name || 'password'}
        type="password"
        value={value}
        onChange={onChange}
        maxLength={20}
        onFocus={() => {
          setShowRulesContainer(true)
          onFocus()
        }}
        onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
          onBlur(event)
          if (!event.target.value) {
            setShowRulesContainer(false)
          }
        }}
        placeholder={translate({ string: 'password' })}
        error={
          errorKey &&
          translate({
            string: errorKey === 'required' ? 'required' : 'newPassword'
          })
        }
        inputClassName="masked ensightencustomevent"
        data-reason="password"
        data-type="login"
        data-object="field"
        data-private
        leftIcon={<FontAwesomeIcon icon={FA_LOCK} />}
        errorIcon={<FontAwesomeIcon icon={FA_EXCLAMATION_CIRCLE} />}
        showMaskedOption
        hideTextValue={translate({ string: 'hide' })}
        showTextValue={translate({ string: 'show' })}
        width="275px"
        required
        {...props}
      />
      <RulesContainer showRulesContainer={showRulesContainer}>
        {showRulesContainer && (errorKey || pristine) ? (
          <>
            {Object.keys(validationErrors).map(rule => {
              const validationRule = validationErrors[rule]
              const validationIcon = determineValidationIcon(validationRule)

              return (
                rule !== 'specialChar' &&
                rule !== 'required' &&
                rule !== 'lastFour' && (
                  <Rule
                    key={rule}
                    pristine={validationRule === 'pristine'}
                    hasError={!validationRule}
                  >
                    <IconWrapper>{validationIcon}</IconWrapper>
                    <RulesDescription>
                      {checkPasswordRule(rule)}
                    </RulesDescription>
                  </Rule>
                )
              )
            })}
            <Rule pristine>
              <IconWrapper>
                <FontAwesomeIcon icon={FA_INFO_CIRCLE} />
              </IconWrapper>
              <RulesDescription>
                {checkPasswordRule('specialChar')}
              </RulesDescription>
            </Rule>
            {updatePassword && (
              <Rule pristine>
                <IconWrapper>
                  <FontAwesomeIcon icon={FA_INFO_CIRCLE} />
                </IconWrapper>
                <RulesDescription>
                  {checkPasswordRule('lastFour')}
                </RulesDescription>
              </Rule>
            )}
          </>
        ) : (
          !errorKey &&
          !pristine && (
            <Rule>
              <IconWrapper>
                <FontAwesomeIcon icon={FA_CHECK} />
              </IconWrapper>

              <span>{translate({ string: 'readyToGo' })}</span>
            </Rule>
          )
        )}
      </RulesContainer>
    </>
  )
}

export default TextfieldWithValidationRules
