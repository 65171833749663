import React, { ReactElement, SyntheticEvent, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import {
  formatPhoneNumber,
  translate,
  triggerAnalyticsEvent,
  validate
} from 'helpers'
import Header from 'ui/atoms/Header'
import {
  FlexButtonContainer,
  GhostButton,
  PrimaryButton
} from 'ui/atoms/Button'
import RadioGroup from 'ui/molecules/RadioGroup'
import StandardRadio from 'ui/atoms/RadioButtons/StandardRadio'
import { RootState } from 'redux/store'
import { colors } from 'theme'
import {
  clearIdpState,
  postIdpOtpInitiate,
  resetIdpError,
  updateIdpErrorCode,
  updateLastFourOfSelectedPhoneNumber
} from 'redux/idp/actions'
import { updateFormData } from 'redux/login/actions'
import { updateIsModalOpen, updateIsSubmitting } from 'redux/uiUtils/actions'
import { FA_EXCLAMATION_CIRCLE, FA_PHONE_ALT } from 'const/iconProp'
import InlineError from 'ui/atoms/InlineError/IdP'
import { SELECT_ALL_REQUIRED_FIELDS } from 'const/idpErrors'
import { CommunicationPreference } from 'redux/idp/typings'
import {
  Container,
  Form,
  GroupTitle,
  HelpText,
  RadioContainer,
  RadioWrapper,
  TextfieldWithMargin
} from './subcomponents'
import { OtpInitiateErrors, OtpInitiateValues } from './typings'
import { OTPMethods, phoneConfirmState } from './const'

const Confirm = (): ReactElement => {
  const { mfaPhoneNumbers, isSubmitting, isPhoneConfirmMax } = useSelector(
    (state: RootState) => {
      return {
        mfaPhoneNumbers: state.idp.mfaPhoneNumbers,
        isSubmitting: state.uiUtils.isSubmitting,
        isPhoneConfirmMax: state.idp.isPhoneConfirmMax
      }
    }
  )

  const [formValues, setFormValues] = useState<OtpInitiateValues>({
    ...phoneConfirmState.initialValues,
    selectedPhoneNumber: mfaPhoneNumbers[0]?.phone.phoneNumber
  })

  const [errors, setErrors] = useState<OtpInitiateErrors>(
    phoneConfirmState.initialErrors
  )

  const { phoneNumber, selectedCommunication, selectedPhoneNumber } = formValues

  const { phoneNumberError, deliveryAddressError, deliveryMethodError } = errors
  const isValidSelection =
    selectedCommunication && selectedPhoneNumber && !phoneNumberError
  const phoneInputValue = isPhoneConfirmMax
    ? ''
    : formatPhoneNumber(phoneNumber)

  const dispatch = useDispatch()

  const handleCancel = () => {
    dispatch(updateIsModalOpen(false))
    dispatch(updateIsSubmitting(false))
    dispatch(clearIdpState())
    dispatch(resetIdpError())
    triggerAnalyticsEvent({ subFunction: '' })
    dispatch(updateFormData({ userId: '', password: '' }))
  }

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault()
    const lastFour = selectedPhoneNumber?.slice(-4)
    dispatch(updateLastFourOfSelectedPhoneNumber(lastFour))
    dispatch(updateIsSubmitting(true))
    if (isValidSelection) {
      dispatch(
        postIdpOtpInitiate({
          phoneNumber: selectedPhoneNumber,
          deliveryMethod: selectedCommunication
        })
      )
    } else {
      setErrors({
        ...errors,
        deliveryMethodError: !selectedCommunication,
        deliveryAddressError: !selectedPhoneNumber
      })
      dispatch(updateIsSubmitting(false))
      dispatch(updateIdpErrorCode(SELECT_ALL_REQUIRED_FIELDS))
    }
  }

  const handlePhoneNumberEntry = (e: React.ChangeEvent<HTMLInputElement>) => {
    const phoneNumberValue = e.target.value.replace(/-/g, '')
    setFormValues({
      ...formValues,
      phoneNumber: phoneNumberValue,
      selectedPhoneNumber: phoneNumberValue
    })
    setErrors({ ...errors, phoneNumberError: '' })
  }

  const handleOnBlur = (): void => {
    const validatePhoneNumberError = validate('phoneNumber', phoneNumber)
    setErrors({
      ...errors,
      deliveryAddressError: isValidSelection
        ? false
        : errors.deliveryAddressError,
      phoneNumberError: validatePhoneNumberError
    })
  }

  return (
    <Container>
      <Header
        title={translate({ string: 'confirmYourIdentity' })}
        description={translate({ string: 'sendYouOneTimeCode' })}
        data-test="subHeader"
        labelledby="verification-title"
        describedby="verification-description"
      />
      <InlineError />
      <Form data-test="form" onSubmit={handleSubmit} noValidate>
        <RadioContainer>
          <RadioWrapper>
            <GroupTitle>
              {translate({ string: 'selectPhoneNumber' })}
            </GroupTitle>
            <RadioGroup
              data-test="sendCodeTo"
              data-reason="select"
              hasError={deliveryAddressError}
              aria-label={translate({ string: 'selectPhoneNumber' })}
              aria-required
              selectedValue={selectedPhoneNumber}
              toggleRadioValue={(value: string) => {
                setFormValues({
                  ...formValues,
                  phoneNumber: '',
                  selectedPhoneNumber: value
                })
                setErrors({
                  ...errors,
                  deliveryAddressError: false,
                  phoneNumberError: ''
                })
                dispatch(resetIdpError())
              }}
              secondary
            >
              {mfaPhoneNumbers.map(mfaPhone => {
                const { phone } = mfaPhone
                return (
                  <StandardRadio
                    name={translate({ string: 'selectPhoneNumber' })}
                    label={translate({
                      string: 'phoneNumberEnding',
                      argument: phone.phoneNumber.slice(6, 10)
                    })}
                    value={phone.phoneNumber}
                    key={phone.phoneNumber}
                  />
                )
              })}
            </RadioGroup>
            <TextfieldWithMargin
              id="phoneNumber"
              name="phoneNumber"
              type="text"
              data-reason="enter"
              data-test="phone-number-field"
              value={phoneInputValue}
              onChange={handlePhoneNumberEntry}
              onFocus={() => dispatch(resetIdpError())}
              maxLength={10}
              placeholder={translate({ string: 'enterPhoneNumber' })}
              onBlur={() => phoneNumber && handleOnBlur()}
              error={phoneNumberError}
              leftIcon={
                <FontAwesomeIcon
                  icon={FA_PHONE_ALT}
                  color={colors.darkGreyFiftyFour}
                />
              }
              errorIcon={<FontAwesomeIcon icon={FA_EXCLAMATION_CIRCLE} />}
              width="250px"
              isDisabled={isPhoneConfirmMax && mfaPhoneNumbers?.length > 0}
              data-private
            />
          </RadioWrapper>

          <RadioWrapper>
            <GroupTitle>{translate({ string: 'sendCodeAs' })}</GroupTitle>
            <RadioGroup
              data-test="sendCodeAs"
              hasError={deliveryMethodError}
              aria-label={translate({ string: 'sendCodeAs' })}
              aria-required
              selectedValue={selectedCommunication}
              toggleRadioValue={(value: CommunicationPreference) => {
                setFormValues({ ...formValues, selectedCommunication: value })
                setErrors({ ...errors, deliveryMethodError: false })
                dispatch(resetIdpError())
              }}
              secondary
            >
              {OTPMethods.map(method => (
                <StandardRadio
                  key={method.value}
                  name={translate({ string: 'sendCodeAs' })}
                  label={method.label}
                  value={method.value}
                />
              ))}
            </RadioGroup>
          </RadioWrapper>
        </RadioContainer>

        <FlexButtonContainer centerAlign pt="20px">
          <PrimaryButton
            type="submit"
            buttonType="primary"
            title={translate({ string: 'submit' })}
            disabled={isSubmitting}
            className="ensightencustomevent"
            data-reason="continue"
            data-type="otp"
            data-object="button"
          >
            {isSubmitting
              ? translate({ string: 'sending' })
              : translate({ string: 'continue' })}
          </PrimaryButton>
          <GhostButton
            buttonType="ghost"
            title={translate({ string: 'cancelToCloseDialog' })}
            onClick={handleCancel}
            className="ensightencustomevent"
            data-reason="cancel"
            data-type="otp"
            data-object="button"
          >
            {translate({ string: 'cancel' })}
          </GhostButton>
        </FlexButtonContainer>
      </Form>
      <HelpText data-test="helpText">
        {translate({ string: 'whenYouChooseContinue' })}
      </HelpText>
    </Container>
  )
}
export default Confirm
