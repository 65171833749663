import { JwtAction, JwtState } from './typings'

export const initialState: JwtState = {
  active: false,
  returnTo: '',
  idpSessionId: '',
  isJwtLoading: true,
  canRetryLogin: false
}

export default function (
  state: JwtState = initialState,
  action: JwtAction = undefined
): JwtState {
  if (
    action.type === 'UPDATE_JWT_INTROSPECT' ||
    action.type === 'UPDATE_IS_JWT_LOADING' ||
    action.type === 'UPDATE_JWT_ERROR'
  ) {
    return { ...state, ...action.payload }
  }
  return state
}
