import { DebounceOneMS } from 'helpers/typings'

export const debounce = (
  // eslint-disable-next-line @typescript-eslint/ban-types
  func: Function,
  wait: number,
  immediate?: boolean
): (() => void) => {
  let timeout: number
  return function executedFunction(...args: unknown[]) {
    const later = () => {
      timeout = null
      if (!immediate) func.apply(this, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = window.setTimeout(later, wait)
    if (callNow) func.apply(this, args)
  }
}

export const debounceOneMS: DebounceOneMS = debounce(
  // eslint-disable-next-line @typescript-eslint/ban-types
  (func: Function) => func(),
  1000
)
