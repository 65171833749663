import React, { ReactElement } from 'react'
import ErrorPage from 'ui/atoms/ErrorPage'
import GoToSignInButton from 'ui/atoms/GoToSignInButton'
import translate from 'helpers/translate'
import { FA_EXCLAMATION_TRIANGLE } from 'const/iconProp'

const AlreadyRegisteredError = (): ReactElement => (
  <ErrorPage
    title={translate({ string: 'accountAlreadyRegisteredTitle' })}
    description={translate({ string: 'accountAlreadyRegisteredBody' })}
    errorIcon={FA_EXCLAMATION_TRIANGLE}
  >
    <GoToSignInButton />
  </ErrorPage>
)

export default AlreadyRegisteredError
