import { history } from 'helpers/triggerAnalyticsEvent'

const determineCplRoute = (): boolean => {
  const pattern1 = /\/cpl/
  const pattern2 = /\/persist/
  const pattern3 = /\/persist-error/
  const pattern4 = /\/google/
  const pattern5 = /\/alexa/
  return (
    pattern1.test(history.location.pathname) ||
    pattern2.test(history.location.pathname) ||
    pattern3.test(history.location.pathname) ||
    pattern4.test(history.location.pathname) ||
    pattern5.test(history.location.pathname)
  )
}

export default determineCplRoute
