import api from 'services'
import config from 'const/config'
import {
  addBmTelemetryToHeaders,
  convertPayloadToFormData,
  createCodeChallenge,
  triggerAnalyticsEvent
} from 'helpers'
import handoverToClient from 'services/cpl/persistLogin/handoverToClient'
import apiEndpoints from 'const/apiEndpoints'
import configApi from 'services/akamaiConfig'
import {
  GetCplTokenProps,
  GetTokenRequest,
  GetTokenResponse
} from 'services/typings'

const generateToken = async ({
  authCode,
  codeVerifier,
  accountType,
  clientRedirectUri,
  errorDescriptorKey,
  handleError
}: GetCplTokenProps): Promise<void> => {
  const codeChallenge = createCodeChallenge(codeVerifier)
  const payload: GetTokenRequest = {
    grant_type: 'authorization_code',
    code: authCode,
    client_id: config.PERSIST_APIGEE_CLIENT_ID,
    redirect_uri: 'https://redirect.synchronycredit.com',
    code_challenge: codeChallenge,
    code_challenge_method: 'S256',
    code_verifier: codeVerifier
  }
  const formattedPayload: string = convertPayloadToFormData(payload)

  const reqHeaders = await addBmTelemetryToHeaders({
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
  try {
    const response = await api.post(
      apiEndpoints.dsecurity.TOKEN_EXCHANGE,
      formattedPayload,
      reqHeaders
    )

    const responseCode = response?.status
    const responseData: GetTokenResponse = response?.data
    // if success, get Service token and handover to servicing
    if (responseCode === 200) {
      const {
        config: { hasJwtToken = false }
      } = await configApi.getAkamaiConfig()

      triggerAnalyticsEvent({
        pageName: 'complete'
      })

      handoverToClient({
        accessToken: responseData.access_token,
        accountType,
        clientRedirectUri,
        errorDescriptorKey,
        handleError,
        hasJwtToken
      })
    } else {
      handleError({
        errorCode: responseCode,
        redirectUri: clientRedirectUri,
        errorDescriptorKey
      })
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    handleError({
      errorCode: error?.response?.status || 0,
      redirectUri: clientRedirectUri,
      errorDetail: error?.response?.data?.message || '',
      errorDescriptorKey
    })
  }
}

export default generateToken
