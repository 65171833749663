import {
  addBmTelemetryToHeaders,
  getBlackBoxValues,
  getCorrelationId,
  getCurrentLanguageFromStorage
} from 'helpers'
import { addMinutes, format } from 'date-fns'
import { isBrowser } from 'react-device-detect'
import { generateTransId, hostNameServices } from 'services'
import config from 'const/config'
import { CheckForPWOLITokenProps } from './typings'

const checkForPWOLIToken = async (
  options: CheckForPWOLITokenProps
): Promise<string> => {
  const { route, identifier = '', clientId, api = hostNameServices } = options
  try {
    const currentLanguage = getCurrentLanguageFromStorage()
    const blackboxValues = await getBlackBoxValues()
    const date = new Date()
    const payload = {
      client_id: clientId || config.RC_CLIENT_ID,
      syf_identifier: identifier,
      lang_id: currentLanguage,
      trace_id: getCorrelationId(),
      trans_id: generateTransId(),
      timestamp: format(
        addMinutes(date, date.getTimezoneOffset()),
        'Y.MM.dd.HH.mm.ss'
      ),
      channel_id: `dsecurity_${isBrowser ? 'ess' : 'mss'}`,
      black_box: blackboxValues.blackbox
    }

    const reqHeaders = await addBmTelemetryToHeaders({
      headers: {}
    })

    const {
      data: { featureStatus, token }
    } = await api.post(route, payload, reqHeaders)

    return featureStatus === 'AVAILABLE' ? token : ''
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    throw error
  }
}

export default checkForPWOLIToken
