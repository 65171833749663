import React, { ReactElement } from 'react'
import { RootState } from 'redux/store'
import { useSelector } from 'react-redux'
import Verify from './Verify'
import Confirm from './Confirm'
import Sorry from './Sorry'

const OTP = (): ReactElement => {
  const { currentOtpStep } = useSelector((state: RootState) => state.idp)

  switch (currentOtpStep) {
    case 'confirm':
      return <Confirm />
    case 'verify':
      return <Verify />
    case 'sorry':
      return <Sorry />
    default:
      return null
  }
}

export default OTP
