import React, { ReactElement } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import translate from 'helpers/translate'
import { PrimaryButton } from 'ui/atoms/Button'
import Header from 'ui/atoms/Header'
import {
  ButtonContainer,
  EbillSuccess,
  EnrollmentFailure
} from './subcomponents'

interface CompleteProps {
  handleAccountSummary: () => void
  isEbillFailure?: boolean
  isAlertFailure?: boolean
}

const Complete = ({
  handleAccountSummary,
  isEbillFailure,
  isAlertFailure
}: CompleteProps): ReactElement => {
  const { isSubmitting, isIdpRegistrationFlow } = useSelector(
    (state: RootState) => ({
      isSubmitting: state.uiUtils.isSubmitting,
      isIdpRegistrationFlow: state.akamaiConfig.isIdpRegistrationFlow
    }),
    shallowEqual
  )

  const primaryButtonLabel = isIdpRegistrationFlow ? 'continue' : 'goToLogin'
  const isEnrollmentFailed = isEbillFailure || isAlertFailure

  const getTitleText = (): string => {
    if (isEnrollmentFailed) {
      return translate({
        string: isEbillFailure
          ? 'updateStatementPreference'
          : 'profileEnrollmentFailedTitle'
      })
    }
    return translate({ string: 'registrationComplete' })
  }

  const getDescriptionText = (): ReactElement => {
    if (isEnrollmentFailed) {
      return (
        <EnrollmentFailure>
          {translate({
            string: isEbillFailure
              ? 'thankYouMsg'
              : 'profileEnrollmentFailedBody'
          })}
        </EnrollmentFailure>
      )
    }

    return (
      <EbillSuccess>
        {translate({ string: 'registerOnline' })}
        <br />
        {isIdpRegistrationFlow
          ? translate({ string: 'rememberUserIdPwd' })
          : translate({ string: 'resetConfirmationBody' })}
      </EbillSuccess>
    )
  }

  return (
    <div>
      <Header title={getTitleText()} description={getDescriptionText()} />
      <ButtonContainer>
        <PrimaryButton
          buttonType="primary"
          onClick={handleAccountSummary}
          className="ensightencustomevent"
          disabled={isSubmitting}
          data-reason="go-to-login"
          data-type="login"
          data-object="button"
        >
          {isSubmitting
            ? translate({ string: 'submitting' })
            : translate({
                string: primaryButtonLabel
              })}
        </PrimaryButton>
      </ButtonContainer>
    </div>
  )
}

export default Complete
