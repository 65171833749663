import React, { ReactElement } from 'react'
import { useLocation } from 'react-router-dom'
import ErrorPage from 'ui/atoms/ErrorPage'
import GoToSignInButton from 'ui/atoms/GoToSignInButton'
import translate from 'helpers/translate'
import { ErrorLocationStateProps } from 'types/locationState'

const StandardError = (): ReactElement => {
  const location = useLocation<ErrorLocationStateProps>()
  const { customerService, errorCode } = location.state || {}

  return (
    <ErrorPage
      title={translate({ string: 'somethingWentWrong' })}
      description={translate({
        string: 'somethingWentWrongContent',
        argument: {
          number: customerService,
          error: errorCode
        }
      })}
    >
      <GoToSignInButton />
    </ErrorPage>
  )
}

export default StandardError
