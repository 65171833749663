import { clientInfo } from 'configureBrand'
import {
  AUTH_DUPLICATE_USER_ID,
  AUTH_LOCKED_STANDARD_CSR1_DUALCARD,
  AUTH_LOCKED_STANDARD_CSR1_LOAN,
  AUTH_LOCKED_STANDARD_CSR1_PLCC,
  AUTH_LOCKED_STANDARD_CSR2_DUALCARD,
  AUTH_LOCKED_STANDARD_CSR2_LOAN,
  AUTH_LOCKED_STANDARD_CSR2_PLCC,
  AUTH_LOCKED_STANDARD_CSR3_FRAUD,
  AUTH_ONE_ATTEMPT_LEFT,
  AUTH_RESET_PASSWORD_EXPIRED_OR_EMPTY,
  AUTH_RESET_PASSWORD_LOCKED,
  AUTH_TRY_AGAIN,
  AUTH_USER_ID_UNUSABLE_RECENTLY_UPDATED,
  DIGITS_ONLY,
  FIELD_REQUIRED,
  IDP_AUTHENTICATE_REGISTRATION_ERROR_REGISTERED,
  IDP_AUTHENTICATE_REGISTRATION_ERROR_VERIFICATION,
  MIN_FOUR_DIGITS,
  OTP_INITIATE_INCORRECT_DELIVERY_METHOD,
  OTP_INITIATE_PHONE_CONFIRM_ERROR,
  OTP_INITIATE_PHONE_CONFIRM_ERROR_MAX,
  OTP_INITIATE_PHONE_CONFIRM_ERROR_ONE_ATTEMPT_LEFT,
  OTP_INITIATE_PHONE_CONFIRM_MAX,
  OTP_SOFTENER_INCORRECT_CODE,
  OTP_SOFTENER_INCORRECT_CODE_AND_ONE_MORE_ATTEMPT_LEFT,
  OTP_SOFTENER_INCORRECT_CODE_AND_VALIDATION_REACHED,
  OTP_VALIDATE_INCORRECT_CODE,
  OTP_VALIDATE_INCORRECT_CODE_AND_MAX_ATTEMPTS_REACHED_ONE,
  OTP_VALIDATE_INCORRECT_CODE_AND_MAX_ATTEMPTS_REACHED_THREE,
  OTP_VALIDATE_INCORRECT_CODE_AND_MAX_ATTEMPTS_REACHED_TWO,
  PROFILE_CREATE_ERROR_FAILED_ACCOUNT_LOCKED,
  PROFILE_CREATE_ERROR_FAILED_REGISTERED_ACCOUNT,
  PROFILE_CREATE_ERROR_FAILED_REGISTERED_USERID,
  SELECT_ALL_REQUIRED_FIELDS
} from 'const/idpErrors'
import translate from 'helpers/translate'
import { FlowId } from 'const/idp'
import { GenerateIdpErrorMessageOptions } from 'helpers/typings'

const {
  phoneNumbers: { customerService, fraud }
} = clientInfo

const generateIdpErrorMessage = ({
  idpErrorCode,
  flowId = FlowId.LOGIN
}: GenerateIdpErrorMessageOptions): string => {
  switch (idpErrorCode) {
    case AUTH_TRY_AGAIN:
    case OTP_SOFTENER_INCORRECT_CODE:
    case IDP_AUTHENTICATE_REGISTRATION_ERROR_VERIFICATION:
      return translate({
        string: 'tryAgainUpToSix'
      })
    case AUTH_LOCKED_STANDARD_CSR1_PLCC:
    case AUTH_LOCKED_STANDARD_CSR1_DUALCARD:
    case AUTH_LOCKED_STANDARD_CSR1_LOAN:
    case OTP_SOFTENER_INCORRECT_CODE_AND_VALIDATION_REACHED:
    case PROFILE_CREATE_ERROR_FAILED_ACCOUNT_LOCKED:
      return translate({
        string: 'lockedStandard',
        argument: customerService
      })
    case AUTH_LOCKED_STANDARD_CSR2_PLCC:
    case AUTH_LOCKED_STANDARD_CSR2_DUALCARD:
    case AUTH_LOCKED_STANDARD_CSR2_LOAN:
      return translate({
        string: 'lockedProblem',
        argument: customerService
      })
    case AUTH_LOCKED_STANDARD_CSR3_FRAUD:
      return translate({
        string: 'lockedProblem',
        argument: fraud
      })
    case AUTH_RESET_PASSWORD_LOCKED:
    case AUTH_RESET_PASSWORD_EXPIRED_OR_EMPTY:
      return translate({
        string: 'resetPassword'
      })
    case AUTH_USER_ID_UNUSABLE_RECENTLY_UPDATED:
      return translate({
        string: 'recentlyUpdatedUserId'
      })
    case AUTH_ONE_ATTEMPT_LEFT:
      return translate({
        string:
          flowId === FlowId.LOGIN
            ? 'oneAttemptRemaining'
            : 'oneAttemptRemainingShort'
      })
    case AUTH_DUPLICATE_USER_ID:
      return translate({
        string: 'gapBrandAccountIssue',
        argument: customerService
      })
    case OTP_INITIATE_PHONE_CONFIRM_ERROR_ONE_ATTEMPT_LEFT:
      return translate({
        string: 'phoneConfirmOneTimeLeft'
      })
    case OTP_INITIATE_PHONE_CONFIRM_ERROR:
    case OTP_INITIATE_PHONE_CONFIRM_ERROR_MAX:
      return translate({
        string: 'unableToVerify',
        argument: fraud
      })
    case OTP_INITIATE_INCORRECT_DELIVERY_METHOD:
      return translate({
        string: 'landlineNumbeErrorMessage'
      })
    case OTP_INITIATE_PHONE_CONFIRM_MAX:
      return translate({
        string: 'unableToConfirm',
        argument: fraud
      })
    case OTP_VALIDATE_INCORRECT_CODE:
      return translate({
        string: 'invalidOTP'
      })
    case OTP_VALIDATE_INCORRECT_CODE_AND_MAX_ATTEMPTS_REACHED_ONE:
    case OTP_VALIDATE_INCORRECT_CODE_AND_MAX_ATTEMPTS_REACHED_TWO:
    case OTP_VALIDATE_INCORRECT_CODE_AND_MAX_ATTEMPTS_REACHED_THREE:
      return translate({
        string: 'sorryDescription'
      })
    case SELECT_ALL_REQUIRED_FIELDS:
      return translate({
        string: 'selectAllRequired'
      })
    case FIELD_REQUIRED:
      return translate({
        string: FIELD_REQUIRED
      })
    case DIGITS_ONLY:
      return translate({
        string: DIGITS_ONLY
      })
    case MIN_FOUR_DIGITS:
      return translate({
        string: MIN_FOUR_DIGITS
      })
    case IDP_AUTHENTICATE_REGISTRATION_ERROR_REGISTERED:
      return translate({
        string: 'accountAlreadyRegistered'
      })
    case OTP_SOFTENER_INCORRECT_CODE_AND_ONE_MORE_ATTEMPT_LEFT:
      return translate({
        string: 'oneAttemptRemainingShort'
      })
    case PROFILE_CREATE_ERROR_FAILED_REGISTERED_USERID:
    case PROFILE_CREATE_ERROR_FAILED_REGISTERED_ACCOUNT:
      return translate({
        string: 'unavailableUID'
      })
    default:
      return translate({
        string: 'default'
      })
  }
}

export default generateIdpErrorMessage
