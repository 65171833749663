import React, { ReactElement } from 'react'
import {
  Eclipse,
  Loader,
  LoadingTitle,
  LoadingWrapper,
  Spinner,
  Wrapper
} from './subcomponents'
import { LoaderProps } from './typings'

const Loading = ({ isLoading, showLabel }: LoaderProps): ReactElement => {
  return (
    <Wrapper>
      {isLoading && (
        <LoadingWrapper>
          <LoadingTitle data-test="loading-title">
            {showLabel && 'Logging you into your account'}
          </LoadingTitle>
          <Loader data-test="loader">
            <Spinner />
            <Eclipse />
          </Loader>
        </LoadingWrapper>
      )}
    </Wrapper>
  )
}

export default Loading
