import React, { ReactElement } from 'react'
import ErrorPage from 'ui/atoms/ErrorPage'
import GoToSignInButton from 'ui/atoms/GoToSignInButton'
import { translate } from 'helpers'
import { clientInfo } from 'configureBrand'
import { FA_LOCK } from 'const/iconProp'

const AccountLockedError = (): ReactElement => (
  <ErrorPage
    title={translate({ string: 'accountLockedTitle' })}
    description={translate({
      string: 'accountLockedBody',
      argument: clientInfo.phoneNumbers.customerService
    })}
    errorIcon={FA_LOCK}
  >
    <GoToSignInButton buttonText={translate({ string: 'previousPage' })} />
  </ErrorPage>
)

export default AccountLockedError
