import { clientInfo } from 'configureBrand'
import { MfaValidationType } from 'const/mfa'

export interface ErrorResult {
  errMsg: {
    key: string
    argument?: string
  }
  svcNum: string
  clientError: string
  clientErrorDescription: string
  analyticsSubFunction: string
}

const determinePersistResult = (errorNum: string | number): ErrorResult => {
  switch (errorNum) {
    case 1002:
      return {
        errMsg: {
          key: 'lockedProblem',
          argument: clientInfo.phoneNumbers.plcc
        },
        svcNum: clientInfo.phoneNumbers.plcc,
        clientError: '401',
        clientErrorDescription: 'Password Expired',
        analyticsSubFunction: 'password blank'
      }
    case 1009:
      return {
        errMsg: {
          key: 'recentlyUpdatedUserId'
        },
        svcNum: '',
        clientError: '401',
        clientErrorDescription: 'User ID Unusable',
        analyticsSubFunction: 'userid unusable'
      }
    case 1007:
      return {
        errMsg: {
          key: 'userIdUnusable'
        },
        svcNum: clientInfo.phoneNumbers.plcc,
        clientError: '401',
        clientErrorDescription: 'User ID Unusable',
        analyticsSubFunction: 'userid unusable'
      }
    case 1008:
      return {
        errMsg: {
          key: 'userLocked',
          argument: clientInfo.phoneNumbers.fraud
        },
        svcNum: clientInfo.phoneNumbers.fraud,
        clientError: '403',
        clientErrorDescription: 'Account Locked',
        analyticsSubFunction: 'account locked'
      }
    case 1003:
      return {
        errMsg: {
          key: 'userLocked',
          argument: clientInfo.phoneNumbers.plcc
        },
        svcNum: clientInfo.phoneNumbers.plcc,
        clientError: '403',
        clientErrorDescription: 'Account Locked',
        analyticsSubFunction: 'login not permitted'
      }
    case 1004:
      return {
        errMsg: {
          key: 'lockedProblem',
          argument: clientInfo.phoneNumbers.fraud
        },
        svcNum: clientInfo.phoneNumbers.fraud,
        clientError: '403',
        clientErrorDescription:
          'Customer Account Error - Login was not permitted on this device',
        analyticsSubFunction: 'fraud device'
      }
    case 1006:
      return {
        errMsg: {
          key: 'userLocked',
          argument: clientInfo.phoneNumbers.fraud
        },
        svcNum: clientInfo.phoneNumbers.fraud,
        clientError: '403',
        clientErrorDescription:
          'Customer Account Error - OTP Verification Required',
        analyticsSubFunction: MfaValidationType.OTP
      }
    case 1011:
      return {
        errMsg: {
          key: 'userLocked',
          argument: clientInfo.phoneNumbers.fraud
        },
        svcNum: clientInfo.phoneNumbers.fraud,
        clientError: '403',
        clientErrorDescription:
          'Customer Account Error - Login was not permitted for this account',
        analyticsSubFunction: 'login not permitted'
      }
    case 3002:
    case 3004:
    case 3005:
      return {
        errMsg: {
          key: 'lockedProblem',
          argument: clientInfo.phoneNumbers.fraud
        },
        svcNum: clientInfo.phoneNumbers.fraud,
        clientError: '403',
        clientErrorDescription:
          'Customer account error - login was not permitted on this device',
        analyticsSubFunction: 'login not permitted'
      }
    case 3007:
      return {
        errMsg: {
          key: 'userLocked',
          argument: clientInfo.phoneNumbers.fraud
        },
        svcNum: clientInfo.phoneNumbers.fraud,
        clientError: '403',
        clientErrorDescription:
          'Customer account error - login was not permitted for this account',
        analyticsSubFunction: 'login not permitted'
      }
    case 500:
      return {
        errMsg: {
          key: 'default'
        },
        svcNum: '',
        clientError: '500',
        clientErrorDescription: 'Synchrony system error, try again',
        analyticsSubFunction: 'process error'
      }
    case 404:
      return {
        errMsg: {
          key: 'beenAnError'
        },
        svcNum: clientInfo.phoneNumbers.plcc,
        clientError: '401',
        clientErrorDescription: 'Unspecified Synchrony system error, try again',
        analyticsSubFunction: ''
      }
    case 406:
      return {
        errMsg: {
          key: 'lastFourNotMatch'
        },
        svcNum: clientInfo.phoneNumbers.plcc,
        clientError: '406',
        clientErrorDescription:
          "There was a problem linking your account. It looks like you're logging into an account that isn't in your Amazon wallet. Please add that Store Card to your Amazon.com account and try again",
        analyticsSubFunction: 'password did not match'
      }
    case 403:
      return {
        errMsg: {
          key: 'lockedProblem',
          argument: clientInfo.phoneNumbers.plcc
        },
        svcNum: clientInfo.phoneNumbers.plcc,
        clientError: '402',
        clientErrorDescription: 'Inconsistent Link',
        analyticsSubFunction: 'inconsistent link'
      }
    case 400:
      return {
        errMsg: {
          key: 'beenAnError'
        },
        svcNum: clientInfo.phoneNumbers.plcc,
        clientError: '409',
        clientErrorDescription: 'Synchrony account error, try again',
        analyticsSubFunction: ''
      }
    case 401:
      return {
        errMsg: {
          key: 'lockedProblem',
          argument: clientInfo.phoneNumbers.plcc
        },
        svcNum: clientInfo.phoneNumbers.plcc,
        clientError: '403',
        clientErrorDescription:
          'Customer Account Error - Login was not permitted for this account',
        analyticsSubFunction: 'login not permitted'
      }
    default:
      return {
        errMsg: {
          key: 'beenAnError'
        },
        svcNum: clientInfo.phoneNumbers.plcc,
        clientError: '500',
        clientErrorDescription: 'Synchrony system error, try again',
        analyticsSubFunction: ''
      }
  }
}

export default determinePersistResult
