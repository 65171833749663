import React, { ReactElement } from 'react'
import { translate } from 'helpers'
import GoToSignInButton from 'ui/atoms/GoToSignInButton'
import { FAS_CHECK_CIRCLE } from 'const/iconProp'
import ErrorPage from 'ui/atoms/ErrorPage'

const Confirmation = (): ReactElement => {
  return (
    <ErrorPage
      title={translate({ string: 'resetConfirmationTitle' })}
      description={translate({ string: 'resetConfirmationBody' })}
      errorIcon={FAS_CHECK_CIRCLE}
    >
      <GoToSignInButton dataType="reset-password-completed" />
    </ErrorPage>
  )
}

export default Confirmation
