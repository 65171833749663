import React, { ReactElement, ReactNode } from 'react'
import { clientInfo } from 'configureBrand'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  postOtpInitiate,
  postOtpValidate,
  resetOtpState,
  updateOtpError
} from 'redux/otp/actions'
import { RootState } from 'redux/store'
import { updateIsSubmitting } from 'redux/uiUtils/actions'
import { useLocation } from 'react-router'
import { validate } from 'helpers'
import { MfaValidationType } from 'const/mfa'
import Confirm from './Confirm'
import Verify from './Verify'
import Sorry from './Sorry'
import { Props } from './typings'

const OTP = ({
  authData,
  endpoint,
  handleError,
  handleVerified,
  handleCancel,
  type,
  queryParams,
  returnTo: returnToPath
}: Props): ReactElement => {
  const dispatch = useDispatch()
  const {
    currentOtpStep,
    otpStatusCode,
    error,
    lastFour,
    isSubmitting,
    otpPin,
    bindDevice
  } = useSelector(
    (reduxState: RootState) => ({
      currentOtpStep: reduxState.otp.currentOtpStep,
      otpStatusCode: reduxState.otp.otpStatusCode,
      error: reduxState.otp.error,
      lastFour: reduxState.otp.lastFour,
      isSubmitting: reduxState.uiUtils.isSubmitting,
      otpPin: reduxState.otp.otpPin,
      bindDevice: reduxState.otp.bindDevice
    }),
    shallowEqual
  )

  const { state, search } = useLocation<{
    returnTo: string
  }>()

  const toggleIsSending = (): void => {
    dispatch(updateIsSubmitting(!isSubmitting))
  }

  const renderConfirm = (): ReactNode => {
    return (
      <Confirm
        data-reason="confirm"
        phoneNumbers={authData?.phoneNumbers}
        handleCancel={() => {
          dispatch(resetOtpState())
          handleCancel()
        }}
        toggleIsSending={toggleIsSending}
        handleStatusCode={() => {
          dispatch(resetOtpState())
        }}
        isSending={isSubmitting}
        error={error}
        statusCode={otpStatusCode}
        handleValidate={({ selectedCommunication, selectedPhoneNumber }) => {
          dispatch(
            postOtpInitiate({
              sessionId: authData?.sessionId,
              phoneNumber: selectedPhoneNumber,
              deliveryMode: selectedCommunication,
              endpoint
            })
          )
        }}
      />
    )
  }

  const renderVerify = (): ReactElement => {
    const { zipCode, sessionId } = authData
    if (!lastFour) return null

    return (
      <Verify
        data-reason="verify"
        handleCancel={() => {
          dispatch(resetOtpState())
          handleCancel()
        }}
        handleValidate={() => {
          const errorMessage = validate(MfaValidationType.OTP, otpPin)
          if (errorMessage) {
            dispatch(
              updateOtpError({
                key: errorMessage,
                extension: ''
              })
            )
          } else {
            dispatch(
              postOtpValidate({
                sessionId,
                code: otpPin,
                bindDevice,
                zipCode,
                endpoint,
                returnTo: returnToPath || state?.returnTo,
                queryParams: queryParams || search,
                type,
                handleVerified,
                handleError
              })
            )
          }
        }}
      />
    )
  }

  const renderSorry = (): ReactElement => {
    return (
      <Sorry
        contactNumber={clientInfo.phoneNumbers.fraud}
        handleCancel={() => {
          handleCancel()
          dispatch(resetOtpState())
        }}
        data-reason="sorry"
      />
    )
  }

  return (
    <>
      {currentOtpStep === 'confirm' && renderConfirm()}
      {currentOtpStep === 'verify' && renderVerify()}
      {currentOtpStep === 'sorry' && renderSorry()}
    </>
  )
}

export default OTP
