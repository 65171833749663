import { configServices } from 'services'
import apiEndpoints from 'const/apiEndpoints'
import { AkamaiConfigResponseData } from './typings'

const akamaiConfig = {
  getAkamaiConfig: async (): Promise<AkamaiConfigResponseData> => {
    const { data } = await configServices.get(apiEndpoints.config.AKAMAI_CONFIG)
    return data
  }
}

export default akamaiConfig
