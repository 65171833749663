import React, { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { RootState } from 'redux/store'
import {
  handleVerified,
  resetRegistrationState
} from 'redux/registration/actions'
import triggerAnalyticsEvent from 'helpers/triggerAnalyticsEvent'
import RegistrationStep from 'const/registrationStep'
import routes from 'const/routes'
import VerificationEndpoint from 'const/verificationEndPoint'
import { MfaValidationType } from 'const/mfa'
import Template from 'ui/molecules/Template'
import NeedsVerificationModal from 'ui/organisms/NeedsVerificationModal'
import ExpeditedRegistrationSSN from 'ui/organisms/ExpeditedRegistrationSSN'
import RegistrationAccountLookup from './RegistrationAccountLookup'
import RegistrationComplete from './RegistrationComplete'
import RegistrationForm from './RegistrationForm'

const Registration = (): ReactElement => {
  const {
    uiUtils: { isModalOpen },
    registration: {
      searchUserResponse,
      validationModal,
      currentStep,
      transmitId
    }
  } = useSelector((reduxState: RootState) => reduxState)
  const authData = searchUserResponse?.responseData
  const { session_id: sessionId, phone_numbers: phoneNumbers } = authData || {}
  const dispatch = useDispatch()
  const { state } = useLocation<{
    returnTo: string
  }>()
  const { returnTo = routes.HOME } = state || {}
  const showNeedsVerification =
    !transmitId || (transmitId && validationModal !== MfaValidationType.SSN)

  const lookUpScreen = transmitId ? (
    <ExpeditedRegistrationSSN data-test="expedited-registration-form" />
  ) : (
    <RegistrationAccountLookup data-test="registration-lookup" />
  )

  const renderRegistrationStep = () => {
    switch (currentStep) {
      case RegistrationStep.LOOKUP:
        return lookUpScreen
      case RegistrationStep.REGISTRATION:
        return (
          <RegistrationForm data-test="registration-form" returnTo={returnTo} />
        )
      case RegistrationStep.COMPLETE:
      default:
        return (
          <RegistrationComplete
            data-test="registration-complete"
            returnTo={transmitId ? routes.HOME : returnTo}
          />
        )
    }
  }

  return (
    <Template shouldLoadAllScripts>
      {renderRegistrationStep()}
      {authData?.status && showNeedsVerification && (
        <NeedsVerificationModal
          endpoint={VerificationEndpoint.REGISTRATION}
          showModal={isModalOpen}
          verificationType={validationModal}
          containerId="registrationModal"
          handleCancel={() => {
            triggerAnalyticsEvent({ subFunction: '' })
            dispatch(resetRegistrationState())
          }}
          authData={{
            phoneNumbers,
            sessionId,
            userId: ''
          }}
          handleVerified={options => {
            const { accessToken, ebillStatus, cipherAccountId } = options || {}
            dispatch(
              handleVerified({
                accessToken,
                ebillStatus,
                cipherAccountId,
                currentStep: 'registration',
                validationModal: ''
              })
            )
          }}
        />
      )}
    </Template>
  )
}

export default Registration
