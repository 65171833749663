import styled from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import CLSystemNavigation from 'syf-component-library/ui/patterns/SystemNavigation'

export const HeaderContainer = styled.header`
  height: 110px;
  display: flex;
  align-items: center;
  background-color: ${p => p.theme.headerBackground};
  border-bottom: 4px solid ${p => p.theme.headerDivider};
  box-sizing: border-box;
`

export const SystemNavigation = styled(CLSystemNavigation)`
  width: 100%;
  height: 100%;
  max-height: 90px;

  img {
    max-width: 100%;
  }

  @media ${query.lessThanMedium} {
    img {
      max-height: 35px;
      height: auto;
    }
  }
`
