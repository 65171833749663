import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'normalize.css'
import React from 'react'
import { Provider } from 'react-redux'
import store from 'redux/store'
import ReactDOM from 'react-dom'
import 'services'
import App from 'pages/App'

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line global-require
  import('react-axe').then(({ default: axe }) => {
    axe(React, ReactDOM, 1000)
  })
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)
