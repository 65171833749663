import { IvaAction, IvaState } from './typings'

export const initialState: IvaState = {
  loginAttempts: 0,
  showChatCta: false,
  isLive: false
}

export default function (
  state: IvaState = initialState,
  action: IvaAction = undefined
): IvaState {
  switch (action.type) {
    case 'INCREMENT_LOGIN_ATTEMPTS_COUNT':
      return {
        ...state,
        loginAttempts: state.loginAttempts + 1
      }
    case 'SHOW_CHATCTA_IVA':
      return {
        ...state,
        showChatCta: !state.showChatCta
      }
    case 'UPDATE_IVA_IS_LIVE':
      return { ...state, isLive: action.payload }
    default:
      return state
  }
}
