import React, { ReactElement } from 'react'
import KeyNames from 'const/keyNames'
import {
  RadioIcon,
  StandardRadioButtonWrapper,
  StandardRadioInput
} from './subcomponents'
import { RadioOptionProps } from './typings'

const StandardRadio = ({
  value,
  label,
  showIcon,
  className = '',
  toggleRadioValue,
  selectedValue,
  name,
  ...props
}: RadioOptionProps): ReactElement => {
  const { ENTER_KEY, SPACE_KEY } = KeyNames
  const selected = selectedValue === value

  return (
    <StandardRadioButtonWrapper
      onClick={() => toggleRadioValue(value)}
      onKeyDown={event => {
        if (event.key === ENTER_KEY || event.key === SPACE_KEY) {
          toggleRadioValue(value)
        }
      }}
      selected={selected}
      tabIndex={0}
      data-test="standardRadioButton"
    >
      <StandardRadioInput
        id={`${value}+1`}
        key={`${selected}${value}+1`}
        className={className}
        type="radio"
        checked={selected}
        onChange={() => {}}
        aria-checked={selected}
        name={name}
        {...props}
      />
      <RadioIcon />
      <label htmlFor={`${value}+1`}>{label}</label>
    </StandardRadioButtonWrapper>
  )
}

export default StandardRadio
