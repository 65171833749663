import styled from 'styled-components'
import MessageBox from 'syf-component-library/ui/patterns/MessageBox/MessageBox'
import query from 'syf-component-library/const/mediaQueries'
import { colors } from 'theme'
import Body from 'syf-component-library/ui/typography/Body'
import Small from 'syf-component-library/ui/typography/Small'
import config from 'const/config'

export const Img = styled.img.attrs({
  src: `${config.TARGET_ORIGIN}/assets/synchrony/syf_icon.svg`,
  alt: 'account manager banner'
})`
  width: 27px;
  height: auto;
  margin: 0 auto 0 auto;
`

export const Container = styled(MessageBox)`
  width: 100%;
  color: ${colors.white};
  background-color: ${colors.darkCyanBlue};
  box-sizing: border-box;
  margin: 0 -20px 0 0;
  flex-grow: 1;
  @media ${query.lessThanMedium} {
    margin: 0;
    flex-grow: 0;
  }
`

export const Title = styled(Body)`
  color: ${colors.white};
`

export const Content = styled(Small)`
  color: ${colors.white};
`
