import { ThunkDispatch } from 'redux-thunk'
import { RootState } from 'redux/store'
import { initialize } from 'services/ivaClient'
import {
  IncrementLoginAttemptsAction,
  IvaAction,
  ShowChatCtaAction,
  UpdateIvaIsLiveAction
} from './typings'

export type IvaThunkDispatch = ThunkDispatch<RootState, undefined, IvaAction>

export const updateLoginAttempt = (): IncrementLoginAttemptsAction => ({
  type: 'INCREMENT_LOGIN_ATTEMPTS_COUNT'
})

export const showChatCtaIva = (): ShowChatCtaAction => {
  return {
    type: 'SHOW_CHATCTA_IVA'
  }
}

export const updateIvaIsLive = (status: boolean): UpdateIvaIsLiveAction => {
  return {
    type: 'UPDATE_IVA_IS_LIVE',
    payload: status
  }
}

export const incrementLoginAttemptsCount =
  () =>
  (dispatch: IvaThunkDispatch, getState: () => RootState): void => {
    const { iva } = getState()
    if (!iva?.isLive) {
      const ivaInitialized = initialize()
      if (ivaInitialized) {
        dispatch(updateIvaIsLive(ivaInitialized))
      }
    }

    dispatch(updateLoginAttempt())
  }
