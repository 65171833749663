import React, { ReactElement } from 'react'
import NeedsVerificationModal from 'ui/organisms/NeedsVerificationModal/NonIdP'
import triggerAnalyticsEvent from 'helpers/triggerAnalyticsEvent'
import Template from 'ui/molecules/Template'
import { MfaError } from 'ui/organisms/NeedsVerificationModal/typings'
import { updateIsModalOpen } from 'redux/uiUtils/actions'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import VerificationEndpoint from 'const/verificationEndPoint'
import { RootState } from 'redux/store'
import { MfaValidationType } from 'const/mfa'
import LoginContainer from './LoginContainer'

const AccountLinking = (): ReactElement => {
  const {
    cpl: {
      authenticateCplResponse: {
        phone_numbers: phoneNumbers,
        session_id: sessionId,
        user_id: userId
      }
    }
  } = useSelector((state: RootState) => state, shallowEqual)
  const dispatch = useDispatch()

  const handleOtpCancel = (): void => {
    dispatch(updateIsModalOpen(false))
    triggerAnalyticsEvent({ subFunction: '' })
  }

  return (
    <Template>
      <LoginContainer data-test="login-containter" />
      <NeedsVerificationModal
        endpoint={VerificationEndpoint.ACCOUNT_LINKING}
        verificationType={MfaValidationType.OTP}
        data-test="needs-verification-modal"
        containerId="cplOtpModal"
        handleCancel={handleOtpCancel}
        authData={{
          phoneNumbers,
          sessionId,
          userId
        }}
        handleError={(errorData: MfaError) => {
          if (
            errorData.type === 'lost-stolen' ||
            errorData.type === 'customer-service'
          ) {
            dispatch(updateIsModalOpen(false))
          }
        }}
      />
    </Template>
  )
}

export default AccountLinking
