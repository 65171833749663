import { addBmTelemetryToHeaders } from 'helpers'
import config from 'const/config'
import getCorrelationId from 'helpers/getCorrelationId'
import apiEndpoints from 'const/apiEndpoints'
import {
  CplOtpInitOptions,
  CplOtpInitRequest,
  CplOtpInitResponseData,
  VerificationEndpoint
} from 'services/typings'
import api, { generateTransId } from 'services'

const otpInitiate = async (
  options: CplOtpInitOptions,
  endpoint: VerificationEndpoint
): Promise<CplOtpInitResponseData> => {
  const {
    sessionId = '',
    phoneNumber = '',
    deliveryMode,
    oauthIdClientId
  } = options || {}

  const payload: CplOtpInitRequest = {
    otp_info: {
      delivery_mode: deliveryMode,
      phone_number: phoneNumber
    },
    session_info: {
      session_id: sessionId
    }
  }

  const reqHeaders = await addBmTelemetryToHeaders({
    headers: {
      'X-SYF-Request-TrackingId': generateTransId(),
      'X-SYF-Request-CorrelationId': getCorrelationId()
    },
    params: {
      client_id:
        endpoint !== 'account-linking'
          ? config.PERSIST_APIGEE_CLIENT_ID || '' // persistent login
          : oauthIdClientId // account-linking
    }
  })

  const response = await api.post(
    endpoint !== 'account-linking'
      ? apiEndpoints.cpl.PERSISTENT_OTP // persistent login
      : apiEndpoints.dsecurity.LEGACY_OTP, // account-linking
    payload,
    reqHeaders
  )

  return response.data
}

export default otpInitiate
