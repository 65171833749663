import styled from 'styled-components'
import H5 from 'syf-component-library/ui/typography/H5'
import { brandImages } from 'configureBrand'
import { colors } from 'theme'
import config from 'const/config'

export const BannerWrapper = styled.section`
  width: 100%;
  box-sizing: border-box;
  background-color: ${colors.weissThree};
  border-bottom: 1px solid ${colors.lightGrey};
  padding: 1rem;
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
  box-sizing: border-box;
  flex-direction: column;
`

export const Img = styled.img.attrs({
  src: config.ASSET_ORIGIN + brandImages.voiceCplLogo,
  alt: 'voice banner'
})`
  display: block;
  width: 100%;
  height: auto;
  margin: 0 auto 0 auto;
`

export const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100px;
`

export const Title = styled(H5)`
  padding-top: 10px;
`
