import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { colors } from 'theme'
import translate from 'helpers/translate'
import { TextButton } from 'ui/atoms/Button'
import Small from 'syf-component-library/ui/typography/Small'
import Stack from 'syf-component-library/ui/spacing/Stack'

export const DefaultMessageContainer = styled.div`
  color: ${colors.grey};
  max-width: 500px;
  text-align: left;
`

interface DefaultMessageProps {
  eConsentLink: string
}

const DefaultMessage = ({
  eConsentLink
}: DefaultMessageProps): ReactElement => (
  <DefaultMessageContainer>
    <Small>
      {translate({ string: 'eStatementDisclaimer' })}
      <TextButton
        role="link"
        buttonType="text"
        onClick={() => window.open(eConsentLink)}
      >
        {translate({ string: 'inlineTermsAndConditions' })}
      </TextButton>{' '}
      {translate({ string: 'eStatementDisclaimerContinue' })}
    </Small>
    <Stack size="small" />
    <Small>{translate({ string: 'ePaperDisclaimer' })}</Small>
  </DefaultMessageContainer>
)

export default DefaultMessage
