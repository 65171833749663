import { clientInfo } from 'configureBrand'
import translate from 'helpers/translate'
import { AnyObject } from 'types/custom'
import { AuthResult } from 'helpers/typings'

const determineAuthCplResult = (data: AnyObject): AuthResult => {
  const {
    status: { response_code: code },
    account_type: accountType,
    phone_numbers: phoneNumbers
  } = data

  switch (code) {
    case '1001':
      return {
        message: {
          key: 'tryAgain',
          extension: `${translate({ string: 'code' })}: 1001`,
          argument: clientInfo.vanityUrl
        }
      }
    case '1002':
      return {
        message: {
          key: 'lockedStandard',
          argument:
            clientInfo.phoneNumbers[
              accountType as keyof typeof clientInfo.phoneNumbers
            ],
          extension: `${translate({ string: 'code' })}: 1002`
        }
      }
    case '1003':
      return {
        message: {
          key: 'userLocked',
          argument:
            clientInfo.phoneNumbers[
              accountType as keyof typeof clientInfo.phoneNumbers
            ],
          extension: `${translate({ string: 'code' })}: 1003`
        }
      }
    case '1004':
      return {
        message: {
          key: 'lockedProblem',
          argument: clientInfo.phoneNumbers.fraud,
          extension: `${translate({ string: 'code' })}: 1004`
        }
      }
    case '1005':
      return {}
    case '1006':
      if (phoneNumbers?.length) {
        return { otp: true }
      }
      return {
        message: {
          key: 'lockedProblem',
          argument: clientInfo.phoneNumbers.fraud,
          extension: `${translate({ string: 'code' })}: 1006`
        }
      }
    case '1007':
      return {}
    case '1008':
      return {
        message: {
          key: 'userLocked',
          argument: clientInfo.phoneNumbers.fraud,
          extension: `${translate({ string: 'code' })}: 1008`
        }
      }
    case '1009':
      return {
        message: {
          key: 'recentlyUpdatedUserId',
          extension: `${translate({ string: 'code' })}: 1009`
        }
      }
    case '1012':
      return {
        message: {
          key: 'oneAttemptRemaining',
          extension: `${translate({ string: 'code' })}: 1012`
        }
      }
    case '500':
      return {
        message: {
          key: 'misMatchLast4Digit',
          extension: `${translate({ string: 'code' })}: 500`
        }
      }
    case '401':
      return {
        message: {
          key: 'misMatchLast4Digit',
          extension: `${translate({ string: 'code' })}: 401`
        }
      }
    case '404':
      return {
        message: {
          key: 'default',
          extension: `${translate({ string: 'code' })}: 404`
        }
      }

    case '406':
      return {
        message: {
          key: 'oneAttemptRemaining',
          extension: `${translate({ string: 'code' })}: 406`
        }
      }
    case '400':
      return {
        message: {
          key: 'oneAttemptRemaining',
          extension: `${translate({ string: 'code' })}: 400`
        }
      }

    case '1011':
      return {
        message: {
          key: 'userLocked',
          argument:
            clientInfo.phoneNumbers[
              accountType as keyof typeof clientInfo.phoneNumbers
            ],
          extension: `${translate({ string: 'code' })}: 1011`
        }
      }
    case '3002':
      return {
        message: {
          key: 'oneAttemptRemaining',
          extension: `${translate({ string: 'code' })}: 3002`
        }
      }

    case '3004':
      return {
        message: {
          key: 'oneAttemptRemaining',
          extension: `${translate({ string: 'code' })}: 3004`
        }
      }

    case '3005':
      return {
        message: {
          key: 'oneAttemptRemaining',
          extension: `${translate({ string: 'code' })}: 3005`
        }
      }

    case '3007':
      return {
        message: {
          key: 'oneAttemptRemaining',
          extension: `${translate({ string: 'code' })}: 3007`
        }
      }

    default: {
      const transId = data && data.status ? data.status.transId : 'None'
      return {
        message: {
          key: 'default',
          extension: `${translate({ string: 'code' })}: ${transId}`
        }
      }
    }
  }
}

export default determineAuthCplResult
