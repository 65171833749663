import React, { ReactElement } from 'react'
import { useLocation } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import { updateIsInUsa } from 'redux/registration/actions'
import { SearchUserInfo } from 'services/typings'
import translate from 'helpers/translate'
import routes from 'const/routes'
import CreditCardLookupForm from 'ui/organisms/CreditCardLookupForm'
import ToastError from 'ui/atoms/ToastError'
import Header from 'ui/atoms/Header'

interface AccountLookupProps {
  handleSearchResult: (options: SearchUserInfo) => void
  handleTextfieldFocus: () => void
}

const AccountLookup = ({
  handleSearchResult,
  handleTextfieldFocus
}: AccountLookupProps): ReactElement => {
  const { registration } = useSelector(
    (reduxState: RootState) => ({
      registration: reduxState.registration
    }),
    shallowEqual
  )
  const dispatch = useDispatch()
  const { error, isInUsa, isExpiredTransmitId } = registration
  const { state } = useLocation<{
    queryParams: string
    returnTo: string
  }>()

  const { returnTo = routes.HOME, queryParams = '' } = state || {}

  return (
    <>
      {isExpiredTransmitId && (
        <ToastError
          data-test="transmit-id-expired-error"
          type="info"
          message={translate({ string: 'linkExpiredToast' })}
        />
      )}
      <Header
        title={translate({ string: 'registerForOnlineAccess' })}
        description={
          isInUsa
            ? translate({ string: 'enterYourAccountNumberZipCode' })
            : translate({ string: 'accountNumberAndDateOfBirth' })
        }
      />
      <CreditCardLookupForm
        onSubmit={handleSearchResult}
        error={error}
        onFocusTextField={handleTextfieldFocus}
        inUsa={isInUsa}
        setInUsa={(isUsa: boolean) => dispatch(updateIsInUsa(isUsa))}
        returnTo={`${returnTo}${queryParams}`}
      />
    </>
  )
}

export default AccountLookup
