import React, { ReactElement } from 'react'
import Link from 'syf-component-library/ui/atoms/Link'
import { formatAccountLengthsIntoString } from 'helpers/fieldFormatters'
import routes from 'const/routes'
import { AnyObject } from 'types/custom'

const englishTranslations: AnyObject = {
  billingInUSTitle: 'This is only if account billing address is not in the US.',
  billingInUSText: 'My account billing address is in the US.',
  invalidDob: 'Please enter a valid Date of Birth. MM/DD/YYYY',
  dateOfBirth: 'Date of Birth',
  dobHelptext: 'Enter as MM/DD/YYYY',
  billingNotUs: 'My account billing address is not in the US',
  sendCodeAs: 'Send code as:',
  sendCodeTo: 'Send code to:',
  sms: 'SMS / Text',
  call: 'Call / Voicemail',
  updateStatementPreference: 'Enroll in online statements',
  registrationComplete: 'Registration Complete',
  whenYouChooseContinue:
    'For security, when you choose “Continue”, we will send you a one-time, numeric passcode by phone or text. Enter the code on the next screen. Standard text messaging and phone rates may apply.',
  yes: 'Yes',
  no: 'No',
  saveComputer: 'Save computer?',
  getANewOne: 'Get a new one',
  didNotReceiveCode: 'Did not receive your code?',
  rememberDevice: (
    <span>
      We can remember this computer so that you can avoid having to enter these
      codes in the future.{' '}
      <b>Do not save this computer if it does not belong to you.</b>
    </span>
  ),
  recentlyUpdatedUserId: (
    <span>
      It appears you recently updated your User ID. Please use your new User ID,
      or you can{' '}
      <Link isInline to={routes.FIND_USER_ID}>
        Find your User ID
      </Link>{' '}
      if you have forgotten.
    </span>
  ),
  recentlyUpdatedUserIdAnalytics:
    'It appears you recently updated your User ID. Please use your new User ID, or you can Find your User ID if you have forgotten.',
  missingOld: 'Missing old account number',
  sending: 'Sending',
  logInToYourAccount: 'Log In To Your Account',
  accessYourAccount: 'Access Your Account',
  userId: 'User ID',
  userIdHelpText: (vanityUrl: string) =>
    `Case-sensitive, may differ from your ${vanityUrl} User ID`,
  password: 'Password',
  emailAddress: 'Email Address',
  show: 'Show',
  hide: 'Hide',
  readyToGo: 'Ready to go!',
  rememberUserId: 'Remember User ID (Optional)',
  findUserId: 'Find User ID',
  findYourUserId: 'Find your User ID',
  resetYourPassword: 'Reset Your Password',
  resetPwd: 'Reset Password',
  subHeadDescription: 'Please use the primary account holder information.',
  yourUserId: 'Your User ID',
  yourUserIdIs: 'Your User ID is',
  thisIsTheUserId: 'This is the User ID for the primary account holder.',
  loginToYourAccount: 'Login To Your Account',
  lostStolenSubtitle:
    'Great news! Your preferences have transferred over to your new account.',
  lostStolenDescription: (oldCard: string) =>
    `We have replaced your old card ending in ${oldCard}. These will be the same on your new card.`,
  lostStolenSoonToReceive: (newCard: string) =>
    `If you have not received your new card ending in ${newCard}, it should arrive soon.`,
  autopay: 'Autopay',
  scheduledPayments: 'Scheduled Payments',
  enterUserID: 'ENTER USER ID',
  selectPassword: 'SELECT PASSWORD',
  login: 'LOGIN',
  enterAccountNumber: 'Enter Account Number',
  zipCode: 'Zip Code',
  continue: 'Continue',
  searching: 'Searching',
  iForgotMy: 'I forgot my',
  or: 'or',
  iWantTo: 'I want to',
  register: 'Register',
  apply: 'Apply',
  notYou: 'Not you?',
  inAHurry: (userId: string) => `Hi ${userId}. In a hurry?`,
  weAreSorry: "We're Sorry",
  somethingWentWrong: 'Oops, something went wrong',
  letsTryThatAgain: "Let's Try That Again",
  postRegistrationError:
    "You've registered successfully but we couldn't connect you to your account. Please go to the sign in page and try again with the credentials you just created.",
  oops: 'Oops...',
  tryAgainLater:
    'Unfortunately we are unable to process your request at this time.Please try again later',
  tryAgainButton: 'Try Again',
  corruptedTokenErrorTitle: `Let’s Try That Again`,
  corruptedTokenErrorDescription:
    'The page you are trying to reach is not available. We apologize for the inconvenience. Please use the button below to return to the home page.',
  errorOccurred: 'An error occurred while processing your request',
  somethingWentWrongContent: (clientData: AnyObject) => {
    switch (clientData?.error) {
      case 400:
      case 401:
      case 403:
      case 405:
      case 415:
        return `We apologize for the inconvenience. Please use the button below to return to the homepage or call Customer Service at ${clientData.number}. Error Code: ${clientData.error}`
      case 404:
        return `The page you're trying to reach is not available. We apologize for the inconvenience. Please use the button below to return to the homepage or call Customer Service at ${clientData.number}. Error Code: 404`
      default:
        return "The page you're trying to reach is not available. We apologize for the inconvenience. Please use the button below to return to the home page."
    }
  },
  secureLogin: 'Secure Login',
  cancel: 'Cancel',
  loggingIn: 'Logging in...',
  creatingKey: 'Creating key...',
  goToLogin: 'Go to Login',
  payAsGuestButton: 'Pay as Guest',
  payAsGuestHeader: 'Just looking to make a payment?',
  payAsGuestSubHeader: 'Skip login or registration and pay as a guest.',
  payWithoutLogIn: 'Pay Without Log In',
  submit: 'Submit',
  submitting: 'Submitting...',
  loading: 'Loading...',
  verifying: 'Verifying',
  verifyCode: 'Verify Code',
  onlinePrivacyPolicy: 'Online Privacy Policy',
  privacyPolicy: 'Privacy Policy',
  websiteUsageAgreement: 'Online Usage Agreement',
  fraudProtection: 'Fraud Protection',
  storeLocator: 'Store Locator',
  forYourSecurity: 'For your security...',
  continueRegistration: 'Continue with your Registration.',
  selectAllRequired: 'Please select all required fields',
  sendYouOneTimeCode: (
    <span>
      For your security, we will send you a <b>one time passcode</b> to enter on
      the next screen.
    </span>
  ),
  confirmYourIdentity: 'Please confirm your identity to continue.',
  pleaseEnterTheInfo:
    'Please enter the following information to continue to your account.',
  littleMoreInformation: (
    <div style={{ width: '355px' }}>
      <p>
        We just need a little more information to complete your registration.
      </p>
      <p> Please enter the following information to continue.</p>
    </div>
  ),
  pwdHelpText:
    'We ask for this to protect your account and prevent unauthorized access to your information.',
  last4DigitSSN: 'Last 4-digits of SSN',
  fourDigitCode: '4-digit code',
  threeDigitCode: '3-digit security code',
  linkExpiredToast:
    'This registration feature is not available. To continue to register your account, you will need your account number displayed on your card.',
  registerForOnlineAccess: 'Register for Online Access',
  enterYourAccountNumberZipCode:
    'Please enter your account number and billing zip code',
  registerOnlineDescription:
    'Please remember your entries to gain access to your account later.',
  alertsNotifications:
    'Alerts, notifications, important messages, etc will be sent to this address',
  sendMeEmailOffers: 'Please send me email offers (Optional)',
  bySelecting: (clientName: string) =>
    `By selecting this, you authorize Synchrony Bank, the issuer of this account, to send you email offers and to provide your email address to ${clientName} so you can receive email offers from ${clientName}.`,
  paperlessStatements: 'Paperless Statements',
  paperStatements: 'Paper Statements',
  eStatementDisclaimer:
    'If you choose Paperless Statements, you confirm your consent to the ',
  inlineTermsAndConditions: 'terms and conditions',
  eStatementDisclaimerContinue:
    'for paperless communications. You will receive an email notifying you when your paperless statement is available online. You can review your paperless statement by logging into this service site and clicking on the "Statements" link. You will no longer receive a paper statement.',
  ePaperDisclaimer:
    'If you choose Paper Statements, you will receive your statement through the U.S. Mail and no email notification will be provided.',
  termsAndConditions: 'Terms & Conditions',
  alexaTermsTitle: 'Terms & Conditions for Amazon Alexa Usage',
  googleHomeTermsTitle: 'Terms & Conditions for Google Home Usage',
  readBeforeProceeding: 'Please read before proceeding.',
  importantDisclaimer:
    'Important information about consent to paperless communications. By registering for online services for your Account, you hereby agree to the terms and this will constitute your consent for us to send you paperless communications about your Account.',
  disclaimer:
    'By clicking "Continue", you authorize your wireless operator to disclose your mobile number, name, address, email, network status, customer type, customer role, billing type, mobile device identifiers (IMSI and IMEI) and other subscriber and device details, if available, to Synchrony and service providers for the duration of the business relationship, solely for identity verification and fraud avoidance. See Synchrony\'s Privacy Policy for how we treat your data. If you do not wish to continue, click "Cancel"',
  pwdLength: 'Between 7-20 characters',
  noWhiteSpace: 'No Spaces',
  hasNumber: 'Contains 1 number',
  hasLetter: 'Contains 1 letter',
  pwdSpecialChar: 'May contain special characters',
  lastFour: "Can't be last 4 passwords",
  minSixCharEmail:
    'Minimum 6 characters, email address permitted, at least one letter',
  notAnAcceptedLength: (acceptedLengths: number[]) =>
    `Account number should be ${formatAccountLengthsIntoString(
      acceptedLengths,
      'or'
    )} digits`,
  phoneNumberAcceptedLength: 'Phone number should be 10 digits',
  phoneNumberNotVerified: 'Entered phone number cannot be verified',
  wrongDeliveryMode: 'Wrong delivery mode for landline phone number',
  maximumAttemptsReached:
    'Maximum attempts reached for entered phone number verification',
  minSixChar: 'This field cannot be less than 6 characters.',
  minFourDigits: 'This field cannot be less than 4 digits.',
  minFiveDigits: 'This field cannot be less than 5 digits.',
  minSixteenDigits: 'Account number should be 16 digits.',
  required: 'This is a required field.',
  invalidUID: 'Please enter a valid User ID.',
  invalidEmail: 'Please enter a valid email address.',
  digitsOnly: 'Please enter only numbers between 0-9.',
  unavailableUID: 'User ID is unavailable. Please try a different User ID.',
  default:
    'Our system is experiencing technical difficulties at the moment. Please try again later.',
  newPassword:
    'Please follow the rules below for new passwords. The following special characters are permitted: !#$*+-.:;=?@^_`|~,',
  userIdSpecialChar:
    'User IDs cannot contain special characters. Please try again.',
  cvvMinLength: 'This field cannot be less than 3 digits.',
  otpField: 'Enter code',
  otpMinDigits: 'The code must be 4 digits.',
  lockedProblem: (phoneNumber: string) =>
    `Due to a problem with your account, you may not log in at this time. Please contact Customer Service at ${phoneNumber}`,
  lockedStandard: (phoneNumber: string) =>
    `Your account has been locked. Please call Customer Service at ${phoneNumber}`,
  tryAgainUpToSix:
    'The information you entered does not match ours. Please try again up to 6 times.',
  tryAgain: (vanityUrl: string) =>
    `The information you entered does not match ours. Please try again. Note: This is your user ID and password for your Synchrony credit account, not for ${vanityUrl}.`,
  resetPassword:
    'You have entered your login information incorrectly too many times. Please reset your password.',
  oneAttemptRemaining:
    'The information you entered does not match ours. You have 1 login attempt remaining before a password reset will be required.',
  oneAttemptRemainingShort:
    'The information you entered does not match ours. You have 1 attempt remaining.',
  invalidOTP:
    'The one time passcode you provided is incorrect. Please try again.',
  cannotReuse:
    'For your security, you cannot reuse one of your last 4 passwords. Please enter a new password.',
  accountAlreadyRegistered:
    'This Account Number is already registered. Please login using your credentials.',
  misMatchLast4Digit: (
    <span>
      It looks like you’re logging into an account for an Amazon Store Card or
      Amazon Credit Builder that isn’t loaded into your Amazon Wallet. Please
      load that card into your Amazon Wallet and/or check your card details, and
      try again.
      <a
        href="https://www.amazon.com/gp/help/customer/display.html?nodeId=921842"
        target="_blank"
        className="ensightencustomevent"
        data-reason="learn more"
        data-type="login-not-permitted"
        data-object="link"
        rel="noopener noreferrer"
      >
        Learn more.
      </a>
    </span>
  ),
  accountNumberAndDateOfBirth:
    'Please enter your account number and date of birth',
  cancelToCloseDialog: 'Cancel to close this popup',
  verifyTheCode: 'Verify the code you entered',
  closeThePopup: 'Close sent Code Popup',
  close: 'Close',
  codeEnter: 'Enter the 4-digit passcode sent to your device.',
  weApologize: 'We apologize.',
  accountLockedError: (fraudNumber: string): string =>
    `Due to a problem with your account, you cannot register your account online at this time. Please Contact Customer Service at ${fraudNumber} for assistance.`,
  sorryDescription:
    'You cannot access your account from this computer. If you have any questions please call',
  yourCodeHasBeenSent: (lastFour: string) => (
    <>
      The code has been sent to your phone number ending in <b>{lastFour}</b>
    </>
  ),
  phoneNumberEnding: (number: string) => `Phone number ending in ${number}`,
  thankYouMsg: `You've successfully registered your account, but we weren't able to enroll you in online statements. Please sign in and navigate to the Statements page to enroll.`,
  registerOnline: 'Your card has now been registered for online access!',
  rememberUserIdPwd:
    'Please remember your user ID and password for future reference.',
  goToAccountSummary: 'Go to Account Summary',
  notFound: 'Not found',
  userLocked: (phoneNumber: string) =>
    `Due to your account status or the number of days your account has been delinquent, you may not log in at this time. Please contact Customer Service at ${phoneNumber}`,
  linkingProblem: (phoneNumber: string) =>
    `There was a problem linking your account. Please contact Customer Service at ${phoneNumber} for assistance`,
  findUserIdUnregisteredCard: (
    <span>
      The information you entered does not match ours.{' '}
      <Link isInline to={routes.REGISTER}>
        Register Card
      </Link>
      .
    </span>
  ),
  gapBrandAccountIssue: (phoneNumber: string) =>
    `We're sorry. There is an issue with your account. Please call customer service at ${phoneNumber}.`,
  selectPhoneNumber: 'Select or enter phone number',
  enterPhoneNumber: 'Enter Phone Number',
  phoneConfirmOneTimeLeft: `Sorry, we weren't able to confirm your identity at that phone number. Please try a different number. Your account will be locked after one more failed attempt.`,
  unableToVerify: (phoneNumber: string): string =>
    `We were unable to verify your identity. Please use a different phone number to try again or call ${phoneNumber}.`,
  unableToConfirm: (phoneNumber: string): string =>
    `Unable to confirm your identity. To manage your account, please call customer service at ${phoneNumber}.`,
  landlineNumbeErrorMessage:
    "The number you selected can't receive SMS / Text. Continue with Call / Voicemail for this number or use a different number.",
  identityConfiramation: 'Unable to confirm your identity',
  manageAccount: 'To manage your account, please call customer service at',
  maintenanceAlert: (maintenanceAlertData: AnyObject): ReactElement => {
    const { formattedStartDate, formattedStartTime, formattedStopTime } =
      maintenanceAlertData
    return (
      <span>
        <b>Upcoming Maintenance</b>
        <p>
          Our site will be down for maintenance on {formattedStartDate} from{' '}
          {formattedStartTime} to {formattedStopTime}.We apologize for any
          inconvenience.
        </p>
      </span>
    )
  },
  code: 'Code',
  createaVoiceKey: 'Create a Voice Key',
  createVoiceKeyDescription: (
    <span>
      <p>
        To protect your information, you can create a 4-digit numerical Voice
        Key.
      </p>
      <p>
        You’ll be asked to say this Voice Key when you access sensitive
        information while using this skill.
      </p>
      <p>
        As a best practice, do not use the same PINs you used for other
        services.
      </p>
    </span>
  ),
  createKey: 'Create Key',
  accept: 'Accept',
  voiceTitle: 'Please read and agree to the usage terms and conditions:',
  voiceTermsAndCondition:
    'I acknowledge that by clicking "Accept", I agree to these terms and conditions.',
  logIntoYourAccount: 'Log Into Your Account',
  findingYourAccount: 'Finding your account...',
  securelyLocatingYourAccount: 'We are securely locating your account.',
  noCardNumber: 'No card number?',
  chatWithSydneyForHelp: 'Chat with Sydney to get help',
  lastFourNotMatch: 'Last 4 digits did not match',
  userIdUnusable: 'User ID Unusable',
  beenAnError: "There's been an error.",
  loggingIntoYourAccount: 'Logging you into your account',
  continueWithoutLinking: 'Continue without linking',
  tryLinkingAccountAgain: 'Try linking account again',
  cplSystemErrorSubheader:
    'We were not able to link your account at this time. We apologize for the inconvenience. Please select from the buttons below for how you wish to proceed.',
  persistSystemErrorSubheader:
    'We were not able to log you in automatically. Please select from the buttons below for how you wish to proceed.',
  continueToLogin: 'Continue to login',
  goBack: 'Go Back',
  idpBannerTitle: 'Introducing our new account manager',
  idpBannerSubtitle: 'Want a sneak peek? Turn on the new experience today.',
  idpBannerToggleText: 'Account manager',
  on: 'ON',
  off: 'OFF',
  stillLooking: 'Still looking to make a payment?',
  accessibilityLinkText: 'Accessibility',
  accessibilityLinkPath: 'web-accessibility-statement',
  fraudProtectionLinkPath: 'fraud-protection',
  accountLockedTitle: 'For security, your account has been locked',
  accountLockedBody: (phoneNumber: string): string =>
    `Sorry, your account has been locked after too many attempts. Please call ${phoneNumber}`,
  softenerValidationFailedTitle: `We need to be sure it's you`,
  softenerValidationFailedBody: (phoneNumber: string): string =>
    `Our system couldn't validate the information you entered. For your security, please contact our customer service team at ${phoneNumber}. Once they verify who you are, you can restart the registration process.`,
  returnToSignIn: 'Return to Sign In',
  profileEnrollmentFailedTitle: 'Enroll in alerts',
  profileEnrollmentFailedBody: `Your online registration was successful—but we need to update your account settings to enroll in alerts. Please review your settings in our system.`,
  sessionTimeOutTitle: 'Your session timed out for your security',
  sessionTimeOutBody: `To help protect your account, your session has ended.`,
  gotoSignIn: 'Go to Sign In',
  previousPage: 'Previous Page',
  resetConfirmationTitle: 'Your password has been updated successfully',
  resetConfirmationBody:
    'Please go to the sign in page and use the password you just created.',
  accountAlreadyRegisteredTitle: 'Account already registered',
  accountAlreadyRegisteredBody:
    'Please use the button below to return to the home page.',
  requirementMet: 'Requirement met',
  requirementNotMet: 'Requirement not met'
}

export default englishTranslations
