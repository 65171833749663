import {
  determineCplDescriptorKey,
  determineCplErrorCode,
  determineOtpInitiateResult,
  determineValidateResult,
  determineWhereToHandover,
  getParameterByName,
  handleRedirectToErrorScreen,
  history,
  isCplFlow,
  isCplRedirectError,
  isFourHundredError,
  redirectToClientUrl,
  translate,
  triggerAnalyticsEvent
} from 'helpers'
import routes from 'const/routes'
import cplOtpInitiate from 'services/cpl/otpInitiate'
import { updateIsModalOpen, updateIsSubmitting } from 'redux/uiUtils/actions'
import { UiAction } from 'redux/uiUtils/typings'
import otpInitiate from 'services/nonIdP/otpInitiate'
import cplOtpValidate from 'services/cpl/otpValidate'
import otpValidate from 'services/nonIdP/otpValidate'
import config from 'const/config'
import verify from 'services/nonIdP/verify'
import { handleCplErrorResponseRedirect } from 'redux/cpl/actions'
import apiEndpoints from 'const/apiEndpoints'
import generateToken from 'services/nonIdP/generateToken'
import { codeVerifier as authenticateCodeVerifer } from 'services/nonIdP/login'
import { codeVerifier as searchUserCodeVerifier } from 'services/nonIdP/searchUser'
import { ThunkDispatch } from 'redux-thunk'
import { RootState } from 'redux/store'
import { AnyObject } from 'types/custom'
import { RegisterAction } from 'redux/registration/typings'
import { OtpComplete, OtpStart, OtpVerify } from 'const/analytics/otp'
import { getPsfMessage } from 'redux/psfMessage/actions'
import {
  handleAccountLinkingRedirect,
  isAccountLinkingRedirect,
  isPersistentLoginRedirect
} from './helpers'
import {
  CatchErrorProps,
  HandleGenerateTokenProps,
  HandleValidateProps,
  OtpAction,
  OtpStep,
  PostCplOtpInitiate,
  PostCplOtpValidate,
  VerifyOtpProps
} from './typings'

type OtpThunkDispatch = ThunkDispatch<
  RootState,
  undefined,
  UiAction | OtpAction | RegisterAction
>

type OtpReturnType = void | OtpAction | UiAction | AnyObject

export const resetError = (): OtpAction => {
  return { type: 'RESET_OTP_ERROR' }
}

export const updateCurrentStep = (payload: OtpStep): OtpAction => {
  return { type: 'UPDATE_CURRENT_STEP', payload }
}

export const updateOtpError = (payload: AnyObject): OtpAction => {
  return { type: 'UPDATE_OTP_ERROR', payload }
}

export const resetOtpState = (): OtpAction => {
  return { type: 'RESET_OTP_STATE' }
}

export const updateOtpPin = (payload: string): OtpAction => {
  return { type: 'UPDATE_OTP_PIN', payload }
}

export const updateBindDevice = (payload: boolean): OtpAction => {
  return { type: 'UPDATE_BIND_DEVICE', payload }
}

export const updateOtpInitiateSuccess = (
  statusResponseCode: string,
  phoneNumber: string
): OtpAction => {
  triggerAnalyticsEvent({
    subFunction: OtpVerify.PAGE_SUBFUNCTION,
    pageName: OtpVerify.PAGE_NAME
  })

  return {
    type: 'UPDATE_OTP_INITIATE_SUCCESS',
    payload: {
      currentOtpStep: 'verify',
      otpStatusCode: statusResponseCode || '',
      lastFour: phoneNumber.slice(-4),
      showGetNewCode: statusResponseCode !== '2002'
    }
  }
}

export const updateOtpStatusCode = (statusResponseCode: string): OtpAction => {
  return {
    type: 'UPDATE_OTP_STATUS_CODE',
    payload: statusResponseCode
  }
}

export const handleCatchError =
  ({ endpoint, status }: CatchErrorProps) =>
  async (dispatch: OtpThunkDispatch): Promise<OtpReturnType> => {
    dispatch(updateIsSubmitting(false))

    if (isCplFlow(endpoint)) {
      const errorDescriptorKey = determineCplDescriptorKey(endpoint)
      if (isCplRedirectError(status, errorDescriptorKey)) {
        dispatch(updateIsModalOpen(false))
        dispatch(resetOtpState())
        return dispatch(
          handleCplErrorResponseRedirect(status, errorDescriptorKey)
        )
      }
    }

    if (isFourHundredError(status)) {
      dispatch(updateIsModalOpen(false))
      dispatch(resetOtpState())
      return handleRedirectToErrorScreen({
        errorCode: status
      })
    }

    return dispatch(
      updateOtpError({
        key: 'default',
        extension: `${translate({ string: 'code' })}: ${status}`
      })
    )
  }

export const updateIsMaximumAttemptReached = (
  isMaximumAttemptReached: boolean
): OtpAction => {
  return {
    type: 'UPDATE_IS_MAXIMUM_ATTEMPT_REACHED',
    payload: isMaximumAttemptReached
  }
}

export const postOtpInitiate =
  ({ sessionId, phoneNumber, deliveryMode, endpoint }: PostCplOtpInitiate) =>
  async (dispatch: OtpThunkDispatch): Promise<OtpReturnType> => {
    dispatch(updateIsSubmitting(true))
    dispatch(resetError())
    try {
      const oauthIdClientId = decodeURIComponent(
        getParameterByName('client_id')
      )
      const {
        status: {
          response_code: statusResponseCode,
          response_desc: responseDescription
        }
      } = isCplFlow(endpoint)
        ? await cplOtpInitiate(
            {
              sessionId,
              phoneNumber,
              deliveryMode,
              oauthIdClientId
            },
            endpoint
          )
        : await otpInitiate(
            {
              sessionId,
              phoneNumber,
              deliveryMode
            },
            endpoint
          )

      const responseData = determineOtpInitiateResult(
        statusResponseCode,
        responseDescription
      )
      dispatch(updateIsSubmitting(false))

      if (responseData?.message) {
        triggerAnalyticsEvent({
          messageKey: responseData?.message.key,
          pageName: OtpStart.NAME,
          pageFunction: OtpStart.PAGE_SUBFUNCTION
        })
        return dispatch(updateOtpStatusCode(statusResponseCode))
      }

      if (responseData?.lockedOut) {
        triggerAnalyticsEvent({
          messageKey: 'maximumAttemptsReached',
          pageName: OtpStart.NAME,
          pageFunction: OtpStart.PAGE_SUBFUNCTION
        })
        dispatch(updateIsMaximumAttemptReached(true))
        return dispatch(updateCurrentStep('sorry'))
      }

      return dispatch(updateOtpInitiateSuccess(statusResponseCode, phoneNumber))
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      triggerAnalyticsEvent({
        messageKey: 'default',
        pageName: OtpStart.NAME,
        pageFunction: OtpStart.PAGE_SUBFUNCTION
      })
      const { status } = error?.response || {}
      return dispatch(
        handleCatchError({
          endpoint,
          status
        })
      )
    }
  }

const handleRedirectOnVerify =
  ({
    zipCode,
    endpoint,
    sessionId,
    returnTo,
    queryParams,
    handleError,
    handleVerified
  }: VerifyOtpProps) =>
  async (dispatch: OtpThunkDispatch): Promise<OtpReturnType> => {
    try {
      const { responseData, result } = await verify(
        {
          sessionId
        },
        endpoint
      )
      const {
        user_id: verifyUserId,
        authorization_code: verifyAuthCode = '',
        prev_last_4_acct_no: oldAccountLastFourNo = '',
        last_4_acct_no: newAccountLastFourNo = '',
        account_type: accountType = '',
        ebill_status: eBillStatus = false,
        session_id: verifySessionId = ''
      } = responseData
      const { message, lostStolen } = result
      const cipherAccountId = responseData?.['cipher.accountId']

      if (message) {
        dispatch(updateIsSubmitting(false))
        triggerAnalyticsEvent({ messageKey: message?.key })
        return dispatch(updateOtpError(message))
      }

      if (endpoint === 'lookup-userid' && verifyUserId) {
        dispatch(updateIsSubmitting(false))
        return history.push({
          pathname: routes.FOUND,
          state: {
            userId: verifyUserId,
            zipCode,
            returnTo,
            queryParams
          }
        })
      }

      const accessToken = await generateToken({
        authCode: verifyAuthCode,
        codeVerifier: searchUserCodeVerifier
      })

      if (accessToken && !eBillStatus && cipherAccountId) {
        await dispatch(
          getPsfMessage({
            accessToken,
            cipherAccountId
          })
        )
      }

      dispatch(updateIsModalOpen(false))

      if (lostStolen) {
        return handleError({
          type: apiEndpoints.dsecurity.LOST_STOLEN,
          oldCard: oldAccountLastFourNo,
          newCard: newAccountLastFourNo,
          accessToken,
          accountType
        })
      }

      return handleVerified({
        sessionId: verifySessionId,
        userId: verifyUserId,
        ebillStatus: eBillStatus,
        accessToken,
        cipherAccountId
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      triggerAnalyticsEvent({ messageKey: 'default' })
      const { status } = error?.response || {}
      return dispatch(
        handleCatchError({
          endpoint,
          status
        })
      )
    }
  }

const handleGenerateToken =
  ({
    lostStolen,
    handleError,
    otpData,
    returnTo,
    queryParams
  }: HandleGenerateTokenProps) =>
  async (
    dispatch: OtpThunkDispatch,
    getState: () => RootState
  ): Promise<OtpReturnType> => {
    const {
      akamaiConfig: { hasJwtToken }
    } = getState()
    const {
      account_type: accountType,
      authorization_code: authCode,
      client_id: clientId = config.RC_CLIENT_ID
    } = otpData

    const accessToken = await generateToken({
      authCode,
      codeVerifier: authenticateCodeVerifer,
      clientId
    })

    if (lostStolen) {
      dispatch(updateIsSubmitting(false))
      return handleError({
        type: apiEndpoints.dsecurity.LOST_STOLEN,
        oldCard: otpData.prev_last_4_acct_no || '',
        newCard: otpData.last_4_acct_no || '',
        accessToken,
        accountType: otpData.account_type
      })
    }

    return determineWhereToHandover({
      accountType,
      clientId,
      history,
      returnTo,
      queryParams,
      hasJwtToken
    })
  }

const handleValidateSuccess =
  ({
    otpData,
    returnTo,
    queryParams,
    endpoint,
    sessionId,
    zipCode,
    handleVerified,
    handleError
  }: HandleValidateProps) =>
  async (dispatch: OtpThunkDispatch): Promise<OtpReturnType> => {
    const {
      account_type: accountType,
      status: { response_code: responseCode = '' }
    } = otpData

    const { message, lostStolen, lockedOut } = determineValidateResult(
      responseCode,
      accountType
    )

    if (message) {
      triggerAnalyticsEvent({
        messageKey: 'invalidOTP',
        pageName: OtpVerify.PAGE_NAME,
        pageFunction: OtpVerify.PAGE_SUBFUNCTION
      })
      dispatch(updateIsSubmitting(false))
      return dispatch(updateOtpError(message))
    }

    if (lockedOut) {
      triggerAnalyticsEvent({
        messageKey: 'maximumAttemptsReached',
        pageName: OtpVerify.PAGE_NAME,
        pageFunction: OtpVerify.PAGE_SUBFUNCTION
      })
      dispatch(updateIsSubmitting(false))
      dispatch(updateIsMaximumAttemptReached(true))
      triggerAnalyticsEvent({
        pageName: 'bad device',
        messageKey: 'analyticsBadDevice',
        pageFunction: OtpVerify.PAGE_SUBFUNCTION
      })
      return dispatch(updateCurrentStep('sorry'))
    }

    if (lostStolen || endpoint === 'login') {
      return dispatch(
        handleGenerateToken({
          lostStolen,
          handleError,
          otpData,
          endpoint,
          returnTo,
          queryParams
        })
      )
    }

    triggerAnalyticsEvent({
      subFunction: OtpComplete.PAGE_SUBFUNCTION,
      pageName: OtpComplete.PAGE_NAME,
      messageKey: OtpComplete.MESSAGE_KEY
    })

    // do verification for register, find userid, reset passsword
    return dispatch(
      handleRedirectOnVerify({
        sessionId,
        zipCode,
        endpoint,
        returnTo,
        queryParams,
        handleError,
        handleVerified
      })
    )
  }

export const postOtpValidate =
  ({
    sessionId,
    code,
    bindDevice,
    zipCode,
    endpoint,
    returnTo,
    queryParams,
    type,
    handleVerified,
    handleError
  }: PostCplOtpValidate) =>
  async (dispatch: OtpThunkDispatch): Promise<OtpReturnType> => {
    dispatch(updateIsSubmitting(true))
    dispatch(resetError())
    try {
      const oauthIdClientId = decodeURIComponent(
        getParameterByName('client_id')
      )
      const otpData = isCplFlow(endpoint)
        ? await cplOtpValidate(
            {
              sessionId,
              code,
              bindDevice,
              oauthIdClientId
            },
            endpoint
          )
        : await otpValidate(
            {
              sessionId,
              code,
              bindDevice
            },
            endpoint
          )

      const {
        authorization_code: authCode = '',
        status: { response_code: responseCode = '' }
      } = otpData

      if (isAccountLinkingRedirect(endpoint, responseCode)) {
        if (type === 'voiceAuthentication') {
          dispatch(updateIsSubmitting(false))
          return handleVerified(otpData)
        }

        const redirectUri = handleAccountLinkingRedirect({
          queryParams,
          authCode
        })

        triggerAnalyticsEvent({
          subFunction: OtpComplete.PAGE_SUBFUNCTION,
          pageName: OtpComplete.PAGE_NAME,
          messageKey: OtpComplete.MESSAGE_KEY
        })
        triggerAnalyticsEvent({ pageName: 'complete' })
        return redirectToClientUrl(redirectUri)
      }

      if (isPersistentLoginRedirect(endpoint, responseCode)) {
        triggerAnalyticsEvent({
          subFunction: OtpComplete.PAGE_SUBFUNCTION,
          pageName: OtpComplete.PAGE_NAME,
          messageKey: OtpComplete.MESSAGE_KEY
        })
        return handleVerified(otpData)
      }

      return dispatch(
        handleValidateSuccess({
          otpData,
          returnTo,
          queryParams,
          endpoint,
          sessionId,
          zipCode,
          handleVerified,
          handleError
        })
      )
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      triggerAnalyticsEvent({
        messageKey: 'default',
        subFunction: OtpVerify.PAGE_SUBFUNCTION,
        pageName: OtpVerify.PAGE_NAME
      })
      const errorCode = determineCplErrorCode(error, endpoint)
      return dispatch(
        handleCatchError({
          endpoint,
          status: errorCode
        })
      )
    }
  }
