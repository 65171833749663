import { addBmTelemetryToHeaders } from 'helpers'
import config from 'const/config'
import getCorrelationId from 'helpers/getCorrelationId'
import apiEndpoints from 'const/apiEndpoints'
import {
  CplOtpValidateOptions,
  CplOtpValidateRequest,
  CplOtpValidateResponseData,
  VerificationEndpoint
} from 'services/typings'
import api, { generateTransId } from 'services'

const otpValidate = async (
  options: CplOtpValidateOptions,
  endpoint: VerificationEndpoint
): Promise<CplOtpValidateResponseData> => {
  const {
    sessionId = '',
    code = '',
    bindDevice = false,
    oauthIdClientId
  } = options || {}
  const payload: CplOtpValidateRequest = {
    otp_info: {
      code,
      bind_device: bindDevice
    },
    session_info: {
      session_id: sessionId
    }
  }

  const reqHeaders = await addBmTelemetryToHeaders({
    headers: {
      'X-SYF-Request-TrackingId': generateTransId(),
      'X-SYF-Request-CorrelationId': getCorrelationId()
    },
    params: {
      client_id:
        endpoint !== 'account-linking'
          ? config.PERSIST_APIGEE_CLIENT_ID || ''
          : oauthIdClientId
    }
  })

  const response = await api.post(
    endpoint !== 'account-linking'
      ? apiEndpoints.cpl.VALIDATE_PERSISTENT_OTP
      : apiEndpoints.dsecurity.VALIDATE_LEGACY_OTP,
    payload,
    reqHeaders
  )

  return response.data
}

export default otpValidate
