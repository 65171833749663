import redirectToUrl from 'syf-js-utilities/helpers/redirectToUrl'
import apiEndpoints from 'const/apiEndpoints'
import config, { publicUrlExtension } from 'const/config'
import getCorrelationId from 'helpers/getCorrelationId'
import api, { generateTransId } from 'services'
import handoverToServicing from 'services/nonIdP/handoverToServicing'
import { SelectAcountOptions } from 'services/typings'

const selectAccount = async ({
  accountType,
  syfIdentifier,
  hasJwtToken
}: SelectAcountOptions): Promise<void> => {
  const redirectUri = 'https://redirect.synchronycredit.com'
  try {
    if (accountType !== 'loan')
      return handoverToServicing({ accountType, syfIdentifier, hasJwtToken })

    const correlationId = getCorrelationId()
    const {
      data: { authorizationCode }
    } = await api.put(
      apiEndpoints.dsecurity.MULTI_ACCOUNT,
      {
        auth: {
          clientId: config.SETPAY_APIGEE_CLIENT_ID,
          redirectUri,
          scope: 'login',
          state: 'mEY9a6NpxrRk426loQC57T%2FgMbM1qz1%2F5kIoSxhA2Rs%3D'
        },
        syfIdentifier,
        sessionId: sessionStorage.getItem('madSessionId')
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('madAccessToken')}`,
          'X-SYF-Request-TrackingId': generateTransId(),
          'X-SYF-Request-CorrelationId': correlationId
        }
      }
    )
    const pathname = publicUrlExtension('setpay/')
    const queryParams = `?authCode=${authorizationCode}&correlationId=${correlationId}&syfIdentifier=${syfIdentifier}&redirectUri=${redirectUri}`
    const sendToSetpay = () => {
      sessionStorage.setItem('madAccessToken', '')
      sessionStorage.setItem('madSessionId', '')
      sessionStorage.setItem('madApiBaseUrl', '')
      redirectToUrl(config.TARGET_ORIGIN + pathname + queryParams)
    }
    return sendToSetpay()
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    throw error
  }
}

export default selectAccount
