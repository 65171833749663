import config from 'const/config'

const handleRememberMe = (idTokenHint: string, rememberMe: boolean): void => {
  try {
    const rememberClient = `remember-${config.RC_CLIENT_ID}`
    if (rememberMe) {
      const localStorgeData = `${idTokenHint}&&${config.RC_CLIENT_ID}`
      localStorage.setItem(rememberClient, localStorgeData)
    } else {
      localStorage.removeItem(rememberClient)
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}

export default handleRememberMe
