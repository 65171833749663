import React, { ReactElement, useEffect, useState } from 'react'
import searchUser, { codeVerifier } from 'services/nonIdP/searchUser'
import { SearchResponse, SearchUserInfo } from 'services/typings'
import {
  handleRedirectToErrorScreen,
  translate,
  triggerAnalyticsEvent
} from 'helpers'
import updatePassword from 'services/nonIdP/updatePassword'
import generateToken from 'services/nonIdP/generateToken'
import { ErrorType } from 'types/errorTypes'
import verify from 'services/nonIdP/verify'
import routes from 'const/routes'
import NeedsVerificationModal from 'ui/organisms/NeedsVerificationModal/NonIdP'
import LostStolen from 'ui/organisms/LostStolen'
import Template from 'ui/molecules/Template'
import ResetPassword from 'const/analytics/reset'
import { useLocation } from 'react-router-dom'
import { MfaError } from 'ui/organisms/NeedsVerificationModal/typings'
import { LocationState } from 'types/locationState'
import windowScrollTop from 'syf-js-utilities/helpers/windowScrollTop'
import VerificationEndpoint from 'const/verificationEndPoint'
import { MfaValidationType } from 'const/mfa'
import handleMfaMethodAnalyticsEvent from 'helpers/handleMfaMethodAnalyticsEvent'
import UpdatePassword from './UpdatePassword'
import LookupForm from './LookupForm'
import { LostStolenTypes, ResetStep } from './typings'
import Confirmation from './Confirmation'

const Reset = (): ReactElement => {
  const location = useLocation<Partial<LocationState>>()
  const { state } = location || {}
  const {
    userId,
    zipCode,
    dob,
    returnTo = routes.HOME,
    queryParams = ''
  } = state || {}
  const errorReference = state ? state.error : {}
  const [currentStep, setCurrentStep] = useState<ResetStep>(ResetStep.LOOKUP)
  const [validationModal, setValidationModal] = useState<
    MfaValidationType.CVV | MfaValidationType.SSN | MfaValidationType.OTP | ''
  >('')
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [error, setError] = useState<ErrorType>(errorReference)
  const [accessToken, setAccessToken] = useState<string>('')
  const [authData, setAuthData] = useState<SearchResponse>({})
  const [lostStolenInfo, setLostStolenInfo] = useState<LostStolenTypes>({})

  const updateCurrentStep = (step: ResetStep) => {
    setCurrentStep(step)
    triggerAnalyticsEvent({
      pageName: ResetPassword[step]
    })
  }

  const handleResetPassword = async (newPassword: string): Promise<void> => {
    setIsSubmitting(true)
    try {
      if (!authData || !accessToken)
        throw new Error('No authdata or no accessToken')

      const { success, message } = await updatePassword({
        password: newPassword,
        sessionId: authData.session_id,
        accessToken
      })

      if (success) {
        updateCurrentStep(ResetStep.COMPLETE)
      } else if (message && message.key) {
        triggerAnalyticsEvent({ messageKey: message.key })
        setError(message)
        setIsSubmitting(false)
      } else {
        throw new Error(translate({ string: 'default' }))
      }
    } catch (resetError) {
      // eslint-disable-next-line no-console
      console.error(resetError)
      const { status } = resetError.response || {}
      handleRedirectToErrorScreen({
        errorCode: status
      })

      setError({
        key: 'default',
        extension: `${translate({ string: 'code' })}: ${status}`
      })
      setIsSubmitting(false)
    }
  }

  const handleSearchResult = async (fields: SearchUserInfo): Promise<void> => {
    try {
      setIsSubmitting(true)
      const search = await searchUser(fields, 'reset-password')
      const searchResult = search.result || {}
      const authDataValue: SearchResponse = search.responseData

      // need to send to the otp modal

      setAuthData(authDataValue)
      setError({})

      // Handle all the possilbe cases coming back from elegibility
      if (searchResult.validation) {
        setValidationModal(searchResult?.validation)
        handleMfaMethodAnalyticsEvent(searchResult?.validation)
        setError({})
        setIsSubmitting(false)
      } else if (searchResult.message) {
        triggerAnalyticsEvent({ messageKey: searchResult.message.key })
        setError(searchResult.message)
        setIsSubmitting(false)
      } else {
        const { responseData, result } = await verify(
          {
            sessionId: authDataValue.session_id
          },
          'reset-password'
        )

        const {
          authorization_code: authCode,
          last_4_acct_no: newCard,
          prev_last_4_acct_no: oldCard
        } = responseData

        const accessTokenData = await generateToken({
          authCode,
          codeVerifier
        })

        const lostStolenWithAccounts = result.lostStolen && oldCard && newCard
        const lostStolenWithMissingAccounts =
          result.lostStolen && (!oldCard || !newCard)
        if (lostStolenWithAccounts) {
          setValidationModal('')
          updateCurrentStep(ResetStep.LOST_STOLEN)
          setLostStolenInfo({
            oldCard,
            newCard,
            accessToken: accessTokenData
          })
          setIsSubmitting(false)
        } else if (lostStolenWithMissingAccounts) {
          setError({ key: 'default' })
          setIsSubmitting(false)
        } else if (result.message) {
          triggerAnalyticsEvent({ messageKey: result.message.key })
          setError({ ...result.message })
          setIsSubmitting(false)
        } else {
          setValidationModal('')
          updateCurrentStep(ResetStep.SELECT)
          setAccessToken(accessTokenData)
          setError({})
          setIsSubmitting(false)
        }
      }
    } catch (searchResultError) {
      // eslint-disable-next-line no-console
      console.error(searchResultError)
      const { status } = searchResultError.response || {}
      handleRedirectToErrorScreen({
        errorCode: status
      })
      setError({
        key: 'default',
        extension: `${translate({ string: 'code' })}: ${status}`
      })
      setIsSubmitting(false)
    }
  }

  const steps = [
    {
      label: translate({ string: 'enterUserID' }),
      value: 0
    },
    {
      label: translate({ string: 'selectPassword' }),
      value: 1
    },
    {
      label: translate({ string: 'login' }),
      value: 2
    }
  ]

  useEffect(() => {
    windowScrollTop()
  }, [currentStep])

  return (
    <Template shouldLoadAllScripts>
      {authData && (
        <NeedsVerificationModal
          data-test="needs-verification-modal"
          endpoint={VerificationEndpoint.RESET_PASSWORD}
          showModal={!!validationModal}
          verificationType={validationModal}
          containerId="resetModal"
          handleCancel={() => {
            setValidationModal('')
            setError({})
            triggerAnalyticsEvent({ subFunction: '' })
          }}
          authData={{
            phoneNumbers: authData.phone_numbers,
            sessionId: authData.session_id,
            userId: ''
          }}
          handleError={(errorValue: MfaError) => {
            if (errorValue.type === 'lost-stolen') {
              updateCurrentStep(ResetStep.LOST_STOLEN)
              setValidationModal('')
              setAccessToken(errorValue.accessToken)
              setError(null)
              setLostStolenInfo({
                oldCard: errorValue.oldCard,
                newCard: errorValue.newCard,
                accessToken: errorValue.accessToken
              })
            }
          }}
          handleVerified={({
            accessToken: accessTokenData,
            oldCard = null,
            newCard = null,
            result = {}
          }) => {
            const setStep = result.lostStolen
              ? ResetStep.LOST_STOLEN
              : ResetStep.SELECT
            setValidationModal('')
            setError(null)
            setAccessToken(accessTokenData)
            setLostStolenInfo({ oldCard, newCard })
            updateCurrentStep(setStep)
          }}
        />
      )}

      {currentStep === 'lookup' && (
        <LookupForm
          data-test="lookup-form"
          onSubmit={handleSearchResult}
          error={error}
          onFocusTextField={() => setError({})}
          userId={userId}
          zipCode={zipCode}
          dob={dob}
          steps={steps}
          isSubmitting={isSubmitting}
          returnTo={returnTo + queryParams}
        />
      )}
      {currentStep === 'select' && (
        <UpdatePassword
          data-test="update-password"
          handleSubmit={handleResetPassword}
          steps={steps}
          onFocusTextField={() => setError({})}
          submissionError={error}
          isSubmitting={isSubmitting}
          returnTo={returnTo + queryParams}
        />
      )}
      {currentStep === 'lost-stolen' && (
        <LostStolen
          data-test="lost-stolen"
          dataType="reset password"
          oldCard={lostStolenInfo.oldCard}
          newCard={lostStolenInfo.newCard}
          handleButtonClick={() => updateCurrentStep(ResetStep.SELECT)}
        />
      )}
      {currentStep === ResetStep.COMPLETE && <Confirmation />}
    </Template>
  )
}

export default Reset
