import * as idpErrors from 'const/idpErrors'

const {
  OTP_INITIATE_INCORRECT_DELIVERY_METHOD,
  OTP_INITIATE_PHONE_CONFIRM_ERROR,
  OTP_INITIATE_PHONE_CONFIRM_ERROR_ONE_ATTEMPT_LEFT,
  OTP_INITIATE_PHONE_CONFIRM_ERROR_MAX,
  OTP_INITIATE_PHONE_CONFIRM_MAX
} = idpErrors

const isToastError = (errorCode: string): boolean => {
  const toastErrors = [
    OTP_INITIATE_INCORRECT_DELIVERY_METHOD,
    OTP_INITIATE_PHONE_CONFIRM_ERROR,
    OTP_INITIATE_PHONE_CONFIRM_ERROR_ONE_ATTEMPT_LEFT,
    OTP_INITIATE_PHONE_CONFIRM_ERROR_MAX,
    OTP_INITIATE_PHONE_CONFIRM_MAX
  ]
  const isToastErrorCode = toastErrors.find(
    toastErrorCode => toastErrorCode === errorCode
  )
  return !!isToastErrorCode
}

export default isToastError
