import styled, { FlattenSimpleInterpolation, css } from 'styled-components'
import { colors } from 'theme'
import query from 'syf-component-library/const/mediaQueries'
import { brandImages, palette } from 'configureBrand'
import config from 'const/config'
import {
  BackgroundWrapperProps,
  InnerProps
} from 'ui/molecules/Template/typings'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: auto;
`

const innerQueryCplStyles = (): FlattenSimpleInterpolation =>
  css`
    @media ${query.lessThanMedium} {
      padding: 20px 10px 32px;
    }
  `

export const Inner = styled.div<InnerProps>`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  ${({ isCplRoute }) => isCplRoute && innerQueryCplStyles()};
  ${props => props.css || ''};
`

const nonCplStyles = (): FlattenSimpleInterpolation =>
  css`
    padding-top: 20px;
    padding-bottom: 50px;
    flex-grow: 1;
    height: auto;
  `

const backgroundStyles = (): FlattenSimpleInterpolation => {
  return brandImages.hero
    ? css`
        background-image: url(${config.ASSET_ORIGIN + brandImages.hero});
      `
    : css`
        background-color: ${palette.heroBackground};
      `
}

export const BackgroundWrapper = styled.div<BackgroundWrapperProps>`
  ${({ isCplRoute }) => !isCplRoute && nonCplStyles()};
  height: auto;
  background-color: white;
  ${({ renderBackground }) => renderBackground && backgroundStyles()};
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  @media ${query.lessThanMedium} {
    ${p => (p.renderBackground ? 'height:auto' : '')};
    background-image: none;
    background-color: ${colors.white};
    padding-top: 0;
  }
`
