import React, { ReactElement } from 'react'
import { useLocation } from 'react-router-dom'
import { RootState } from 'redux/store'
import { useDispatch, useSelector } from 'react-redux'
import {
  handleVerified,
  resetRegistrationState
} from 'redux/registration/actions'
import triggerAnalyticsEvent from 'helpers/triggerAnalyticsEvent'
import RegistrationStep from 'const/registrationStep'
import VerificationEndpoint from 'const/verificationEndPoint'
import routes from 'const/routes'
import Template from 'ui/molecules/Template'
import NeedsVerificationModal from 'ui/organisms/NeedsVerificationModal'
import RegistrationAccountLookup from './RegistrationAccountLookup'
import RegistrationComplete from './RegistrationComplete'
import RegistrationForm from './RegistrationForm'

const Registration = (): ReactElement => {
  const {
    idp: { postAuthResponseCode, idpValidationModal },
    registration: { currentStep }
  } = useSelector((reduxState: RootState) => reduxState)
  const dispatch = useDispatch()
  const { state } = useLocation<{
    returnTo: string
  }>()
  const { returnTo = routes.HOME } = state || {}

  const renderRegistrationStep = () => {
    switch (currentStep) {
      case RegistrationStep.LOOKUP:
        return <RegistrationAccountLookup data-test="registration-lookup" />
      case RegistrationStep.REGISTRATION:
        return (
          <RegistrationForm data-test="registration-form" returnTo={returnTo} />
        )
      case RegistrationStep.COMPLETE:
      default:
        return <RegistrationComplete data-test="registration-complete" />
    }
  }

  return (
    <Template shouldLoadAllScripts>
      {renderRegistrationStep()}
      {!!postAuthResponseCode && (
        <NeedsVerificationModal
          isIdpFlow
          data-test="verification-modal"
          endpoint={VerificationEndpoint.REGISTRATION}
          verificationType={idpValidationModal}
          containerId="registrationModal"
          handleCancel={() => {
            triggerAnalyticsEvent({ subFunction: '' })
            dispatch(resetRegistrationState())
          }}
          handleVerified={options => {
            dispatch(
              handleVerified({
                accessToken: options?.accessToken,
                ebillStatus: options?.ebillStatus,
                currentStep: 'registration',
                validationModal: ''
              })
            )
          }}
        />
      )}
    </Template>
  )
}

export default Registration
