import { OtpInitiateResult } from './typings'

const determineOtpInitiateResult = (
  code: '2001' | '2002' | '2003' | '2004' | '2005' | '',
  description: string
): OtpInitiateResult => {
  switch (code) {
    case '2001':
    case '2002':
      return {
        status: {
          response_code: code,
          response_desc: description
        }
      }
    case '2003':
      return {
        message: { key: 'phoneNumberNotVerified', extension: 'Code: 2003' }
      }
    case '2004':
      return { lockedOut: true }
    case '2005':
      return {
        message: {
          key: 'wrongDeliveryMode',
          extension: 'Code: 2005'
        }
      }
    default:
      return { message: { key: 'default' } }
  }
}

export default determineOtpInitiateResult
