import React, { ReactElement, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  formatQueryParams,
  getParameterByName,
  redirectToClientUrl,
  translate,
  triggerAnalyticsEvent
} from 'helpers'
import NeedsVerificationModal from 'ui/organisms/NeedsVerificationModal/NonIdP'
import Header from 'ui/atoms/Header'
import SpinnerLoader from 'syf-component-library/ui/atoms/Loader/Spinner'
import {
  handlePersistHandover,
  postPersistentAuthentication
} from 'redux/cpl/actions'
import Template from 'ui/molecules/Template'
import { updateIsModalOpen } from 'redux/uiUtils/actions'
import { RootState } from 'redux/store'
import VerificationEndpoint from 'const/verificationEndPoint'
import { MfaValidationType } from 'const/mfa'
import PersistError from '../PersistError'
import { LoadingWrapper, SpinnerWrapper } from './subcomponents'
import { Error, OtpValidationType } from './typings'

const PersistLogin = (): ReactElement => {
  const {
    cpl: {
      authenticateCplResponse: {
        phone_numbers: phoneNumbersData = [],
        session_id: sessionIdData = '',
        user_id: userIdData = '',
        codeVerifier
      },
      errorCode,
      errorDescriptor,
      isPersistError
    },
    uiUtils: { isSubmitting }
  } = useSelector((reduxState: RootState) => reduxState, shallowEqual)
  const dispatch = useDispatch()
  const oauthRedirectUri = decodeURIComponent(
    getParameterByName('redirect_uri', window.location.search)
  )

  const handleOtpResponse = (otpValidateResp: OtpValidationType): void => {
    const { authorization_code: authCode, account_type: accountType } =
      otpValidateResp

    dispatch(
      handlePersistHandover({
        authCode,
        accountType,
        codeVerifier,
        oauthRedirectUri
      })
    )
  }

  const handleCancelOtp = (): void => {
    const redirectUri = formatQueryParams(
      oauthRedirectUri,
      'error=450&error_description=User Cancelled OTP'
    )
    redirectToClientUrl(redirectUri)
    triggerAnalyticsEvent({ subFunction: '' })
  }

  useEffect(() => {
    dispatch(postPersistentAuthentication())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const Loading = (): ReactElement => {
    return (
      <LoadingWrapper>
        <SpinnerWrapper>
          <SpinnerLoader />
        </SpinnerWrapper>
        <Header
          data-test="loadingHeader"
          title={translate({ string: 'loggingIntoYourAccount' })}
        />
      </LoadingWrapper>
    )
  }

  return (
    <Template>
      {isPersistError && (
        <PersistError
          data-test="persist-error"
          errorContext={{
            errorNum: errorCode,
            errDetail: errorDescriptor
          }}
        />
      )}
      <NeedsVerificationModal
        endpoint={VerificationEndpoint.PERSISTENT_LOGIN}
        verificationType={MfaValidationType.OTP}
        data-test="needs-verification-modal"
        containerId="persistOtpModal"
        handleCancel={() => handleCancelOtp()}
        authData={{
          phoneNumbers: phoneNumbersData,
          sessionId: sessionIdData,
          userId: userIdData
        }}
        handleError={(error: Error) => {
          if (
            error.type === 'lost-stolen' ||
            error.type === 'customer-service'
          ) {
            dispatch(updateIsModalOpen(false))
          }
        }}
        handleVerified={handleOtpResponse}
      />
      {isSubmitting && <Loading />}
    </Template>
  )
}
export default PersistLogin
