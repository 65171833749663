import cplSystemErrorDescriptors, {
  CplErrorDescriptorKeys
} from 'const/cplSystemErrorDescriptors'
import { VerificationEndpoint } from 'services/typings'
import apiEndpoints from 'const/apiEndpoints'
import { AnyObject } from 'types/custom'
import determineCplErrorType from './determineCplErrorType'

const {
  cpl: { ACCOUNT_LINKING, PERSISTENT_LOGIN }
} = apiEndpoints

export const isCplFlow = (endpoint: VerificationEndpoint): boolean =>
  endpoint === ACCOUNT_LINKING || endpoint === PERSISTENT_LOGIN

export const determineCplDescriptorKey = (
  endpoint: VerificationEndpoint
): CplErrorDescriptorKeys => {
  return endpoint === ACCOUNT_LINKING
    ? CplErrorDescriptorKeys.OTP
    : CplErrorDescriptorKeys.PERSIST_OTP
}

export const determineCplErrorCode = (
  error: AnyObject,
  endpoint: VerificationEndpoint
): number => {
  const status = error?.response?.status
  if (status === 500 && endpoint === ACCOUNT_LINKING) {
    const responseCode = error?.response?.data?.code
    return responseCode === 'client.authorization.authcodeError' ? 9001 : 500
  }
  return status
}

const isCplRedirectError = (
  status: number,
  endpoint: CplErrorDescriptorKeys
): boolean => {
  const cplErrorType = determineCplErrorType()
  const cplErrorDescriptor = cplSystemErrorDescriptors[endpoint][cplErrorType]
  return (
    cplErrorDescriptor &&
    Object.keys(cplErrorDescriptor).indexOf(`${status}`) >= 0
  )
}

export default isCplRedirectError
