import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { colors } from 'theme'

interface ErrorMessageWrapperContainer {
  centerAlign?: boolean
}

export const ErrorMessageWrapper = styled.div<ErrorMessageWrapperContainer>`
  display: flex;
  align-items: center;
  width: 275px;
  margin-bottom: 15px;
  font-size: 14px;
  color: ${colors.error};
  min-height: 24px;
  padding-bottom: 10px;
  ${p => p.centerAlign && 'margin: 0 auto;'};

  &:focus {
    outline: none;
  }
`

export const FontAwesomeIconStyle = styled(FontAwesomeIcon)`
  font-size: 15px;
  margin-right: 5px;
`

export const ErrorMessage = styled.span`
  width: 260px;
  margin: 0 auto;
`
