import React, { ChangeEvent, ReactElement, SyntheticEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RootState } from 'redux/store'
import translate from 'helpers/translate'
import validate from 'helpers/validate'
import {
  handleNonIdpReset,
  handleNonIdpVerify,
  handleValidate,
  updateCvv,
  updateValidationError
} from 'redux/cvv/actions'
import AuthDataTypes from 'types/authDataTypes'
import { VerificationEndpoint } from 'services/typings'
import { FA_EXCLAMATION_CIRCLE, FA_LOCK } from 'const/iconProp'
import { Verified } from 'ui/organisms/NeedsVerificationModal/typings'
import { MfaValidationType } from 'const/mfa'
import Header from 'ui/atoms/Header'
import InlineError from 'ui/atoms/InlineError/NonIdP'
import {
  Form,
  TextfieldWithMargin
} from 'ui/organisms/NeedsVerificationModal/subcomponents'
import ModalFooter from 'ui/organisms/NeedsVerificationModal/NonIdP/ModalFooter'

interface CVVProps {
  authData?: AuthDataTypes
  endpoint?: VerificationEndpoint
  handleCancel?: () => void
  handleVerified?: (data: Verified) => void
}

const CVV = ({
  authData,
  endpoint,
  handleCancel,
  handleVerified
}: CVVProps): ReactElement => {
  const {
    cvv: { code, validationError, submissionError }
  } = useSelector((state: RootState) => ({
    cvv: state.cvv,
    isSubmitting: state.uiUtils.isSubmitting,
    isAccountLocked: state.idp.isAccountLocked
  }))
  const dispatch = useDispatch()

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault()
    const formValidationError = validate(MfaValidationType.CVV, code)

    if (formValidationError) {
      dispatch(updateValidationError(formValidationError))
    } else {
      dispatch(handleNonIdpVerify(authData, endpoint, handleVerified))
    }
  }

  return (
    <>
      <Header
        data-test="subHeader"
        title={translate({ string: 'forYourSecurity' })}
        description={translate({ string: 'pleaseEnterTheInfo' })}
        labelledby="verification-title"
        describedby="verification-description"
      />
      <InlineError
        data-test="error-wrapper"
        centerAlign
        error={submissionError || {}}
      />
      <Form data-reason="form" onSubmit={handleSubmit} noValidate>
        <TextfieldWithMargin
          id="cvv"
          name="cvv"
          type="password"
          inputMode="numeric"
          value={code}
          data-reason="cvv-field"
          className="masked"
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            dispatch(updateCvv(event.target.value))
          }
          onBlur={(event: ChangeEvent<HTMLInputElement>) =>
            dispatch(handleValidate(event.target.value))
          }
          maxLength={3}
          placeholder={translate({ string: 'threeDigitCode' })}
          error={validationError}
          leftIcon={<FontAwesomeIcon icon={FA_LOCK} />}
          errorIcon={<FontAwesomeIcon icon={FA_EXCLAMATION_CIRCLE} />}
          width="275px"
          data-private
          required
        />
        <ModalFooter
          dataType="cvv verify"
          handleCancel={() => {
            handleCancel()
            dispatch(handleNonIdpReset())
          }}
          submissionError={submissionError && submissionError.locked}
        />
      </Form>
    </>
  )
}

export default CVV
