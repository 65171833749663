import styled, { FlattenSimpleInterpolation, css } from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import { PrimaryButton } from 'ui/atoms/Button'
import { ReactComponent as ActionClock } from 'assets/clock.svg'
import { colors, opacities } from 'theme'
import { PayAsGuestContainerProps } from './typings'

const mediumDownStyles = (hasIdpBanner: boolean): FlattenSimpleInterpolation =>
  css`
    margin-top: ${hasIdpBanner && '-20px'};
    padding: ${hasIdpBanner ? '32px 8px 12px 8px' : '12px 8px'};
  `

const smallDownStyles = (hasIdpBanner: boolean): FlattenSimpleInterpolation =>
  css`
    padding: ${hasIdpBanner ? '32px 3px 12px 3px' : '12px 3px'};
    justify-content: center;
    flex-wrap: wrap;
  `

export const PayAsGuestContainer = styled.div<PayAsGuestContainerProps>`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  padding: 12px 20px;
  border: 1px solid ${colors.fordGrey};
  box-shadow: 0 2px 2px 0 ${opacities.greyTwentyTwo},
    0 0 2px 0 ${opacities.greyEightyEight};
  border-radius: 10px;
  background-color: ${colors.greyNinetyOne};
  justify-content: space-between;
  flex-grow: 1;
  min-height: 102px;
  @media ${query.lessThanMedium} {
    ${({ hasIdpBanner }) => mediumDownStyles(hasIdpBanner)}
  }
  @media ${query.lessThanSmall} {
    ${({ hasIdpBanner }) => smallDownStyles(hasIdpBanner)}
  }
`

export const BannerTextAndIconContainer = styled.div`
  display: flex;
  @media ${query.lessThanMedium} {
    margin-bottom: 26px;
  }
  @media ${query.lessThanSmall} {
    margin-bottom: 0px;
  }
`

export const ClockIcon = styled(ActionClock)`
  display: none;
  @media ${query.greaterThanSmall} {
    display: flex;
    fill: ${colors.blueLagoon};
    transform: scale(1.2);
    margin: 0 8px;
  }
`

export const BannerTextContainer = styled.div`
  margin-bottom: 26px;
  text-align: center;
  @media ${query.greaterThanSmall} {
    display: block;
    text-align: start;
    margin: auto 0;
    padding-left: 16px;
  }
`

export const BannerHeader = styled.h3`
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  margin: auto 0;
  margin-bottom: 5px;
  @media ${query.greaterThanSmall} {
    text-align: start;
  }
`

export const BannerBody = styled.h5`
  margin: auto 0;
`

export const PAGButton = styled(PrimaryButton)`
  background-color: ${colors.blueLagoon};
  width: 200px;
  color: ${colors.white};
  &:hover {
    background-color: ${colors.blueLagoon};
  }
  @media ${query.lessThanSmall} {
    min-width: 275px;
  }
`
