import routes from 'const/routes'
import { clientInfo } from 'configureBrand'
import translate from 'helpers/translate'
import { TermsDetails, VoiceTermsPathName } from './typings'

const pathNames: VoiceTermsPathName = {}
pathNames[routes.GOOGLE] = 'google'
pathNames[routes.ALEXA] = 'alexa'
let termsTitle = ''
let termsAndConditionsLink = ''

const getTermsAndConditionDetails = (pathname: string): TermsDetails => {
  const { voice } = clientInfo
  if (pathname in pathNames) {
    const path = pathNames[pathname]
    const loginType = voice[path as keyof typeof voice] as VoiceTermsPathName
    termsTitle =
      pathname === routes.ALEXA
        ? translate({ string: 'alexaTermsTitle' })
        : translate({ string: 'googleHomeTermsTitle' })
    termsAndConditionsLink = loginType.termsAndConditionsLink
  }
  return { termsTitle, termsAndConditionsLink }
}

export default getTermsAndConditionDetails
