import React from 'react'
import styled, { css } from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import { getCurrentLanguageFromStorage } from 'helpers/translate'
import { brandImages, clientInfo } from 'configureBrand'
import config from 'const/config'

export const LoginWrapper = styled.div`
  margin: auto;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  @media ${query.lessThanMedium} {
    margin-right: 0;
  }
`

export const LoginBlock = styled.div`
  width: 325px;
`

export const MainPromoBlock = styled.div`
  display: none;
  @media ${query.greaterThanMedium} {
    display: block;
    width: 498px;
    margin-left: 35px;
  }
`

export const PromoImage = styled.img`
  display: block;
  width: 100%;
  height: auto;
`

export const LoginInner = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const PromoWrapper = (): React.ReactElement => {
  const language = getCurrentLanguageFromStorage()

  if (clientInfo.promoUrl) {
    return (
      <a href={clientInfo.promoUrl} target="_blank" rel="noopener noreferrer">
        <PromoImage
          src={`${config.ASSET_ORIGIN}${brandImages.mainPromo[language]}`}
          alt="Main promo"
        />
      </a>
    )
  }

  return (
    <PromoImage
      src={`${config.ASSET_ORIGIN}${brandImages.mainPromo[language]}`}
      alt="Main promo"
    />
  )
}
