import React, { ReactElement, SyntheticEvent, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  formatCreditCard,
  formatDate,
  maskCreditCard,
  translate,
  validate
} from 'helpers'
import LookupFormContainer from 'ui/molecules/LookupFormContainer'
import BillingInUsHeader from 'ui/atoms/BillingInUsHeader'
import {
  FlexButtonContainer,
  GhostButton,
  PrimaryButton,
  TextButton
} from 'ui/atoms/Button'
import { clientInfo } from 'configureBrand'
import {
  FA_CALENDAR,
  FA_CREDIT_CARD,
  FA_EXCLAMATION_CIRCLE,
  FA_MAP_MARKER
} from 'const/iconProp'
import useCancelRedirection from 'hooks/useCancelRedirection'
import { LastTextfield, TextfieldWithMargin } from './subcomponents'
import { CreditCardLookupFormProps } from './typings'

const CreditCardLookupForm = ({
  inUsa,
  setInUsa,
  returnTo,
  error,
  onFocusTextField,
  onSubmit
}: CreditCardLookupFormProps): ReactElement => {
  const {
    uiUtils: { isSubmitting }
  } = useSelector((state: RootState) => state)
  const accountNumberLength = Math.max(...clientInfo.accountNumberLengths)
  const [formValues, setFormValues] = useState({
    creditCard: '',
    acceptedLengths: clientInfo.accountNumberLengths,
    dob: '',
    zipCode: '',
    maskedCreditCard: '',
    errors: {
      creditCard: '',
      dob: '',
      zipCode: ''
    }
  })
  const handleCancelRedirection = useCancelRedirection(returnTo)

  const getFieldValue = (type: string, value: string): string => {
    switch (type) {
      case 'creditCard':
        return value.replace(/ /g, '')
      case 'dob':
        return formatDate(value)
      default:
        return value
    }
  }

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target
    const fieldValue = getFieldValue(name, value)
    const maskedCreditCard =
      name === 'creditCard' ? value : formValues.maskedCreditCard

    setFormValues({
      ...formValues,
      [name]: fieldValue,
      maskedCreditCard,
      errors: {
        ...formValues.errors,
        [name]: ''
      }
    })
  }

  const handleOnBlur = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target
    const fieldValue = getFieldValue(name, value)
    const fieldError = validate(name, fieldValue, { ...formValues, inUsa })

    let creditCardFields = {}
    if (name === 'creditCard' && formValues.maskedCreditCard) {
      creditCardFields = {
        maskedCreditCard: maskCreditCard(fieldValue),
        creditCard: fieldValue
      }
    }

    setFormValues({
      ...formValues,
      ...creditCardFields,
      errors: {
        ...formValues.errors,
        [name]: fieldError
      }
    })
  }

  const validateAllFields = (): string => {
    const validation = { errors: {} }
    const { errors } = formValues

    Object.keys(errors).forEach(key => {
      const { [key as keyof typeof errors]: fieldValue } = formValues

      const formError = validate(key, fieldValue, {
        ...formValues,
        inUsa
      })
      validation.errors = { ...validation.errors, [key]: formError }
    })

    setFormValues({
      ...formValues,
      errors: { ...formValues.errors, ...validation.errors }
    })

    return Object.keys(validation.errors).find(
      event => validation.errors[event as keyof typeof validation.errors]
    )
  }

  const handleSubmit = (event: SyntheticEvent): void => {
    event.preventDefault()
    const { creditCard, zipCode, dob } = formValues

    if (!validateAllFields()) {
      onSubmit({
        accountNumber: creditCard,
        zipCode,
        dob
      })
    }
  }

  const onSubHeaderClick = (): void => {
    setFormValues({ ...formValues, dob: '' })
    setInUsa(true)
  }

  const handleCreditCardFocus = (): void => {
    onFocusTextField()
    setFormValues({
      ...formValues,
      creditCard: formValues.maskedCreditCard,
      maskedCreditCard: formValues.creditCard
    })
  }

  const errorIcon = <FontAwesomeIcon icon={FA_EXCLAMATION_CIRCLE} />

  return (
    <LookupFormContainer
      onSubmit={handleSubmit}
      error={error}
      data-reason="lookupFormContainer"
      subheader={!inUsa && <BillingInUsHeader handleClick={onSubHeaderClick} />}
    >
      <TextfieldWithMargin
        id="creditCard"
        name="creditCard"
        type="text"
        data-reason="creditCard"
        value={
          formValues.maskedCreditCard
            ? formatCreditCard(formValues.maskedCreditCard)
            : formValues.maskedCreditCard
        }
        onChange={handleOnChange}
        maxLength={accountNumberLength + 3}
        placeholder={translate({ string: 'enterAccountNumber' })}
        onFocus={handleCreditCardFocus}
        onBlur={handleOnBlur}
        error={formValues.errors.creditCard}
        leftIcon={<FontAwesomeIcon icon={FA_CREDIT_CARD} />}
        errorIcon={errorIcon}
        width="275px"
        data-private
        required
      />
      {inUsa ? (
        <LastTextfield
          id="zipCode"
          name="zipCode"
          type="text"
          data-reason="zipCode"
          value={formValues.zipCode}
          onChange={handleOnChange}
          onFocus={onFocusTextField}
          onBlur={handleOnBlur}
          maxLength={5}
          placeholder={translate({ string: 'zipCode' })}
          error={formValues.errors.zipCode}
          leftIcon={<FontAwesomeIcon icon={FA_MAP_MARKER} />}
          errorIcon={errorIcon}
          width="275px"
          required
        />
      ) : (
        <LastTextfield
          type="text"
          id="dob"
          name="dob"
          data-reason="dateOfBirth"
          value={formValues.dob}
          error={formValues.errors.dob}
          placeholder={translate({ string: 'dateOfBirth' })}
          helpText={translate({ string: 'dobHelptext' })}
          onChange={handleOnChange}
          onFocus={onFocusTextField}
          onBlur={handleOnBlur}
          errorIcon={errorIcon}
          leftIcon={<FontAwesomeIcon icon={FA_CALENDAR} />}
          width="275px"
          required
        />
      )}
      {clientInfo.crossBorder && inUsa && (
        <TextButton
          buttonType="text"
          data-test="cross-border-billing"
          onClick={() => {
            setFormValues({ ...formValues, zipCode: '' })
            setInUsa(false)
          }}
        >
          {translate({ string: 'billingNotUs' })}
        </TextButton>
      )}
      <FlexButtonContainer pt="10px">
        <PrimaryButton
          buttonType="primary"
          type="submit"
          disabled={isSubmitting}
          className="ensightencustomevent"
          data-type="register"
          data-reason="continue"
          data-object="button"
        >
          {isSubmitting
            ? translate({ string: 'searching' })
            : translate({ string: 'continue' })}
        </PrimaryButton>
        <GhostButton
          buttonType="ghost"
          onClick={handleCancelRedirection}
          className="ensightencustomevent"
          data-type="register"
          data-reason="cancel"
          data-object="button"
        >
          {translate({ string: 'cancel' })}
        </GhostButton>
      </FlexButtonContainer>
    </LookupFormContainer>
  )
}

export default CreditCardLookupForm
