import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowAltRight } from '@fortawesome/pro-light-svg-icons/faArrowAltRight'
import { faCalendar } from '@fortawesome/pro-light-svg-icons/faCalendar'
import { faCloudRain } from '@fortawesome/pro-light-svg-icons/faCloudRain'
import { faCreditCard } from '@fortawesome/pro-light-svg-icons/faCreditCard'
import { faCreditCardBlank } from '@fortawesome/pro-light-svg-icons/faCreditCardBlank'
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope'
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons/faExclamationCircle'
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle'
import { faFileAlt } from '@fortawesome/pro-light-svg-icons/faFileAlt'
import { faGlobe } from '@fortawesome/pro-light-svg-icons/faGlobe'
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle'
import { faLock } from '@fortawesome/pro-light-svg-icons/faLock'
import { faMapMarker } from '@fortawesome/pro-light-svg-icons/faMapMarker'
import { faMinus } from '@fortawesome/pro-light-svg-icons/faMinus'
import { faPrint } from '@fortawesome/pro-light-svg-icons/faPrint'
import { faStopwatch } from '@fortawesome/pro-light-svg-icons/faStopwatch'
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser'
import { faUserCircle } from '@fortawesome/pro-light-svg-icons/faUserCircle'
import { faTools } from '@fortawesome/pro-light-svg-icons/faTools'
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle'
import { faComments } from '@fortawesome/pro-light-svg-icons/faComments'

import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck'
import { faPhoneAlt } from '@fortawesome/pro-regular-svg-icons/faPhoneAlt'
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes'
import { faSpinner } from '@fortawesome/pro-regular-svg-icons/faSpinner'

import { faCheckCircle as fasCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle'

// Light icons
export const FA_ARROW_ALT_RIGHT = faArrowAltRight
export const FA_CALENDAR = faCalendar
export const FA_CHECK_CIRCLE = faCheckCircle
export const FA_CLOUD_RAIN = faCloudRain
export const FA_CREDIT_CARD_BLANK = faCreditCardBlank
export const FA_CREDIT_CARD = faCreditCard
export const FA_ENVELOPE = faEnvelope
export const FA_EXCLAMATION_CIRCLE = faExclamationCircle
export const FA_FILE_ALT = faFileAlt
export const FA_GLOBE = faGlobe
export const FA_INFO_CIRCLE = faInfoCircle
export const FA_LOCK = faLock
export const FA_MAP_MARKER = faMapMarker
export const FA_MINUS = faMinus
export const FA_PRINT = faPrint
export const FA_EXCLAMATION_TRIANGLE = faExclamationTriangle
export const FA_USER_CIRCLE = faUserCircle
export const FA_USER = faUser
export const FA_TOOLS = faTools
export const FA_STOPWATCH = faStopwatch

// Regular icons
export const FA_CHECK = faCheck
export const FA_PHONE_ALT = faPhoneAlt
export const FA_TIMES = faTimes

// Solid icons
export const FAS_CHECK_CIRCLE = fasCheckCircle

library.add(
  faCalendar,
  faExclamationTriangle,
  faLock,
  faInfoCircle,
  faMinus,
  faExclamationCircle,
  faUser,
  faExclamationTriangle,
  faFileAlt,
  faCreditCard,
  faMapMarker,
  faEnvelope,
  faCheck,
  faTimes,
  faGlobe,
  faPrint,
  faArrowAltRight,
  faCheckCircle,
  faUserCircle,
  faCreditCardBlank,
  faPhoneAlt,
  faCloudRain,
  faStopwatch,
  fasCheckCircle,
  faComments,
  faSpinner
)
