import { RootState } from 'redux/store'
import { ThunkDispatch } from 'redux-thunk'
import { log } from 'helpers'
import getPsfConfig from 'services/getPsfConfig'
import { RegisterAction } from 'redux/registration/typings'
import { UiAction } from 'redux/uiUtils/typings'
import { PsfMessage } from 'services/typings'
import getAccountUtilityFeature from 'services/getAccountUtilityFeature'
import { GetPsfMessageProps, PsfMessageAction } from './typings'

type PsfMessageDispatch = ThunkDispatch<
  RootState,
  undefined,
  PsfMessageAction | RegisterAction | UiAction
>

export const updatePsfMessage = (psfMessage: PsfMessage): PsfMessageAction => {
  return { type: 'UPDATE_PSF_MESSAGE', payload: psfMessage }
}

export const updateHideEnrollment = (
  hideEnrollment: boolean
): PsfMessageAction => {
  return { type: 'UPDATE_HIDE_ENROLLMENT', payload: hideEnrollment }
}

export const getPsfMessage =
  ({ cipherAccountId, accessToken, correlationId }: GetPsfMessageProps) =>
  async (dispatch: PsfMessageDispatch): Promise<void> => {
    try {
      const response = await getAccountUtilityFeature({
        correlationId,
        accessToken,
        cipherAccountId
      })
      const {
        data: { paperFeeApplicable }
      } = response || {}

      if (paperFeeApplicable) {
        const psfMessage = await getPsfConfig()
        const { dual, standard } = psfMessage || {}
        dispatch(updatePsfMessage({ dual, standard }))
      }
    } catch (error) {
      log.error(error)
      dispatch(updateHideEnrollment(true))
    }
  }
