import React, { ReactElement } from 'react'
import translate from 'helpers/translate'
import {
  FlexButtonContainer,
  GhostButton,
  PrimaryButton
} from 'ui/atoms/Button'
import Header from 'ui/atoms/Header'
import Template from 'ui/molecules/Template'
import routes from 'const/routes'
import { useHistory, useLocation } from 'react-router-dom'
import { LocationState } from 'types/locationState'
import { Container, UserID, UserIDContainer } from './subcomponents'

const UserIDFound = (): ReactElement => {
  const history = useHistory()
  const location = useLocation<Partial<LocationState>>()
  const { userId, zipCode, dob, returnTo, queryParams } = location.state || {}
  return (
    <Template shouldLoadAllScripts>
      <Container>
        <Header
          title={translate({ string: 'yourUserId' })}
          description={translate({ string: 'thisIsTheUserId' })}
          data-reason="subheader"
        />
        <UserIDContainer>
          {translate({ string: 'yourUserIdIs' })}: <UserID>{userId}</UserID>
        </UserIDContainer>
        <FlexButtonContainer style={{ margin: 'auto' }}>
          <PrimaryButton
            buttonType="primary"
            onClick={() => {
              history.push({
                pathname: returnTo || routes.HOME,
                search: queryParams || '',
                state: { userId, zipCode, dob, returnTo, queryParams }
              })
            }}
            className="ensightencustomevent"
            data-reason="go-to-login"
            data-type="find user id"
            data-object="button"
          >
            {translate({ string: 'goToLogin' })}
          </PrimaryButton>
          <GhostButton
            buttonType="ghost"
            onClick={() =>
              history.push({
                pathname: routes.RESET,
                state: { userId, zipCode, dob, returnTo, queryParams },
                search: queryParams
              })
            }
            className="ensightencustomevent"
            data-reason="reset-password"
            data-type="find user id"
            data-object="button"
          >
            {translate({ string: 'resetPwd' })}
          </GhostButton>
        </FlexButtonContainer>
      </Container>
    </Template>
  )
}

export default UserIDFound
