import React, { ReactElement, SyntheticEvent, useState } from 'react'
import { RouteComponentProps, useLocation } from 'react-router-dom'
import H5 from 'syf-component-library/ui/typography/H5'
import { translate, triggerAnalyticsEvent } from 'helpers'
import VoiceLinking from 'pages/Cpl/Voice/Linking'
import VoiceBanner from 'ui/atoms/VoiceBanner'
import VoiceTermsAnalytics from 'const/analytics/voiceTerms'
import Template from 'ui/molecules/Template'
import {
  AcceptButton,
  Container,
  TermsAndConditions,
  TermsAndConditionsLink
} from './subcomponents'
import getTermsAndConditionDetails from './getTermsAndConditionDetails'

const Terms = (): ReactElement => {
  const [renderVoiceLogin, setRenderVoiceLogin] = useState<boolean>(false)
  const location = useLocation<RouteComponentProps>()

  const { pathname } = location
  const { termsTitle, termsAndConditionsLink } =
    getTermsAndConditionDetails(pathname)

  const handleClick = (e: SyntheticEvent): void => {
    e.preventDefault()
    setRenderVoiceLogin(true)
    triggerAnalyticsEvent({
      pageFunction: VoiceTermsAnalytics.PAGE_FUNCTION,
      subFunction: VoiceTermsAnalytics.PAGE_SUBFUNCTION,
      pageName: VoiceTermsAnalytics.NAME
    })
  }

  return renderVoiceLogin ? (
    <VoiceLinking />
  ) : (
    <Template>
      <VoiceBanner />
      <Container>
        <H5>{translate({ string: 'voiceTitle' })}</H5>
        <TermsAndConditionsLink
          data-test="terms"
          onClick={() => {
            triggerAnalyticsEvent({
              subFunction: 'terms-conditions'
            })
          }}
          href={termsAndConditionsLink}
          target="_"
        >
          {termsTitle}
        </TermsAndConditionsLink>

        <TermsAndConditions weight="bold">
          {translate({ string: 'voiceTermsAndCondition' })}
        </TermsAndConditions>
        <AcceptButton
          title={translate({ string: 'accept' })}
          buttonType="primary"
          className="ensightencustomevent"
          data-reason="accept"
          data-test="accept"
          data-type="acknowledge"
          data-object="button"
          onClick={handleClick}
        >
          {translate({ string: 'accept' })}
        </AcceptButton>
      </Container>
    </Template>
  )
}

export default Terms
