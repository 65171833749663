import api from 'services'
import apiEndpoints from 'const/apiEndpoints'
import { addBmTelemetryToHeaders, getParameterByName } from 'helpers'
import config from 'const/config'
import { JwtResponse } from 'services/typings'

const getReqHeaders = async () => {
  return addBmTelemetryToHeaders({
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

const jwtIntrospect = {
  postJwtIntrospect: async (): Promise<JwtResponse> => {
    const reqHeaders = await getReqHeaders()
    const tokenQueryParam = getParameterByName('token')
    const payload = new URLSearchParams()
    payload.append('token', tokenQueryParam)
    payload.append('X-SYF-Client-Id', config.AUTH_CLIENT_ID)
    payload.append('token_type_hint', 'jwt')

    const { data } = await api.post(
      apiEndpoints.jwt.INTROSPECT,
      payload,
      reqHeaders
    )
    return data
  }
}

export default jwtIntrospect
