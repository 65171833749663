import styled from 'styled-components'
import Link from 'syf-component-library/ui/atoms/Link'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 18px;
  padding-bottom: 18px;
  white-space: pre-wrap;
`

export const StillLooking = styled(Link)`
  margin-top: 60px;
`
