import React, { ReactElement } from 'react'
import { FA_CHECK } from 'const/iconProp'
import KeyNames from 'const/keyNames'
import {
  FontAwesomeIconStyle,
  RadioButtonWrapper,
  RadioInput,
  RadioLabel,
  RadioTitle
} from './subcomponents'
import { RadioOptionProps } from './typings'

const RoundedRadio = ({
  value,
  label,
  style,
  showIcon,
  className = '',
  selectedValue,
  name,
  toggleRadioValue,
  ...props
}: RadioOptionProps): ReactElement => {
  const { ENTER_KEY, SPACE_KEY } = KeyNames
  const selected = selectedValue === value

  return (
    <RadioButtonWrapper
      tabIndex={0}
      data-test="roundedRadioButton"
      onKeyDown={event => {
        if (event.key === ENTER_KEY || event.key === SPACE_KEY) {
          toggleRadioValue(value)
        }
      }}
    >
      <RadioInput
        id={`${value}+1`}
        name={name}
        key={`${selected}${value}+1`}
        className={className}
        type="radio"
        checked={selected}
        data-checked={selected}
        tabIndex={-1}
        aria-checked={selected}
        onChange={() => toggleRadioValue(value)}
        data-test="roundedRadioInput"
        {...props}
      />

      <RadioLabel htmlFor={`${value}+1`}>
        {showIcon && selected && (
          <FontAwesomeIconStyle
            icon={FA_CHECK}
            data-test="roundedRadioSelectedIcon"
          />
        )}
        <RadioTitle>{label}</RadioTitle>
      </RadioLabel>
    </RadioButtonWrapper>
  )
}

export default RoundedRadio
