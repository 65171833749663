import React, { ReactElement, ReactNode, SyntheticEvent, useState } from 'react'
import Body from 'syf-component-library/ui/typography/Body'
import Small from 'syf-component-library/ui/typography/Small'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import {
  clearErrorState,
  clearRegistrationState,
  handleFormUpdate,
  updateFormErrors
} from 'redux/registration/actions'
import translate from 'helpers/translate'
import { clientInfo } from 'configureBrand'
import {
  FA_ENVELOPE,
  FA_EXCLAMATION_CIRCLE,
  FA_FILE_ALT,
  FA_USER
} from 'const/iconProp'
import {
  FlexButtonContainer,
  GhostButton,
  PrimaryButton
} from 'ui/atoms/Button'
import Header from 'ui/atoms/Header'
import TextfieldWithValidationRules from 'ui/molecules/TextfieldWithValidationRules'
import RadioGroup from 'ui/molecules/RadioGroup'
import useCancelRedirection from 'hooks/useCancelRedirection'
import PsfMessage from 'ui/atoms/PsfMessage'
import { validateErrors, validateRegistrationForm } from './helpers'
import {
  CheckboxWithRightLabel,
  Container,
  FileAltIconWithMargin,
  FormContainer,
  LastTextfield,
  StatementsContainer,
  StyledOption,
  SubText,
  TermsButton,
  TextfieldWithMargin
} from './subcomponents'

interface FormProps {
  returnTo?: string
  eConsentLink: string
  ebillStatus?: boolean
  ebillSelection: string
  handleEbillSelection: (ebillStatus: string) => void
  handleOnBlur: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleOnSubmit: (sendEmailOffers?: boolean) => void
  errorWrapper: ReactNode
  hasEmailOffers: boolean
}

const Form = ({
  returnTo,
  eConsentLink,
  ebillStatus,
  ebillSelection,
  hasEmailOffers,
  handleOnBlur,
  handleOnSubmit,
  handleEbillSelection,
  errorWrapper
}: FormProps): ReactElement => {
  const { isSubmitting, registration, psfMessage } = useSelector(
    (state: RootState) => ({
      isSubmitting: state.uiUtils?.isSubmitting,
      registration: state.registration,
      psfMessage: state.psfMessage
    }),
    shallowEqual
  )
  const { registerForm, formErrors, isUniqueUserId } = registration || {}
  const { newPassword, registrationUID, email } = registerForm || {}
  const { hideEnrollment } = psfMessage || {}
  const [sendEmailOffers, setSendEmailOffers] = useState<boolean>(false)
  const dispatch = useDispatch()
  const handleCancelRedirection = useCancelRedirection(returnTo)

  const handleSubmit = async (event: SyntheticEvent): Promise<void> => {
    event.preventDefault()
    const errors = validateRegistrationForm({
      formErrors,
      registerForm,
      isUniqueUserId
    })

    if (errors) {
      dispatch(updateFormErrors({ ...formErrors, ...errors }))
    }

    const hasErrors = validateErrors({ errors, isUniqueUserId })

    if (!hasErrors) {
      handleOnSubmit(sendEmailOffers)
    }
  }

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target
    dispatch(handleFormUpdate({ target: name, value }))
  }

  return (
    <Container>
      <Header
        title={translate({ string: 'registerForOnlineAccess' })}
        description={translate({ string: 'registerOnlineDescription' })}
      />
      {errorWrapper}
      <FormContainer
        data-reason="registration-form"
        onSubmit={handleSubmit}
        noValidate
      >
        <TextfieldWithMargin
          id="registrationUID"
          name="registrationUID"
          type="text"
          value={registrationUID}
          onChange={handleOnChange}
          onFocus={() => dispatch(clearErrorState())}
          onBlur={handleOnBlur}
          maxLength={50}
          placeholder={translate({ string: 'userId' })}
          error={formErrors?.registrationUID}
          helpText={translate({ string: 'minSixCharEmail' })}
          data-reason="registrationUID"
          leftIcon={<FontAwesomeIcon icon={FA_USER} />}
          errorIcon={<FontAwesomeIcon icon={FA_EXCLAMATION_CIRCLE} />}
          width="275px"
          required
        />

        <TextfieldWithValidationRules
          name="newPassword"
          value={newPassword}
          onChange={handleOnChange}
          onFocus={() => dispatch(clearErrorState())}
          onBlur={handleOnBlur}
          validationErrors={formErrors?.newPassword}
          data-reason="passwordField"
          data-private
        />

        <LastTextfield
          id="email"
          name="email"
          type="text"
          value={email}
          onChange={handleOnChange}
          onFocus={() => dispatch(clearErrorState())}
          onBlur={handleOnBlur}
          maxLength={50}
          placeholder={translate({ string: 'emailAddress' })}
          error={formErrors?.email}
          helpText={translate({ string: 'alertsNotifications' })}
          leftIcon={<FontAwesomeIcon icon={FA_ENVELOPE} />}
          errorIcon={<FontAwesomeIcon icon={FA_EXCLAMATION_CIRCLE} />}
          width="275px"
          data-reason="email address"
          data-type="register"
          data-object="field"
          data-private
          required
        />

        {hasEmailOffers && (
          <CheckboxWithRightLabel
            onChange={() => setSendEmailOffers(!sendEmailOffers)}
            isChecked={sendEmailOffers}
            data-reason="email offers opt in"
            data-type="register"
            data-object="checkbox"
            data-test="email-offers"
          >
            <Small isInline>{translate({ string: 'sendMeEmailOffers' })}</Small>
          </CheckboxWithRightLabel>
        )}

        {sendEmailOffers && (
          <SubText isInline>
            {translate({
              string: 'bySelecting',
              argument: clientInfo.displayName
            })}
          </SubText>
        )}

        {!ebillStatus && !hideEnrollment && (
          <StatementsContainer>
            <RadioGroup
              id="statements"
              aria-label="type of statements received"
              showIcon
              selectedValue={ebillSelection}
              toggleRadioValue={value => handleEbillSelection(value)}
              style={{ marginBottom: 20 }}
            >
              <StyledOption
                value="1"
                key="1"
                name="type of statements received"
                label={translate({ string: 'paperlessStatements' })}
                data-reason="statements paperless"
                data-type="register"
                data-object="button"
                className="ensightencustomevent"
              />

              <StyledOption
                value="2"
                key="2"
                name="type of statements received"
                label={translate({ string: 'paperStatements' })}
                data-reason="statements paper"
                data-type="register"
                data-object="button"
                className="ensightencustomevent"
              />
            </RadioGroup>

            <PsfMessage eConsentLink={eConsentLink} />
          </StatementsContainer>
        )}

        <TermsButton
          buttonType="secondary"
          data-reason="terms"
          onClick={() => window.open(eConsentLink)}
          role="link"
        >
          <FileAltIconWithMargin icon={FA_FILE_ALT} />
          <div>
            <Body>{translate({ string: 'termsAndConditions' })}</Body>
            <Small>{translate({ string: 'readBeforeProceeding' })}</Small>
          </div>
        </TermsButton>

        <SubText>{translate({ string: 'importantDisclaimer' })}</SubText>

        <FlexButtonContainer centerAlign>
          <PrimaryButton
            buttonType="primary"
            type="submit"
            disabled={isSubmitting}
            className="ensightencustomevent"
            data-reason="submit"
            data-type="register"
            data-object="button"
          >
            {isSubmitting
              ? translate({ string: 'submitting' })
              : translate({ string: 'submit' })}
          </PrimaryButton>
          <GhostButton
            buttonType="ghost"
            onClick={() => {
              dispatch(clearRegistrationState())
              handleCancelRedirection()
            }}
            className="ensightencustomevent"
            data-reason="cancel"
            data-type="register"
            data-object="button"
          >
            {translate({ string: 'cancel' })}
          </GhostButton>
        </FlexButtonContainer>
      </FormContainer>
    </Container>
  )
}

export default Form
