import styled from 'styled-components'
import Textfield from 'syf-component-library/ui/atoms/Textfield'
import ProgressStepBar from 'syf-component-library/ui/patterns/ProgressStepBar'

export const TextfieldWithMargin = styled(Textfield)`
  margin-bottom: 35px;
`

export const LastTextfield = styled(Textfield)`
  margin-bottom: 25px;
`

export const HorizontalProgressStepBar = styled(ProgressStepBar)`
  padding-top: 30px;
  padding-left: 0px;
  margin: 10px auto 25px;
`
