import { MfaValidationType, OtpMethodType } from 'const/mfa'
import {
  AUTH_RESET_PASSWORD_EXPIRED_OR_EMPTY,
  AUTH_RESET_PASSWORD_LOCKED,
  IDP_AUTHENTICATE_REGISTRATION_ERROR_ACCOUNT_LOCKED,
  IDP_AUTHENTICATE_REGISTRATION_ERROR_REGISTERED,
  IDP_AUTHORIZATION_REGISTRATION_ERROR,
  IDP_INTERACTION_ID_NOT_FOUND,
  IDP_PROFILE_EROR_CSR1_DUALCARD,
  IDP_PROFILE_EROR_CSR1_LOAN,
  IDP_PROFILE_EROR_CSR1_PLCC,
  OTP_INITIATE_MAXIMUM_ATTEMPTS_REACHED,
  OTP_INITIATE_PHONE_CONFIRM_MAX,
  OTP_INITIATE_SESSION_EXPIRED,
  OTP_SOFTENER_CSR1_DUALCARD,
  OTP_SOFTENER_CSR1_LOAN,
  OTP_SOFTENER_CSR1_PLCC,
  OTP_SOFTENER_INCORRECT_CODE_AND_VALIDATION_REACHED,
  OTP_VALIDATE_INCORRECT_CODE_AND_MAX_ATTEMPTS_REACHED_ONE,
  OTP_VALIDATE_INCORRECT_CODE_AND_MAX_ATTEMPTS_REACHED_THREE,
  OTP_VALIDATE_INCORRECT_CODE_AND_MAX_ATTEMPTS_REACHED_TWO,
  OTP_VALIDATE_INVALID_SESSIONID,
  PROFILE_CREATE_ERROR_FAILED_REGISTERED_ACCOUNT,
  PROFILE_CREATE_ERROR_FAILED_REGISTERED_USERID,
  PROFILE_CREATE_USERID_EXISTS
} from 'const/idpErrors'
import isFourHundredError from 'helpers/isFourHundredError'
import { ValidationType } from 'helpers/handleMfaMethodAnalyticsEvent'
import { BusinessError } from './typings'

export const parseBusinessErrors = (
  businessErrors: Array<BusinessError>
): string => {
  if (businessErrors?.length >= 1) {
    const { code } = businessErrors[0]
    return code
  }
  return ''
}

export const checkForRedirectError = (
  status: number,
  errorCode: string
): boolean => {
  const businessRedirectErrors = [
    AUTH_RESET_PASSWORD_LOCKED,
    AUTH_RESET_PASSWORD_EXPIRED_OR_EMPTY,
    OTP_INITIATE_MAXIMUM_ATTEMPTS_REACHED,
    OTP_VALIDATE_INCORRECT_CODE_AND_MAX_ATTEMPTS_REACHED_ONE,
    OTP_VALIDATE_INCORRECT_CODE_AND_MAX_ATTEMPTS_REACHED_TWO,
    OTP_VALIDATE_INCORRECT_CODE_AND_MAX_ATTEMPTS_REACHED_THREE,
    IDP_AUTHENTICATE_REGISTRATION_ERROR_REGISTERED,
    IDP_INTERACTION_ID_NOT_FOUND,
    IDP_AUTHENTICATE_REGISTRATION_ERROR_ACCOUNT_LOCKED,
    OTP_INITIATE_SESSION_EXPIRED,
    OTP_VALIDATE_INVALID_SESSIONID,
    OTP_SOFTENER_CSR1_PLCC,
    OTP_SOFTENER_CSR1_DUALCARD,
    OTP_SOFTENER_CSR1_LOAN,
    IDP_PROFILE_EROR_CSR1_PLCC,
    IDP_PROFILE_EROR_CSR1_LOAN,
    IDP_PROFILE_EROR_CSR1_DUALCARD,
    IDP_AUTHORIZATION_REGISTRATION_ERROR
  ]

  const isBuisnessErrorRedirect = businessRedirectErrors.indexOf(errorCode) >= 0
  const isSystemErrorRedirect = status === 500 || isFourHundredError(status)
  return isBuisnessErrorRedirect || isSystemErrorRedirect
}

export const isAccountLockedOnMfaValidation = (errorCode: string): boolean => {
  return errorCode === OTP_SOFTENER_INCORRECT_CODE_AND_VALIDATION_REACHED
}

export const checkValidationType = (
  validationType: OtpMethodType,
  isInUsa: boolean
): ValidationType => {
  if (validationType === OtpMethodType.OTP) {
    return MfaValidationType.OTP
  }
  if (validationType === OtpMethodType.OTPSOFTENER && isInUsa) {
    return MfaValidationType.SSN
  }
  if (validationType === OtpMethodType.OTPSOFTENER && !isInUsa) {
    return MfaValidationType.CVV
  }
  return ''
}

export const isUserIdUnavailable = (errorCode: string): boolean => {
  return (
    errorCode === PROFILE_CREATE_ERROR_FAILED_REGISTERED_ACCOUNT ||
    errorCode === PROFILE_CREATE_ERROR_FAILED_REGISTERED_USERID ||
    errorCode === PROFILE_CREATE_USERID_EXISTS
  )
}

export const isPhoneConfirmMaximumAttemptReached = (
  errorCode: string
): boolean => errorCode === OTP_INITIATE_PHONE_CONFIRM_MAX
