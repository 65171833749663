import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { colors } from 'theme'

export const IdpErrorMessageWrapper = styled.div<{ centerAlign?: boolean }>`
  display: flex;
  align-items: center;
  max-width: 350px;
  margin-bottom: 15px;
  color: ${colors.error};
  min-height: 24px;
  padding-bottom: 10px;
  margin: 0 auto;
  ${({ centerAlign }) => !centerAlign && 'text-align: left;'}
`

export const FontAwesomeIconStyle = styled(FontAwesomeIcon)`
  font-size: 16px;
  margin-right: 16px;
`
