import React, { ReactElement, SyntheticEvent, useState } from 'react'
import { translate, validate } from 'helpers'
import {
  FlexButtonContainer,
  GhostButton,
  PrimaryButton
} from 'ui/atoms/Button'
import InlineError from 'ui/atoms/InlineError/NonIdP'
import TextfieldWithValidationRules from 'ui/molecules/TextfieldWithValidationRules'
import Header from 'ui/atoms/Header'
import { Errors, UpdatePasswordProps } from 'pages/Reset/typings'
import useCancelRedirection from 'hooks/useCancelRedirection'
import { Container, Form, HorizontalProgressStepBar } from './subcomponents'

const UpdatePassword = ({
  steps,
  submissionError,
  isSubmitting,
  returnTo,
  onFocusTextField,
  handleSubmit
}: UpdatePasswordProps): ReactElement => {
  const [newPassword, setNewPassword] = useState<string>('')
  const [errors, setErrors] = useState<Errors>({
    required: 'pristine',
    length: 'pristine',
    hasNumber: 'pristine',
    hasLetter: 'pristine',
    noWhiteSpace: 'pristine',
    specialChar: 'pristine'
  })
  const handleCancelRedirection = useCancelRedirection(returnTo)

  const onSubmit = (e: SyntheticEvent): void => {
    e.preventDefault()
    const validationErrors = validate('newPassword', newPassword)
    const errorExists = Object.keys(validationErrors).find(
      error => !validationErrors[error]
    )
    if (errorExists) {
      setErrors(validationErrors)
    } else {
      handleSubmit(newPassword)
    }
  }

  return (
    <Container>
      <Header
        data-test="header content"
        title={translate({ string: 'resetYourPassword' })}
        description={translate({ string: 'subHeadDescription' })}
      />
      <HorizontalProgressStepBar
        steps={steps}
        width={450}
        circleRadius={4}
        activeStep={1}
      />
      <InlineError centerAlign error={submissionError} />
      <Form data-reason="reset-update-form" onSubmit={onSubmit} noValidate>
        <TextfieldWithValidationRules
          value={newPassword}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setNewPassword(e.target.value)
          }
          onFocus={onFocusTextField}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
            setErrors(validate('newPassword', e.target.value))
          }
          validationErrors={errors}
          data-reason="passwordField"
          updatePassword
        />
        <FlexButtonContainer centerAlign pt="10px">
          <PrimaryButton
            buttonType="primary"
            type="submit"
            disabled={isSubmitting}
            className="ensightencustomevent"
            data-reason="submit"
            data-type="reset password"
            data-object="button"
          >
            {isSubmitting
              ? translate({ string: 'submitting' })
              : translate({ string: 'submit' })}
          </PrimaryButton>
          <GhostButton
            buttonType="ghost"
            className="ensightencustomevent"
            data-reason="cancel"
            data-type="reset password"
            data-object="button"
            onClick={handleCancelRedirection}
          >
            {translate({ string: 'cancel' })}
          </GhostButton>
        </FlexButtonContainer>
      </Form>
    </Container>
  )
}

export default UpdatePassword
