import routes from './routes'

export const launchParams = {
  pointOfNeed: 'PointOfNeed',
  login: 'Login',
  register: 'Register',
  reset: 'Password',
  foundUserId: 'Username'
}
export default {
  [routes.HOME]: launchParams.login,
  [routes.REGISTER]: launchParams.register,
  [routes.RESET]: launchParams.reset,
  [routes.FOUND]: launchParams.foundUserId
}
