import styled from 'styled-components'
import Body from 'syf-component-library/ui/typography/Body'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 20px;
  text-align: center;
`

export const UserIDContainer = styled.div`
  color: ${p => p.theme.bodyText};
  text-align: center;
  margin: 30px 0px;
  font-weight: 100;
`

export const UserID = styled(Body)`
  color: inherit;
  white-space: pre-wrap;
  word-break: break-all;
`
