import { updateIsModalOpen, updateIsSubmitting } from 'redux/uiUtils/actions'
import { UiAction } from 'redux/uiUtils/typings'
import { ThunkDispatch } from 'redux-thunk'
import { RootState } from 'redux/store'
import idpApi from 'services/idP/api'
import { AxiosError } from 'axios'
import { updateFormData } from 'redux/login/actions'
import { incrementLoginAttemptsCount } from 'redux/iva/actions'
import { Dispatch } from 'redux'
import {
  AUTH_RESET_PASSWORD_EXPIRED_OR_EMPTY,
  AUTH_RESET_PASSWORD_LOCKED,
  IDENTITY_COMPLETE_AUTHENTICATION_FAILURE,
  IDP_AUTHENTICATE_REGISTRATION_ERROR_ACCOUNT_LOCKED,
  IDP_AUTHENTICATE_REGISTRATION_ERROR_REGISTERED,
  IDP_AUTHORIZATION_REGISTRATION_ERROR,
  IDP_COMPLETE_TRANSFER,
  IDP_PROFILE_EROR_CSR1_DUALCARD,
  IDP_PROFILE_EROR_CSR1_LOAN,
  IDP_PROFILE_EROR_CSR1_PLCC,
  OTP_INITIATE_MAXIMUM_ATTEMPTS_REACHED,
  OTP_INITIATE_SESSION_EXPIRED,
  OTP_SOFTENER_CSR1_DUALCARD,
  OTP_SOFTENER_CSR1_LOAN,
  OTP_SOFTENER_CSR1_PLCC,
  OTP_VALIDATE_INCORRECT_CODE_AND_MAX_ATTEMPTS_REACHED_ONE,
  OTP_VALIDATE_INCORRECT_CODE_AND_MAX_ATTEMPTS_REACHED_THREE,
  OTP_VALIDATE_INCORRECT_CODE_AND_MAX_ATTEMPTS_REACHED_TWO,
  OTP_VALIDATE_INVALID_SESSIONID
} from 'const/idpErrors'
import {
  getIdpErrorMessage,
  history,
  log,
  translate,
  triggerAnalyticsEvent,
  validate
} from 'helpers'
import routes from 'const/routes'
import ErrorCode from 'const/errorCode'
import {
  GetCompleteResponseData,
  IdpAuthenticateOptions,
  PostIdpOtpSoftenerOptions,
  PostProfileCreateResponse
} from 'services/typings'
import { FlowId } from 'const/idp'
import { MfaValidationType } from 'const/mfa'
import RegistrationStep from 'const/registrationStep'
import {
  handleUserIdValidationFailed,
  updateCurrentStep,
  updateFormErrors,
  updateIsUniqueUserId
} from 'redux/registration/actions'
import { RegisterAction } from 'redux/registration/typings'
import Registration from 'const/analytics/registration'
import { OtpComplete, OtpStart, OtpVerify } from 'const/analytics/otp'
import Ssn from 'const/analytics/ssn'
import config from 'const/config'
import getQueryParamFromString from 'syf-js-utilities/formats/getQueryParamFromString'
import getAuthCode from 'authorization-module/helpers/getAuthCode'
import getAccessToken from 'authorization-module/helpers/getAccessToken'
import { getPsfMessage } from 'redux/psfMessage/actions'
import handleMfaMethodAnalyticsEvent, {
  ValidationType
} from 'helpers/handleMfaMethodAnalyticsEvent'
import handleVistaRedirect from '../../helpers/handleVistaRedirect'
import {
  GetCompleteSuccess,
  HandleIdpAuthErrorThunkActions,
  IdpAction,
  LostStolenInfo,
  MapErrorResponseToMessageOptions,
  MfaPhone,
  OtpStep,
  PostIdpAuthenticateThunkActions,
  PostIdpOtpInitiate,
  PostIdpOtpValidateProp,
  PostMfaSuccess
} from './typings'
import {
  checkForRedirectError,
  checkValidationType,
  isAccountLockedOnMfaValidation,
  isPhoneConfirmMaximumAttemptReached,
  isUserIdUnavailable,
  parseBusinessErrors
} from './helpers'

type IdpActionType = UiAction | IdpAction | void | RegisterAction

export type IdpThunkDispatch = ThunkDispatch<
  RootState,
  undefined,
  UiAction | IdpAction | RegisterAction
>

type MapErrorResponseToMessagingThunkDispatch = ThunkDispatch<
  RootState,
  undefined,
  IdpAction | UiAction | RegisterAction
>

type HandleIdpAuthErrorThunkDispatch = ThunkDispatch<
  RootState,
  undefined,
  HandleIdpAuthErrorThunkActions
>

type PostIdpAuthenticateThunkDispatch = ThunkDispatch<
  RootState,
  undefined,
  PostIdpAuthenticateThunkActions
>

type PostIdpOtpInitiateThunkDispatch = ThunkDispatch<
  RootState,
  undefined,
  UiAction | IdpAction
>

type GetMfaOptionsThunkDispatch = ThunkDispatch<
  RootState,
  undefined,
  UiAction | IdpAction
>

export const updateIdpAuthResponse = (code: number): IdpAction => {
  return { type: 'UPDATE_IDP_AUTH_RESPONSE', payload: code }
}

export const clearIdpState = (): IdpAction => {
  return { type: 'CLEAR_IDP_STATE' }
}

export const updateIdpCompleteResponse = (
  completeResponse: GetCompleteResponseData
): IdpAction => {
  return { type: 'UPDATE_IDP_COMPLETE_RESPONSE', payload: completeResponse }
}

export const updatePostOtpInitiateResponse = (code: number): IdpAction => {
  return { type: 'UPDATE_POST_OTP_INITIATE_RESPONSE', payload: code }
}

export const updatePostOtpValidateResponse = (code: number): IdpAction => {
  return { type: 'UPDATE_POST_OTP_VALIDATE_RESPONSE', payload: code }
}

export const updatePostOtpSoftenerResponse = (code: number): IdpAction => {
  return { type: 'UPDATE_POST_OTP_SOFTENER_RESPONSE', payload: code }
}

export const updateGetMfaOptionsResponse = (code: number): IdpAction => {
  return { type: 'UPDATE_GET_MFA_OPTIONS_RESPONSE', payload: code }
}

export const updateCurrentOtpStep = (step: OtpStep): IdpAction => {
  return { type: 'UPDATE_CURRENT_OTP_STEP', payload: step }
}

export const updateShowGetNewCode = (isShowNewCode: boolean): IdpAction => {
  return { type: 'UPDATE_SHOW_GET_NEW_CODE', payload: isShowNewCode }
}

export const updateLostStolenInfo = (
  lostStolenInfo: LostStolenInfo
): IdpAction => {
  return {
    type: 'UPDATE_LOST_STOLEN_INFO',
    payload: lostStolenInfo
  }
}

export const updateLastFourOfSelectedPhoneNumber = (
  lastFour: string
): IdpAction => {
  return {
    type: 'UPDATE_LAST_FOUR_OF_SELECTED_PHONE_NUMBER',
    payload: lastFour
  }
}

export const updateIdpErrorCode = (errorCode: string): IdpAction => {
  return {
    type: 'UPDATE_IDP_ERROR_CODE',
    payload: errorCode
  }
}

export const updateIsPhoneConfirmMax = (
  isPhoneConfirmMax: boolean
): IdpAction => {
  return { type: 'UPDATE_IS_PHONE_CONFIRM_MAX', payload: isPhoneConfirmMax }
}

export const clearIdpErrorMessage = (): IdpAction => {
  return { type: 'CLEAR_IDP_ERROR_MESSAGE' }
}

export const updateIsIdpFlow = (isIdpFlow: boolean): IdpAction => {
  return { type: 'UPDATE_IS_IDP_FLOW', payload: isIdpFlow }
}

export const updateValidationType = (
  validationType: ValidationType
): IdpAction => {
  handleMfaMethodAnalyticsEvent(validationType)

  return {
    type: 'UPDATE_VALIDATION_MODAL',
    payload: validationType
  }
}

export const updateFlowId = (flowId: FlowId): IdpAction => {
  return { type: 'UPDATE_FLOW_ID', payload: flowId }
}

export const updateIsAccountLocked = (isAccountLocked: boolean): IdpAction => {
  return { type: 'UPDATE_IS_ACCOUNT_LOCKED', payload: isAccountLocked }
}

export const isAccountAlreadyRegistered = (
  isAccountRegistered: boolean
): IdpAction => {
  return {
    type: 'UPDATE_IS_ACCOUNT_ALREADY_REGISTERED',
    payload: isAccountRegistered
  }
}

export const resetIdpError =
  () =>
  (dispatch: Dispatch<UiAction | IdpAction>): void => {
    dispatch(clearIdpErrorMessage())
  }

export const handleRedirectError =
  (errorCode: string) =>
  (dispatch: Dispatch<IdpAction | UiAction>): void => {
    switch (errorCode) {
      case AUTH_RESET_PASSWORD_LOCKED:
      case AUTH_RESET_PASSWORD_EXPIRED_OR_EMPTY:
        history.push({ pathname: routes.RESET })
        break
      case IDP_AUTHENTICATE_REGISTRATION_ERROR_REGISTERED:
        history.push({
          pathname: routes.IDP_HOME,
          search: window?.location?.search
        })
        dispatch(isAccountAlreadyRegistered(true))
        break
      case OTP_INITIATE_MAXIMUM_ATTEMPTS_REACHED:
      case IDENTITY_COMPLETE_AUTHENTICATION_FAILURE:
        history.push({
          pathname: routes.LOCKED_ACCOUNT,
          search: window?.location?.search
        })
        dispatch(updateIsModalOpen(false))
        break
      case OTP_VALIDATE_INCORRECT_CODE_AND_MAX_ATTEMPTS_REACHED_ONE:
      case OTP_VALIDATE_INCORRECT_CODE_AND_MAX_ATTEMPTS_REACHED_TWO:
      case OTP_VALIDATE_INCORRECT_CODE_AND_MAX_ATTEMPTS_REACHED_THREE:
        dispatch(updateCurrentOtpStep('sorry'))
        break
      case OTP_VALIDATE_INVALID_SESSIONID:
      case OTP_INITIATE_SESSION_EXPIRED:
        history.push({
          pathname: routes.SESSION_TIMEOUT_ERROR,
          search: window?.location?.search
        })
        break
      case OTP_SOFTENER_CSR1_PLCC:
      case OTP_SOFTENER_CSR1_DUALCARD:
      case OTP_SOFTENER_CSR1_LOAN:
        history.push({
          pathname: routes.SOFTENER_ERROR,
          search: window?.location?.search
        })
        break
      case IDP_PROFILE_EROR_CSR1_PLCC:
      case IDP_PROFILE_EROR_CSR1_LOAN:
      case IDP_PROFILE_EROR_CSR1_DUALCARD:
      case IDP_AUTHENTICATE_REGISTRATION_ERROR_ACCOUNT_LOCKED:
        history.push({
          pathname: routes.ACCOUNT_LOCKED_ERROR,
          search: window?.location?.search
        })
        break
      case IDP_AUTHORIZATION_REGISTRATION_ERROR:
        history.push({
          pathname: routes.POST_REGISTRATION_ERROR,
          search: window?.location?.search
        })
        break
      default: {
        history.push({ pathname: routes.ERROR })
        dispatch(updateIsModalOpen(false))
      }
    }
  }

const mapErrorResponseToMessaging =
  ({ error, pageSubfunction }: MapErrorResponseToMessageOptions) =>
  (
    dispatch: MapErrorResponseToMessagingThunkDispatch,
    getState: () => RootState
  ): UiAction => {
    const {
      registration: { formErrors },
      idp: { flowId }
    } = getState()
    const errorResponse = error?.response
    const { status, data } = errorResponse || {}
    const errorCode = parseBusinessErrors(data?.businessErrors)
    const idpErrorCode = errorCode || 'default'

    if (isAccountLockedOnMfaValidation(errorCode)) {
      triggerAnalyticsEvent({
        subFunction: pageSubfunction,
        messageKey: 'maximumAttemptsReached'
      })
      dispatch(updateIsAccountLocked(true))
    } else {
      const errorMessage = getIdpErrorMessage({ idpErrorCode, flowId })
      triggerAnalyticsEvent({
        subFunction: pageSubfunction,
        messageKey: errorMessage
      })

      const shouldRedirect = checkForRedirectError(status, errorCode)
      if (shouldRedirect) {
        dispatch(handleRedirectError(errorCode))
      } else if (isPhoneConfirmMaximumAttemptReached(errorCode)) {
        dispatch(updateIsPhoneConfirmMax(true))
      } else if (isUserIdUnavailable(errorCode)) {
        dispatch(
          updateFormErrors({
            ...formErrors,
            registrationUID: errorMessage
          })
        )
      }
    }
    dispatch(updateIdpErrorCode(idpErrorCode))
    return dispatch(updateIsSubmitting(false))
  }

const setAccessToken = (accessToken: string): IdpAction => {
  return {
    type: 'SET_ACCESS_TOKEN',
    payload: accessToken
  }
}

export const handleRegistrationStep =
  (href: string) =>
  async (
    dispatch: IdpThunkDispatch,
    getState: () => RootState
  ): Promise<void> => {
    const {
      idp: { completeResponse },
      jwt: { idpSessionId: correlationId }
    } = getState()
    const baseURL = config.API_HOST
    const clientId = config.AUTH_CLIENT_ID
    try {
      const authCode = await getAuthCode({
        baseURL,
        clientId,
        redirectURI: config.REDIRECT_URL,
        loginHintURL: '',
        idTokenHint: getQueryParamFromString(href, 'id_token_hint'),
        scope: FlowId.REGISTRATION,
        codeVerifierPrefix: config.RC_CLIENT_ID
      })
      if (authCode && typeof authCode === 'string') {
        const codeVerifier = sessionStorage.getItem(
          `${config.RC_CLIENT_ID}-codeVerifier`
        )
        const { data } = await getAccessToken({
          baseURL,
          authCode,
          clientId,
          redirectURI: config.REDIRECT_URL,
          codeVerifier
        })
        const { access_token: accessToken } = data || {}
        if (accessToken) {
          const cipherAccountId = completeResponse?.['cipher.accountId']

          if (!completeResponse?.ebillStatus && cipherAccountId) {
            await dispatch(
              getPsfMessage({
                accessToken,
                cipherAccountId,
                correlationId
              })
            )
          }
          triggerAnalyticsEvent({
            pageName: Registration.registration
          })
          dispatch(setAccessToken(accessToken))
          dispatch(updateIsModalOpen(false))
          dispatch(updateCurrentStep(RegistrationStep.REGISTRATION))
        } else {
          history.push({ pathname: routes.ERROR })
        }
      } else {
        dispatch(updateIsModalOpen(false))
        history.push({ pathname: routes.ERROR })
      }
    } catch (error) {
      log.error(error)
      dispatch(updateIsModalOpen(false))
      history.push({ pathname: routes.ERROR })
    }
  }

export const handleRegistrationComplete =
  () =>
  async (
    dispatch: IdpThunkDispatch,
    getState: () => RootState
  ): Promise<void> => {
    const {
      jwt: { idpSessionId },
      login: {
        formData: { rememberMe }
      }
    } = getState()
    try {
      const { data } = await idpApi.getComplete(idpSessionId)
      const { href = '', rel = '' } = data.links[0] || {}
      dispatch(updateIdpCompleteResponse(data))
      triggerAnalyticsEvent({ pageName: 'complete' })
      handleVistaRedirect({
        href,
        rel,
        rememberMe,
        flowId: FlowId.REGISTRATION
      })
    } catch (error) {
      log.error(error)
      dispatch(updateIsSubmitting(false))
      history.push({ pathname: routes.POST_REGISTRATION_ERROR })
    }
  }

const handleCompleteSuccess =
  (payload: GetCompleteSuccess) =>
  async (
    dispatch: IdpThunkDispatch,
    getState: () => RootState
  ): Promise<void> => {
    const {
      idp: { flowId },
      login: {
        formData: { rememberMe }
      },
      registration: { currentStep }
    } = getState()

    const {
      data: {
        code,
        currentLast4: currentLastFour,
        prevLast4: previousLastFour,
        links = []
      }
    } = payload
    const { href = '', rel = '', name = '' } = links[0] || {}
    dispatch(updateIdpCompleteResponse(payload?.data))
    if (code === IDP_COMPLETE_TRANSFER) {
      dispatch(updateIsModalOpen(false))
      dispatch(
        updateLostStolenInfo({
          currentLastFour,
          previousLastFour,
          href,
          rel,
          name
        })
      )
    } else if (
      flowId === FlowId.REGISTRATION &&
      currentStep === RegistrationStep.LOOKUP
    ) {
      await dispatch(handleRegistrationStep(href))
    } else {
      triggerAnalyticsEvent({ pageName: 'complete' })
      handleVistaRedirect({ href, rel, rememberMe })
    }
  }

export const getIdpComplete =
  () =>
  async (
    dispatch: IdpThunkDispatch,
    getState: () => RootState
  ): Promise<IdpActionType> => {
    const {
      jwt: { idpSessionId }
    } = getState()
    try {
      const { status, data } = await idpApi.getComplete(idpSessionId)
      return dispatch(handleCompleteSuccess({ data, status }))
    } catch (error) {
      log.error(error)
      dispatch(mapErrorResponseToMessaging({ error }))
      return dispatch(updateIsSubmitting(error.response?.status))
    }
  }

export const updateMfaPhoneNumbers = (
  phoneNumbers: Array<MfaPhone>
): IdpAction => {
  return { type: 'UPDATE_MFA_PHONE_NUMBERS', payload: phoneNumbers }
}

const handleMfaSuccess =
  (payload: PostMfaSuccess) =>
  (dispatch: IdpThunkDispatch): IdpActionType => {
    const { data, status, isInUsa } = payload
    const { method = [] } = data[0] || {}

    if (method.length) {
      const validationType = checkValidationType(method[0], isInUsa)
      if (validationType === MfaValidationType.OTP) {
        dispatch(updateMfaPhoneNumbers(data))
      }
      dispatch(updateIsModalOpen(true))
      dispatch(updateValidationType(validationType))
    }

    dispatch(updateGetMfaOptionsResponse(status))
    return dispatch(updateIsSubmitting(false))
  }

export const getMfaOptions =
  () =>
  async (
    dispatch: GetMfaOptionsThunkDispatch,
    getState: () => RootState
  ): Promise<IdpActionType> => {
    const {
      jwt: { idpSessionId },
      registration: { isInUsa }
    } = getState()
    try {
      const response = await idpApi.getMfaOptions(idpSessionId)
      const { status, data } = response
      return dispatch(handleMfaSuccess({ status, data, isInUsa }))
    } catch (error) {
      log.error(error)
      dispatch(mapErrorResponseToMessaging({ error }))
      return dispatch(updateIsSubmitting(false))
    }
  }

export const handleIdpAuthError =
  (error: AxiosError) =>
  (
    dispatch: HandleIdpAuthErrorThunkDispatch
  ): Promise<UiAction> | IdpAction => {
    const { status } = error.response || {}
    if (status === ErrorCode.FORBIDDEN_ERROR) {
      dispatch(getMfaOptions())
      return dispatch(updateIdpAuthResponse(status))
    }
    dispatch(mapErrorResponseToMessaging({ error }))
    dispatch(updateFormData({ userId: '', password: '' }))
    return dispatch(updateIdpAuthResponse(status))
  }

export const postIdpAuthenticate =
  (
    options: IdpAuthenticateOptions,
    flowId: FlowId.REGISTRATION | FlowId.LOGIN
  ) =>
  async (
    dispatch: PostIdpAuthenticateThunkDispatch,
    getState: () => RootState
  ): Promise<IdpActionType> => {
    const {
      jwt: { idpSessionId }
    } = getState()
    try {
      dispatch(resetIdpError())
      dispatch(updateFlowId(flowId))
      const response = await idpApi.postAuthenticate({
        flowId,
        options,
        idpSessionId
      })
      dispatch(updateIdpAuthResponse(response.status))
      return dispatch(getIdpComplete())
    } catch (error) {
      log.error(error)
      if (flowId === FlowId.LOGIN) {
        dispatch(incrementLoginAttemptsCount())
      }
      return dispatch(handleIdpAuthError(error))
    }
  }

export const postIdpOtpValidate =
  (options: PostIdpOtpValidateProp) =>
  async (
    dispatch: IdpThunkDispatch,
    getState: () => RootState
  ): Promise<IdpActionType> => {
    const {
      jwt: { idpSessionId }
    } = getState()
    try {
      dispatch(resetIdpError())
      const response = await idpApi.postOtpValidate({
        ...options,
        idpSessionId
      })
      const { status } = response
      dispatch(updatePostOtpValidateResponse(status))
      triggerAnalyticsEvent({
        subFunction: OtpComplete.PAGE_SUBFUNCTION,
        pageName: OtpComplete.PAGE_NAME,
        messageKey: OtpComplete.MESSAGE_KEY
      })
      return dispatch(getIdpComplete())
    } catch (error) {
      log.error(error)
      dispatch(
        mapErrorResponseToMessaging({
          error,
          pageSubfunction: OtpVerify.PAGE_SUBFUNCTION
        })
      )
      return dispatch({ type: 'POST_IDP_VALIDATE_FAILURE' })
    }
  }

export const handleOtpValidate =
  (options: PostIdpOtpValidateProp) =>
  async (dispatch: IdpThunkDispatch): Promise<IdpActionType> => {
    const errorCode = validate(MfaValidationType.OTP, options?.code)
    if (errorCode) {
      dispatch(updateIdpErrorCode(errorCode))
    } else {
      dispatch(updateIsSubmitting(true))
      dispatch(postIdpOtpValidate(options))
    }
  }

export const postIdpOtpInitiate =
  (options: PostIdpOtpInitiate) =>
  async (
    dispatch: PostIdpOtpInitiateThunkDispatch,
    getState: () => RootState
  ): Promise<void> => {
    const {
      jwt: { idpSessionId }
    } = getState()
    try {
      dispatch(resetIdpError())
      const response = await idpApi.postOtpInitiate({
        ...options,
        idpSessionId
      })
      const { status } = response
      dispatch(updatePostOtpInitiateResponse(status))
      triggerAnalyticsEvent({
        subFunction: OtpVerify.PAGE_SUBFUNCTION,
        pageName: OtpVerify.PAGE_NAME
      })
      dispatch(updateCurrentOtpStep('verify'))
      dispatch(updateIsSubmitting(false))
    } catch (error) {
      log.error(error)
      dispatch(
        mapErrorResponseToMessaging({
          error,
          pageSubfunction: OtpStart.PAGE_SUBFUNCTION
        })
      )
    }
  }

export const postIdpOtpSoftener =
  (options: PostIdpOtpSoftenerOptions) =>
  async (dispatch: IdpThunkDispatch): Promise<IdpActionType> => {
    try {
      dispatch(updateIsSubmitting(true))
      const response = await idpApi.postOtpSoftener(options)
      const { status } = response
      dispatch(updatePostOtpSoftenerResponse(status))
      return dispatch(getIdpComplete())
    } catch (error) {
      log.error(error)
      return dispatch(
        mapErrorResponseToMessaging({
          error,
          pageSubfunction: Ssn.PAGE_SUBFUNCTION
        })
      )
    }
  }

export const handleProfileCreateSuccess = (
  profileCreateResponse: PostProfileCreateResponse
): IdpAction => {
  return {
    type: 'PROFILE_CREATE_SUCCESS',
    payload: profileCreateResponse
  }
}

export const postIdpProfile =
  (ebillSelection: string) =>
  async (
    dispatch: IdpThunkDispatch,
    getState: () => RootState
  ): Promise<IdpActionType> => {
    const {
      idp: { accessToken },
      jwt: { idpSessionId },
      registration: {
        registerForm: { newPassword, registrationUID },
        registerForm: { email }
      },
      akamaiConfig: { isIdpRegistrationFlow }
    } = getState()
    const ebillStatus = ebillSelection === '1'
    const eConsentStatus = isIdpRegistrationFlow
    try {
      dispatch(resetIdpError())
      dispatch(updateIsSubmitting(true))
      const response = await idpApi.postProfileCreate({
        idpSessionId,
        ebillStatus,
        eConsentStatus,
        registrationUID,
        newPassword,
        email,
        accessToken
      })
      const { data } = response
      dispatch(updateIsSubmitting(false))
      dispatch(handleProfileCreateSuccess(data))
      triggerAnalyticsEvent({
        pageName: Registration.complete
      })
      return dispatch(updateCurrentStep(RegistrationStep.COMPLETE))
    } catch (error) {
      log.error(error)
      return dispatch(mapErrorResponseToMessaging({ error }))
    }
  }

export const idpValidateUserId =
  () =>
  async (
    dispatch: IdpThunkDispatch,
    getState: () => RootState
  ): Promise<void> => {
    const {
      idp: { accessToken },
      jwt: { idpSessionId },
      registration: {
        registerForm: { registrationUID },
        formErrors
      }
    } = getState()

    try {
      await idpApi.checkUserId({
        registrationUID,
        idpSessionId,
        accessToken
      })
      dispatch(updateIsUniqueUserId(true))
    } catch (error) {
      log.error(error)
      const { businessErrors } = error?.response?.data
      const errorCode = parseBusinessErrors(businessErrors)
      if (isUserIdUnavailable(errorCode)) {
        dispatch(
          handleUserIdValidationFailed({
            formErrors: {
              ...formErrors,
              registrationUID: translate({ string: 'unavailableUID' })
            },
            isUniqueUserId: false
          })
        )
      }
    }
  }
