import React, { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import {
  checkRegistrationEligibility,
  clearErrorState
} from 'redux/registration/actions'
import { SearchUserInfo } from 'services/typings'
import AccountLookup from 'ui/organisms/Registration/AccountLookup'

const RegistrationAccountLookup = (): ReactElement => {
  const dispatch = useDispatch()

  const handleSearchResult = (options: SearchUserInfo) => {
    dispatch(checkRegistrationEligibility(options))
  }

  const handleTextfieldFocus = () => {
    dispatch(clearErrorState())
  }

  return (
    <AccountLookup
      handleSearchResult={handleSearchResult}
      handleTextfieldFocus={handleTextfieldFocus}
    />
  )
}

export default RegistrationAccountLookup
