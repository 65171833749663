import React, { ReactElement, useCallback } from 'react'
import { ToastType } from 'syf-component-library/ui/patterns/Toast/subcomponents'
import { Error, ErrorWrapper } from './subcomponents'

interface ToastErrorProps {
  message: string
  type?: ToastType
}

const ToastError = ({ ...props }: ToastErrorProps): ReactElement => {
  const { type = 'warning', message } = props

  const errorRef = useCallback((node: HTMLElement | null) => node?.focus(), [])

  return (
    <ErrorWrapper ref={errorRef} tabIndex={0}>
      <Error
        closeHandler={() => null}
        hasClose={false}
        hasShadow
        type={type}
        hasIcon
      >
        {message}
      </Error>
    </ErrorWrapper>
  )
}

export default ToastError
