export enum MfaValidationType {
  OTP = 'otp',
  SSN = 'ssn',
  CVV = 'cvv'
}

export enum OtpMethodType {
  OTP = 'OTP',
  OTPSOFTENER = 'OTP_SOFTENER'
}
