import styled, { keyframes } from 'styled-components'
import { colors } from 'theme'

const Wrapper = styled.div`
  display: flex;
`
const LoadingWrapper = styled.div`
  background-color: ${colors.darkGreySeventyFive};
  z-index: 999;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`

const LoadingTitle = styled.h1`
  color: ${colors.lightGreyTwo};
  position: fixed;
  top: 40%;
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 1em;
`

const Loader = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
  margin-left: -25px;
`

const Spinner = styled.span`
  width: 48px;
  height: 48px;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
`

const eclipseAnimation = keyframes`
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
`

const Eclipse = styled.span`
  width: 12px;
  height: 12px;
  display: inline-block;
  box-sizing: border-box;
  border-radius: 50%;
  background: ${colors.white};
  margin: 12px;
  animation: ${eclipseAnimation} 1s ease-out infinite;

  &:before {
    border-radius: 50%;
    content: ' ';
    width: 48px;
    height: 48px;
    display: inline-block;
    box-sizing: border-box;
    border-top: solid 6px transparent;
    border-right: solid 6px ${colors.white};
    border-bottom: solid 6px transparent;
    border-left: solid 6px transparent;
    position: absolute;
    top: -18px;
    left: -18px;
  }

  &:after {
    border-radius: 50%;
    content: ' ';
    width: 48px;
    height: 48px;
    display: inline-block;
    box-sizing: border-box;
    border-top: solid 6px transparent;
    border-right: solid 6px transparent;
    border-bottom: solid 6px transparent;
    border-left: solid 6px ${colors.white};
    position: absolute;
    top: -18px;
    right: -18px;
  }
`
export { Wrapper, LoadingWrapper, LoadingTitle, Loader, Spinner, Eclipse }
