import React, { ReactElement } from 'react'
import ErrorPage from 'ui/atoms/ErrorPage'
import { translate } from 'helpers'
import { FA_STOPWATCH } from 'const/iconProp'
import GoToSignInButton from 'ui/atoms/GoToSignInButton'

const SessionTimeoutError = (): ReactElement => (
  <ErrorPage
    title={translate({ string: 'sessionTimeOutTitle' })}
    description={translate({
      string: 'sessionTimeOutBody'
    })}
    errorIcon={FA_STOPWATCH}
  >
    <GoToSignInButton />
  </ErrorPage>
)

export default SessionTimeoutError
