import React, { ReactElement, useCallback } from 'react'
import { useSelector } from 'react-redux'
import Body from 'syf-component-library/ui/typography/Body'
import { RootState } from 'redux/store'
import { FA_EXCLAMATION_CIRCLE } from 'const/iconProp'
import getIdpErrorMessage from 'helpers/generateIdpErrorMessage'
import ToastError from 'ui/atoms/ToastError'
import isToastError from 'helpers/isToastError'
import translate from 'helpers/translate'
import { FontAwesomeIconStyle, IdpErrorMessageWrapper } from './subcomponents'

const InlineError = ({
  centerAlign
}: {
  centerAlign?: boolean
}): ReactElement => {
  const { idpErrorCode, isAccountAlreadyRegistered, flowId } = useSelector(
    (state: RootState) => {
      return {
        idpErrorCode: state.idp.idpErrorCode,
        isAccountAlreadyRegistered: state.idp.isAccountAlreadyRegistered,
        flowId: state.idp.flowId
      }
    }
  )

  const idpErrorMessage = idpErrorCode
    ? getIdpErrorMessage({ idpErrorCode, flowId })
    : ''
  const errorRef = useCallback(node => node?.focus(), [])
  const isToastMessage = isToastError(idpErrorCode)

  const errorMessage = isToastMessage ? (
    <ToastError
      type="error"
      data-test="idp-toast-error"
      message={idpErrorMessage}
    />
  ) : (
    <IdpErrorMessageWrapper
      centerAlign={centerAlign}
      ref={errorRef}
      tabIndex={-1}
      aria-live="assertive"
    >
      <FontAwesomeIconStyle
        icon={FA_EXCLAMATION_CIRCLE}
        title={translate({ string: 'requirementNotMet' })}
      />
      <Body data-test="idp-error-message" isInline>
        {idpErrorMessage}
      </Body>
    </IdpErrorMessageWrapper>
  )

  return idpErrorCode && !isAccountAlreadyRegistered ? errorMessage : null
}

export default InlineError
