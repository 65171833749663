import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import Link from 'syf-component-library/ui/atoms/Link'
import { translate } from 'helpers'
import ErrorPage from 'ui/atoms/ErrorPage'

const JwtError = (): ReactElement => {
  const {
    jwt: { returnTo, canRetryLogin }
  } = useSelector((reduxState: RootState) => reduxState)

  const errorTitle = canRetryLogin
    ? translate({ string: 'corruptedTokenErrorTitle' })
    : translate({ string: 'oops' })
  const errorDescription = canRetryLogin
    ? translate({ string: 'corruptedTokenErrorDescription' })
    : translate({ string: 'tryAgainLater' })

  return (
    <ErrorPage title={errorTitle} description={errorDescription}>
      {canRetryLogin && (
        <Link href={returnTo}>{translate({ string: 'goToLogin' })}</Link>
      )}
    </ErrorPage>
  )
}

export default JwtError
