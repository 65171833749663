import React, { ReactElement } from 'react'
import { useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { brandImages, clientInfo } from 'configureBrand'
import config from 'const/config'
import { FA_GLOBE } from 'const/iconProp'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import routes from 'const/routes'
import { HeaderContainer, SystemNavigation } from './subcomponents'
import { HeaderTypes } from './typings'

const Header = ({
  currentLanguage,
  onChangeLanguage,
  languages,
  isCplRoute
}: HeaderTypes): ReactElement => {
  const returnTo = useSelector((state: RootState) => state.jwt.returnTo)
  const isLanguageSelectable = languages.length > 1 && !isCplRoute
  const { search } = useLocation()
  const redirectLink = returnTo
    ? `${routes.ACCOUNTS}${search}`
    : `${routes.HOME}${search}`

  return (
    <HeaderContainer>
      <SystemNavigation
        data-test="logo"
        language={
          isLanguageSelectable &&
          (currentLanguage === 'en' ? 'Español' : 'English')
        }
        altText={clientInfo.displayName}
        logo={config.ASSET_ORIGIN + brandImages.logo[currentLanguage]}
        logoRouteLink={isCplRoute ? `${routes.CPL}${search}` : redirectLink}
        languageIcon={
          isLanguageSelectable && (
            <FontAwesomeIcon icon={FA_GLOBE} data-reason="icon" />
          )
        }
        handleLanguageClick={onChangeLanguage}
      />
    </HeaderContainer>
  )
}

export default Header
