import { OtpAction, OtpState } from './typings'

export const initialState: OtpState = {
  currentOtpStep: 'confirm',
  error: null,
  otpStatusCode: '',
  lastFour: '',
  errorCode: 0,
  showGetNewCode: false,
  isMaximumAttemptReached: false,
  otpPin: '',
  bindDevice: false
}

export default (
  state: OtpState = initialState,
  action: OtpAction = undefined
): OtpState => {
  switch (action.type) {
    case 'UPDATE_OTP_INITIATE_SUCCESS':
      return { ...state, ...action.payload }
    case 'UPDATE_OTP_STATUS_CODE':
      return { ...state, otpStatusCode: action.payload }
    case 'UPDATE_OTP_ERROR':
      return { ...state, error: action.payload }
    case 'RESET_OTP_ERROR':
      return { ...state, error: null, errorCode: 0 }
    case 'UPDATE_CURRENT_STEP':
      return { ...state, currentOtpStep: action.payload }
    case 'UPDATE_IS_MAXIMUM_ATTEMPT_REACHED':
      return { ...state, isMaximumAttemptReached: action.payload }
    case 'RESET_OTP_STATE':
      return initialState
    case 'UPDATE_OTP_PIN':
      return { ...state, otpPin: action.payload }
    case 'UPDATE_BIND_DEVICE':
      return { ...state, bindDevice: action.payload }
    default:
      return state
  }
}
