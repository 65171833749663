import React, { ReactElement } from 'react'
import styled, { FlattenSimpleInterpolation, css } from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import { colors } from 'theme'
import { RadioContainerType, RadioProps } from './typings'

const secondaryStyles = (secondary: boolean): FlattenSimpleInterpolation =>
  secondary
    ? css`
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        border: 1px solid ${colors.burnishedSilver};
        background: ${colors.white};
        @media ${query.lessThanMedium} {
          margin: auto auto 20px auto;
          min-width: 100%;
        }
      `
    : css`
        display: inline-flex;
        margin-top: auto;
      `

const errorStyles = (): FlattenSimpleInterpolation =>
  css`
    border-color: ${colors.error};
  `

const RadioContainer = styled.div<RadioContainerType>`
  ${({ secondary }) => secondaryStyles(secondary)}
  ${({ hasError }) => hasError && errorStyles()}
`

const RadioGroup = ({
  selectedValue,
  toggleRadioValue,
  showIcon,
  children,
  style,
  ...props
}: RadioProps): ReactElement => (
  <RadioContainer style={style} role="radiogroup" {...props}>
    {React.Children.map(children, child =>
      React.cloneElement(child, {
        toggleRadioValue,
        showIcon,
        selectedValue
      })
    )}
  </RadioContainer>
)

export default RadioGroup
