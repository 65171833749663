import React, { ReactElement } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { RootState } from 'redux/store'

const CoBrowse = (): ReactElement => {
  const {
    akamaiConfig: { coBrowse }
  } = useSelector((state: RootState) => state, shallowEqual)

  if (!coBrowse) return null

  return (
    <Helmet data-test="cobrowse-helmet">
      {coBrowse?.isSurflyScriptTagEnabled ? (
        <script type="text/javascript" src="js/surfly.js" />
      ) : (
        <script
          id="glance-cobrowse"
          data-site="CF_COBROWSE"
          data-groupid="19909"
          src="https://www.glancecdn.net/cobrowse/CobrowseJS.ashx?group=19909&site=CF_COBROWSE"
        />
      )}
    </Helmet>
  )
}

export default CoBrowse
