import apiEndpoints from 'const/apiEndpoints'
import { configServices } from 'services'
import { DowntimeResponse } from './typings'

const checkForDowntime = async (): Promise<DowntimeResponse> => {
  try {
    const {
      data: { maintenance }
    } = await configServices.get(apiEndpoints.config.DOWNTIME_CONFIG)

    // eslint-disable-next-line camelcase
    return maintenance
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    throw error
  }
}

export default checkForDowntime
