import { clientInfo } from 'configureBrand'
import { getCurrentLanguageFromStorage } from 'helpers'
import React, { ReactElement } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import styled from 'styled-components'
import { colors } from 'theme'
import { TextButton } from 'ui/atoms/Button'
import Stack from 'syf-component-library/ui/spacing/Stack'
import Small from 'syf-component-library/ui/typography/Small'

export const UpdatedMessageContainer = styled.div`
  color: ${colors.grey};
  max-width: 500px;
  text-align: left;
`

interface UpdatedMessageProps {
  eConsentLink: string
}

const UpdatedMessage = ({
  eConsentLink
}: UpdatedMessageProps): ReactElement => {
  const psfMessage = useSelector(
    (state: RootState) => state.psfMessage,
    shallowEqual
  )

  const languageSelected = getCurrentLanguageFromStorage()

  const updatedDisclaimer = clientInfo.hasDualEnrollment
    ? psfMessage?.dual[languageSelected]
    : psfMessage?.standard[languageSelected]

  const {
    consentToEmail,
    eConsent,
    emailConfirmation,
    consentToPaper,
    paperFee,
    billingNotification,
    alertConfirmation
  } = updatedDisclaimer

  return (
    <UpdatedMessageContainer>
      <Small>
        {consentToEmail}
        <TextButton
          role="link"
          buttonType="text"
          onClick={() => window.open(eConsentLink)}
        >
          {eConsent}
        </TextButton>
        {emailConfirmation}
      </Small>
      <Stack size="small" />
      <Small>
        {consentToPaper} <strong>{paperFee}</strong> {billingNotification}{' '}
        {alertConfirmation}
      </Small>
    </UpdatedMessageContainer>
  )
}

export default UpdatedMessage
