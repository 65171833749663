import React, { ReactElement, SyntheticEvent, useState } from 'react'
import { RouteComponentProps, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import authenticate from 'services/cpl/accountLinking/authenticate'
import { AuthenticateVoiceCplLoginResponse } from 'services/typings'
import {
  debounceOneMS,
  getParameterByName,
  handleRedirectToErrorScreen,
  translate,
  triggerAnalyticsEvent,
  validate
} from 'helpers'
import Link from 'syf-component-library/ui/atoms/Link'
import InlineError from 'ui/atoms/InlineError/NonIdP'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { clientInfo } from 'configureBrand'
import { PrimaryButton } from 'ui/atoms/Button'
import VoiceBanner from 'ui/atoms/VoiceBanner'
import VoicePinContainer from 'pages/Cpl/Voice/Linking/VoicePinContainer'
import routes from 'const/routes'
import { FA_EXCLAMATION_CIRCLE, FA_LOCK, FA_USER } from 'const/iconProp'
import { ErrorType } from 'types/errorTypes'
import EMPTY_STRING from 'const/emptyString'
import {
  Form,
  LinkContainer,
  LinkWrapper,
  LockIconWithMargin,
  Main,
  Section,
  TextfieldWithMargin,
  Title,
  TitleWrapper
} from './subcomponents'
import {
  VoiceLoginContainerInputValues,
  VoiceLoginContainerProps
} from './typings'

const VoiceLoginContainer = ({
  afterSubmit,
  updateSubmitState,
  isSubmitting,
  error,
  showVoiceOtp
}: VoiceLoginContainerProps): ReactElement => {
  const [auth, setAuth] = useState<AuthenticateVoiceCplLoginResponse>({})
  const [showVoiceOtpScreen, setShowVoiceOtpScreen] = useState<boolean>(false)
  const [errors, setErrors] = useState<VoiceLoginContainerInputValues>({
    userId: EMPTY_STRING,
    password: EMPTY_STRING
  })
  const [inputValues, setInputValues] =
    useState<VoiceLoginContainerInputValues>({
      userId: EMPTY_STRING,
      password: EMPTY_STRING
    })
  const [submissionError, setSubmissionError] = useState<ErrorType>({
    key: EMPTY_STRING,
    extension: EMPTY_STRING
  })

  const queryParams = window.location.search
  const oauthClientId = decodeURIComponent(
    getParameterByName('client_id', queryParams)
  )
  const oauthRedirectUri = decodeURIComponent(
    getParameterByName('redirect_uri', queryParams)
  )
  const location = useLocation<RouteComponentProps>()
  const { pathname } = location
  const { displayName } = clientInfo

  const validateAllFields = () => {
    const validation = { errors: {} }
    Object.keys(errors).forEach(key => {
      const { [key as keyof typeof errors]: stateKey } = inputValues
      const errorData = validate(key, stateKey, {
        ...inputValues
      })
      validation.errors = { ...validation.errors, [key]: errorData }
    })
    setErrors(prevState => ({ ...prevState, ...validation.errors }))

    return Object.keys(validation.errors).find(
      e => validation.errors[e as keyof typeof validation.errors]
    )
  }

  const handleOnChange = (target: string, value: string) => {
    setInputValues({ ...inputValues, [target]: value })

    debounceOneMS(() =>
      setErrors(prevState => ({
        ...prevState,
        [target]: validate(target, value)
      }))
    )
  }

  const handleOnBlur = (target: string, value: string) => {
    const validationError = validate(target, value)
    setErrors({ ...errors, [target]: validationError })
  }

  const handleSubmit = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault()
    if (!validateAllFields()) {
      updateSubmitState(true)
      const { userId, password } = inputValues
      const invalidQueryParams =
        oauthClientId === '' ||
        oauthClientId === 'null' ||
        oauthRedirectUri === '' ||
        oauthRedirectUri === 'null'
      if (invalidQueryParams) {
        setSubmissionError({ key: 'default', extension: '' })
        updateSubmitState(false)
        return
      }

      try {
        const authData = await authenticate({ userId, password })
        const { response_code: responseCode } = authData.data.status
        const { message, redirect } = authData.result
        setAuth(authData)
        updateSubmitState(false)
        const isValidResponseCode =
          responseCode === '1005' || responseCode === '1007'
        if (isValidResponseCode) {
          triggerAnalyticsEvent({
            pageFunction: 'login',
            subFunction: 'login',
            pageName: 'complete'
          })
          setShowVoiceOtpScreen(true)
          triggerAnalyticsEvent({
            pageFunction: 'login',
            subFunction: 'voicekey',
            pageName: 'step2'
          })
          return
        }
        if (message && !redirect) {
          setSubmissionError(message)
          updateSubmitState(false)
          triggerAnalyticsEvent({ messageKey: authData.result.message.key })
        } else if (afterSubmit) afterSubmit(authData)
      } catch (loginError) {
        // eslint-disable-next-line no-console
        console.error(loginError)
        const { status } = loginError.response
        handleRedirectToErrorScreen({
          errorCode: status
        })
        setSubmissionError({
          key: 'default',
          extension: `${translate({ string: 'code' })}: ${status}`
        })
        updateSubmitState(false)
      }
    }
  }

  if (showVoiceOtpScreen || showVoiceOtp) {
    return <VoicePinContainer auth={auth} />
  }

  return (
    <>
      <Helmet>
        <title>{`Manage Your ${displayName} Credit Card Account`}</title>
      </Helmet>
      <Main>
        <VoiceBanner />
        <Section>
          <TitleWrapper>
            <Title>{translate({ string: 'logIntoYourAccount' })}</Title>
          </TitleWrapper>
          <Form
            data-test="form"
            onSubmit={handleSubmit}
            autoComplete="off"
            noValidate
          >
            <InlineError
              style={{ minWidth: 'auto' }}
              error={submissionError.key ? submissionError : error}
            />
            <TextfieldWithMargin
              id="userId"
              name="userId"
              type="text"
              value={inputValues.userId}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleOnChange('userId', e.target.value.trim())
              }
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleOnBlur('userId', e.target.value)
              }
              maxLength={50}
              placeholder={translate({ string: 'userId' })}
              error={errors.userId}
              leftIcon={<FontAwesomeIcon icon={FA_USER} />}
              errorIcon={<FontAwesomeIcon icon={FA_EXCLAMATION_CIRCLE} />}
              data-test="user-id"
              autoCapitalize="none"
              width="275px"
              required
            />

            <TextfieldWithMargin
              id="password"
              name="password"
              type="password"
              value={inputValues.password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleOnChange('password', e.target.value.trim())
              }
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleOnBlur('password', e.target.value)
              }
              maxLength={20}
              placeholder={translate({ string: 'password' })}
              error={errors.password}
              inputClassName="masked"
              autoCapitalize="none"
              leftIcon={<FontAwesomeIcon icon={FA_LOCK} />}
              errorIcon={<FontAwesomeIcon icon={FA_EXCLAMATION_CIRCLE} />}
              showMaskedOption
              hideTextValue={translate({ string: 'hide' })}
              showTextValue={translate({ string: 'show' })}
              width="275px"
              data-private
              data-test="password"
              required
            />

            <PrimaryButton
              buttonType="primary"
              type="submit"
              title={translate({ string: 'loginToYourAccount' })}
              disabled={isSubmitting}
              className="ensightencustomevent"
              data-reason="secure login"
              data-test="secure-login"
              data-type="login"
              data-object="button"
            >
              <LockIconWithMargin icon={FA_LOCK} />
              {isSubmitting
                ? translate({ string: 'loggingIn' })
                : translate({ string: 'secureLogin' })}
            </PrimaryButton>
          </Form>
          <LinkContainer>
            <LinkWrapper>
              {translate({ string: 'iForgotMy' })}
              <Link
                to={{
                  pathname: routes.FIND_USER_ID,
                  state: { returnTo: pathname, queryParams }
                }}
                isInline
                className="ensightencustomevent"
                data-test="find-user-id"
                data-reason="find user id"
                data-type="login"
                data-object="link"
              >
                {translate({ string: 'userId' })}
              </Link>
              {translate({ string: 'or' })}
              <Link
                to={{
                  pathname: routes.RESET,
                  state: { returnTo: pathname, queryParams }
                }}
                isInline
                className="ensightencustomevent"
                data-test="reset-password"
                data-reason="reset password"
                data-type="login"
                data-object="link"
              >
                {translate({ string: 'password' })}.
              </Link>
            </LinkWrapper>
            {pathname !== routes.ALEXA && (
              <LinkWrapper>
                {translate({ string: 'iWantTo' })}
                <Link
                  to={{
                    pathname: routes.REGISTER,
                    state: { returnTo: pathname, queryParams }
                  }}
                  isInline
                  className="ensightencustomevent"
                  data-test="register"
                  data-reason="register"
                  data-type="login"
                  data-object="button"
                >
                  {translate({ string: 'register' })}.
                </Link>
              </LinkWrapper>
            )}
          </LinkContainer>
        </Section>
      </Main>
    </>
  )
}

export default VoiceLoginContainer
