import apiEndpoints from 'const/apiEndpoints'
import { VerificationEndpoint } from 'services/typings'
import { formatQueryParams, getParameterByName } from 'helpers'
import { AccountLinkingRedirectProps } from './typings'

export const isAccountLinkingRedirect = (
  endpoint: VerificationEndpoint,
  responseCode: string
): boolean =>
  responseCode === '3001' && endpoint === apiEndpoints.cpl.ACCOUNT_LINKING

export const isPersistentLoginRedirect = (
  endpoint: VerificationEndpoint,
  responseCode: string
): boolean =>
  responseCode === '3001' && endpoint === apiEndpoints.cpl.PERSISTENT_LOGIN

export const handleAccountLinkingRedirect = ({
  queryParams,
  authCode
}: AccountLinkingRedirectProps): string => {
  const oauthRedirectUri = decodeURIComponent(
    getParameterByName('redirect_uri', queryParams)
  )
  const oauthState = decodeURIComponent(
    getParameterByName('state', queryParams)
  )
  return formatQueryParams(
    oauthRedirectUri,
    `code=${authCode}&state=${encodeURIComponent(oauthState)}`
  )
}
