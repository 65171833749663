import { colors } from 'theme'
import styled from 'styled-components'
import React, { ReactElement } from 'react'
import Inset from 'syf-component-library/ui/spacing/Inset'
import Stack from 'syf-component-library/ui/spacing/Stack'
import H4 from 'syf-component-library/ui/typography/H4'
import H5 from 'syf-component-library/ui/typography/H5'
import query from 'syf-component-library/const/mediaQueries'
import { HeaderProps } from './typings'

export const Base = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  @media ${query.lessThanMedium} {
    padding: 1em;
  }
`

export const Row = styled.li`
  margin: auto;
  font-size: 15px;
  padding: 0;
  color: ${colors.black};
  @media ${query.greaterThanSmall} {
    padding: 10px 0;
    font-size: 18px;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const HeaderWrapper = styled.div`
  text-align: center;
`

const SubHeading = styled(H5)`
  color: ${colors.darkGrey};
`

export const Header = ({ title, description }: HeaderProps): ReactElement => {
  return (
    <HeaderWrapper>
      <Inset top="large" bottom="large">
        <H4 as="h1">{title}</H4>
        <Stack size="large" />
        <SubHeading as="h2">{description}</SubHeading>
      </Inset>
    </HeaderWrapper>
  )
}
