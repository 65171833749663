import { isMobile } from 'react-device-detect'
import api from 'services'
import formPost from 'syf-js-utilities/helpers/formPost'
import {
  addBmTelemetryToHeaders,
  getCurrentLanguageFromStorage,
  getParameterByName
} from 'helpers'
import config from 'const/config'
import { clientInfo } from 'configureBrand'
import apiEndpoints from 'const/apiEndpoints'
import { HandoverToServicingProps, PostFormData } from 'services/typings'

const handoverToClient = async ({
  accessToken,
  accountType,
  clientRedirectUri,
  errorDescriptorKey,
  handleError,
  hasJwtToken
}: HandoverToServicingProps): Promise<void> => {
  const reqHeaders = await addBmTelemetryToHeaders({
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
  try {
    const deepLinkQueryParam = getParameterByName('link')
    const handOverQueryParam = deepLinkQueryParam
      ? `?link=${deepLinkQueryParam}`
      : ''
    const apiEndpoint = hasJwtToken
      ? `${apiEndpoints.dsecurity.HANDOVER}?token=jwt`
      : apiEndpoints.dsecurity.HANDOVER
    const handOver = hasJwtToken ? 'handOverJwt' : 'handOver'
    const language = getCurrentLanguageFromStorage()
    const response = await api.get(apiEndpoint, reqHeaders)
    if (response.data.service_token) {
      const serviceToken = response.data.service_token
      const serviceUrl =
        clientInfo.hasMss && isMobile && language === 'en'
          ? `/mService/AccountSummary/${handOver}.action${handOverQueryParam}`
          : `/eService/AccountSummary/${handOver}.action${handOverQueryParam}`
      const handoverUrl = config.TARGET_ORIGIN + serviceUrl
      const formData: PostFormData = {
        data: serviceToken,
        clientId: config.RC_CLIENT_ID,
        transferLoginAccessed: 'false',
        accountType,
        langId: language,
        startTime: '',
        URL: serviceUrl
      }
      if (deepLinkQueryParam) formData.link = deepLinkQueryParam
      formPost(handoverUrl, formData)
    } else {
      handleError({
        errorCode: 0,
        redirectUri: clientRedirectUri,
        errorDescriptorKey
      })
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    handleError({
      errorCode: error?.response?.status || 0,
      redirectUri: clientRedirectUri,
      errorDetail: error?.response?.data?.message || '',
      errorDescriptorKey
    })
  }
}

export default handoverToClient
