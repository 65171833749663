import { addBmTelemetryToHeaders, convertPayloadToFormData } from 'helpers'
import config from 'const/config'
import apiEndpoints from 'const/apiEndpoints'
import { GenerateTokenOptions } from 'services/typings'
import api from 'services'

const generateToken = async ({
  authCode,
  codeVerifier,
  clientId = ''
}: GenerateTokenOptions): Promise<string> => {
  try {
    const payload = {
      grant_type: 'authorization_code',
      code: authCode,
      client_id: config.DSEC_APIGEE_CLIENT_ID,
      redirect_uri: 'https://redirect.synchronycredit.com',
      code_verifier: codeVerifier
    }

    const reqHeaders = await addBmTelemetryToHeaders({
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })

    const formattedPayload: string = convertPayloadToFormData(payload)

    const { data } = await api.post(
      apiEndpoints.dsecurity.TOKEN_EXCHANGE,
      formattedPayload,
      reqHeaders
    )
    const localStorgeData = `${data.id_token}&&${clientId}`
    if (
      localStorage.getItem(`remember-${config.RC_CLIENT_ID}`) ===
      `pending-${config.RC_CLIENT_ID}`
    ) {
      localStorage.setItem(`remember-${config.RC_CLIENT_ID}`, localStorgeData)
    }
    sessionStorage.setItem('madAccessToken', data.access_token)
    sessionStorage.setItem('madApiBaseUrl', `${config.API_HOST}/v1`)
    return data.access_token
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    throw error
  }
}

export default generateToken
