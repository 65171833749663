import query from 'syf-component-library/const/mediaQueries'
import { colors } from 'theme'
import styled from 'styled-components'

const Base = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  @media ${query.lessThanMedium} {
    padding: 1em;
  }
`

const Row = styled.li`
  margin: auto;
  font-size: 15px;
  padding: 0;
  color: ${colors.black};
  @media ${query.greaterThanSmall} {
    padding: 10px 0;
    font-size: 18px;
  }
`
export { Base, Row }
