import styled from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import Link from 'syf-component-library/ui/atoms/Link'
import Body from 'syf-component-library/ui/typography/Body'
import { PrimaryButton } from 'ui/atoms/Button'

export const Container = styled.div`
  width: 290px;
  display: flex;
  flex-direction: column;
  align-self: center;
  padding-top: 20px;
  margin: 0 auto;
  @media ${query.greaterThanMedium} {
    width: 100%;
    align-items: center;
  }
`

export const TermsAndConditionsLink = styled(Link)`
  padding: 18px 0;
`

export const TermsAndConditions = styled(Body)`
  margin-top: 10px;
  margin-bottom: 20px;
  padding-left: 5px;
  padding-right: 5px;
`

export const AcceptButton = styled(PrimaryButton)`
  margin: 10px 0px 20px;
  @media ${query.greaterThanMedium} {
    margin-bottom: 120px;
  }
`
