import { generateTransId } from 'services'

const getCorrelationId = (): string => {
  let correlationId = sessionStorage.getItem('correlationId')
  if (!correlationId) {
    correlationId = generateTransId()
    sessionStorage.setItem('correlationId', correlationId)
  }
  return correlationId
}

export default getCorrelationId
