import apiEndpoints from 'const/apiEndpoints'
import {
  addBmTelemetryToHeaders,
  getCorrelationId,
  getParameterByName
} from 'helpers'
import api, { generateTransId } from 'services'
import {
  CheckUserIdRequest,
  CheckVoicePinOptions,
  CheckVoicePinResponseData
} from 'services/typings'

const checkVoicePin = async (
  options: CheckVoicePinOptions
): Promise<CheckVoicePinResponseData> => {
  const queryParams = window.location.search
  try {
    const clientId = getParameterByName('client_id', queryParams)
    const { sessionId, pin } = options || {}
    const payload: CheckUserIdRequest = {
      'cipher.pin': pin,
      session_info: {
        session_id: sessionId
      }
    }
    const reqHeaders = await addBmTelemetryToHeaders({
      headers: {
        'X-SYF-Request-TrackingId': generateTransId(),
        'X-SYF-Request-CorrelationId': getCorrelationId()
      },
      params: {
        client_id: clientId
      }
    })

    const { data } = await api.post(
      apiEndpoints.dsecurity.CREATE_PIN,
      payload,
      reqHeaders
    )

    return data
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    throw error
  }
}

export default checkVoicePin
