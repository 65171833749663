import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import redirectToUrl from 'syf-js-utilities/helpers/redirectToUrl'
import { RootState } from 'redux/store'
import handleRedirectRoute from 'helpers/handleRedirectRoute'
import { ErrorLocationStateProps } from 'types/locationState'
import { FlexButtonContainer, PrimaryButton } from 'ui/atoms/Button'
import translate from 'helpers/translate'
import routes from 'const/routes'
import config, { publicUrlExtension } from 'const/config'

interface GoToSignInButtonProps {
  buttonText?: string
  dataType?: string
}

const GoToSignInButton = ({
  buttonText,
  dataType = 'page not found'
}: GoToSignInButtonProps): ReactElement => {
  const {
    idp: { isIdpFlow },
    jwt: { returnTo }
  } = useSelector((reduxState: RootState) => reduxState)
  const history = useHistory()
  const location = useLocation<ErrorLocationStateProps>()

  const determineRedirectPath = (): string => {
    if (location.state && location.state.returnTo)
      return location.state.returnTo
    return handleRedirectRoute()
  }

  const handleOnClick = () => {
    const redirectPath = determineRedirectPath()
    if (isIdpFlow || redirectPath === routes.HOME) {
      const fallbackUrl = `${config.TARGET_ORIGIN}${publicUrlExtension(
        'login'
      )}`
      const redirectUrl = returnTo || fallbackUrl
      redirectToUrl(redirectUrl)
    } else {
      history.push({
        pathname: redirectPath,
        search:
          redirectPath !== routes.CPL
            ? location?.search
            : location?.state.queryParams
      })
    }
  }

  return (
    <FlexButtonContainer>
      <PrimaryButton
        buttonType="primary"
        onClick={handleOnClick}
        className="ensightencustomevent"
        data-reason="go-to-login"
        data-type={dataType}
        data-object="button"
      >
        {buttonText || translate({ string: 'gotoSignIn' })}
      </PrimaryButton>
    </FlexButtonContainer>
  )
}

export default GoToSignInButton
