import { SSNAction, SSNState } from './typings'

export const initialState: SSNState = {
  code: '',
  validationError: ''
}

const SSN = (state: SSNState = initialState, action: SSNAction): SSNState => {
  switch (action.type) {
    case 'UPDATE_SSN':
      return { ...state, code: action.payload, validationError: '' }
    case 'UPDATE_VALIDATION_ERROR':
      return { ...state, validationError: action.payload }
    case 'UPDATE_FORM_SUBMISSION_ERROR':
      return { ...state, formSubmissionError: action.payload }
    case 'RESET_SSN_STATE':
      return initialState
    default:
      return state
  }
}

export default SSN
