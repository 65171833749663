import { getCookie, setCookie } from 'helpers/cookieHelpers'
import { clientInfo } from 'configureBrand'
import { MessageData } from 'types/ivaClient'

declare global {
  interface Window {
    ivaEnableFlag: string
    NITGlobal: { clientAvailable: boolean }
  }
}
const logout = (): void => {
  const data: MessageData = {
    message: {
      type: 'UserLogout',
      source: 'client'
    }
  }
  window.postMessage(JSON.stringify(data), window.location.origin)
  setCookie('source', '')
}

const initialize = (): boolean => {
  const { clientAvailable } = window.NITGlobal || {}
  window.ivaEnableFlag = 'Y'
  if (getCookie('source') === 'logout') {
    logout()
  }
  return clientAvailable
}

const launch = (launchPointName: string, launchParameter: string): void => {
  const data: MessageData = {
    message: {
      type: 'LaunchIva',
      source: 'client',
      accessKey: clientInfo.ivaKey,
      launchPointName,
      launchParameter
    }
  }
  window.postMessage(JSON.stringify(data), window.location.origin)
}

export { initialize, logout, launch }
