import config from 'const/config'
import api, { generateTransId } from 'services'
import {
  addBmTelemetryToHeaders,
  createCodeChallenge,
  determineAuthResult,
  getBlackBoxValues,
  getCorrelationId
} from 'helpers'
import { AuthResult } from 'helpers/typings'
import {
  AuthPayload,
  AuthResponseData,
  Authenticate,
  UserCredentials,
  VerificationEndpoint
} from 'services/typings'
import apiEndpoints from 'const/apiEndpoints'
import generateUUID from 'syf-js-utilities/helpers/generateUUID'

export const codeVerifier = generateUUID()
export const codeChallenge = createCodeChallenge(codeVerifier)

const login = {
  postAuthenticate: async (
    options: UserCredentials,
    endpoint: VerificationEndpoint
  ): Promise<Authenticate> => {
    const { userId, password, remember } = options
    const blackboxValues = await getBlackBoxValues()

    const client = config.RC_CLIENT_ID
    const reqHeaders = await addBmTelemetryToHeaders({
      headers: {
        'X-SYF-Request-TrackingId': generateTransId(),
        'X-SYF-Request-CorrelationId': getCorrelationId()
      }
    })

    const payload: AuthPayload = {
      auth: {
        client_id: config.DSEC_APIGEE_CLIENT_ID,
        redirect_uri: 'https://redirect.synchronycredit.com',
        scope: 'login',
        state: 'V1o3Wnk1ZEN5TkR6cWZ5NG8xWmZEanFhUm1vQW9XZG',
        code_challenge: codeChallenge,
        code_challenge_method: 'S256',
        nonce: ''
      },
      device_info: {
        black_box: blackboxValues.blackbox
      },
      user_info: { user_id: userId, 'cipher.password': password }
    }

    const response = await api.post(
      apiEndpoints.dsecurity.AUTHENTICATE(endpoint),
      payload,
      reqHeaders
    )

    const responseData: AuthResponseData = response.data

    localStorage.setItem(
      `remember-${config.RC_CLIENT_ID}`,
      remember ? `pending-${config.RC_CLIENT_ID}` : ''
    )

    const result: AuthResult = determineAuthResult(responseData)

    return {
      responseData,
      result,
      codeVerifier,
      client
    }
  }
}

export default login
