import React, { ReactElement, SyntheticEvent } from 'react'
import Textfield from 'syf-component-library/ui/atoms/Textfield'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FA_EXCLAMATION_CIRCLE } from 'const/iconProp'
import { useDispatch, useSelector } from 'react-redux'
import translate from 'helpers/translate'
import Header from 'ui/atoms/Header'
import { TextButton } from 'ui/atoms/Button'
import RoundedRadio from 'ui/atoms/RadioButtons/RoundedRadio'
import RadioGroup from 'ui/molecules/RadioGroup'
import { MfaValidationType } from 'const/mfa'
import { RootState } from 'redux/store'
import {
  resetOtpState,
  updateBindDevice,
  updateOtpPin
} from 'redux/otp/actions'
import ModalFooter from 'ui/organisms/NeedsVerificationModal/NonIdP/ModalFooter'
import { VerifyProps } from './typings'
import {
  Container,
  DidNotReceiveCodeContainer,
  HelpText,
  Label,
  SaveDetailsContainer,
  VerifyForm
} from './subcomponents'

const radioStyles = {
  height: '32px'
}

const Verify = ({
  handleCancel,
  handleValidate
}: VerifyProps): ReactElement => {
  const { lastFour, error, showGetNewCode, bindDevice, otpPin } = useSelector(
    (state: RootState) => {
      return {
        lastFour: state.otp.lastFour,
        showGetNewCode: state.otp.showGetNewCode,
        error: state.otp.error,
        bindDevice: state.otp.bindDevice,
        otpPin: state.otp.otpPin
      }
    }
  )

  const dispatch = useDispatch()

  const errorExtensionMessage = error?.extension && ` ${error.extension}`
  const errorMessage = `${translate({
    string: error?.key,
    argument: error?.argument
  })}${errorExtensionMessage}`

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault()
    handleValidate()
  }

  const goBackHandler = (event: SyntheticEvent) => {
    event.preventDefault()
    dispatch(resetOtpState())
  }

  return (
    <Container>
      <Header
        data-test="subHeader"
        title={translate({ string: 'codeEnter' })}
        description={translate({
          string: 'yourCodeHasBeenSent',
          argument: lastFour
        })}
        labelledby="verification-title"
        describedby="verification-description"
      />
      <VerifyForm data-test="form" onSubmit={handleSubmit}>
        <Textfield
          id="otp"
          data-test="otp-field"
          onChange={(e: SyntheticEvent) =>
            dispatch(updateOtpPin((e.target as HTMLInputElement).value))
          }
          value={otpPin}
          errorIcon={
            <FontAwesomeIcon
              icon={FA_EXCLAMATION_CIRCLE}
              title={translate({ string: 'requirementNotMet' })}
            />
          }
          error={!!error?.key && errorMessage}
          type="text"
          placeholder={translate({ string: 'otpField' })}
          maxLength={4}
          matchPattern={/^([0-9]*){3,5}$/}
          data-private
        />
        {showGetNewCode && (
          <DidNotReceiveCodeContainer>
            {translate({ string: 'didNotReceiveCode' })}{' '}
            <TextButton
              buttonType="text"
              onClick={goBackHandler}
              className="ensightencustomevent"
              data-reason="didn’t receive code"
              data-type="otp"
              data-object="link"
              data-test="get-new-code"
            >
              {translate({ string: 'getANewOne' })}
            </TextButton>
          </DidNotReceiveCodeContainer>
        )}
        <SaveDetailsContainer>
          <Label>{translate({ string: 'saveComputer' })}</Label>
          <RadioGroup
            aria-label={translate({ string: 'saveComputer' })}
            selectedValue={bindDevice}
            toggleRadioValue={value => dispatch(updateBindDevice(value))}
          >
            <RoundedRadio
              value
              key={translate({ string: 'yes' })}
              name={translate({ string: 'saveComputer' })}
              label={translate({ string: 'yes' })}
              data-reason="save computer:yes"
              data-type="otp"
              data-object="checkbox"
              style={radioStyles}
            />
            <RoundedRadio
              value={false}
              key={translate({ string: 'no' })}
              name={translate({ string: 'saveComputer' })}
              label={translate({ string: 'no' })}
              data-reason="save computer:no"
              data-type="otp"
              data-object="checkbox"
              style={radioStyles}
            />
          </RadioGroup>
        </SaveDetailsContainer>
        <HelpText data-test="rememberDeviceText">
          {translate({ string: 'rememberDevice' })}
        </HelpText>
        <ModalFooter
          dataType={MfaValidationType.OTP}
          handleCancel={handleCancel}
          submissionError={error?.locked}
        />
      </VerifyForm>
    </Container>
  )
}

export default Verify
