import { PwoliAction, PwoliState } from './typings'

export const initialState: PwoliState = {
  pagToken: '',
  pwoliToken: ''
}

export default (
  state: PwoliState = initialState,
  action: PwoliAction = undefined
): PwoliState => {
  switch (action.type) {
    case 'UPDATE_PAG_TOKEN':
      return {
        ...state,
        pagToken: action.payload
      }
    case 'UPDATE_PWOLI_TOKEN':
      return {
        ...state,
        pwoliToken: action.payload
      }
    case 'RESET_QUICKPAY_TOKEN':
      return initialState
    default:
      return state
  }
}
