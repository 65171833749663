import React, { ReactElement, SyntheticEvent, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import {
  formatQueryParams,
  getParameterByName,
  redirectToClientUrl,
  translate,
  validate
} from 'helpers'
import { Helmet } from 'react-helmet-async'
import Loading from 'ui/atoms/Loader'
import Link from 'syf-component-library/ui/atoms/Link'
import HeroBanner from 'ui/atoms/HeroBanner'
import LegalText from 'ui/atoms/LegalText'
import InlineError from 'ui/atoms/InlineError/NonIdP'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { clientInfo } from 'configureBrand'
import { GhostButton, PrimaryButton } from 'ui/atoms/Button'
import routes from 'const/routes'
import { FA_EXCLAMATION_CIRCLE, FA_LOCK, FA_USER } from 'const/iconProp'
import EMPTY_STRING from 'const/emptyString'
import {
  postAccountLinkingAuthentication,
  resetSubmissionError
} from 'redux/cpl/actions'
import { LocationState } from 'types/locationState'
import { RootState } from 'redux/store'
import { CplFormdata } from 'redux/cpl/typings'
import {
  Form,
  LinkContainer,
  LinkWrapper,
  LockIconWithMargin,
  Main,
  Section,
  TextfieldWithMargin
} from './subcomponents'

const LoginContainer = (): ReactElement => {
  const {
    cpl: { submissionError },
    uiUtils: { isSubmitting }
  } = useSelector((state: RootState) => state, shallowEqual)
  const dispatch = useDispatch()
  const location = useLocation<LocationState>()
  const userIdFromState = location.state?.userId || ''
  const [formData, setFormData] = useState<CplFormdata>({
    userId: userIdFromState,
    password: ''
  })
  const [errors, setErrors] = useState<{
    userId: string
    password: string
  }>({ userId: '', password: '' })
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { vanityUrl, displayName } = clientInfo
  const history = useHistory()
  const { state } = location || {}
  const error = state?.error || {}
  const queryParams = window.location.search
  const oauthRedirectUri = decodeURIComponent(
    getParameterByName('redirect_uri', queryParams)
  )

  const validateAllFields = (): string => {
    const validation = { errors: {} }
    Object.keys(errors).forEach(key => {
      const { [key as keyof typeof errors]: stateKey } = formData
      const errorData = validate(key, stateKey)
      validation.errors = { ...validation.errors, [key]: errorData }
    })
    setErrors({ ...errors, ...validation.errors })
    return Object.keys(validation.errors).find(
      e => validation.errors[e as keyof typeof validation.errors]
    )
  }

  const handleOnChange = (target: string, value: string) => {
    setFormData({ ...formData, [target]: value })
    dispatch(resetSubmissionError())
    setErrors({ ...errors, [target]: '' })
  }

  const handleOnBlur = (target: string, value: string) => {
    const validationError = validate(target, value)
    setErrors({ ...errors, [target]: validationError })
  }

  const handleCancel = (): void => {
    if (!oauthRedirectUri) {
      setIsLoading(true)
      history.push({
        pathname: routes.ERROR,
        search: window.location.search,
        state: { returnTo: routes.CPL }
      })
    } else {
      const redirectUri = formatQueryParams(
        oauthRedirectUri,
        `error=406&error_description=User Cancelled`
      )
      redirectToClientUrl(redirectUri)
    }
  }

  const handleSubmit = async (event: SyntheticEvent): Promise<void> => {
    event.preventDefault()
    if (!validateAllFields()) {
      dispatch(postAccountLinkingAuthentication(formData))
    }
  }

  useEffect(() => {
    if (submissionError?.key) {
      setFormData({ userId: '', password: EMPTY_STRING })
    }
  }, [submissionError])

  return (
    <>
      <Helmet>
        <title>{`Manage Your ${displayName} Credit Card Account`}</title>
      </Helmet>
      <Main>
        <HeroBanner />
        <Section>
          <Form
            data-test="form"
            onSubmit={handleSubmit}
            autoComplete="off"
            noValidate
          >
            <InlineError
              error={submissionError?.key ? submissionError : error}
            />
            <TextfieldWithMargin
              id="userId"
              name="userId"
              type="text"
              value={formData.userId}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleOnChange('userId', e.target.value.trim())
              }
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleOnBlur('userId', e.target.value)
              }
              maxLength={50}
              placeholder={translate({ string: 'userId' })}
              helpText={translate({
                string: 'userIdHelpText',
                argument: vanityUrl
              })}
              error={errors.userId}
              leftIcon={<FontAwesomeIcon icon={FA_USER} />}
              errorIcon={<FontAwesomeIcon icon={FA_EXCLAMATION_CIRCLE} />}
              autoCapitalize="none"
              width="275px"
              data-test="login-userid"
              required
            />

            <TextfieldWithMargin
              id="password"
              name="password"
              type="password"
              className="masked"
              value={formData.password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleOnChange('password', e.target.value.trim())
              }
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleOnBlur('password', e.target.value)
              }
              maxLength={20}
              placeholder={translate({ string: 'password' })}
              error={errors.password}
              inputClassName="masked"
              autoCapitalize="none"
              leftIcon={<FontAwesomeIcon icon={FA_LOCK} />}
              errorIcon={<FontAwesomeIcon icon={FA_EXCLAMATION_CIRCLE} />}
              showMaskedOption
              hideTextValue={translate({ string: 'hide' })}
              showTextValue={translate({ string: 'show' })}
              width="275px"
              data-private
              data-test="login-password"
              required
            />

            <PrimaryButton
              buttonType="primary"
              type="submit"
              title={translate({ string: 'loginToYourAccount' })}
              disabled={isSubmitting}
              className="ensightencustomevent"
              data-reason="secure login"
              data-type="login"
              data-object="button"
            >
              <LockIconWithMargin icon={FA_LOCK} />
              {isSubmitting
                ? translate({ string: 'loggingIn' })
                : translate({ string: 'secureLogin' })}
            </PrimaryButton>

            <GhostButton
              buttonType="ghost"
              onClick={handleCancel}
              className="ensightencustomevent"
              data-reason="cancel"
              data-type="login"
              data-object="button"
            >
              {translate({ string: 'cancel' })}
            </GhostButton>
          </Form>
          <LinkContainer>
            <LinkWrapper>
              {translate({ string: 'iForgotMy' })}
              <Link
                to={{
                  pathname: routes.FIND_USER_ID,
                  state: { returnTo: routes.CPL, queryParams }
                }}
                isInline
                className="ensightencustomevent"
                data-reason="find user id"
                data-type="login"
                data-object="link"
              >
                {translate({ string: 'userId' })}
              </Link>
              {translate({ string: 'or' })}
              <Link
                to={{
                  pathname: routes.RESET,
                  state: { returnTo: routes.CPL, queryParams }
                }}
                isInline
                className="ensightencustomevent"
                data-reason="reset password"
                data-type="login"
                data-object="link"
              >
                {translate({ string: 'password' })}.
              </Link>
            </LinkWrapper>

            <LinkWrapper>
              {translate({ string: 'iWantTo' })}
              <Link
                to={{
                  pathname: routes.REGISTER,
                  state: { returnTo: routes.CPL, queryParams }
                }}
                isInline
                className="ensightencustomevent"
                data-reason="register"
                data-type="login"
                data-object="button"
              >
                {translate({ string: 'register' })}.
              </Link>
            </LinkWrapper>
          </LinkContainer>
          <LegalText />
        </Section>
      </Main>

      <Loading showLabel={false} isLoading={isLoading} />
    </>
  )
}

export default LoginContainer
