import React, { ReactElement } from 'react'
import Link from 'syf-component-library/ui/atoms/Link'
import styled from 'styled-components'
import translate from 'helpers/translate'

const HeaderContainer = styled('span')`
  text-align: center;
  display: flex;
  flex-direction: column;
`

const LinkContainer = styled(Link)`
  font-size: 12px;
  margin-bottom: 12px;
`

interface BillingInUsHeaderProps {
  handleClick: () => void
}

const BillingInUsHeader = ({
  handleClick
}: BillingInUsHeaderProps): ReactElement => (
  <HeaderContainer>
    <b>{translate({ string: 'billingInUSTitle' })}</b>
    <p>
      <LinkContainer
        onClick={handleClick}
        data-test="billing-in-us-link"
        href="#/"
      >
        {translate({ string: 'billingInUSText' })}
      </LinkContainer>
    </p>
  </HeaderContainer>
)

export default BillingInUsHeader
