import redirectToUrl from 'syf-js-utilities/helpers/redirectToUrl'
import { FlowId, IdpFormPostRelType } from 'const/idp'
import idpFormPost from './idpFormPost'
import { getCurrentLanguageFromStorage } from './translate'

interface HandleVistaRedirectOptions {
  href: string
  rel: string
  rememberMe?: boolean
  flowId?: FlowId
}

const handleVistaRedirect = ({
  href,
  rel,
  rememberMe = false,
  flowId = FlowId.LOGIN
}: HandleVistaRedirectOptions): void => {
  if (rel === IdpFormPostRelType.REDIRECT) {
    const language = getCurrentLanguageFromStorage()
    return redirectToUrl(`${href}&lang=${language}`)
  }

  return idpFormPost({ href, rememberMe, flowId })
}

export default handleVistaRedirect
