import React, { ReactElement, SyntheticEvent } from 'react'
import styled from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import handoverToPWOLI from 'services/handoverToPWOLI'
import PayAsGuestBanner from 'ui/organisms/PayAsGuestBanner'
import IdpBanner from 'ui/organisms/IdpBanner'

export const Container = styled.div`
  width: 900px;
  margin-left: 36px;
  margin-bottom: 20px;
  @media ${query.lessThanLarge} {
    width: 100%;
    max-width: 900px;
    margin: 0;
    margin-bottom: 20px;
  }
`

export const BannerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media ${query.lessThanMedium} {
    display: grid;
    grid-auto-rows: 1fr;
  }
`

const Banner = (): ReactElement => {
  const {
    quickPay: { pagToken },
    akamaiConfig: { showIdpBanner }
  } = useSelector((reduxState: RootState) => reduxState)

  const onPagClick = (event: SyntheticEvent): void => {
    event.preventDefault()
    handoverToPWOLI(pagToken, 'unregisteredstart')
  }

  return (
    <Container>
      <BannerWrapper id="banner-wrapper">
        {showIdpBanner && <IdpBanner />}
        {pagToken && (
          <PayAsGuestBanner onPagClick={onPagClick} data-test="pay-as-guest" />
        )}
      </BannerWrapper>
    </Container>
  )
}

export default Banner
