export const AUTH_TRY_AGAIN = 'idp.authenticate.error.verification'
export const AUTH_LOCKED_STANDARD_CSR1_PLCC =
  'idp.authenticate.login.error.csr1.plcc'
export const AUTH_LOCKED_STANDARD_CSR2_PLCC =
  'idp.authenticate.login.error.csr2.plcc'
export const AUTH_LOCKED_STANDARD_CSR1_DUALCARD =
  'idp.authenticate.login.error.csr1.dualcard'
export const AUTH_LOCKED_STANDARD_CSR2_DUALCARD =
  'idp.authenticate.login.error.csr2.dualcard'
export const AUTH_LOCKED_STANDARD_CSR1_LOAN =
  'idp.authenticate.login.error.csr1.loan'
export const AUTH_LOCKED_STANDARD_CSR2_LOAN =
  'idp.authenticate.login.error.csr2.loan'
export const AUTH_LOCKED_STANDARD_CSR3_FRAUD =
  'idp.authenticate.login.error.csr3'
export const AUTH_RESET_PASSWORD_LOCKED =
  'idp.authenticate.login.error.user.locked'
export const AUTH_RESET_PASSWORD_EXPIRED_OR_EMPTY =
  'idp.authenticate.login.error.passwordexpiredorempty'
export const AUTH_USER_ID_UNUSABLE_RECENTLY_UPDATED =
  'idp.authenticate.login.error.useridunusable'
export const AUTH_ONE_ATTEMPT_LEFT =
  'idp.authenticate.error.verification.oneattemptleft'
export const AUTH_DUPLICATE_USER_ID =
  'idp.authenticate.login.error.duplicateuserid'
export const OTP_INITIATE_INCORRECT_DELIVERY_METHOD =
  'idp.otp.initiate.incorrect.delivery.method'
export const OTP_INITIATE_MAXIMUM_ATTEMPTS_REACHED =
  'idp.otp.initiate.allowed.attempt.reached'
export const OTP_INITIATE_PHONE_CONFIRM_ERROR =
  'idp.otp.initiate.error.phone.confirm'
export const OTP_INITIATE_PHONE_CONFIRM_ERROR_ONE_ATTEMPT_LEFT =
  'idp.otp.initiate.error.phone.confirm.one.attempt.left'
export const OTP_INITIATE_PHONE_CONFIRM_ERROR_MAX =
  'idp.otp.initiate.error.phone.max'
export const OTP_INITIATE_PHONE_CONFIRM_MAX =
  'idp.otp.initiate.error.phone.confirm.max'
export const IDENTITY_COMPLETE_AUTHENTICATION_FAILURE =
  'idp.complete.login.error.authenticationfailed'
export const OTP_VALIDATE_INCORRECT_CODE = 'idp.otp.validation.incorrect.code'
export const OTP_VALIDATE_INCORRECT_CODE_AND_MAX_ATTEMPTS_REACHED_ONE =
  'idp.otp.validation.incorrect.code.and.validation.maximum.attempts.reached'
export const OTP_VALIDATE_INCORRECT_CODE_AND_MAX_ATTEMPTS_REACHED_TWO =
  'idp.otp.validation.incorrect.code.initiate.otp.maximum.attempts.reached'
export const OTP_VALIDATE_INCORRECT_CODE_AND_MAX_ATTEMPTS_REACHED_THREE =
  'idp.otp.validation.incorrect.code.and.validation.maximum.attempt.reached.and.initiate.otp.maximum.attempts.reached'
export const IDP_COMPLETE_TRANSFER = 'idp.complete.transfer'
export const SELECT_ALL_REQUIRED_FIELDS = 'selectAllRequired'
export const FIELD_REQUIRED = 'required'
export const DIGITS_ONLY = 'digitsOnly'
export const MIN_FOUR_DIGITS = 'minFourDigits'
export const IDP_INTERACTION_ID_NOT_FOUND = 'idp.interaction.not.found'
export const OTP_SOFTENER_INCORRECT_CODE = 'idp.otp-softener.incorrect.code'
export const OTP_SOFTENER_INCORRECT_CODE_AND_ONE_MORE_ATTEMPT_LEFT =
  'idp.otp-softener.incorrect.code.oneattemptleft'
export const OTP_SOFTENER_INCORRECT_CODE_AND_VALIDATION_REACHED =
  'idp.otp-softener.incorrect.code.and.validation.maximum.attempts.reached'
export const IDP_AUTHENTICATE_REGISTRATION_ERROR_REGISTERED =
  'idp.authenticate.error.registered'
export const IDP_AUTHENTICATE_REGISTRATION_ERROR_VERIFICATION =
  'idp.authenticate.error.verification'
export const REGISTRATION_EBILL_FAILURE = 'idp.profile.failed.enrollment.ebill'
export const REGISTRATION_ALERT_FAILURE = 'idp.profile.failed.enrollment.alert'
export const PROFILE_CREATE_ERROR_FAILED_REGISTERED_ACCOUNT =
  'idp.profile.failed.registered.account'
export const PROFILE_CREATE_ERROR_FAILED_REGISTERED_USERID =
  'idp.profile.failed.registered.userid'
export const PROFILE_CREATE_ERROR_FAILED_ACCOUNT_LOCKED =
  'idp.profile.failed.account.locked'
export const PROFILE_CREATE_USERID_EXISTS = 'idp.profile.unusable.userid'
export const IDP_AUTHENTICATE_REGISTRATION_ERROR_ACCOUNT_LOCKED =
  'idp.authenticate.login.error.account.locked'
export const OTP_INITIATE_SESSION_EXPIRED = 'idp.otp.initiate.session.expired'
export const OTP_VALIDATE_INVALID_SESSIONID =
  'idp.otp.validation.invalid.sessionid'
export const OTP_SOFTENER_CSR1_PLCC = 'idp.otp-softener.error.csr1.plcc'
export const OTP_SOFTENER_CSR1_DUALCARD = 'idp.otp-softener.error.csr1.dualcard'
export const OTP_SOFTENER_CSR1_LOAN = 'idp.otp-softener.error.csr1.loan'
export const IDP_PROFILE_EROR_CSR1_PLCC = 'idp.profile.error.csr1.plcc'
export const IDP_PROFILE_EROR_CSR1_LOAN = 'idp.profile.error.csr1.loan'
export const IDP_PROFILE_EROR_CSR1_DUALCARD = 'idp.profile.error.csr1.dualcard'
export const IDP_AUTHORIZATION_REGISTRATION_ERROR =
  'idp.profile.registration.error.authorizationfailed'
