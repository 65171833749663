import { UiAction, UiState } from './typings'

export const initialState: UiState = {
  isSubmitting: false,
  isModalOpen: false,
  isLoading: false
}

export default function (
  state: UiState = initialState,
  action: UiAction = undefined
): UiState {
  switch (action.type) {
    case 'UPDATE_IS_SUBMITTING':
      return {
        ...state,
        isSubmitting: action.payload
      }
    case 'UPDATE_IS_MODAL_OPEN':
      return { ...state, isModalOpen: action.payload, isSubmitting: false }
    case 'UPDATE_IS_LOADING':
      return { ...state, isLoading: action.payload }
    default:
      return state
  }
}
