import formPost from 'syf-js-utilities/helpers/formPost'
import config from 'const/config'
import getCorrelationId from 'helpers/getCorrelationId'

export const handoverToPWOLI = async (
  token: string | boolean,
  path: string
): Promise<string | boolean> => {
  try {
    const formData = {
      _csrf: token,
      trace_id: getCorrelationId()
    }
    formPost(`${config.TARGET_ORIGIN}/quickpay/${path}`, formData)
    return token
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    throw error
  }
}

export default handoverToPWOLI
