import React, { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { clientInfo } from 'configureBrand'
import { translate, triggerAnalyticsEvent } from 'helpers'
import { clearIdpState } from 'redux/idp/actions'
import { updateFormData } from 'redux/login/actions'
import { updateIsModalOpen, updateIsSubmitting } from 'redux/uiUtils/actions'
import { FlexButtonContainer, PrimaryButton } from 'ui/atoms/Button'
import Header from 'ui/atoms/Header'
import { ContactNumber, Container, SorryDescription } from './subcomponents'

const Sorry = (): ReactElement => {
  const dispatch = useDispatch()

  const {
    phoneNumbers: { fraud }
  } = clientInfo

  const handleCancel = () => {
    dispatch(updateIsModalOpen(false))
    dispatch(updateIsSubmitting(false))
    dispatch(clearIdpState())
    triggerAnalyticsEvent({ subFunction: '' })
    dispatch(updateFormData({ userId: '', password: '' }))
  }

  return (
    <Container>
      <Header
        title={translate({ string: 'weApologize' })}
        description={
          <SorryDescription>
            {translate({ string: 'sorryDescription' })}{' '}
            <ContactNumber href={`tel:+${fraud}`}>{fraud}.</ContactNumber>
          </SorryDescription>
        }
        labelledby="verification-title"
        describedby="verification-description"
      />

      <FlexButtonContainer>
        <PrimaryButton
          buttonType="primary"
          onClick={handleCancel}
          data-test="sorry-close-button"
        >
          {translate({ string: 'close' })}
        </PrimaryButton>
      </FlexButtonContainer>
    </Container>
  )
}

export default Sorry
