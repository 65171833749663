import { clientInfo } from 'configureBrand'
import routes from 'const/routes'
import translate from 'helpers/translate'
import { AuthResponseData } from 'services/typings'
import { AuthResult } from 'helpers/typings'

const determineAuthResult = (data: AuthResponseData): AuthResult => {
  const {
    status: { response_code: code },
    account_type: accountType,
    phone_numbers: phoneNumbers
  } = data

  switch (code) {
    case '1001':
      return {
        message: {
          key: 'tryAgain',
          extension: `${translate({ string: 'code' })}: 1001`,
          argument: clientInfo.vanityUrl
        }
      }
    case '1002':
      return {
        message: {
          key: 'lockedStandard',
          argument:
            clientInfo.phoneNumbers[
              accountType as keyof typeof clientInfo.phoneNumbers
            ],
          extension: `${translate({ string: 'code' })}: 1002`
        }
      }
    case '1003':
      return {
        message: {
          key: 'lockedProblem',
          argument:
            clientInfo.phoneNumbers[
              accountType as keyof typeof clientInfo.phoneNumbers
            ],
          extension: `${translate({ string: 'code' })}: 1003`
        }
      }
    case '1004':
      return {
        message: {
          key: 'lockedProblem',
          argument: clientInfo.phoneNumbers.fraud,
          extension: `${translate({ string: 'code' })}: 1004`
        }
      }
    case '1005':
      return { token: true }
    case '1006':
      if (phoneNumbers?.length) {
        return { otp: true }
      }
      return {
        message: {
          key: 'lockedProblem',
          argument: clientInfo.phoneNumbers.fraud,
          extension: `${translate({ string: 'code' })}: 1006`
        }
      }
    case '1007':
      return { token: true }
    case '1008':
      return {
        redirect: routes.RESET,
        message: {
          key: 'resetPassword',
          extension: `${translate({ string: 'code' })}: 1008`
        }
      }
    case '1009':
      return {
        message: {
          key: 'recentlyUpdatedUserId',
          extension: `${translate({ string: 'code' })}: 1009`
        }
      }
    case '1010':
      return { lostStolen: true }
    case '1012':
      return {
        message: {
          key: 'oneAttemptRemaining',
          extension: `${translate({ string: 'code' })}: 1012`
        }
      }
    case '1013':
      return {
        message: {
          key: 'gapBrandAccountIssue',
          argument: clientInfo.phoneNumbers.customerService,
          extension: `${translate({ string: 'code' })}: 1013`
        }
      }
    default: {
      const { trans_id: transId = 'None' } = data?.status || {}
      return {
        message: {
          key: 'default',
          extension: `${translate({ string: 'code' })}: ${transId}`
        }
      }
    }
  }
}

export default determineAuthResult
