import React, { ReactElement } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { RootState } from 'redux/store'
import routes from 'const/routes'
import Complete from 'ui/organisms/Registration/Complete'
import redirectToUrl from 'syf-js-utilities/helpers/redirectToUrl'
import config from 'const/config'
import { LocationState } from 'types/locationState'

interface RegistrationCompleteProps {
  returnTo?: string
}

const RegistrationComplete = ({
  returnTo
}: RegistrationCompleteProps): ReactElement => {
  const { registration } = useSelector(
    (reduxState: RootState) => ({
      registration: reduxState.registration
    }),
    shallowEqual
  )
  const history = useHistory()
  const location = useLocation()

  const onClickAccountSummary = () => {
    const { userId } = registration
    const isCplRoute = returnTo !== routes.HOME

    if (!isCplRoute) {
      return redirectToUrl(`${config.TARGET_ORIGIN}${config.BASENAME}`)
    }

    const { queryParams } = location.state as LocationState
    return history.push({
      pathname: returnTo,
      search: queryParams,
      state: { userId }
    })
  }

  return (
    <Complete
      handleAccountSummary={onClickAccountSummary}
      isEbillFailure={registration?.isEbillFailure}
    />
  )
}

export default RegistrationComplete
