import React, { ReactElement } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import styled from 'styled-components'
import { colors } from 'theme'
import UpdatedMessage from './UpdatedMessage'
import DefaultMessage from './DefaultMessage'

export const PsfMessageContainer = styled.div`
  color: ${colors.grey};
  max-width: 500px;
  text-align: left;
`

interface PsfMessageProps {
  eConsentLink: string
}

const PsfMessage = ({ eConsentLink }: PsfMessageProps): ReactElement => {
  const psfMessage = useSelector(
    (state: RootState) => state.psfMessage,
    shallowEqual
  )

  const hasUpdatedPsfMessage = !!psfMessage?.dual && !!psfMessage?.standard

  return hasUpdatedPsfMessage ? (
    <UpdatedMessage eConsentLink={eConsentLink} />
  ) : (
    <DefaultMessage eConsentLink={eConsentLink} />
  )
}

export default PsfMessage
