import React, { ReactElement } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import translate from 'helpers/translate'
import ToggleVistaButton from 'syf-component-library/ui/atoms/Switch'
import { updateIsIdpFlow } from 'redux/idp/actions'
import Small from 'syf-component-library/ui/typography/Small'
import { Container, Content, Img, Title } from './subcomponents'

const IdpBanner = (): ReactElement => {
  const { isIdpFlow } = useSelector(
    (state: RootState) => ({
      isIdpFlow: state.idp.isIdpFlow
    }),
    shallowEqual
  )
  const dispatch = useDispatch()

  return (
    <Container icon={<Img />} data-test="idp-banner">
      <Title weight="semiBold">
        {translate({
          string: 'idpBannerTitle'
        })}
      </Title>
      <Content>
        {translate({
          string: 'idpBannerSubtitle'
        })}
      </Content>
      <Content data-test="account-manger-state">
        {`${translate({
          string: 'idpBannerToggleText'
        })} `}
        <Small isInline weight="bold">
          {translate({
            string: isIdpFlow ? 'on' : 'off'
          })}
        </Small>
        <ToggleVistaButton
          id="vistaToggle"
          checked={isIdpFlow}
          data-test="account-manager-switch"
          onChange={() => {
            dispatch(updateIsIdpFlow(!isIdpFlow))
          }}
        />
      </Content>
    </Container>
  )
}

export default IdpBanner
