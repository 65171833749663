import { clientInfo } from 'configureBrand'

export interface ConfigType {
  API_HOST?: string
  DSEC_APIGEE_CLIENT_ID: string
  PERSIST_APIGEE_CLIENT_ID?: string
  SETPAY_APIGEE_CLIENT_ID?: string
  IVA_DOMAIN?: string
  BASENAME?: string
  RC_CLIENT_ID: string
  NODE_ENV: string
  TARGET_ORIGIN: string
  ASSET_ORIGIN: string
  AUTH_CLIENT_ID: string
  AUTH_BASE_URL: string
  DOWNTIME_URL: string
  VISTA_DOMAIN: string
  REDIRECT_URL: string
}

type ENV = 'development' | 'production'
const {
  NODE_ENV,
  REACT_APP_RC_CLIENT_ID: RC_CLIENT_ID = '',
  REACT_APP_ASSET_ORIGIN,
  REACT_APP_API_HOST,
  REACT_APP_IVA_DOMAIN,
  REACT_APP_AUTH_BASE_URL,
  REACT_APP_AUTH_CLIENT_ID
} = process.env
const APP_ENV = 'CF_APP_ENV' as ENV
const { hostname, href } = window.location

export const checkForIdpRoute = (url: string): boolean =>
  /\.*accounts/.test(url)

const isIdpRoute = checkForIdpRoute(href)

const determineExtension = (extension: string): string => {
  return new RegExp(`/$`).test(extension) ? extension : `${extension}/`
}

export const publicUrlExtension = (extension: string): string => {
  const TEST_ENV = /\.*\.synchronycredit.com/
  const PCF_ENV = /\.*\.pcf.syfbank.com/
  if (TEST_ENV.test(hostname) || NODE_ENV !== 'production')
    return `/${determineExtension(extension)}${RC_CLIENT_ID}/`
  return PCF_ENV.test(hostname) ? `/${RC_CLIENT_ID}/` : `/${extension}`
}

const defaultConfig: ConfigType = {
  API_HOST: 'CF_API_HOST',
  DSEC_APIGEE_CLIENT_ID: clientInfo.dsecApigeeClientId[APP_ENV],
  PERSIST_APIGEE_CLIENT_ID: clientInfo.persistApigeeClientId[APP_ENV],
  SETPAY_APIGEE_CLIENT_ID: clientInfo.setpayApigeeClientId[APP_ENV],
  IVA_DOMAIN: 'CF_IVA_DOMAIN',
  BASENAME: publicUrlExtension(isIdpRoute ? 'accounts/login' : 'login'),
  REDIRECT_URL: `https://${hostname}${publicUrlExtension(
    'accounts/login/'
  )}callback`,
  RC_CLIENT_ID,
  NODE_ENV,
  TARGET_ORIGIN: `https://${hostname}`,
  ASSET_ORIGIN: 'CF_ASSET_ORIGIN',
  AUTH_CLIENT_ID: 'CF_AUTH_CLIENT_ID',
  AUTH_BASE_URL: 'CF_AUTH_BASE_URL',
  DOWNTIME_URL: `https://${hostname}/DowntimePay/accountlookup?lob=rc&clientId=${RC_CLIENT_ID}`,
  VISTA_DOMAIN: 'CF_VISTA_DOMAIN'
}

export const localConfig: ConfigType = {
  API_HOST: REACT_APP_API_HOST,
  DSEC_APIGEE_CLIENT_ID: clientInfo.dsecApigeeClientId.development,
  PERSIST_APIGEE_CLIENT_ID: clientInfo.persistApigeeClientId.development,
  SETPAY_APIGEE_CLIENT_ID: clientInfo.setpayApigeeClientId.development,
  IVA_DOMAIN: REACT_APP_IVA_DOMAIN,
  BASENAME: `${isIdpRoute ? '/accounts' : ''}/login/${RC_CLIENT_ID}/`,
  REDIRECT_URL: `https://qa.synchronycredit.com/accounts/login/${RC_CLIENT_ID}/callback`,
  RC_CLIENT_ID,
  NODE_ENV,
  TARGET_ORIGIN: 'https://qa.synchronycredit.com',
  ASSET_ORIGIN: REACT_APP_ASSET_ORIGIN,
  AUTH_CLIENT_ID: REACT_APP_AUTH_CLIENT_ID,
  AUTH_BASE_URL: REACT_APP_AUTH_BASE_URL,
  DOWNTIME_URL: `https://qa.synchronycredit.com/DowntimePay/accountlookup?lob=rc&clientId=${RC_CLIENT_ID}`,
  VISTA_DOMAIN: 'https://dev.synchronycredit.com'
}

export default NODE_ENV === 'production' ? defaultConfig : localConfig
