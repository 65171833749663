import { VerificationEndpoint } from 'services/typings'

export default {
  dsecurity: {
    AUTHENTICATE: (endpoint: VerificationEndpoint): string =>
      `/v1/retailcard/identity/${endpoint}/authenticate`,
    CREATE_PROFILE: '/v1/retailcard/identity/registration/profile/create',
    ELIGIBILITY_CHECK: (endpoint: VerificationEndpoint): string =>
      `/v1/retailcard/identity/${endpoint}/eligibility/check`,
    LEGACY_OTP: '/v1/retailcard/identity/login/otp/initiate',
    CREATE_PIN: '/v1/retailcard/identity/login/pin/create',
    RESET: '/v1/retailcard/identity/reset-password/update',
    USER_ID_EXISTS: '/v1/retailcard/identity/userid/exists',
    USER_NAME: '/v1/retailcard/identity/info/user-name',
    VERIFY: (endpoint: VerificationEndpoint): string =>
      `/v1/retailcard/identity/${endpoint}/verify`,
    VALIDATE_LEGACY_OTP: '/v1/retailcard/identity/login/otp/validate',
    HANDOVER: '/v1/retailcard/identity/auth/session/handover',
    LOST_STOLEN: 'lost-stolen',
    TOKEN_EXCHANGE: '/v1/oauth2/token',
    PAY_AS_GUEST: '/quickpay/v3/load',
    PAY_WITHOUT_LOGIN: '/quickpay/v2/load',
    MULTI_ACCOUNT: '/v1/credit/accounts/multi-account/accounts',
    OTP_INITIATE: (endpoint: VerificationEndpoint): string =>
      `/v1/retailcard/identity/${endpoint}/otp/initiate`,
    OTP_VALIDATE: (endpoint: VerificationEndpoint): string =>
      `/v1/retailcard/identity/${endpoint}/otp/validate`
  },
  cpl: {
    ACCOUNT_LINKING: 'account-linking',
    PERSISTENT_AUTHENTICATE:
      '/v1/retailcard/identity/login/persistent/authenticate',
    PERSISTENT_LOGIN: 'persistent-login',
    PERSISTENT_OTP: '/v1/retailcard/identity/login/persistent/otp/initiate',
    VALIDATE_PERSISTENT_OTP:
      '/v1/retailcard/identity/login/persistent/otp/validate'
  },
  idp: {
    IDP_OTP: '/v1/identity/otp-initiate',
    IDP_OTP_FLOW: '/v1/identity/otp-validate',
    IDP_AUTHENTICATE: '/v1/identity/authenticate',
    IDENTITY_COMPLETE: '/v1/identity/complete',
    MFA_METHODS: '/v1/identity/mfa-methods',
    IDP_OTP_SOFTENER: '/v1/identity/otp-softener',
    IDP_PROFILE_CREATE: '/v1/identity/profile',
    AUTHORIZE: '/v3/oauth2/authorize',
    USER_ID_EXISTS: '/v1/identity/profile'
  },
  config: {
    AKAMAI_CONFIG: '/serviceFiles/json/digital-security-config.json',
    DOWNTIME_CONFIG: '/serviceFiles/json/downtime.json',
    PSF_MESSAGE: '/assets/psf/messaging.json'
  },
  jwt: {
    INTROSPECT: '/v3/oauth2/introspect'
  },
  utility: {
    UTILITY_ACCOUNT_FEATURES: '/v1/utility/account-management/product/features'
  }
}
