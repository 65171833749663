enum VerificationEndpoint {
  LOGIN = 'login',
  LOOKUP_USER_ID = 'lookup-userid',
  REGISTRATION = 'registration',
  RESET_PASSWORD = 'reset-password',
  ACCOUNT_LINKING = 'account-linking',
  PERSISTENT_LOGIN = 'persistent-login'
}

export default VerificationEndpoint
