import { AkamaiAction, AkamaiState } from 'redux/akamaiConfig/typings'

export const initialState: AkamaiState = {
  showIdpBanner: false,
  disableExpReg: [],
  maintenanceData: {
    maintenanceStartTime: '',
    maintenanceEndTime: '',
    isUnderMaintenance: false,
    isDowntimePay: false,
    downTimeMessage: ''
  },
  hasJwtToken: true,
  isIdpRegistrationFlow: false
}

export default function (
  state: AkamaiState = initialState,
  action: AkamaiAction = undefined
): AkamaiState {
  switch (action.type) {
    case 'UPDATE_SHOW_IDP_BANNER':
      return { ...state, showIdpBanner: action.payload }
    case 'UPDATE_AKAMAI_CONFIG':
      return {
        ...state,
        ...action.payload
      }
    case 'UPDATE_AKAMAI_DOWNTIME_CONFIG':
      return {
        ...state,
        maintenanceData: action.payload
      }
    case 'UPDATE_SURFLY_FLAG':
      return {
        ...state,
        coBrowse: {
          isSurflyScriptTagEnabled: action.payload
        }
      }
    default:
      return state
  }
}
