import React, { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Inset from 'syf-component-library/ui/spacing/Inset/Inset'
import { RootState } from 'redux/store'
import { resetOtpState } from 'redux/otp/actions'
import { clearSSNState } from 'redux/ssn/actions'
import { clearCvvState } from 'redux/cvv/actions'
import { MfaValidationType } from 'const/mfa'
import { VerificationEndpoint } from 'services/typings'
import AuthDataTypes from 'types/authDataTypes'
import OTP from './OTP'
import CVV from './CVV'
import SSN from './SSN'
import { ModalContainer } from '../subcomponents'
import { MfaError, VerificationType, Verified } from '../typings'

export interface NeedVerificationModalProps {
  endpoint: VerificationEndpoint
  authData: AuthDataTypes
  handleError: (props: MfaError) => void
  handleVerified?: (props: Verified) => void
  handleCancel: () => void
  type?: 'voiceAuthentication' | ''
  verificationType: VerificationType
  showModal?: boolean
  returnTo?: string
  queryParams?: string
  containerId: string
}

const NeedsVerificationModal = ({
  verificationType,
  showModal,
  handleCancel,
  containerId,
  ...props
}: NeedVerificationModalProps): ReactElement => {
  const {
    uiUtils: { isModalOpen }
  } = useSelector((state: RootState) => state)
  const dispatch = useDispatch()
  const verificationMethodProps = { ...props, handleCancel }
  const verificationMethods = {
    otp: OTP,
    ssn: SSN,
    cvv: CVV
  }

  const clearState = () => {
    switch (verificationType) {
      case MfaValidationType.OTP:
        dispatch(resetOtpState())
        break
      case MfaValidationType.SSN:
        dispatch(clearSSNState())
        break
      case MfaValidationType.CVV:
        dispatch(clearCvvState())
        break
      default:
        break
    }
  }

  const VerificationMethod =
    verificationMethods[verificationType as keyof typeof verificationMethods]

  return verificationType ? (
    <ModalContainer
      id="verification-modal"
      aria-labelledby="verification-title"
      aria-describedby="verification-description"
      data-test="needs-verification-modal"
      isOpen={!!showModal || isModalOpen}
      escapeModalHandler={() => {
        clearState()
        handleCancel()
      }}
      containerId={containerId}
    >
      <Inset all="small">
        <VerificationMethod
          {...verificationMethodProps}
          data-test="verification-method"
        />
      </Inset>
    </ModalContainer>
  ) : null
}

export default NeedsVerificationModal
