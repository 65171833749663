import { translate } from 'helpers'
import { CommunicationPreference } from 'redux/idp/typings'

export const OTPMethods = [
  {
    label: translate({ string: 'sms' }),
    value: 'Text' as CommunicationPreference
  },
  {
    label: translate({ string: 'call' }),
    value: 'Call' as CommunicationPreference
  }
]

const initialValues = {
  phoneNumber: '',
  selectedPhoneNumber: '',
  selectedCommunication: OTPMethods[0].value
}

const initialErrors = {
  phoneNumberError: '',
  deliveryMethodError: false,
  deliveryAddressError: false
}

export const phoneConfirmState = { initialValues, initialErrors }
