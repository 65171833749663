import React, { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RootState } from 'redux/store'
import Header from 'ui/atoms/Header'
import InlineError from 'ui/atoms/InlineError/NonIdP'
import { MfaValidationType } from 'const/mfa'
import translate from 'helpers/translate'
import validate from 'helpers/validate'
import { FA_EXCLAMATION_CIRCLE, FA_LOCK } from 'const/iconProp'
import AuthDataTypes from 'types/authDataTypes'
import { VerificationEndpoint } from 'services/typings'
import { Verified } from 'ui/organisms/NeedsVerificationModal/typings'
import {
  handleNonIdpReset,
  handleNonIdpVerify,
  handleValidate,
  updateFormSubmissionError,
  updateSSN,
  updateValidationError
} from 'redux/ssn/actions'
import ModalFooter from 'ui/organisms/NeedsVerificationModal/NonIdP/ModalFooter'
import { Form, TextfieldWithMargin } from './subcomponents'

interface SSNProps {
  authData?: AuthDataTypes
  handleVerified?: (data: Verified) => void
  handleCancel?: () => void
  endpoint?: VerificationEndpoint
  returnTo?: string
  queryParams?: string
}

const SSN = ({
  authData,
  endpoint,
  returnTo,
  queryParams,
  handleVerified,
  handleCancel
}: SSNProps): ReactElement => {
  const { ssn, formSubmissionError, validationError } = useSelector(
    (state: RootState) => ({
      ssn: state.ssn.code,
      isSubmitting: state.uiUtils.isSubmitting,
      formSubmissionError: state.ssn.formSubmissionError,
      validationError: state.ssn.validationError
    })
  )
  const dispatch = useDispatch()

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault()
    const error = validate(MfaValidationType.SSN, ssn)
    if (error?.length) {
      dispatch(updateValidationError(error))
    } else {
      dispatch(
        handleNonIdpVerify(
          authData,
          endpoint,
          handleVerified,
          returnTo,
          queryParams
        )
      )
    }
  }

  return (
    <>
      <Header
        data-test="subHeader"
        title={translate({ string: 'forYourSecurity' })}
        description={translate({ string: 'pleaseEnterTheInfo' })}
        labelledby="verification-title"
        describedby="verification-description"
      />

      <InlineError centerAlign error={formSubmissionError} />
      <Form data-reason="form" onSubmit={handleSubmit} noValidate>
        <TextfieldWithMargin
          id="ssn"
          name="ssn"
          data-reason="ssn-field"
          type="password"
          value={ssn}
          inputMode="numeric"
          className="masked"
          helpText={translate({ string: 'pwdHelpText' })}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            dispatch(updateSSN(event.target.value))
          }
          onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(handleValidate(event.target.value))
          }}
          onFocus={() => dispatch(updateFormSubmissionError(null))}
          maxLength={4}
          placeholder={translate({ string: 'last4DigitSSN' })}
          error={validationError}
          leftIcon={<FontAwesomeIcon icon={FA_LOCK} />}
          errorIcon={<FontAwesomeIcon icon={FA_EXCLAMATION_CIRCLE} />}
          width="275px"
          data-private
          required
        />
        <ModalFooter
          dataType="ssn verify"
          handleCancel={() => {
            handleCancel()
            dispatch(handleNonIdpReset())
          }}
          submissionError={formSubmissionError?.locked}
        />
      </Form>
    </>
  )
}

export default SSN
