import React, { ReactElement, cloneElement } from 'react'
import { Helmet } from 'react-helmet-async'
import {
  determineCplRoute,
  getCurrentLanguageFromStorage,
  getMetaTags
} from 'helpers'
import { clientInfo } from 'configureBrand'
import { MetaTags, TagInfo } from 'helpers/getMetaTags'
import config from 'const/config'
import { BackgroundWrapper, Container, Inner } from './subcomponents'
import { TemplateProps } from './typings'
import CoBrowse from './CoBrowse'

const Template = ({
  shouldLoadAllScripts,
  children,
  innerProps,
  innerCss,
  renderBackground,
  ...rest
}: TemplateProps): ReactElement => {
  const pathnames = window.location.pathname.split('/').filter(v => v)
  const path = pathnames[pathnames.length - 1]
  const language = getCurrentLanguageFromStorage()
  const metaTags: MetaTags = getMetaTags()
  const tagsBasedOnPath: TagInfo =
    metaTags[path as keyof MetaTags] || metaTags.login
  const isCplRoute = determineCplRoute()

  const determineScriptsToLoad = (): Array<ReactElement> => {
    const scripts = [
      !!clientInfo?.ivaKey && (
        <script
          src={`${config.IVA_DOMAIN}/Alme/nextit-script-manager.js`}
          id="nit-alme-main"
          async
        />
      ),
      <script type="text/javascript" src="js/qualtrics.js" />
    ]
    return scripts.filter(script => script)
  }

  return (
    <Container {...rest}>
      <Helmet data-test="template-helmet">
        <title>{tagsBasedOnPath[language].title}</title>
        <meta name="title" content={tagsBasedOnPath[language].title} />
        <meta
          name="description"
          content={tagsBasedOnPath[language].description}
        />
        {shouldLoadAllScripts &&
          determineScriptsToLoad().map((element, index) => {
            return cloneElement(element, {
              /* eslint-disable-next-line react/no-array-index-key */
              key: `script-${index}`
            })
          })}
      </Helmet>
      <CoBrowse />
      <BackgroundWrapper
        renderBackground={renderBackground}
        isCplRoute={isCplRoute}
      >
        <Inner css={innerCss} {...innerProps} isCplRoute={isCplRoute}>
          {children}
        </Inner>
      </BackgroundWrapper>
    </Container>
  )
}

export default Template
