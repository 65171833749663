import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { colors } from 'theme'
import Textfield from 'syf-component-library/ui/atoms/Textfield'
import query from 'syf-component-library/const/mediaQueries'
import Small from 'syf-component-library/ui/typography/Small'
import Body from 'syf-component-library/ui/typography/Body'
import translate from 'helpers/translate'
import { TextButton } from 'ui/atoms/Button'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import {
  resetIdpError,
  updateCurrentOtpStep,
  updateLastFourOfSelectedPhoneNumber,
  updatePostOtpInitiateResponse,
  updateShowGetNewCode
} from 'redux/idp/actions'
import Inline from 'syf-component-library/ui/spacing/Inline'
import Inset from 'syf-component-library/ui/spacing/Inset'

export const HelpText = styled(Small)`
  margin: 0 auto;
  color: ${colors.grey};
  max-width: 500px;
  @media ${query.lessThanMedium} {
    padding: 0px 10px;
  }
`
export const RememberDeviceHelpText = styled.div`
  font-size: 11px;
  padding: 0;
  margin-bottom: 0 !important;
  height: auto;
  color: ${colors.grey};
  max-width: 500px;
  text-align: left;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 16px;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`

export const RadioContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media ${query.lessThanMedium} {
    flex-direction: column;
    align-items: center;
  }
`

export const GroupTitle = styled.div`
  margin-bottom: 11px;
`

export const RadioWrapper = styled.div`
  flex-direction: column;
  width: 250px;
  min-width: 49%;
`

export const TextfieldWithMargin = styled(Textfield)`
  margin-top: 15px;
  margin-bottom: 15px;
  min-width: 100%;
`

export const ContactNumber = styled.a`
  color: ${colors.darkBlue};
  font-weight: bold;
  text-decoration: none;
`

const DidNotReceiveCodeContainer = styled(Inset)`
  font-size: 14px;
  color: ${colors.grey};
  text-align: center;
  display: block;
  max-width: 300px;
`

export const SaveDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  margin-top: 12px;
  margin-bottom: 20px;
  @media ${query.lessThanMedium} {
    margin: 0 auto;
    line-height: 1.5;
    padding-bottom: 10px;
  }
`

export const Label = styled(Body)`
  margin: 0;
  padding-right: 20px;
  @media ${query.lessThanMedium} {
    padding-bottom: 5px;
    padding-top: 5px;
  }
`

export const VerifyForm = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  maxwidth: 400px;
  margin: 0 auto;
`

export const SorryDescription = styled.span`
  display: block;
  max-width: 400px;
  padding: 0 16px;
`

export const radioStyles = {
  height: '32px'
}

export const GetNewCodeWrapper = (): ReactElement => {
  const { showGetNewCode } = useSelector((state: RootState) => state.idp)
  const dispatch = useDispatch()

  const handleSendNewCode = () => {
    dispatch(resetIdpError())
    dispatch(updateCurrentOtpStep('confirm'))
    dispatch(updateLastFourOfSelectedPhoneNumber(''))
    dispatch(updatePostOtpInitiateResponse(null))
    dispatch(updateShowGetNewCode(false))
  }

  return showGetNewCode ? (
    <DidNotReceiveCodeContainer all="base">
      {translate({ string: 'didNotReceiveCode' })}
      <Inline size="small" />
      <TextButton
        buttonType="text"
        onClick={handleSendNewCode}
        className="ensightencustomevent"
        data-reason="didn’t receive code"
        data-type="otp"
        data-object="link"
        data-test="send-new-code"
      >
        {translate({ string: 'getANewOne' })}
      </TextButton>
    </DidNotReceiveCodeContainer>
  ) : null
}
