import api, { generateTransId } from 'services'
import { clientInfo } from 'configureBrand'
import config from 'const/config'
import { addBmTelemetryToHeaders, getCorrelationId, translate } from 'helpers'
import apiEndpoints from 'const/apiEndpoints'
import { UpdatePasswordRequest, UpdatePasswordResponse } from 'services/typings'

const updatePassword = async (options: {
  password: string
  sessionId: string
  accessToken: string
}): Promise<UpdatePasswordResponse> => {
  const { password, sessionId, accessToken } = options

  const payload: UpdatePasswordRequest = {
    'cipher.new_password': password,
    session_info: {
      session_id: sessionId
    }
  }

  const reqHeaders = await addBmTelemetryToHeaders({
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'X-SYF-Request-TrackingId': generateTransId(),
      'X-SYF-Request-CorrelationId': getCorrelationId()
    },
    params: {
      client_id: config.DSEC_APIGEE_CLIENT_ID
    }
  })

  const { data } = await api.post(
    apiEndpoints.dsecurity.RESET,
    payload,
    reqHeaders
  )

  switch (data.status.response_code) {
    case '6001':
      return {
        success: true,
        accountType: data.account_type,
        clientId: data.client_id
      }
    case '6002':
      return {
        message: {
          key: 'lockedStandard',
          argument:
            clientInfo.phoneNumbers[
              data.account_type as keyof typeof clientInfo.phoneNumbers
            ],
          extension: `${translate({ string: 'code' })}: 6002`
        }
      }
    case '6003':
      return {
        message: {
          key: 'cannotReuse',
          extension: `${translate({ string: 'code' })}: 6003`
        }
      }
    default:
      return {
        message: { key: 'default' }
      }
  }
}

export default updatePassword
