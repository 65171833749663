import React, { ReactElement } from 'react'
import Link from 'syf-component-library/ui/atoms/Link'
import { formatAccountLengthsIntoString } from 'helpers/fieldFormatters'
import routes from 'const/routes'
import { AnyObject } from 'types/custom'

export default {
  billingInUSTitle:
    'Esto es solo si la dirección de facturación de la cuenta no está en los Estados Unidos.',
  billingInUSText: 'La dirección de la cuenta está en los Estados Unidos.',
  invalidDob: 'Por favor ingrese una Fecha de Nacimiento válida. MM/DD/YYYY',
  dateOfBirth: 'Fecha de Nacimiento',
  dobHelptext: 'Ingresar como MM/DD/YYYY',
  billingNotUs:
    '¿La dirección de facturación de la cuenta no está en los Estados Unidos?',
  sendCodeAs: 'Enviar código a:',
  sendCodeTo: 'Enviar código como:',
  sms: 'SMS/Mensaje de Texto',
  call: 'Llamada/Correo de voz',
  updateStatementPreference:
    'Inscribirse para recibir estados de cuenta en línea',
  registrationComplete: 'Registro Completo',
  whenYouChooseContinue:
    'Por razones de seguridad, cuando seleccione "Continuar", le enviaremos una contraseña numérica de acceso único por teléfono o texto. Ingrese el código en la próxima pantalla. Pueden aplicarse las tarifas estándar de mensajes de texto y de teléfono.',
  yes: 'Sí',
  no: 'No',
  saveComputer: '¿Desea guardar la información de esta computadora?',
  getANewOne: 'Obtenga uno nuevo',
  didNotReceiveCode: '¿No recibió su código?',
  rememberDevice: (
    <span>
      Podemos guardar la información de esta computadora para que no tenga que
      ingresar estos códigos en el futuro.{' '}
      <b>No guarde la información de esta computadora si no le pertenece.</b>
    </span>
  ),
  recentlyUpdatedUserId: (
    <span>
      It appears you recently updated your User ID. Please use your new User ID,
      or you can{' '}
      <Link isInline to={routes.FIND_USER_ID}>
        Find your User ID
      </Link>{' '}
      if you have forgotten.
    </span>
  ),
  missingOld: 'Missing old account number',
  sending: 'Enviando',
  logInToYourAccount: 'Iniciar Sesión en Su Cuenta',
  accessYourAccount: 'Acceder a tu cuenta',
  userId: 'Usuario',
  userIdHelpText: (vanityUrl: string): string =>
    `Distingue mayúsculas y minúsculas, puede diferir de su ID de usuario de ${vanityUrl}`,
  password: 'Contraseña',
  emailAddress: 'Dirección de Correo Electrónico',
  show: 'Mostrar',
  hide: 'Ocultar',
  readyToGo: '¡Listo para ir!',
  rememberUserId: 'Recordar Identificación de Usuario (Opcional)',
  findUserId: 'Buscar ID de Usuario',
  findYourUserId: 'Encontrar su Identificación de Usuario',
  resetYourPassword: 'Restablecer Su Contraseña',
  resetPwd: 'Restablecer Contraseña',
  subHeadDescription:
    'Por favor use la información del titular de la cuenta primario.',
  yourUserId: 'Su Identificación de Usuario',
  yourUserIdIs: 'Su Identificación de Usuario es',
  thisIsTheUserId:
    'Esta es la identificación de usuario para el titular de la cuenta primario.',
  loginToYourAccount: 'Iniciar Sesión en Su Cuenta',
  lostStolenSubtitle:
    '¡Una gran noticia! Sus preferencias se han transferido a su nueva cuenta.',
  lostStolenDescription: (oldCard: string): string =>
    `Hemos reemplazado su tarjeta anterior que termina en ${oldCard}. Estos serán los mismos en su nueva tarjeta.`,
  lostStolenSoonToReceive: (newCard: string): string =>
    `Si no ha recibido su nueva tarjeta que termina en ${newCard}, debería llegar pronto.`,
  autopay: 'Pago automático',
  scheduledPayments: 'Pagos programados',
  enterUserID: 'Ingresar Identificación de Usuario',
  selectPassword: 'Seleccionar Contraseña',
  login: 'Iniciar Sesión',
  enterAccountNumber: 'Ingrese el Número de Cuenta',
  zipCode: 'Código postal',
  continue: 'Continuar',
  searching: 'Buscando',
  iForgotMy: 'Olvidé mi ID de',
  or: 'o',
  iWantTo: 'Quiero',
  register: 'Registrarme',
  apply: 'Solicitar',
  notYou: '¿No tú?',
  inAHurry: (userId: string): string => `Hola ${userId}. ¿Apurado?`,
  weAreSorry: 'Lo sentimos',
  somethingWentWrong: 'Uy, algo no está bien',
  letsTryThatAgain: 'Volvamos a intentarlo',
  postRegistrationError:
    'Se ha registrado exitosamente, pero no pudimos conectarlo con su cuenta. Vaya a la página de inicio de sesión y vuelva a intentarlo con las credenciales que acaba de crear.',
  oops: 'Oops...',
  tryAgainLater:
    'Lamentablemente, no podemos procesar su solicitud en este momento. Vuelva a intentarlo más tarde',
  corruptedTokenErrorTitle: `Volvamos a intentarlo.`,
  corruptedTokenErrorDescription:
    'La página que busca no está disponible. Lamentamos la inconveniencia. Use el botón que está a continuación para regresar a la página de inicio.',
  errorOccurred: 'Se produjo un error al procesar su solicitud',
  tryAgainButton: 'Intentar otra vez',
  somethingWentWrongContent: (clientData: AnyObject): string => {
    switch (clientData.error) {
      case 400:
      case 401:
      case 403:
      case 405:
      case 415:
      case 404:
        return `La página que usted está intentando acceder no está disponible. Discúlpenos por el inconveniente. Por favor, use el botón que aparece a continuación para regresar a la página web o llame a Servicio al Cliente al ${clientData.number}. Código de Error: ${clientData.error}`
      default:
        return 'La página que usted está tratando de encontrar no está disponible. Lamentamos el inconveniente. Por favor, use el botón de abajo para regresar a la página principal.'
    }
  },
  secureLogin: 'Inicio de Sesión Seguro',
  cancel: 'Cancelar',
  loggingIn: 'Iniciar sesión...',
  creatingKey: 'Creando clave...',
  goToLogin: 'Vaya a Ingresar',
  payAsGuestButton: 'Pague como invitado',
  payAsGuestHeader: '¿Solo quiere hacer un pago?',
  payAsGuestSubHeader:
    'Evite iniciar sesión o el registro y pague como invitado.',
  payWithoutLogIn: 'Pagar sin iniciar sesión',
  submit: 'Enviar',
  submitting: 'Sumisión...',
  loading: 'Cargando...',
  verifying: 'Verificando',
  verifyCode: 'Verificar el código',
  onlinePrivacyPolicy: 'Política de Privacidad en línea',
  privacyPolicy: 'Política de Privacidad',
  websiteUsageAgreement: 'Acuerdo de Uso en Línea',
  fraudProtection: 'Protección Contra Fraude',
  storeLocator: 'Localizador de tiendas',
  forYourSecurity: 'Para su seguridad...',
  continueRegistration: 'Continúe con su registro.',
  selectAllRequired: 'Por favor seleccione todos los campos requeridos',
  sendYouOneTimeCode: (
    <span>
      Para su seguridad, le enviaremos{' '}
      <b>una contraseña numérica de acceso único</b> para entrar en la siguiente
      pantalla.
    </span>
  ),
  confirmYourIdentity: 'Por favor confirme su identidad para continuar.',
  pleaseEnterTheInfo:
    'Por favor ingrese la siguiente información para continuar a su cuenta.',
  littleMoreInformation: (
    <div style={{ width: '355px' }}>
      <p>
        Solo necesitamos un poco más de información para completar su registro.
      </p>
      <p>Ingrese la siguiente información para continuar</p>
    </div>
  ),
  pwdHelpText:
    'Solicitamos esto para proteger y prevenir su cuenta de acceso no autorizado a su información.',
  last4DigitSSN: 'Últimos 4-Dígitos del Número de Seguro Social',
  fourDigitCode: '4-dígitos del código',
  threeDigitCode: '3-dígitos del código de seguridad',
  linkExpiredToast:
    'Esta función de registro no está disponible. Para continuar con el registro de su cuenta, necesitará el número de cuenta que aparece en su tarjeta.',
  registerForOnlineAccess: 'Registrarse para Acceso en Línea',
  enterYourAccountNumberZipCode:
    'Ingrese su número de cuenta y código postal de facturación',
  registerOnlineDescription:
    'Recuerde sus entradas para ingresar en su cuenta más tarde.',
  alertsNotifications:
    'Las alertas, las notificaciones, los mensajes importantes, etc. se le enviarán a esta dirección',
  sendMeEmailOffers: 'Enviarme ofertas por correo electrónico (Opcional)',
  bySelecting: (clientName: string): string =>
    `Al seleccionar esto, usted autoriza a Synchrony Bank, el emisor de esta cuenta, a enviarle ofertas por correo electrónico y a proporcionarle su dirección de correo electrónico a ${clientName} para que pueda recibir ofertas de ${clientName} por correo electrónico.`,
  paperlessStatements: 'Estados de Cuenta Electrónicos',
  paperStatements: 'Estados de Cuenta Impresos',
  eStatementDisclaimer:
    'Si elige Estados de Cuenta Electrónicos, confirma su consentimiento a los ',
  inlineTermsAndConditions: 'términos y condiciones',
  eStatementDisclaimerContinue:
    'para comunicaciones electrónicas. Recibirá un mensaje de correo electrónico en el que se le notificará cuando su estado de cuenta electrónico esté disponible en línea. Para revisar su estado de cuenta electrónico inicie sesión en este sitio de servicio y haga clic en el vínculo "Estados de Cuenta". Ya no recibirá estados de cuenta impresos.',
  ePaperDisclaimer:
    'Si elige Estados de Cuenta Impresos, recibirá su estado de cuenta por Correo Postal de los EE. UU. y no se proporcionará ninguna notificación por correo electrónico.',
  termsAndConditions: 'Términos y Condiciones',
  alexaTermsTitle: 'Términos y Condiciones para uso de Amazon Alexa',
  googleHomeTermsTitle: 'Términos y Condiciones para uso de Google Home',
  readBeforeProceeding: 'Por favor lea antes de continuar.',
  importantDisclaimer:
    'Información importante sobre el consentimiento para comunicaciones electrónicas. Al suscribirse para obtener servicios en línea para su Cuenta, por la presente, usted acepta los términos y esto representará su consentimiento para que le enviemos comunicaciones electrónicas sobre su Cuenta.',
  disclaimer:
    'Al hacer clic en "Continuar", autoriza a su operador inalámbrico a revelar su número de móvil, nombre, dirección, correo electrónico, estado de la red, tipo de cliente, función del cliente, tipo de facturación, identificadores de dispositivos móviles (IMSI e IMEI) y otros suscriptores y dispositivos detalles, si están disponibles, para Synchrony y los proveedores de servicios durante la duración de la relación comercial, únicamente para verificar la identidad y evitar fraudes. Consulte la Política de privacidad de Synchrony para ver cómo tratamos sus datos. Si no desea continuar, haga clic en "Cancelar"',
  pwdLength: 'Entre 7 y 20 caracteres.',
  noWhiteSpace: 'No debe contener espacios.',
  hasNumber: 'Debe contener 1 número.',
  hasLetter: '	Debe contener 1 letra.',
  pwdSpecialChar: 'Puede contener caracteres especiales.',
  lastFour: 'No puede ser ninguna de las últimas 4 contraseñas.',
  minSixCharEmail:
    'Minimo de 6 caracteres, se permite la dirección, de correo electrónico, al menos una letra',
  notAnAcceptedLength: (acceptedLengths: number[]): string =>
    `El número de cuenta debe ser ${formatAccountLengthsIntoString(
      acceptedLengths,
      'o'
    )} digitos`,
  phoneNumberAcceptedLength: 'El número de teléfono debe tener 10 dígitos',
  phoneNumberNotVerified:
    'No se puede verificar el número de teléfono ingresado',
  wrongDeliveryMode:
    'Modo de entrega incorrecto para el número de teléfono fijo',
  maximumAttemptsReached:
    'Se alcanzó el máximo de intentos para la verificación del número de teléfono ingresado',
  minSixChar: 'Este campo requiere un mínimo de 6 caracteres.',
  minFourDigits: 'Este campo requiere un mínimo de 4 dígitos.',
  minFiveDigits: 'Este campo requiere un mínimo de 5 dígitos.',
  minSixteenDigits: 'El número de Cuenta debe tener 16 dígitos.',
  required: 'Este es un campo obligatorio.',
  invalidUID: 'Por favor ingrese una ID de Usuario válida.',
  invalidEmail: 'Por favor ingrese una dirección de correo electrónico válida.',
  digitsOnly: 'Por favor ingrese solo números entre 0 y 9.',
  unavailableUID:
    'La ID de Usuario no está disponible. Por favor intente con una ID de Usuario diferente.',
  default:
    'Nuestro sistema está experimentando dificultades técnicas en este momento. Por favor intente nuevamente más tarde.',
  newPassword:
    'Por favor siga las reglas que se indican a continuación para las contraseñas nuevas. Los siguientes caracteres especiales están permitidos: !#$*+-.:;=?@^_`|~',
  userIdSpecialChar:
    'Las ID de usuario no deben contener caracteres especiales. Por favor intente nuevamente.',
  cvvMinLength: 'Este campo requiere un mínimo de 3 dígitos.',
  otpField: 'Ingresar código',
  otpMinDigits: 'El código debe tener 4 dígitos.',
  lockedProblem: (phoneNumber: string): string =>
    `Debido a un problema con su cuenta, no podrá ingresar esta vez. Por favor comuníquese con Atención al Cliente al ${phoneNumber}`,
  lockedStandard: (phoneNumber: string): string =>
    `Su cuenta ha sido bloqueada. Por favor comuníquese con Atención al Cliente al ${phoneNumber}`,
  tryAgainUpToSix:
    'La información que ingresó no coincide con la nuestra. Por favor intente nuevamente, hasta seis veces.',
  tryAgain: (vanityUrl: string): string =>
    `La información que ingresó no coincide con la nuestra. Por favor intente nuevamente. Note: Esta es su identificación de usuario y contraseña para su cuenta de crédito Synchrony, no para ${vanityUrl}`,
  resetPassword: 'Para su seguridad, por favor reestablezca su contraseña.',
  oneAttemptRemaining:
    'La información que ingresó no coincide con la nuestra. Tienes 1 intento restante.',
  oneAttemptRemainingShort:
    'La información que ingresó no coincide con la nuestra. Le queda 1 intento restante.',
  invalidOTP:
    'La contraseña para uso de una sola vez que proporcionó es incorrecta. Por favor intente nuevamente.',
  cannotReuse:
    'Para su seguridad, no puede volver a usar una de sus últimas 4 contraseñas. Por favor ingrese una contraseña nueva.',
  accountAlreadyRegistered:
    'Este Número de Cuenta ya está registrado. Inicie sesión con sus credenciales.',
  misMatchLast4Digit: (
    <span>
      It looks like you’re logging into an account for an Amazon Store Card or
      Amazon Credit Builder that isn’t loaded into your Amazon Wallet. Please
      load that card into your Amazon Wallet and/or check your card details, and
      try again.
      <a
        href="https://www.amazon.com/gp/help/customer/display.html?nodeId=921842"
        target="_blank"
        className="ensightencustomevent"
        data-reason="learn more"
        data-type="login-not-permitted"
        data-object="link"
        rel="noopener noreferrer"
      >
        Learn more.
      </a>
    </span>
  ),
  accountNumberAndDateOfBirth:
    'Por favor ingrese su número de cuenta y fecha de nacimiento',
  cancelToCloseDialog: 'Cancelar para cerrar esta ventana emergente',
  verifyTheCode: 'Verifique el código que ingresó',
  closeThePopup: 'Cerrar ventana emergente de código enviada',
  close: 'Cerca',
  codeEnter:
    'Ingrese el código de acceso de 4 dígitos enviado a su dispositivo.',
  weApologize: 'Pedimos disculpas.',
  accountLockedError: (fraudNumber: string): string =>
    `Debido a un problema con su cuenta, no puede registrar su cuenta en línea en este momento. Comuníquese con el Servicio de atención al cliente al ${fraudNumber} para asistencia.`,
  sorryDescription:
    'No puede acceder a su cuenta desde esta computadora. si tienes alguna pregunta, por favor llama',
  yourCodeHasBeenSent: (lastFour: string): ReactElement => (
    <>
      El código se envió a su número de teléfono que termina en{' '}
      <b>{lastFour}</b>
    </>
  ),
  phoneNumberEnding: (number: string): string =>
    `Número de teléfono con terminación en ${number}`,
  thankYouMsg:
    'Ha registrado exitosamente su cuenta, pero no pudimos inscribirlo para recibir estados de cuenta en línea. Inicie sesión y navegue hasta la página Estados  de Cuenta para inscribirse.',
  registerOnline: '¡Su tarjeta ha sido registrada para el acceso en línea!',
  rememberUserIdPwd:
    'Por favor, recuerde su identificación de usuario y contraseña para referencia en el futuro.',
  goToAccountSummary: 'Vaya a Resumen de Cuenta',
  notFound: 'No se encontró',
  userLocked: (phoneNumber: string): string =>
    `Due to your account status or the number of days your account has been delinquent, you may not log in at this time. Please contact Customer Service at ${phoneNumber}`,
  linkingProblem: (phoneNumber: string): string =>
    `There was a problem linking your account. Please contact Customer Service at ${phoneNumber} for assistance`,
  findUserIdUnregisteredCard: (
    <span>
      La información que ingresó no coincide con la nuestra.{' '}
      <Link isInline to={routes.REGISTER}>
        Registrar la Tarjeta
      </Link>
      .
    </span>
  ),
  gapBrandAccountIssue: (phoneNumber: string): string =>
    `Lo sentimos. Hay un problema con tu cuenta. Llame al servicio de atención al cliente al ${phoneNumber}`,
  selectPhoneNumber: 'Seleccione o ingrese un número de teléfono',
  enterPhoneNumber: 'Ingrese el Número de Teléfono',
  phoneConfirmOneTimeLeft: `Lo sentimos, no pudimos confirmar su identidad con ese número de teléfono. Intente con un número diferente. Su cuenta se bloqueará si lo intenta una vez más y falla.`,
  unableToVerify: (phoneNumber: string): string =>
    `No hemos podido verificar su identidad. Use un número de teléfono diferente para intentarlo nuevamente o llame al ${phoneNumber}.`,
  unableToConfirm: (phoneNumber: string): string =>
    ` No se pudo confirmar su identidad. Para administrar su cuenta, llame al servicio de atención al cliente al ${phoneNumber}.`,
  landlineNumbeErrorMessage:
    'El número que usted eligió no puede recibir mensajes de SMS/Texto. Continúe con Llamada/Correo de voz en este número o use un número diferente.',
  identityConfiramation: 'No podemos confirmar su identidad',
  manageAccount:
    'Para administrar su cuenta, por favor llame al servicio de atención a clientes al',
  maintenanceAlert: (maintenanceAlertData: AnyObject): ReactElement => {
    const { formattedStartDate, formattedStartTime, formattedStopTime } =
      maintenanceAlertData
    return (
      <span>
        <b>Próximo Mantenimiento</b>
        <p>
          Nuestro sitio estará inactivo por mantenimiento el{' '}
          {formattedStartDate} de {formattedStartTime} a {formattedStopTime}.
          Nos disculpamos por cualquier inconveniente.
        </p>
      </span>
    )
  },
  code: 'Código',
  createaVoiceKey: 'Crear una Clave de Voz',
  createVoiceKeyDescription: (
    <span>
      <p>
        Para proteger su información, puede crear una Clave de Voz numérica de 4
        dígitos.
      </p>
      <p>
        Se le pedirá que diga esta Clave de Voz cuando acceda a información
        sensible mientras usa esta aplicación.
      </p>
    </span>
  ),
  createKey: 'Crear Clave',
  accept: 'Aceptar',
  voiceTitle: 'Lea y acepte los términos de uso y condiciones:',
  voiceTermsAndCondition:
    'Reconozco que al hacer clic en "Aceptar" yo acepto estos términos y condiciones.',
  logIntoYourAccount: 'Ingrese a su cuenta',
  findingYourAccount: 'Encontrar su cuenta...',
  securelyLocatingYourAccount: 'Estamos localizando su cuenta de forma segura.',
  noCardNumber: '¿No tienes número de tarjeta?',
  chatWithSydneyForHelp: 'Chatea con Sydney para obtener ayuda',
  lastFourNotMatch: 'Los últimos 4 dígitos no coinciden',
  userIdUnusable: 'ID de usuario inutilizable',
  beenAnError: 'Hubo un error.',
  loggingIntoYourAccount: 'Iniciar sesión en su cuenta',
  continueWithoutLinking: 'Continuar sin vincular',
  tryLinkingAccountAgain: 'Intente vincular la cuenta de nuevo',
  cplSystemErrorSubheader:
    'No pudimos vincular su cuenta en este momento. Pedimos disculpas por las molestias. Seleccione de los botones a continuación para saber cómo desea proceder.',
  persistSystemErrorSubheader:
    'No pudimos iniciar sesión automáticamente. Seleccione uno de los botones a continuación para saber cómo desea proceder.',
  continueToLogin: 'Continuar para iniciar sesión',
  goBack: 'Vaya espalda',
  idpBannerTitle: 'Presentamos a nuestro nuevo administrador de cuentas',
  idpBannerSubtitle: '¿Quiere un adelanto? Encienda la nueva experiencia hoy.',
  idpBannerToggleText: 'Gerente de cuentas',
  on: 'en',
  off: 'apagada',
  stillLooking: 'Still looking to make a payment?',
  accessibilityLinkText: 'Accesibilidad',
  accessibilityLinkPath: 'web-accessibility-statement-sp',
  fraudProtectionLinkPath: 'fraud-protection-sp',
  accountLockedTitle: 'Por seguridad, su cuenta ha sido bloqueada',
  accountLockedBody: (phoneNumber: string): string =>
    `Lo sentimos, su cuenta ha sido bloqueada después de muchos intentos. Llame al  ${phoneNumber}`,
  softenerValidationFailedTitle: `Necesitamos estar seguros de que es usted`,
  softenerValidationFailedBody: (phoneNumber: string): string =>
    `Nuestro sistema no pudo validar la información que usted ingresó. Por su seguridad, comuníquese con nuestro equipo de atención al cliente al ${phoneNumber}. Una vez que verifiquen su identidad, podrá reiniciar el proceso de inscripción.`,
  returnToSignIn: 'Volver a Iniciar Sesión',
  profileEnrollmentFailedTitle: 'Suscríbase para recibir alertas',
  profileEnrollmentFailedBody: `Su inscripción en línea se completó con éxito; pero necesitamos actualizar la configuración de su cuenta para la suscripción de alertas. Revise su configuración en nuestro sistema.`,
  sessionTimeOutTitle: 'Se agotó el tiempo de sesión por su seguridad',
  sessionTimeOutBody: `Para ayudar a proteger su cuenta, su sesión ha terminado.`,
  gotoSignIn: 'Vaya a Iniciar Sesión',
  previousPage: 'Página Anterior',
  resetConfirmationTitle: 'Su contraseña se actualizó correctamente',
  resetConfirmationBody:
    'Por favor, vaya a la página de inicio de sesión y utilice la contraseña que acaba de crear.',
  accountAlreadyRegisteredTitle: 'Este Número de Cuenta ya está registrado',
  accountAlreadyRegisteredBody:
    'Por favor, use el botón de abajo para regresar a la página principal.',
  requirementMet: 'Requisito cumplido',
  requirementNotMet: 'Requisito no cumplido'
}
