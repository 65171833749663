import getParameterByName from 'helpers/getParameterByName'
import { FlowId } from 'const/idp'
import { IdpAction, IdpState } from './typings'

export const initialState: IdpState = {
  idpValidationModal: '',
  idpErrorCode: '',
  postAuthResponseCode: null,
  getMfaOptionsResponseCode: null,
  postOtpInitiateResponseCode: null,
  postOtpValidateResponseCode: null,
  postOtpSoftenerResponseCode: null,
  mfaPhoneNumbers: [],
  lastFourOfSelectedPhoneNumber: '',
  currentOtpStep: 'confirm',
  showGetNewCode: true,
  currentLastFour: '',
  previousLastFour: '',
  isPhoneConfirmMax: false,
  href: '',
  isIdpFlow: !!getParameterByName('token'),
  rel: '',
  name: '',
  flowId: FlowId.LOGIN,
  isAccountLocked: false,
  isAccountAlreadyRegistered: false,
  completeResponse: null,
  profileCreateResponse: null
}

export default function (
  state: IdpState = initialState,
  action: IdpAction = undefined
): IdpState {
  switch (action.type) {
    case 'UPDATE_IDP_AUTH_RESPONSE':
      return {
        ...state,
        postAuthResponseCode: action.payload
      }
    case 'UPDATE_MFA_PHONE_NUMBERS':
      return { ...state, mfaPhoneNumbers: action.payload }
    case 'UPDATE_CURRENT_OTP_STEP':
      return { ...state, currentOtpStep: action.payload }
    case 'UPDATE_POST_OTP_INITIATE_RESPONSE':
      return { ...state, postOtpInitiateResponseCode: action.payload }
    case 'UPDATE_POST_OTP_VALIDATE_RESPONSE':
      return { ...state, postOtpValidateResponseCode: action.payload }
    case 'UPDATE_POST_OTP_SOFTENER_RESPONSE':
      return { ...state, postOtpSoftenerResponseCode: action.payload }
    case 'UPDATE_GET_MFA_OPTIONS_RESPONSE':
      return { ...state, getMfaOptionsResponseCode: action.payload }
    case 'UPDATE_LAST_FOUR_OF_SELECTED_PHONE_NUMBER':
      return { ...state, lastFourOfSelectedPhoneNumber: action.payload }
    case 'UPDATE_IDP_ERROR_CODE':
      return { ...state, idpErrorCode: action.payload }
    case 'UPDATE_IS_PHONE_CONFIRM_MAX':
      return { ...state, isPhoneConfirmMax: action.payload }
    case 'UPDATE_IS_ACCOUNT_ALREADY_REGISTERED':
      return { ...state, isAccountAlreadyRegistered: action.payload }
    case 'CLEAR_IDP_ERROR_MESSAGE':
      return {
        ...state,
        idpErrorCode: '',
        isAccountLocked: false,
        isAccountAlreadyRegistered: false
      }
    case 'CLEAR_IDP_STATE':
      return { ...initialState, isIdpFlow: state.isIdpFlow }
    case 'UPDATE_SHOW_GET_NEW_CODE':
      return { ...state, showGetNewCode: action.payload }
    case 'UPDATE_LOST_STOLEN_INFO': {
      const { currentLastFour, previousLastFour, href } = action.payload
      return { ...state, currentLastFour, previousLastFour, href }
    }
    case 'UPDATE_IS_IDP_FLOW': {
      return {
        ...state,
        isIdpFlow: action.payload
      }
    }
    case 'UPDATE_VALIDATION_MODAL':
      return { ...state, idpValidationModal: action.payload }
    case 'UPDATE_FLOW_ID': {
      return { ...state, flowId: action.payload }
    }
    case 'UPDATE_IS_ACCOUNT_LOCKED':
      return { ...state, isAccountLocked: action.payload }
    case 'UPDATE_IDP_COMPLETE_RESPONSE':
      return { ...state, completeResponse: action.payload }
    case 'PROFILE_CREATE_SUCCESS':
      return { ...state, profileCreateResponse: action.payload }
    case 'SET_ACCESS_TOKEN':
      return { ...state, accessToken: action.payload }
    default:
      return state
  }
}
