import React, { ReactElement } from 'react'
import { HeaderTitle, HeaderWrapper, SubHeading } from './subcomponents'

interface HeaderTypes {
  title?: string
  description?: string | ReactElement
  labelledby?: string
  describedby?: string
}

const Header = (props: HeaderTypes): ReactElement => {
  const {
    title = '',
    description = '',
    labelledby = null,
    describedby = null
  } = props

  return (
    <HeaderWrapper>
      {title && (
        <HeaderTitle as="h1" weight="bold" aria-labelledby={labelledby}>
          {title}
        </HeaderTitle>
      )}

      {description && (
        <SubHeading as="h2" aria-describedby={describedby}>
          {description}
        </SubHeading>
      )}
    </HeaderWrapper>
  )
}

export default Header
