import { MfaValidationType } from 'const/mfa'
import { SubmissionError } from 'types/errorTypes'
import { ThunkDispatch } from 'redux-thunk'
import { clearIdpState, postIdpOtpSoftener } from 'redux/idp/actions'
import { IdpAction } from 'redux/idp/typings'
import { RootState } from 'redux/store'
import { updateIsModalOpen, updateIsSubmitting } from 'redux/uiUtils/actions'
import { UiAction } from 'redux/uiUtils/typings'
import verify from 'services/nonIdP/verify'
import { codeVerifier } from 'services/nonIdP/searchUser'
import generateToken from 'services/nonIdP/generateToken'
import SSN from 'const/analytics/ssn'
import {
  handleRedirectRoute,
  history,
  log,
  triggerAnalyticsEvent,
  validate
} from 'helpers'
import routes from 'const/routes'
import AuthDataTypes from 'types/authDataTypes'
import { VerificationEndpoint } from 'services/typings'
import { Verified } from 'ui/organisms/NeedsVerificationModal/NonIdP/OTP/typings'
import { clientInfo } from 'configureBrand'
import { getPsfMessage } from 'redux/psfMessage/actions'
import { SSNAction } from './typings'

export type SSNThunkDispatch = ThunkDispatch<
  RootState,
  undefined,
  SSNAction | UiAction | IdpAction
>

export const updateValidationError = (validationError: string): SSNAction => {
  return { type: 'UPDATE_VALIDATION_ERROR', payload: validationError }
}

export const updateFormSubmissionError = (
  formSubmissionError: SubmissionError
): SSNAction => {
  return {
    type: 'UPDATE_FORM_SUBMISSION_ERROR',
    payload: formSubmissionError || null
  }
}

export const clearSSNState = (): SSNAction => {
  return { type: 'RESET_SSN_STATE' }
}

export const updateSSN = (ssn: string): SSNAction => {
  return { type: 'UPDATE_SSN', payload: ssn }
}

export const handleValidate =
  (code: string) =>
  (dispatch: SSNThunkDispatch): void => {
    const validationError = validate(MfaValidationType.SSN, code)
    dispatch(updateValidationError(validationError || ''))
  }

export const handleNonIdpReset =
  () =>
  (dispatch: SSNThunkDispatch): void => {
    dispatch(updateIsModalOpen(false))
    dispatch(clearSSNState())
  }

export const handleCancel =
  () =>
  (dispatch: SSNThunkDispatch): void => {
    dispatch(updateIsModalOpen(false))
    dispatch(clearIdpState())
    triggerAnalyticsEvent({ subFunction: '' })
    dispatch(clearSSNState())
  }

export const handleIdpVerify =
  () =>
  (dispatch: SSNThunkDispatch, getState: () => RootState): void => {
    const {
      ssn: { code },
      jwt: { idpSessionId }
    } = getState()

    const validationError = validate(MfaValidationType.SSN, code)

    if (!validationError) {
      dispatch(postIdpOtpSoftener({ idpSessionId, code }))
    } else {
      dispatch(updateValidationError(validationError || ''))
    }
  }

export const handleNonIdpVerify =
  (
    authData: AuthDataTypes,
    endpoint: VerificationEndpoint,
    handleVerified?: (data: Verified) => void,
    returnTo?: string,
    queryParams?: string
  ) =>
  async (
    dispatch: SSNThunkDispatch,
    getState: () => RootState
  ): Promise<void> => {
    const {
      ssn: { code }
    } = getState()
    try {
      dispatch(updateIsSubmitting(true))
      dispatch(updateFormSubmissionError(null))
      const { responseData, result } = await verify(
        {
          sessionId: authData.sessionId,
          validationCode: code
        },
        endpoint
      )
      const {
        authorization_code: authCode = '',
        ebill_status: ebillStatus,
        last_4_acct_no: newCard = '',
        prev_last_4_acct_no: oldCard = '',
        session_id: sessionId = '',
        client_id: clientId,
        user_id: userId = ''
      } = responseData || {}
      const cipherAccountId = responseData?.['cipher.accountId']

      if (result?.message) {
        triggerAnalyticsEvent({
          messageKey: result.message.key,
          pageName: SSN.NAME,
          pageFunction: SSN.PAGE_SUBFUNCTION
        })
        dispatch(updateFormSubmissionError(result.message))
        dispatch(updateIsSubmitting(false))
      } else if (endpoint === 'lookup-userid' && userId) {
        dispatch(handleNonIdpReset())
        history.push({
          pathname: routes.FOUND,
          state: {
            returnTo,
            queryParams,
            userId,
            zipCode: authData.zipCode
          }
        })
      } else {
        const accessToken = await generateToken({
          authCode,
          codeVerifier,
          clientId
        })

        if (accessToken && !ebillStatus && cipherAccountId) {
          await dispatch(
            getPsfMessage({
              accessToken,
              cipherAccountId
            })
          )
        }

        handleVerified({
          sessionId: sessionId || authData.sessionId,
          accessToken: accessToken || '',
          oldCard,
          newCard,
          result,
          userId,
          ebillStatus,
          cipherAccountId
        })
        dispatch(handleNonIdpReset())
      }
    } catch (error) {
      log.error(error)
      dispatch(handleNonIdpReset())
      triggerAnalyticsEvent({
        subFunction: SSN.PAGE_SUBFUNCTION,
        pageName: SSN.NAME,
        messageKey: SSN.MESSAGE_KEY
      })

      const { phoneNumbers } = clientInfo
      const { customerService } = phoneNumbers
      const redirectPath = handleRedirectRoute()
      history.push({
        pathname: routes.ERROR,
        search: history.location.search,
        state: {
          returnTo: redirectPath,
          customerService,
          errorCode: error?.response?.status
        }
      })
    }
  }
