import React, { ReactElement, SyntheticEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation } from 'react-router'
import { RootState } from 'redux/store'
import Header from 'ui/atoms/Header'
import { history, translate } from 'helpers'
import {
  FlexButtonContainer,
  GhostButton,
  PrimaryButton
} from 'ui/atoms/Button'
import {
  Form,
  TextfieldWithMargin
} from 'ui/organisms/NeedsVerificationModal/subcomponents'
import routes from 'const/routes'
import { FA_EXCLAMATION_CIRCLE, FA_LOCK } from 'const/iconProp'
import {
  handleIdpCancel,
  handleIdpVerify,
  handleValidate,
  updateCvv
} from 'redux/cvv/actions'
import { resetIdpError } from 'redux/idp/actions'
import InlineError from 'ui/atoms/InlineError/IdP'

const CVV = (): ReactElement => {
  const {
    cvv: { code, validationError },
    isSubmitting,
    isAccountLocked
  } = useSelector((state: RootState) => ({
    cvv: state.cvv,
    isSubmitting: state.uiUtils.isSubmitting,
    isAccountLocked: state.idp.isAccountLocked
  }))
  const dispatch = useDispatch()
  const location = useLocation()

  const handleSubmit = (e: SyntheticEvent): void => {
    e.preventDefault()
    dispatch(handleIdpVerify())
  }

  return (
    <>
      <Header
        data-test="subHeader"
        title={translate({ string: 'forYourSecurity' })}
        description={translate({ string: 'pleaseEnterTheInfo' })}
        labelledby="verification-title"
        describedby="verification-description"
      />
      <InlineError data-test="error-wrapper" />
      <Form data-reason="form" onSubmit={handleSubmit} noValidate>
        <TextfieldWithMargin
          id="cvv"
          name="cvv"
          type="password"
          inputMode="numeric"
          value={code}
          data-reason="cvv-field"
          className="masked"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            dispatch(updateCvv(e.target.value))
          }
          onFocus={() => dispatch(resetIdpError())}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
            dispatch(handleValidate(e.target.value))
          }
          maxLength={3}
          placeholder={translate({ string: 'threeDigitCode' })}
          error={validationError}
          leftIcon={<FontAwesomeIcon icon={FA_LOCK} />}
          errorIcon={<FontAwesomeIcon icon={FA_EXCLAMATION_CIRCLE} />}
          width="275px"
          data-private
          required
        />

        <FlexButtonContainer>
          {isAccountLocked ? (
            <PrimaryButton
              title={translate({ string: 'submit' })}
              buttonType="primary"
              className="ensightencustomevent"
              data-test="goToLogin"
              data-reason="submit"
              data-type="cvv verify"
              data-object="button"
              onClick={() => {
                history.push({
                  pathname: routes.HOME,
                  search: location?.search
                })
              }}
            >
              {translate({ string: 'goToLogin' })}
            </PrimaryButton>
          ) : (
            <PrimaryButton
              title={translate({ string: 'submit' })}
              type="submit"
              buttonType="primary"
              disabled={isSubmitting}
              className="ensightencustomevent"
              data-test="submit"
              data-reason="submit"
              data-type="cvv verify"
              data-object="button"
            >
              {isSubmitting
                ? translate({ string: 'submitting' })
                : translate({ string: 'submit' })}
            </PrimaryButton>
          )}

          <GhostButton
            buttonType="ghost"
            type="button"
            title={translate({ string: 'cancelToCloseDialog' })}
            onClick={() => dispatch(handleIdpCancel())}
            className="ensightencustomevent"
            data-reason="cancel"
            data-type="cvv verify"
            data-object="button"
            data-test="cvv-cancel-button"
          >
            {translate({ string: 'cancel' })}
          </GhostButton>
        </FlexButtonContainer>
      </Form>
    </>
  )
}

export default CVV
