import React, { ReactElement } from 'react'
import { clientInfo } from 'configureBrand'
import {
  BannerWrapper,
  Container,
  Img,
  ImgWrapper,
  Title
} from './subcomponents'

const VoiceBanner = (): ReactElement => {
  return clientInfo.voice.cardName !== '' ? (
    <BannerWrapper>
      <Container>
        <ImgWrapper>
          <Img />
        </ImgWrapper>
        <Title>{clientInfo.voice.cardName}</Title>
      </Container>
    </BannerWrapper>
  ) : null
}

export default VoiceBanner
