import React, { ReactElement } from 'react'
import translate from 'helpers/translate'
import { payAsGuest } from 'const/analytics'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import {
  BannerBody,
  BannerHeader,
  BannerTextAndIconContainer,
  BannerTextContainer,
  ClockIcon,
  PAGButton,
  PayAsGuestContainer
} from './subcomponents'
import { PayAsGuestBannerProps } from './typings'

const PayAsGuestBanner = ({
  onPagClick
}: PayAsGuestBannerProps): ReactElement => {
  const {
    akamaiConfig: { showIdpBanner }
  } = useSelector((state: RootState) => state)

  return (
    <PayAsGuestContainer hasIdpBanner={showIdpBanner}>
      <BannerTextAndIconContainer>
        <ClockIcon />
        <BannerTextContainer>
          <BannerHeader>
            {translate({ string: 'payAsGuestHeader' })}
          </BannerHeader>
          <BannerBody>
            {translate({ string: 'payAsGuestSubHeader' })}
          </BannerBody>
        </BannerTextContainer>
      </BannerTextAndIconContainer>
      <PAGButton
        type="button"
        id="pay-as-guest"
        buttonType="primary"
        className="ensightencustomevent"
        data-reason={payAsGuest.clickEvents.REASON}
        data-object={payAsGuest.clickEvents.OBJECT}
        data-type={payAsGuest.clickEvents.TYPE}
        onClick={onPagClick}
      >
        {translate({ string: 'payAsGuestButton' })}
      </PAGButton>
    </PayAsGuestContainer>
  )
}

export default PayAsGuestBanner
