import React, { ReactElement, useState } from 'react'
import { useLocation } from 'react-router'
import NeedsVerificationModal from 'ui/organisms/NeedsVerificationModal/NonIdP'
import triggerAnalyticsEvent from 'helpers/triggerAnalyticsEvent'
import {
  AuthenticateCplLoginResponse,
  CplOtpValidateResponseData
} from 'services/typings'
import Template from 'ui/molecules/Template'
import { MfaError } from 'ui/organisms/NeedsVerificationModal/typings'
import { LocationState } from 'types/locationState'
import VerificationEndpoint from 'const/verificationEndPoint'
import { MfaValidationType } from 'const/mfa'
import VoiceLoginContainer from './VoiceLoginContainer'

const VoiceLinking = (): ReactElement => {
  const location = useLocation<Partial<LocationState>>()
  const [phoneNumbers, setPhoneNumbers] = useState<Array<string>>([])
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [showOtp, setShowOtp] = useState<boolean>(false)
  const error = location.state ? location.state.error : {}
  const [sessionId, setSessionId] = useState<string>('')
  const [userId, setUserId] = useState<string>('')
  const [showVoiceOtp, setShowVoiceOtp] = useState<boolean>(false)

  const handleCancelOtp = (): void => {
    setShowOtp(false)
    setIsSubmitting(false)
    triggerAnalyticsEvent({ subFunction: '' })
  }

  const handleAuthResult = async (
    auth: AuthenticateCplLoginResponse
  ): Promise<void> => {
    const { data, result } = auth
    if (result.otp) {
      const {
        phone_numbers: phoneNumbersData,
        session_id: sessionIdData,
        user_id: userIdData
      } = data

      setShowOtp(true)
      setPhoneNumbers(phoneNumbersData)
      setSessionId(sessionIdData)
      setUserId(userIdData)
    }
    if (result.redirect) {
      setShowVoiceOtp(true)
    }
  }

  const handleVerified = ({
    status: { response_code: responseCode }
  }: CplOtpValidateResponseData): void => {
    if (responseCode === '3001') {
      setShowOtp(false)
      setShowVoiceOtp(true)
    } else {
      setShowOtp(false)
      setShowVoiceOtp(false)
    }
  }

  return (
    <Template>
      <VoiceLoginContainer
        showVoiceOtp={showVoiceOtp}
        data-test="login-containter"
        error={error}
        afterSubmit={(data: AuthenticateCplLoginResponse) =>
          handleAuthResult(data)
        }
        isSubmitting={isSubmitting}
        updateSubmitState={(state: boolean) => setIsSubmitting(state)}
      />
      <NeedsVerificationModal
        endpoint={VerificationEndpoint.ACCOUNT_LINKING}
        verificationType={MfaValidationType.OTP}
        data-test="needs-verification-modal"
        containerId="voiceOtpModal"
        showModal={showOtp}
        handleCancel={() => handleCancelOtp()}
        authData={{
          phoneNumbers,
          sessionId,
          userId
        }}
        type="voiceAuthentication"
        handleError={(errorData: MfaError) => {
          if (
            errorData.type === 'lost-stolen' ||
            errorData.type === 'customer-service'
          ) {
            setShowOtp(false)
          }
        }}
        handleVerified={handleVerified}
      />
    </Template>
  )
}

export default VoiceLinking
