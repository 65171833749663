import React, { ReactElement, useEffect } from 'react'
import { useLocation } from 'react-router'
import { useDispatch } from 'react-redux'
import { updateFormData } from 'redux/login/actions'
import { getCurrentLanguageFromStorage } from 'helpers/translate'
import { brandImages } from 'configureBrand'
import { LocationState } from 'types/locationState'
import LoginForm, { LoginFormProps } from 'ui/organisms/LoginForm'
import EMPTY_STRING from 'const/emptyString'

import {
  LoginBlock,
  LoginWrapper,
  MainPromoBlock,
  PromoWrapper
} from './subcomponents'

const LoginContainer = ({ handleSubmit }: LoginFormProps): ReactElement => {
  const language = getCurrentLanguageFromStorage()
  const dispatch = useDispatch()
  const location = useLocation<Partial<LocationState>>()
  const userIdFromState = location.state?.userId || ''

  useEffect(() => {
    if (userIdFromState) {
      dispatch(
        updateFormData({ userId: userIdFromState, password: EMPTY_STRING })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <LoginWrapper>
      <LoginBlock>
        <LoginForm handleSubmit={handleSubmit} />
      </LoginBlock>
      <MainPromoBlock>
        {brandImages.mainPromo[language] && (
          <PromoWrapper data-test="promo-image" />
        )}
      </MainPromoBlock>
    </LoginWrapper>
  )
}

export default LoginContainer
