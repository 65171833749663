import React, { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootState } from 'redux/store'
import routes from 'const/routes'
import translate from 'helpers/translate'
import { updateIsModalOpen } from 'redux/uiUtils/actions'
import {
  FlexButtonContainer,
  GhostButton,
  PrimaryButton
} from 'ui/atoms/Button'

interface ModalFooterProps {
  handleCancel: () => void
  submissionError: boolean
  dataType: string
}

const ModalFooter = ({
  handleCancel,
  submissionError,
  dataType
}: ModalFooterProps): ReactElement => {
  const history = useHistory()
  const {
    uiUtils: { isSubmitting }
  } = useSelector((state: RootState) => state)
  const dispatch = useDispatch()

  return (
    <FlexButtonContainer>
      {submissionError ? (
        <PrimaryButton
          title={translate({ string: 'goToLogin' })}
          buttonType="primary"
          className="ensightencustomevent"
          data-reason="goToLogin"
          data-type={dataType}
          data-object="button"
          onClick={() => {
            dispatch(updateIsModalOpen(false))
            history.push({ pathname: routes.HOME })
          }}
          data-test="modal-login-button"
        >
          {translate({ string: 'goToLogin' })}
        </PrimaryButton>
      ) : (
        <PrimaryButton
          title={translate({ string: 'submit' })}
          type="submit"
          buttonType="primary"
          disabled={isSubmitting}
          className="ensightencustomevent"
          data-reason="submit"
          data-type={dataType}
          data-object="button"
        >
          {translate({ string: isSubmitting ? 'submitting' : 'submit' })}
        </PrimaryButton>
      )}

      <GhostButton
        buttonType="ghost"
        type="button"
        title={translate({ string: 'cancelToCloseDialog' })}
        onClick={handleCancel}
        className="ensightencustomevent"
        data-reason="cancel"
        data-type={dataType}
        data-object="button"
        data-test="modal-cancel-button"
      >
        {translate({ string: 'cancel' })}
      </GhostButton>
    </FlexButtonContainer>
  )
}

export default ModalFooter
