import { ReactNode } from 'react'
import { format, isBefore } from 'date-fns'
import translate from 'helpers/translate'
import { DownTimeState } from 'redux/akamaiConfig/typings'

interface FormattedMaintenanceData {
  formattedStartDate: string
  formattedStartTime: string
  formattedStopTime: string
}

const formatMaintenanceData = (
  maintenanceData: DownTimeState
): FormattedMaintenanceData => {
  const { maintenanceStartTime, maintenanceEndTime } = maintenanceData || {}

  if (maintenanceStartTime && maintenanceEndTime) {
    const formattedStartDate = format(
      new Date(maintenanceStartTime),
      'MM/dd/yyyy'
    )
    const formattedStartTime = format(
      new Date(maintenanceStartTime),
      'hh:mm aaaa'
    )
    const formattedStopTime = format(
      new Date(maintenanceEndTime),
      'hh:mm aaaa zzz'
    )
    return { formattedStartDate, formattedStartTime, formattedStopTime }
  }
  return {
    formattedStartDate: '',
    formattedStartTime: '',
    formattedStopTime: ''
  }
}

export const shouldShowMaintenance = (
  maintenanceData: DownTimeState
): boolean => {
  const { maintenanceStartTime, maintenanceEndTime } = maintenanceData || {}

  return (
    isBefore(new Date(), new Date(maintenanceStartTime)) &&
    isBefore(new Date(maintenanceStartTime), new Date(maintenanceEndTime))
  )
}

export const formatMaintenanceMessage = (
  maintenanceData: DownTimeState
): ReactNode => {
  const { formattedStartDate, formattedStartTime, formattedStopTime } =
    formatMaintenanceData(maintenanceData)

  return translate({
    string: 'maintenanceAlert',
    argument: {
      formattedStartDate,
      formattedStartTime,
      formattedStopTime
    }
  })
}
