import React, { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Inset from 'syf-component-library/ui/spacing/Inset/Inset'
import { RootState } from 'redux/store'
import { resetOtpState } from 'redux/otp/actions'
import { clearSSNState } from 'redux/ssn/actions'
import { clearCvvState } from 'redux/cvv/actions'
import { clearIdpState } from 'redux/idp/actions'
import { MfaValidationType } from 'const/mfa'
import OTP from './NonIdP/OTP'
import CVV from './NonIdP/CVV'
import SSN from './NonIdP/SSN'
import IdpOTP from './IdP/OTP'
import IdpCVV from './IdP/CVV'
import { ModalContainer } from './subcomponents'
import { NeedVerificationModalProps } from './typings'
import IdpSSN from './IdP/SSN'

const NeedsVerificationModal = ({
  verificationType,
  showModal,
  handleCancel,
  containerId,
  isIdpFlow,
  ...props
}: NeedVerificationModalProps): ReactElement => {
  const {
    uiUtils: { isModalOpen }
  } = useSelector((state: RootState) => state)
  const dispatch = useDispatch()
  const verificationMethodProps = { ...props, handleCancel }
  const verificationMethodDataTest = isIdpFlow
    ? 'verification-method-idp'
    : 'verification-method'
  const verificationMethods = {
    otp: isIdpFlow ? IdpOTP : OTP,
    ssn: isIdpFlow ? IdpSSN : SSN,
    cvv: isIdpFlow ? IdpCVV : CVV
  }

  const clearState = () => {
    if (isIdpFlow) clearIdpState()
    switch (verificationType) {
      case MfaValidationType.OTP:
        dispatch(resetOtpState())
        break
      case MfaValidationType.SSN:
        dispatch(clearSSNState())
        break
      case MfaValidationType.CVV:
        dispatch(clearCvvState())
        break
      default:
        break
    }
  }

  const VerificationMethod =
    verificationMethods[verificationType as keyof typeof verificationMethods]

  return VerificationMethod ? (
    <ModalContainer
      id="verification-modal"
      aria-labelledby="verification-title"
      aria-describedby="verification-description"
      data-test="needs-verification-modal"
      isOpen={!!showModal || isModalOpen}
      escapeModalHandler={() => {
        clearState()
        handleCancel()
      }}
      containerId={containerId}
    >
      <Inset all="small">
        <VerificationMethod
          {...verificationMethodProps}
          data-test={verificationMethodDataTest}
        />
      </Inset>
    </ModalContainer>
  ) : null
}

export default NeedsVerificationModal
