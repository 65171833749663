import React, { ReactElement, useEffect } from 'react'
import { useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import { updateError } from 'redux/login/actions'
import { checkForQuickPay } from 'redux/quickPay/actions'
import { LocationState } from 'types/locationState'
import { ErrorType } from 'types/errorTypes'
import NonIdpLogin from './NonIdP'
import IdpLogin from './IdP'

const Login = (): ReactElement => {
  const {
    idp: { isIdpFlow }
  } = useSelector((reduxState: RootState) => reduxState)
  const location = useLocation<Partial<LocationState>>()
  const { state } = location || {}
  const errorFromState = state?.error as ErrorType
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(checkForQuickPay())
    dispatch(updateError(errorFromState))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isIdpFlow ? (
    <IdpLogin data-test="idp-login" />
  ) : (
    <NonIdpLogin data-test="non-idp-login" />
  )
}

export default Login
