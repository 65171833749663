import { RootState } from 'redux/store'
import { ThunkDispatch } from 'redux-thunk'
import {
  getRememberDatafromCookie,
  history,
  triggerAnalyticsEvent
} from 'helpers'
import checkForPWOLIToken from 'services/checkForPWOLIToken'
import apiEndpoints from 'const/apiEndpoints'
import { payAsGuest, payWithoutLogin } from 'const/analytics'
import WebAuthnSupportMessage from 'const/webAuthnSupportMessage'
import findUserIdWithIdentifier from 'services/nonIdP/findUserIdWithIdentifier'
import { updateFormData } from 'redux/login/actions'
import { LoginAction } from 'redux/login/typings'
import determineWebAuthnSupport from 'helpers/determineWebAuthnSupport'
import { clientInfo } from 'configureBrand'
import {
  PwoliAction,
  ResetQuickPayTokenAction,
  UpdateQuickPayTokenAction,
  UpdateQuickPayTokenPayload
} from './typings'

type PwoliThunkDispatch = ThunkDispatch<
  RootState,
  undefined,
  PwoliAction | LoginAction
>

export const resetQuickpayToken = (): ResetQuickPayTokenAction => {
  return {
    type: 'RESET_QUICKPAY_TOKEN'
  }
}

export const updateQuickPayToken = ({
  isPwoli,
  payload
}: UpdateQuickPayTokenPayload): UpdateQuickPayTokenAction => {
  return {
    type: isPwoli ? 'UPDATE_PWOLI_TOKEN' : 'UPDATE_PAG_TOKEN',
    payload
  }
}

export const handleFetchUserId =
  (identifier: string) =>
  async (
    dispatch: PwoliThunkDispatch,
    getState: () => RootState
  ): Promise<void> => {
    const {
      login: { formData }
    } = getState()
    const foundUserId = await findUserIdWithIdentifier(identifier)
    if (foundUserId) {
      dispatch(
        updateFormData({ ...formData, userId: foundUserId, rememberMe: true })
      )
    }
  }

export const handlePayWithoutLogin =
  (
    identifier: string,
    clientId: string,
    webAuthSupportMessage: WebAuthnSupportMessage
  ) =>
  async (dispatch: PwoliThunkDispatch): Promise<void> => {
    const pwoliTokenResponse = await checkForPWOLIToken({
      route: apiEndpoints.dsecurity.PAY_WITHOUT_LOGIN,
      identifier,
      clientId
    })

    if (pwoliTokenResponse) {
      dispatch(
        updateQuickPayToken({
          isPwoli: true,
          payload: pwoliTokenResponse
        })
      )
    }

    triggerAnalyticsEvent({
      subFunction: pwoliTokenResponse
        ? payWithoutLogin.pageEvents.PAGE_SUBFUNCTION
        : '',
      messageKey: webAuthSupportMessage
    })
  }

export const handlePayAsGuest =
  (webAuthSupportMessage: WebAuthnSupportMessage) =>
  async (dispatch: PwoliThunkDispatch): Promise<void> => {
    const pagTokenResponse = await checkForPWOLIToken({
      route: apiEndpoints.dsecurity.PAY_AS_GUEST
    })

    if (pagTokenResponse) {
      dispatch(
        updateQuickPayToken({
          isPwoli: false,
          payload: pagTokenResponse
        })
      )
    }

    triggerAnalyticsEvent({
      subFunction: pagTokenResponse
        ? payAsGuest.pageEvents.PAGE_SUBFUNCTION
        : '',
      messageKey: webAuthSupportMessage
    })
  }

export const checkForQuickPay =
  () =>
  async (dispatch: PwoliThunkDispatch): Promise<void> => {
    const { identifier = '', clientId = '' } = getRememberDatafromCookie() || {}
    const webAuthSupportMessage = await determineWebAuthnSupport()
    try {
      if (identifier) {
        dispatch(handleFetchUserId(identifier))
        if (clientInfo.disablePWOLI) {
          await dispatch(handlePayAsGuest(webAuthSupportMessage))
        } else {
          await dispatch(
            handlePayWithoutLogin(identifier, clientId, webAuthSupportMessage)
          )
        }
      } else {
        await dispatch(handlePayAsGuest(webAuthSupportMessage))
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      triggerAnalyticsEvent({
        pathName: history.location.pathname,
        messageKey: webAuthSupportMessage
      })
    }
  }
