import React, { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import { updateIsModalOpen } from 'redux/uiUtils/actions'
import { postIdpAuthenticate } from 'redux/idp/actions'
import triggerAnalyticsEvent from 'helpers/triggerAnalyticsEvent'
import generateIdpErrorMessage from 'helpers/generateIdpErrorMessage'
import { clientInfo } from 'configureBrand'
import VerificationEndpoint from 'const/verificationEndPoint'
import { MfaValidationType } from 'const/mfa'
import { FlowId } from 'const/idp'
import ToastError from 'ui/atoms/ToastError'
import Template from 'ui/molecules/Template'
import SmartBanner from 'ui/molecules/SmartBanner'
import NeedsVerificationModal from 'ui/organisms/NeedsVerificationModal/IdP'
import IdpLostStolen from 'ui/organisms/IdpLostStolen'
import MaintenanceMessageBox from 'ui/organisms/MaintenanceMessageBox'
import BannerContainer from 'pages/Login/BannerContainer'
import { LoginInner } from 'pages/Login/subcomponents'
import LoginContainer from 'pages/Login/LoginContainer'

const Login = (): ReactElement => {
  const {
    idp: {
      isAccountAlreadyRegistered,
      currentLastFour,
      previousLastFour,
      href,
      idpErrorCode
    },
    uiUtils: { isModalOpen },
    login: { formData }
  } = useSelector((reduxState: RootState) => reduxState)
  const dispatch = useDispatch()
  const idpLostStolenInfo = !!currentLastFour && !!previousLastFour && !!href
  const idpErrorMessage = generateIdpErrorMessage({ idpErrorCode })

  const handleCancelOtp = (): void => {
    dispatch(updateIsModalOpen(false))
    triggerAnalyticsEvent({ subFunction: '' })
  }

  const handleSubmit = () => {
    dispatch(
      postIdpAuthenticate(
        { userId: formData?.userId, password: formData?.password },
        FlowId.LOGIN
      )
    )
  }

  return (
    <Template
      renderBackground={!idpLostStolenInfo}
      innerCss={LoginInner}
      shouldLoadAllScripts
    >
      {(clientInfo.iOSAppId || clientInfo.androidApp.id) && (
        <SmartBanner data-test="smart-banner" />
      )}
      <MaintenanceMessageBox />
      {idpLostStolenInfo ? (
        <IdpLostStolen dataType="login" />
      ) : (
        <>
          <BannerContainer />
          {idpErrorMessage && isAccountAlreadyRegistered && (
            <ToastError message={idpErrorMessage} />
          )}
          <LoginContainer
            data-test="login-container"
            handleSubmit={handleSubmit}
          />
        </>
      )}
      <NeedsVerificationModal
        endpoint={VerificationEndpoint.LOGIN}
        verificationType={MfaValidationType.OTP}
        showModal={isModalOpen}
        containerId="idpOtpModal"
        handleCancel={() => handleCancelOtp()}
        data-test="needs-verification-modal"
      />
    </Template>
  )
}

export default Login
