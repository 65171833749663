import React, { ReactElement } from 'react'
import { clientInfo } from 'configureBrand'
import { P, Wrapper } from './subcomponents'

const LegalText = (): ReactElement => (
  <Wrapper>
    <P>
      By clicking on &quot;Secure Login&quot;, I acknowledge that Synchrony may
      use my data to link my {clientInfo.vanityUrl} and Synchrony accounts.
      Synchrony&apos;s use and transmission of my data will continue to be
      governed by Synchrony&apos;s Privacy Policy.
    </P>
  </Wrapper>
)

export default LegalText
