import { clientInfo } from 'configureBrand'
import translate from 'helpers/translate'
import { AuthResult } from './typings'

const determineValidateResult = (
  code: string,
  accountType?: string
): AuthResult => {
  switch (code) {
    case '3001':
      return { token: true }
    case '3002':
      return { lockedOut: true }
    case '3003':
      return {
        message: {
          key: 'invalidOTP',
          extension: `${translate({ string: 'code' })}: 3003`
        }
      }
    case '3004':
      return { lockedOut: true }
    case '3005':
      return { lockedOut: true }
    case '3006':
      return { lostStolen: true }
    case '3007':
      return {
        message: {
          key: 'lockedProblem',
          argument:
            clientInfo.phoneNumbers[
              accountType as keyof typeof clientInfo.phoneNumbers
            ],
          extension: `${translate({ string: 'code' })}: 3007`
        }
      }
    case '3008':
      return {
        message: {
          key: 'lockedProblem',
          argument:
            clientInfo.phoneNumbers[
              accountType as keyof typeof clientInfo.phoneNumbers
            ],
          extension: `${translate({ string: 'code' })}: 3008`
        }
      }
    default:
      return { message: { key: 'default' } }
  }
}

export default determineValidateResult
