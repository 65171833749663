import { CplAction, CplState } from './typings'

export const initialState: CplState = {
  errorCode: null,
  errorDescriptor: '',
  isAccountLinking: false,
  authenticateCplResponse: {
    status: {
      response_code: undefined,
      response_desc: '',
      trans_id: ''
    },
    session_id: '',
    last_4_acct_no: '',
    user_id: '',
    client_id: '',
    account_type: '',
    authorization_code: '',
    phone_numbers: [],
    codeVerifier: ''
  },
  isPersistError: false
}

export default function (
  state: CplState = initialState,
  action: CplAction = undefined
): CplState {
  switch (action.type) {
    case 'UPDATE_CPL_SYSTEM_ERROR': {
      const { code, descriptor, isAccountLinking } = action.payload
      return {
        ...state,
        errorDescriptor: descriptor,
        errorCode: code,
        isAccountLinking
      }
    }
    case 'UPDATE_CPL_AUTHENTICATE_RESPONSE': {
      return {
        ...state,
        authenticateCplResponse: action.payload,
        submissionError: null
      }
    }
    case 'UPDATE_IS_PERSIST_ERROR': {
      return {
        ...state,
        isPersistError: action.payload
      }
    }
    case 'RESET_SUBMISSION_ERROR': {
      return { ...state, submissionError: null }
    }
    case 'UPDATE_SUBMISSION_ERROR': {
      return {
        ...state,
        submissionError: action.payload
      }
    }
    default: {
      return state
    }
  }
}
