import { clientInfo } from 'configureBrand'
import routes from 'const/routes'
import { history } from 'helpers/triggerAnalyticsEvent'
import { PathNames } from 'helpers/typings'

const handleRedirectRoute = (): string => {
  const { GOOGLE, ALEXA, CPL, HOME } = routes
  const { pathname } = history.location

  const voicePath = () => {
    const path = pathname.substring(1)
    const voiceType = clientInfo.voice[
      path as keyof typeof clientInfo.voice
    ] as PathNames
    const hasVoice = voiceType.termsAndConditionsLink
    return hasVoice ? pathname : HOME
  }

  if (pathname === GOOGLE || pathname === ALEXA) return voicePath()

  if (pathname === CPL && clientInfo.hasCpl) return CPL
  return HOME
}

export default handleRedirectRoute
