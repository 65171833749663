import en from 'locales/en'
import es from 'locales/es'
import { AnyObject } from 'types/custom'
import { SyntheticEvent } from 'react'
import getParameterByName from 'helpers/getParameterByName'
import config from 'const/config'

const langs: AnyObject = { en, es }

export type LanguageType = 'en' | 'es'

export interface Translation {
  string: string
  argument?: string | number | AnyObject | SyntheticEvent
}

export const getCurrentLanguageFromStorage = (): LanguageType => {
  const availableLanguages = Object.keys(langs)
  const languageFromParam = getParameterByName(
    'lang',
    window.location.search
  ) as LanguageType
  try {
    const language = `language-${config.RC_CLIENT_ID}`
    const languageFromStorage = localStorage.getItem(language) as LanguageType
    if (availableLanguages.includes(languageFromParam)) {
      return languageFromParam
    }
    if (availableLanguages.includes(languageFromStorage)) {
      return languageFromStorage
    }
    localStorage.setItem(`language-${config.RC_CLIENT_ID}`, 'en')
    return 'en'
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error occcured in local storage', error)
    return 'en'
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const translate = ({ string, argument }: Translation): any =>
  argument
    ? langs[getCurrentLanguageFromStorage()][string](argument)
    : langs[getCurrentLanguageFromStorage()][string]

export default translate
