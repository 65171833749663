import React, { ReactElement } from 'react'
import translate from 'helpers/translate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Header from 'ui/atoms/Header'
import Template from 'ui/molecules/Template'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import config from 'const/config'
import { FA_TOOLS } from 'const/iconProp'
import { StillLooking, Wrapper } from './subcomponents'

const Maintenance = (): ReactElement => {
  const {
    maintenanceData: { downTimeMessage, isDowntimePay }
  } = useSelector((state: RootState) => state.akamaiConfig, shallowEqual)

  return (
    <Template>
      <Wrapper>
        <Header
          data-test="maintenance-error"
          title={translate({ string: 'weAreSorry' })}
          description={downTimeMessage}
        />
        <FontAwesomeIcon icon={FA_TOOLS} style={{ fontSize: 90 }} />
        {isDowntimePay && (
          <StillLooking
            href={config.DOWNTIME_URL}
            data-test="still-looking-link"
          >
            {translate({ string: 'stillLooking' })}
          </StillLooking>
        )}
      </Wrapper>
    </Template>
  )
}

export default Maintenance
