import { shallowEqual, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import redirectToUrl from 'syf-js-utilities/helpers/redirectToUrl'
import config from 'const/config'
import { RootState } from 'redux/store'

const useCancelRedirection = (returnTo: string): (() => void) => {
  const history = useHistory()
  const { isIdpFlow } = useSelector(
    (state: RootState) => ({ isIdpFlow: state.idp.isIdpFlow }),
    shallowEqual
  )

  if (isIdpFlow || !!history.location.state) return () => history.push(returnTo)
  return () => redirectToUrl(`${config.TARGET_ORIGIN}${config.BASENAME}`)
}

export default useCancelRedirection
