import config from './config'

export enum IdpFormPostRelType {
  POST = 'POST',
  REDIRECT = 'REDIRECT',
  COMPLETE = 'COMPLETE'
}

export const TermsOfService = {
  en: `${config.ASSET_ORIGIN}/assets/generic/en/eConsent_document.pdf`,
  es: `${config.ASSET_ORIGIN}/assets/generic/es/eConsent_document.pdf`
}

export enum FlowId {
  LOGIN = 'login',
  REGISTRATION = 'registration'
}
