import { ThunkDispatch } from 'redux-thunk'
import verify from 'services/nonIdP/verify'
import generateToken from 'services/nonIdP/generateToken'
import {
  handleRedirectRoute,
  history,
  log,
  triggerAnalyticsEvent,
  validate
} from 'helpers'
import { codeVerifier } from 'services/nonIdP/searchUser'
import routes from 'const/routes'
import { RootState } from 'redux/store'
import { UiAction } from 'redux/uiUtils/typings'
import { updateIsModalOpen, updateIsSubmitting } from 'redux/uiUtils/actions'
import { clearIdpState, postIdpOtpSoftener } from 'redux/idp/actions'
import { IdpAction } from 'redux/idp/typings'
import { MfaValidationType } from 'const/mfa'
import AuthDataTypes from 'types/authDataTypes'
import { VerificationEndpoint } from 'services/typings'
import { SubmissionError } from 'types/errorTypes'
import { clientInfo } from 'configureBrand'
import { Verified } from 'ui/organisms/NeedsVerificationModal/typings'
import { getPsfMessage } from 'redux/psfMessage/actions'
import Cvv from 'const/analytics/cvv'
import { CvvAction } from './typings'

export type CvvThunkDispatch = ThunkDispatch<
  RootState,
  undefined,
  CvvAction | UiAction | IdpAction
>

export const updateCvv = (cvv: string): CvvAction => {
  return { type: 'UPDATE_CVV', payload: cvv }
}

export const updateValidationError = (validationError: string): CvvAction => {
  return { type: 'UPDATE_VALIDATION_ERROR', payload: validationError }
}

export const updateSubmissionError = (
  submissionError: SubmissionError
): CvvAction => {
  return { type: 'UPDATE_SUBMISSION_ERROR', payload: submissionError }
}

export const handleValidate =
  (cvv: string) =>
  (dispatch: CvvThunkDispatch): void => {
    const validationError = validate(MfaValidationType.CVV, cvv)
    dispatch(updateValidationError(validationError || ''))
  }

export const clearCvvState = (): CvvAction => {
  return { type: 'RESET_CVV_STATE' }
}

export const handleIdpCancel =
  () =>
  (dispatch: CvvThunkDispatch): void => {
    dispatch(updateIsModalOpen(false))
    dispatch(clearIdpState())
    triggerAnalyticsEvent({ subFunction: '' })
    dispatch(clearCvvState())
  }

export const handleIdpVerify =
  () =>
  (dispatch: CvvThunkDispatch, getState: () => RootState): void => {
    const {
      cvv: { code },
      jwt: { idpSessionId }
    } = getState()
    const validationError = validate(MfaValidationType.CVV, code)

    if (!validationError) {
      dispatch(postIdpOtpSoftener({ idpSessionId, code }))
    } else {
      dispatch(updateValidationError(validationError || ''))
    }
  }

export const handleNonIdpReset =
  () =>
  (dispatch: CvvThunkDispatch): void => {
    dispatch(updateIsModalOpen(false))
    dispatch(clearCvvState())
  }

export const handleNonIdpVerify =
  (
    authData: AuthDataTypes,
    endpoint: VerificationEndpoint,
    handleVerified?: (data: Verified) => void
  ) =>
  async (
    dispatch: CvvThunkDispatch,
    getState: () => RootState
  ): Promise<void> => {
    const {
      cvv: { code }
    } = getState()

    try {
      dispatch(updateIsSubmitting(true))
      const { responseData, result } = await verify(
        {
          sessionId: authData.sessionId,
          validationCode: code
        },
        endpoint
      )

      const {
        authorization_code: authCode = '',
        ebill_status: ebillStatus,
        last_4_acct_no: newCard = '',
        prev_last_4_acct_no: oldCard = '',
        session_id: sessionId = '',
        user_id: userId = '',
        client_id: clientId
      } = responseData || {}
      const cipherAccountId = responseData?.['cipher.accountId']

      if (result?.message) {
        triggerAnalyticsEvent({
          messageKey: result.message.key,
          pageName: Cvv.NAME,
          pageFunction: Cvv.PAGE_SUBFUNCTION
        })
        dispatch(updateIsSubmitting(false))
        dispatch(updateSubmissionError(result.message))
      } else if (endpoint === 'lookup-userid' && userId) {
        dispatch(handleNonIdpReset())
        history.push({
          pathname: routes.FOUND,
          state: {
            userId,
            dob: authData.dob
          }
        })
      } else {
        const accessToken = await generateToken({
          authCode,
          codeVerifier,
          clientId
        })

        if (accessToken && !ebillStatus && cipherAccountId) {
          await dispatch(
            getPsfMessage({
              accessToken,
              cipherAccountId
            })
          )
        }

        handleVerified({
          sessionId: sessionId || authData.sessionId,
          accessToken,
          oldCard,
          newCard,
          result,
          ebillStatus,
          userId
        })
        dispatch(handleNonIdpReset())
      }
    } catch (error) {
      log.error(error)
      dispatch(handleNonIdpReset())

      const { phoneNumbers } = clientInfo
      const { customerService } = phoneNumbers
      const redirectPath = handleRedirectRoute()
      history.push({
        pathname: routes.ERROR,
        search: history.location.search,
        state: {
          returnTo: redirectPath,
          customerService,
          errorCode: error?.response?.status
        }
      })
    }
  }
