import { addBmTelemetryToHeaders, getCorrelationId, translate } from 'helpers'
import config from 'const/config'
import api, { generateTransId } from 'services'
import apiEndpoints from 'const/apiEndpoints'
import { CheckUserIdOptions, CheckUserIdResponseData } from 'services/typings'

const checkUserId = async (options: CheckUserIdOptions): Promise<string> => {
  try {
    const { accessToken, registrationUID } = options || {}
    const payload = {
      user_id: registrationUID
    }
    const reqHeaders = await addBmTelemetryToHeaders({
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'X-SYF-Request-TrackingId': generateTransId(),
        'X-SYF-Request-CorrelationId': getCorrelationId()
      },
      params: {
        client_id: config.DSEC_APIGEE_CLIENT_ID
      }
    })

    const response = await api.post(
      apiEndpoints.dsecurity.USER_ID_EXISTS,
      payload,
      reqHeaders
    )

    const responseData: CheckUserIdResponseData = response.data

    return responseData.status.response_code !== '1002'
      ? translate({ string: 'unavailableUID' })
      : ''
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    throw error
  }
}

export default checkUserId
