import React, { ReactElement } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import { handleRegistrationComplete } from 'redux/idp/actions'
import { updateIsSubmitting } from 'redux/uiUtils/actions'
import {
  REGISTRATION_ALERT_FAILURE,
  REGISTRATION_EBILL_FAILURE
} from 'const/idpErrors'
import Complete from 'ui/organisms/Registration/Complete'

const RegistrationComplete = (): ReactElement => {
  const { profileCreateResponse } = useSelector(
    (state: RootState) => ({
      profileCreateResponse: state.idp.profileCreateResponse
    }),
    shallowEqual
  )

  const dispatch = useDispatch()
  const { code } = profileCreateResponse || {}

  const onClickAccountSummary = () => {
    dispatch(updateIsSubmitting(true))
    dispatch(handleRegistrationComplete())
  }

  return (
    <Complete
      handleAccountSummary={onClickAccountSummary}
      isEbillFailure={code === REGISTRATION_EBILL_FAILURE}
      isAlertFailure={code === REGISTRATION_ALERT_FAILURE}
    />
  )
}

export default RegistrationComplete
