import sha256 from 'crypto-js/sha256'
import base64 from 'crypto-js/enc-base64'

const base64url = (source: CryptoJS.lib.WordArray) => {
  // Encode in classical base64
  let encodedSource = base64.stringify(source)

  // Remove padding equal characters
  encodedSource = encodedSource.replace(/=+$/, '')

  // Replace characters according to base64url specifications
  encodedSource = encodedSource.replace(/\+/g, '-')
  encodedSource = encodedSource.replace(/\//g, '_')

  return encodedSource
}

// eslint-disable-next-line import/prefer-default-export
export const createCodeChallenge = (codeVerifier: string): string =>
  base64url(sha256(codeVerifier))
