import { ThunkDispatch } from 'redux-thunk'
import redirectToUrl from 'syf-js-utilities/helpers/redirectToUrl'
import { analyticsConfig } from 'syf-js-utilities/helpers/triggerAnalyticsEvent'
import { RootState } from 'redux/store'
import {
  IsJwtLoading,
  JwtAction,
  JwtError,
  JwtErrorAction,
  JwtErrorOptions,
  JwtLoadingAction,
  JwtState
} from 'redux/jwt/typings'
import jwtIntrospect from 'services/idP/jwtIntrospect'
import routes from 'const/routes'
import { history } from 'helpers/triggerAnalyticsEvent'
import config from 'const/config'

type JwtDispatch = ThunkDispatch<
  RootState,
  undefined,
  JwtAction | JwtLoadingAction | JwtErrorAction
>

export const updateJwtIntrospect = (payload: JwtState): JwtAction => {
  return { type: 'UPDATE_JWT_INTROSPECT', payload }
}

export const updateJwtError = (payload: JwtError): JwtErrorAction => {
  return { type: 'UPDATE_JWT_ERROR', payload }
}

const handleError =
  ({ canRetryLogin }: JwtErrorOptions) =>
  (dispatch: JwtDispatch): void => {
    // redirect to error page with a fallback vista link
    const vistaLink = `${config.VISTA_DOMAIN}/accounts/?client=${config.RC_CLIENT_ID}`
    dispatch(
      updateJwtError({
        returnTo: vistaLink,
        isJwtLoading: false,
        canRetryLogin
      })
    )

    history.push({
      pathname: routes.JWT_ERROR,
      search: window.location?.search
    })
  }

export const updateIsJwtLoading = (payload: IsJwtLoading): JwtLoadingAction => {
  return { type: 'UPDATE_IS_JWT_LOADING', payload }
}

export const checkForJwtIntrospect =
  () =>
  async (dispatch: JwtDispatch): Promise<void> => {
    try {
      dispatch(updateIsJwtLoading({ isJwtLoading: true }))
      const data = await jwtIntrospect.postJwtIntrospect()
      const {
        active,
        interactionId = '',
        returnTo,
        consumerKey = ''
      } = data || {}

      analyticsConfig.defaults.RelyingParty = consumerKey
      const { newrelic } = window

      if (interactionId && !!newrelic) {
        newrelic.setCustomAttribute('correlationId', interactionId)
      }

      if (active && interactionId) {
        const vistaLink = `${config.VISTA_DOMAIN}/accounts/?client=${config.RC_CLIENT_ID}`
        sessionStorage.setItem('correlationId', interactionId)
        dispatch(
          updateJwtIntrospect({
            idpSessionId: interactionId,
            active,
            returnTo: returnTo || vistaLink,
            isJwtLoading: false
          })
        )
      } else if (returnTo) {
        // redirect to the returnTo url to generate a new token
        redirectToUrl(returnTo)
      } else {
        // seems like token is corrupted. lets retry from error page.
        dispatch(handleError({ canRetryLogin: true }))
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      dispatch(updateIsJwtLoading({ isJwtLoading: false }))
      // seems like introspect is down. Ask user to try again later.
      dispatch(handleError({ canRetryLogin: false }))
    }
  }

export const handleVistaRedirect =
  () =>
  (dispatch: JwtDispatch, getState: () => RootState): void => {
    const {
      jwt: { returnTo }
    } = getState()

    dispatch(updateIsJwtLoading({ isJwtLoading: true }))
    redirectToUrl(returnTo)
  }
