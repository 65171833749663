import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import MultiAccountDashboard from 'syf-multi-account-dashboard/ui'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import { Account } from 'services/typings'
import selectAccount from 'services/nonIdP/selectAccount'
import triggerAnalyticsEvent, { history } from 'helpers/triggerAnalyticsEvent'
import routes from 'const/routes'
import { clientInfo } from 'configureBrand'
import config from 'const/config'
import AccountLinking from 'pages/Cpl/AccountLinking'
import PersistLogin from 'pages/Cpl/PersistLogin'
import FindUserID from 'pages/FindUserID'
import Login from 'pages/Login'
import Registration from 'pages/Registration'
import Reset from 'pages/Reset'
import Found from 'pages/UserIDFound'
import VoiceTerms from 'pages/Cpl/Voice/Terms'
import StandardError from 'pages/errors/StandardError'
import JwtError from 'pages/errors/JwtError'
import PostRegistrationError from 'pages/errors/PostRegistrationError'
import LockAccountError from 'pages/errors/LockedAccountError'
import CplError from 'pages/errors/CplError'
import AccountLockoutError from 'pages/errors/AccountLockedError'
import SoftenerError from 'pages/errors/SoftenerError'
import SessionTimeoutError from 'pages/errors/SessionTimeoutError'
import AlreadyRegisteredError from 'pages/errors/AlreadyRegisteredError'
import { getVoiceRouteTermsLink } from './helpers'
import { LocationState } from './typings'

const Routes = (): React.ReactElement => {
  const {
    akamaiConfig: { hasJwtToken }
  } = useSelector((state: RootState) => state)
  const voiceRouteTermsLink = getVoiceRouteTermsLink()

  return (
    <Switch>
      <Route exact path={[routes.HOME, routes.IDP_HOME]} component={Login} />
      <Route exact path={routes.REGISTER} component={Registration} />
      <Route exact path={routes.RESET} component={Reset} />
      <Route exact path={routes.FOUND} component={Found} />
      <Route
        exact
        path={routes.ACCOUNTS}
        render={() => {
          const {
            location: { search }
          } = history
          return <Redirect to={`${routes.HOME}${search}`} />
        }}
      />
      <Route
        exact
        path={routes.MULTI_ACCOUNT}
        render={props => {
          const {
            location: { state = {} },
            history: historyFromProps
          } = props
          const { accounts = [] } = state as LocationState
          const madAccessToken = sessionStorage.getItem('madAccessToken')
          return accounts.length && madAccessToken ? (
            <MultiAccountDashboard
              {...props}
              data-test="multi-account-dashboard"
              clientId={config.RC_CLIENT_ID}
              accounts={accounts}
              clientName={clientInfo.displayName}
              triggerAnalyticsEvent={triggerAnalyticsEvent}
              handleSelectAccount={({ accountType, syfIdentifier }: Account) =>
                selectAccount({
                  accountType,
                  syfIdentifier,
                  hasJwtToken
                })
              }
              handleError={() =>
                historyFromProps.push({
                  pathname: routes.HOME,
                  state: {}
                })
              }
            />
          ) : (
            <Redirect to={routes.HOME} />
          )
        }}
      />
      <Route exact path={routes.FIND_USER_ID} component={FindUserID} />
      {clientInfo.hasCpl && (
        <Route exact path={routes.CPL} component={AccountLinking} />
      )}
      {clientInfo.hasCpl && (
        <Route exact path={routes.PERSIST} component={PersistLogin} />
      )}
      <Route exact path={routes.ERROR} component={StandardError} />
      <Route exact path={routes.JWT_ERROR} component={JwtError} />
      <Route
        exact
        path={routes.SESSION_TIMEOUT_ERROR}
        component={SessionTimeoutError}
      />
      <Route exact path={routes.SOFTENER_ERROR} component={SoftenerError} />
      <Route
        exact
        path={routes.ACCOUNT_LOCKED_ERROR}
        component={AccountLockoutError}
      />
      <Route
        exact
        path={routes.POST_REGISTRATION_ERROR}
        component={PostRegistrationError}
      />
      <Route
        exact
        path={routes.ALREADY_REGISTERED}
        component={AlreadyRegisteredError}
      />
      <Route exact path={routes.LOCKED_ACCOUNT} component={LockAccountError} />
      <Route exact path={routes.CPL_ERROR} component={CplError} />
      {voiceRouteTermsLink && (
        <Route
          exact
          path={[routes.ALEXA, routes.GOOGLE]}
          component={VoiceTerms}
        />
      )}
      <Route component={StandardError} />
    </Switch>
  )
}

export default Routes
