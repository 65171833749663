/**
 *  Note: This helper function will only be used to redirect to client urls.
 *  For other redirects, use https://bitbucket.glb.syfbank.com/projects/EUU/repos/syf-js-utilities/browse/src/helpers/redirectToUrl.ts
 *
 */

const redirectToClientUrl = (url: string): void => {
  window.location.href = url
}

export default redirectToClientUrl
