import React, { ReactElement } from 'react'
import styled from 'styled-components'
import SyfLoader from 'syf-component-library/ui/atoms/Loader/SyfLoader'
import Template from 'ui/molecules/Template'

const CenterLoader = styled.div`
  margin: auto;
`

const JwtLoader = (): ReactElement => {
  return (
    <CenterLoader>
      <Template shouldLoadAllScripts>
        <SyfLoader size={4} />
      </Template>
    </CenterLoader>
  )
}

export default JwtLoader
