import { clientInfo } from 'configureBrand'
import routes from 'const/routes'
import handoverToServicing from 'services/nonIdP/handoverToServicing'
import lookupMultiAccount from 'services/nonIdP/lookupMultiAccount'
import selectAccount from 'services/nonIdP/selectAccount'
import { HandoverOptions } from './typings'

const determineWhereToHandover = async ({
  accountType,
  clientId,
  history,
  returnTo = routes.HOME,
  queryParams = '',
  hasJwtToken
}: HandoverOptions): Promise<void> => {
  if (returnTo !== routes.HOME) {
    const { userId } = history.location.state
    return history.push({
      pathname: returnTo,
      search: queryParams,
      state: { userId }
    })
  }

  const triggerHandover = () =>
    handoverToServicing({ accountType, clientId, hasJwtToken })
  if (!clientInfo.isMultiAccount) return triggerHandover()
  const accounts = await lookupMultiAccount()
  if (accounts?.length > 1) {
    return history.push({
      pathname: routes.MULTI_ACCOUNT,
      state: {
        accounts,
        accountType,
        clientId
      }
    })
  }

  if (accounts?.length && accounts[0]?.accountType === 'loan') {
    return selectAccount({
      accountType: accounts[0].accountType,
      syfIdentifier: accounts[0].syfIdentifier,
      hasJwtToken
    })
  }
  return triggerHandover()
}

export default determineWhereToHandover
