/* eslint-disable no-underscore-dangle */

import { createBrowserHistory } from 'history'
import { isBrowser, isMobile, isTablet } from 'react-device-detect'
import { analytics } from 'const/analytics'
import config from 'const/config'
import en from 'locales/en'
import { getCurrentLanguageFromStorage, getParameterByName } from 'helpers'
import { BootstrapperObject, PageEvent } from 'helpers/typings'
import trigger, {
  analyticsConfig
} from 'syf-js-utilities/helpers/triggerAnalyticsEvent'
import { OtpError } from 'const/analytics/otp'
import {
  MessageKeyAnalytics,
  MessageKeyTypes
} from 'const/analytics/messageKey'
import getCorrelationId from './getCorrelationId'

declare global {
  interface Window {
    _SFDDL: {
      pageInfo?: {
        // eslint-disable-next-line camelcase
        Country_Language: string
        SiteIdentifier: string
        Device: string
        PNL: string
        ClientName?: string
        CustomerType: string
        Product: string
        PageKind?: string
        pageName?: string
        PageFunction?: string
        SubFunction?: string
        PageSubFunction?: string
        Token?: string
        MessageType?: string
        RelyingParty: string
      }
    }
    Bootstrapper: BootstrapperObject
  }
}

analyticsConfig.defaults.Country_Language = 'us|en'
analyticsConfig.defaults.PNL = 'rc'
analyticsConfig.defaults.CustomerType = 'consumer'
analyticsConfig.defaults.Product = 'generic'

export const history = createBrowserHistory({
  basename: config.BASENAME
})

const getCustomPageFunctionOrDefault = (
  defaultPageFunction: string
): string => {
  if (defaultPageFunction === 'registration') {
    return getParameterByName('tid')
      ? 'expedited registration'
      : defaultPageFunction
  }
  return defaultPageFunction
}

const determineAnalyticsErrorMessage = (messageKey: string): string => {
  return OtpError[messageKey as keyof typeof OtpError]
}

export const device =
  (isBrowser && analytics.device.DESK) ||
  (isMobile && analytics.device.MOBI) ||
  (isTablet && analytics.device.TAB)

export const siteIdentifier =
  (analytics.pageEvents[history.location.pathname] &&
    analytics.pageEvents[history.location.pathname].SITE_IDENTIFIER) ||
  analytics.defaults.SITE_IDENTIFIER

const pageKind =
  (analytics.pageEvents[history.location.pathname] &&
    analytics.pageEvents[history.location.pathname].PAGE_KIND) ||
  analytics.defaults.PAGE_KIND

const determineErrorMessage = (messageKey: string) => {
  let errorMessage = ''
  switch (typeof en[messageKey]) {
    case MessageKeyTypes.FUNCTION:
      errorMessage = en[messageKey]()
      break
    case MessageKeyTypes.OBJECT:
      errorMessage = en[`${messageKey}${MessageKeyAnalytics.ANALYTICS}`]
      break
    default:
      errorMessage =
        en[messageKey] || determineAnalyticsErrorMessage(messageKey)
  }
  return errorMessage ? `Error: ${errorMessage}` : messageKey
}

const triggerAnalyticsEvent = ({
  pageFunction = analytics.pageEvents[history.location.pathname] &&
    analytics.pageEvents[history.location.pathname].PAGE_FUNCTION,
  pageName = analytics.pageEvents[history.location.pathname] &&
    analytics.pageEvents[history.location.pathname].PAGE_NAME,
  subFunction = analytics.pageEvents[history.location.pathname] &&
    analytics.pageEvents[history.location.pathname].SUB_FUNCTION,
  messageKey = analytics.pageEvents[history.location.pathname]
    ? analytics.pageEvents[history.location.pathname].MESSAGE_KEY
    : '',
  pathName = ''
}: PageEvent): void => {
  trigger({
    pageFunction: getCustomPageFunctionOrDefault(pageFunction),
    token: getCorrelationId(),
    messageType: determineErrorMessage(messageKey), // analytics shouldn't get spanish messaging
    pageName,
    pageSubFunction: subFunction,
    pathName,
    countryLanguage: `us|${getCurrentLanguageFromStorage()}`,
    clientName: config.RC_CLIENT_ID,
    pageKind:
      pathName && analytics.pageEvents[pathName]?.PAGE_KIND
        ? analytics.pageEvents[pathName]?.PAGE_KIND
        : pageKind,
    siteIdentifier,
    device
  })
}

export default triggerAnalyticsEvent
