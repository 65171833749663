import styled from 'styled-components'
import ChatCTA from 'syf-component-library/ui/patterns/ChatCTA'

export const HelpChatPrompt = styled(ChatCTA)`
  margin: 0 auto 28px auto;
  height: 50px;
  width: 275px;
`

export default HelpChatPrompt
