import React, { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getCurrentLanguageFromStorage,
  handleRedirectToErrorScreen,
  translate,
  triggerAnalyticsEvent
} from 'helpers'
import Template from 'ui/molecules/Template'
import Header from 'ui/atoms/Header'
import searchUser from 'services/nonIdP/searchUser'
import { SearchUserInfo } from 'services/typings'
import NeedsVerificationModal from 'ui/organisms/NeedsVerificationModal/NonIdP'
import verify from 'services/nonIdP/verify'
import CreditCardLookupForm from 'ui/organisms/CreditCardLookupForm'
import routes from 'const/routes'
import { showChatCtaIva } from 'redux/iva/actions'
import Body from 'syf-component-library/ui/typography/Body'
import { clientInfo } from 'configureBrand'
import { RootState } from 'redux/store'
import { useHistory, useLocation } from 'react-router-dom'
import { LocationState } from 'types/locationState'
import VerificationEndpoint from 'const/verificationEndPoint'
import { updateIsSubmitting } from 'redux/uiUtils/actions'
import handleMfaMethodAnalyticsEvent from 'helpers/handleMfaMethodAnalyticsEvent'
import HelpChatPrompt from './subcomponents'
import { ValidationModalType } from './typings'

const FindUserID = (): ReactElement => {
  const history = useHistory()
  const location = useLocation<LocationState>()
  const { isLive } = useSelector((reduxState: RootState) => reduxState.iva)
  const { state } = location || {}
  const { returnTo = routes.HOME, queryParams = '' } = state || {}
  const [validationModal, setValidationModal] =
    useState<ValidationModalType>('')
  const [zipCode, setZipCode] = useState<string>('')
  const [dob, setDob] = useState<string>('')
  const [inUsa, setInUsa] = useState<boolean>(true)
  const [error, setError] = useState<{
    key?: string
    argument?: string
    extension?: string
    locked?: boolean
  }>({})
  const [phoneNumbers, setPhoneNumbers] = useState<string[]>([])
  const [sessionId, setSessionId] = useState<string>('')

  const dispatch = useDispatch()

  const onSubmit = async (fields: SearchUserInfo): Promise<void> => {
    try {
      dispatch(updateIsSubmitting(true))
      setZipCode(fields.zipCode)
      setDob(fields.dob)

      const { result = {}, responseData: authData } = await searchUser(
        fields,
        'lookup-userid'
      )

      // Handle all the possible cases coming back from eligibility
      if (authData && result.validation) {
        setPhoneNumbers(authData.phone_numbers)
        setSessionId(authData.session_id)
        setValidationModal(result.validation)
        handleMfaMethodAnalyticsEvent(result.validation)
        dispatch(updateIsSubmitting(false))
      } else if (result.message) {
        triggerAnalyticsEvent({ messageKey: result.message.key })
        setError(result.message)
        dispatch(updateIsSubmitting(false))
      } else {
        const { responseData, result: verifyResult } = await verify(
          {
            sessionId: authData.session_id
          },
          'lookup-userid'
        )

        if (verifyResult.message) {
          setError(verifyResult.message)
          dispatch(updateIsSubmitting(false))
        } else {
          history.push({
            pathname: routes.FOUND,
            state: {
              userId: responseData.user_id,
              zipCode,
              dob,
              returnTo,
              queryParams
            }
          })
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
      const { status } = err.response || {}
      handleRedirectToErrorScreen({
        errorCode: status
      })
      setError({
        key: 'default',
        extension: `${translate({ string: 'code' })}: ${status}`
      })
      dispatch(updateIsSubmitting(false))
    }
  }

  const handleChatCTAClick = () => {
    dispatch(showChatCtaIva())
  }
  const renderChatCTA =
    isLive &&
    clientInfo.ivaKey &&
    returnTo === routes.HOME &&
    getCurrentLanguageFromStorage() === 'en'

  return (
    <Template shouldLoadAllScripts>
      <NeedsVerificationModal
        returnTo={returnTo}
        queryParams={queryParams}
        endpoint={VerificationEndpoint.LOOKUP_USER_ID}
        data-test="needs-verification-modal"
        verificationType={validationModal}
        containerId="userIdModal"
        handleCancel={() => {
          setValidationModal('')
          triggerAnalyticsEvent({ subFunction: '' })
        }}
        authData={{
          phoneNumbers,
          sessionId,
          userId: '',
          dob,
          zipCode
        }}
        handleError={err => {
          if (
            (err.type as string) === 'fraud' ||
            (err.type as string) === 'customer-service'
          ) {
            setValidationModal('')
            setError({})
            dispatch(updateIsSubmitting(false))
          }
        }}
        showModal={Boolean(validationModal)}
      />
      <Header
        data-test="subHeader"
        title={translate({ string: 'findUserId' })}
        description={translate({ string: 'subHeadDescription' })}
      />
      {renderChatCTA && (
        <HelpChatPrompt
          data-type="ivachat-button"
          data-reason="ivachat"
          data-object="button"
          className="ensightencustomevent"
          onClick={handleChatCTAClick}
        >
          <Body>{translate({ string: 'noCardNumber' })}</Body>
          <Body weight="semiBold">
            {translate({ string: 'chatWithSydneyForHelp' })}
          </Body>
        </HelpChatPrompt>
      )}
      <CreditCardLookupForm
        error={error}
        onFocusTextField={() => setError({})}
        onSubmit={onSubmit}
        inUsa={inUsa}
        data-test="credit-card-lookup-form"
        setInUsa={setInUsa}
        returnTo={returnTo + queryParams}
      />
    </Template>
  )
}

export default FindUserID
