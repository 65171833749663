import { PsfMessageAction, PsfMessageState } from './typings'

export const initialState: PsfMessageState = {
  dual: null,
  standard: null,
  hideEnrollment: false
}

export default function (
  state: PsfMessageState = initialState,
  action: PsfMessageAction = undefined
): PsfMessageState {
  switch (action.type) {
    case 'UPDATE_PSF_MESSAGE':
      return { ...state, ...action.payload }
    case 'UPDATE_HIDE_ENROLLMENT':
      return { ...state, hideEnrollment: action.payload }
    default:
      return state
  }
}
