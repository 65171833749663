import { AxiosResponse } from 'axios'
import apiEndpoints from 'const/apiEndpoints'
import apiInstance, { generateTransId } from 'services'
import {
  GetAccountUtilityFeatureOptions,
  GetAccountUtilityFeatureResponse
} from './typings'

const getAccountUtilityFeature = async ({
  correlationId = generateTransId(),
  accessToken,
  cipherAccountId
}: GetAccountUtilityFeatureOptions): Promise<
  AxiosResponse<GetAccountUtilityFeatureResponse>
> => {
  const reqHeaders = {
    headers: {
      'X-SYF-Request-CorrelationId': correlationId,
      'X-SYF-Request-TrackingId': generateTransId(),
      Authorization: `Bearer ${accessToken}`
    }
  }
  const accountUtilityFeatureEndpoint = `${apiEndpoints.utility.UTILITY_ACCOUNT_FEATURES}?cipher.accountId=${cipherAccountId}&accountIdType=guid`
  return apiInstance.get(accountUtilityFeatureEndpoint, reqHeaders)
}

export default getAccountUtilityFeature
